import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import _ from "lodash";

import {
  Spinner, SubHeader, Card,
} from "components";
import { getHelpDetails } from "api/services/settings";

import "./Help.scss";

function HelpPage() {
  const { helpId } = useParams();

  const { isPending, data } = useQuery({
    queryKey: ["help-content", helpId],
    queryFn: () => getHelpDetails(helpId),
    staleTime: Infinity,
  });

  const safeHtml = (html) => parse(DOMPurify.sanitize(html));

  return (
    <Container>
      <div className="settingsPage help-page">
        <SubHeader
          title={_.unescape(data?.name)}
        />
        {isPending ? (
          <Spinner />
        ) : data && (
        <Card customClass="help-page__card">
          <div className="html-content">
            {safeHtml(data?.content)}
          </div>
        </Card>
        )}
      </div>
    </Container>
  );
}

export default HelpPage;
