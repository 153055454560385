import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";

import { SubNav } from "components";

import { clearLiveSessionData, setActiveStream } from "store/actions/session";

import SignalCheck from "pages/App/Common/SignalCheck/SignalCheck";
import Calibration from "pages/App/Common/ProtocolCalibration/ProtocolCalibration";

function ProtocolCalibration() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [stage, setStage] = useState("signal");

  useEffect(() => {
    if (stage === "calibration") dispatch(setActiveStream("status"));
    return () => dispatch(setActiveStream(null));
  }, [stage]);

  useEffect(() => () => {
    dispatch(clearLiveSessionData());
  }, []);

  return (
    <Container>
      {stage === "signal" && (
        <>
          <SubNav title="Protocol Calibration" onBack={() => navigate(-1)} staticTitle noSensors />
          <SignalCheck
            onComplete={() => setStage("calibration")}
            onCancel={() => navigate(-1)}
          />
        </>
      )}
      {stage === "calibration" && (
        <>
          <SubNav title="Protocol Calibration" onBack={() => navigate(-1)} staticTitle audioGuide="coach-protocol-calibration" />
          <Calibration
            onComplete={() => navigate("/app/meditation/protocol-calibrated")}
            onCancel={() => navigate(-1)}
            onError={() => setStage("signal")}
          />
        </>
      )}
    </Container>
  );
}

export default ProtocolCalibration;
