import { useMemo } from "react";
import { ResponsiveBar } from "@nivo/bar";
import _ from "lodash";

export function BarChart({
  chartData, keys, hiddenFrq,
}) {
  const filteredData = useMemo(() => {
    if (chartData) {
      if (hiddenFrq) return chartData.filter((serie) => hiddenFrq[serie.frq]);
      return chartData;
    }
    return null;
  }, [chartData, hiddenFrq]);

  const getLabel = (data) => {
    switch (data.id) {
      case "leftValue":
        return "L";
      case "rightValue":
        return "R";
      case "frontValue":
        return "F";
      case "backValue":
        return "B";
      default:
        return null;
    }
  };

  const getTooltip = (data) => {
    const formatLabel = (label) => {
      let formattedLabel = label;
      formattedLabel = formattedLabel.replace("value -", "whole ");
      formattedLabel = formattedLabel.replace("frontValue -", "front ");
      formattedLabel = formattedLabel.replace("backValue -", "back ");
      formattedLabel = formattedLabel.replace("leftValue -", "left ");
      formattedLabel = formattedLabel.replace("rightValue -", "right ");
      return formattedLabel;
    };
    const getPosition = () => {
      if (chartData.length > 1) {
        if (data.index === 0) return "is-left";
        if (data.index === chartData.length - 1) return "is-right";
      }
      return "";
    };
    return (
      <div className={`custom-tooltip ${getPosition()}`}>
        <div className="custom-tooltip__color" style={{ background: data.color }} />
        <span>{formatLabel(data.label)}</span>
        <strong>{data.formattedValue}</strong>
      </div>
    );
  };

  return filteredData && (
    <ResponsiveBar
      theme={{
        background: "transparent",
        text: {
          fontSize: 11,
          fontFamily: "Quicksand",
          fill: "#171d66",
        },
        tooltip: {
          container: {
            background: "#ffffff",
            color: "#171d66",
            fontSize: 11,
          },
        },
      }}
      data={filteredData}
      keys={keys}
      indexBy="frq"
      margin={{
        top: 15, right: 10, bottom: 50, left: 30,
      }}
      maxValue={3}
      minValue={-3}
      padding={0.3}
      innerPadding={5}
      borderRadius={4}
      borderWidth={5}
      borderColor={{
        from: "color",
        modifiers: [
          ["opacity", "0"],
        ],
      }}
      groupMode="grouped"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colorBy="indexValue"
      colors={filteredData.map((data) => data.color)}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        format: (label) => _.capitalize(label),
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: 6,
      }}
      gridYValues={6}
      label={(data) => getLabel(data)}
      labelTextColor="white"
      labelSkipHeight={14}
      enableLabel
      tooltip={(data) => getTooltip(data)}
      role="application"
    />
  );
}

export default BarChart;
