import { Container } from "react-bootstrap";

import Techniques from "pages/App/Common/Techniques/Techniques";
import { SubHeader } from "components";

function TechniquesPage() {
  return (
    <Container>
      <div className="settingsPage">
        <SubHeader
          title="Techniques"
          text="Change information about your techniques practice."
          audioGuide="coach-techniques"
        />
        <Techniques saveBtnText="Save" />
      </div>
    </Container>
  );
}

export default TechniquesPage;
