import { useState, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Modal } from "react-bootstrap";
import _ from "lodash";

import {
  Card, GenericButton, ProtocolDetails, InfoProgress, Dialog, EmptyData, Spinner,
} from "components";
import Scrollbar from "react-perfect-scrollbar-z";

import { PROTOCOLS_ICONS } from "constants/onBoarding_protocols";
import { ReactComponent as SunIcon } from "assets/ic_protocols/ic_sun.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle, faMinus, faPlus, faMagnifyingGlass, faUsersSlash,
} from "@fortawesome/free-solid-svg-icons";

import { certifyProtocol, getProtocolUniqueContributors } from "api/services/protocols";

import { LEVEL_CARDS_COLORS } from "constants/onBoarding_level";

import "./ProtocolTable.scss";

function CertificationTable({ protocol, certifications }) {
  const queryClient = useQueryClient();
  const refScroll = useRef(null);

  const [collapsed, setCollapsed] = useState(false);
  const [showProtocolDetails, setShowProtocolDetails] = useState(false);
  const [uniqueUsersModal, setUniqueUsersModal] = useState({ show: false, id: null });
  const [protocolLvlData, setProtocolLvlData] = useState(null);

  const { data: uniqueUsers, isLoading } = useQuery({
    queryKey: ["unique-users", uniqueUsersModal.id],
    queryFn: () => getProtocolUniqueContributors(uniqueUsersModal.id),
    enabled: uniqueUsersModal.show,
  });

  const getProtocolIcon = (iconName) => PROTOCOLS_ICONS.find(({ name }) => name === iconName)?.getIcon() || <SunIcon className="protocol_icon" />;

  const certifyProtocolLvl = useMutation({
    mutationFn: ({ protocolLevelID, payload }) => certifyProtocol(protocolLevelID, payload),
    onSuccess: (_data, request) => {
      const updatedCertificationList = (list, protocolLevelID) => {
        const newList = [...list];
        const index = list.findIndex((item) => item.protocolLevelID === protocolLevelID);
        newList[index].certified = !newList[index].certified;
        return newList;
      };
      queryClient.setQueryData(
        ["certifications"],
        (staleList) => updatedCertificationList(staleList, request.protocolLevelID),
      );
      setProtocolLvlData(null);
    },
  });

  const onCertify = () => {
    const { protocolLevelID, certified } = protocolLvlData;
    certifyProtocolLvl.mutate({ protocolLevelID, payload: { certified: !certified } });
  };

  return (
    <>
      <Card customClass="protocols-table">
        <div key={protocol.protocolID}>
          <div className="protocols-table__header">
            <div className="protocols-table__protocol">
              <div className="protocols-table__protocol-icon" style={{ fill: protocol.color }}>{getProtocolIcon(protocol.icon)}</div>
              <h3 style={{ color: protocol.color }}>{protocol.protocolName}</h3>
              <button
                type="button"
                className="protocols-table__protocol-details"
                onClick={() => setShowProtocolDetails(true)}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </button>
            </div>
            <GenericButton
              text={`${collapsed ? "Hide" : "Show"} Certifications`}
              className="button--default btn-xs protocols-table__collapse"
              iconSide="right"
              iconElement={<FontAwesomeIcon icon={collapsed ? faMinus : faPlus} />}
              onButtonClick={() => setCollapsed((state) => !state)}
            />
          </div>
          {collapsed && (
            <table className="protocols-table__table table">
              <thead>
                <tr>
                  <th>Level</th>
                  <th>Contributions</th>
                  <th>Unique Users</th>
                  <th aria-label="actions" />
                </tr>
              </thead>
              <tbody>
                {certifications.map((item) => (
                  <tr key={item.level}>
                    <td>
                      <span
                        className="text-white ms-2"
                        style={{ backgroundColor: LEVEL_CARDS_COLORS[item.level - 1], width: 22 }}
                      >
                        {item.level}
                      </span>
                    </td>
                    <td>
                      <div className={`progress ${item.contributions >= 200 ? "fulfilled" : ""}`}>
                        <span>{`${item.contributions}/200`}</span>
                        <InfoProgress current={item.contributions} max={200} />
                      </div>
                    </td>
                    <td>
                      <div className="users ms-3">
                        {item.uniqueUsers}
                        <span
                          role="presentation"
                          className="btn-xs cursor-pointer"
                          onClick={() => setUniqueUsersModal(
                            { show: true, id: item.protocolLevelID },
                          )}
                        >
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </span>
                      </div>
                    </td>
                    <td>
                      {item.certified
                        && <strong className="me-3 text-xs text-primary">Certified</strong>}
                      <GenericButton
                        text={item.certified ? "Revoke" : "Certify"}
                        className={`btn-xs me-3 button--${item.certified ? "danger" : "primary"}`}
                        onButtonClick={() => setProtocolLvlData(item)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </Card>

      <Modal
        animation
        dialogClassName="dialog"
        show={showProtocolDetails}
        onHide={() => setShowProtocolDetails(false)}
      >
        <Modal.Header closeButton>
          <h2 className="protocolDetails__protocol-title m-0" style={{ color: protocol.color }}>
            {_.unescape(protocol.protocolName)}
          </h2>
        </Modal.Header>
        <Modal.Body>
          <ProtocolDetails protocol={protocol} />
        </Modal.Body>
      </Modal>

      <Modal
        animation
        dialogClassName="dialog"
        show={uniqueUsersModal.show}
        onHide={() => setUniqueUsersModal({ show: false, id: null })}
      >
        <Modal.Header closeButton>
          <h2 className="m-0">Unique Users</h2>
        </Modal.Header>
        <Modal.Body>
          {isLoading || !uniqueUsers ? <Spinner className="my-4" />
            : (
              <Scrollbar refScroll={refScroll} always maxHeight="80vh">
                {uniqueUsers?.length
                  ? (
                    <table className="protocols-table__table table mt-0">
                      <thead>
                        <tr>
                          <th className="text-start ps-4">Name</th>
                          <th className="text-start ps-4">Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {uniqueUsers?.map((user) => (
                          <tr key={user.email}>
                            <td className="text-start ps-4">{user.name}</td>
                            <td className="text-start ps-4">{user.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )
                  : (
                    <EmptyData
                      title="No users found"
                      text="There are no contributors yet."
                      icon={faUsersSlash}
                    />
                  )}
              </Scrollbar>
            )}
        </Modal.Body>
      </Modal>
      <Dialog
        show={!!protocolLvlData}
        title="Manage certification"
        onSuccess={onCertify}
        onCancel={() => setProtocolLvlData(null)}
      >
        <p className={protocolLvlData?.certified ? "text-danger" : ""}>
          {`Are you sure you want to ${protocolLvlData?.certified
            ? "revoke this protocol certification"
            : "certify this protocol"}?`}
        </p>
      </Dialog>
    </>
  );
}

export default CertificationTable;
