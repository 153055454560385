import { Col, Row } from "react-bootstrap";

import { SelectTile, DatePickerForm } from "components";

function BrainwavesSelect({
  title, name, size, choices, formData, setFormValue,
}) {
  const getSize = (i) => (typeof size === "number" ? size : size(i));
  const maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 10));

  return (
    <div className="brainwavesPage__section">
      <p className="text-muted mt-3">
        {title}
      </p>
      <Row>
        {name === "birthday" ? (
          <Col xs={size}>
            <div>
              <DatePickerForm
                selectedDate={formData[name]}
                onChange={(date) => setFormValue(date, [name])}
                maxDate={maxDate}
              />
            </div>
          </Col>
        ) : choices?.[name]?.map(({ key, value }, i) => (
          <Col key={value} xs={getSize(i)}>
            <div className="brainwavesPage__select-wrapper">
              <SelectTile
                customClass={`${formData[name] === value ? "selectTile--active" : ""}`}
                onSelect={() => setFormValue(value, name)}
              >
                {key}
              </SelectTile>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default BrainwavesSelect;
