import { formatDuration } from "utils/time-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Close } from "assets/icons_close.svg";

import "./TimeRange.scss";

export function TimeRange({ timeline, setTimeline }) {
  return (
    <div className="sessionChart__timerange">
      <FontAwesomeIcon
        className="icon"
        icon={faClock}
      />
      {timeline?.start !== null && timeline?.end ? (
        <div className="sessionChart__timerange-content">
          <span>
            <strong>{formatDuration(timeline.start * 1000)}</strong>
            {timeline.end && (
              <>
                {" "}
                -
                {" "}
                <strong>{formatDuration(timeline.end * 1000)}</strong>
              </>
            )}
          </span>
          <button
            type="button"
            className="sessionChart__timerange-clear"
            onClick={() => setTimeline({ start: null, end: null })}
          >
            <Close />
          </button>
        </div>
      ) : (
        <div className="sessionChart__timerange-content">
          <strong>Full Session</strong>
        </div>
      )}
    </div>
  );
}

export default TimeRange;
