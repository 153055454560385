import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Loader, ProtocolSelectContent, Spinner } from "components";
import CalibrationSession from "features/CalibrationSession/CalibrationSession";
import useProtocolCalibration from "hooks/useProtocolCalibration";

import { getLiveSessionData } from "store/actions/session";
import { saveUserProfile } from "store/actions/profile";
import { updateCalibratedProtocol } from "store/actions/protocols";
import { changeGraphProtocol } from "store/actions/reporting";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { saveCalibration, updateProtocolCertificationQuery } from "api/services/protocols";

function ProtocolCalibration({ onComplete, onCancel, onError }) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const loggedInUserId = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.pk,
  );

  const { protocolCalibration } = useSelector(
    ({ protocolsReducer }) => protocolsReducer,
  );
  const ruleData = useMemo(() => protocolCalibration.protocol?.protocolRules?.find(
    (rule) => rule?.rule === protocolCalibration.rule,
  ), [protocolCalibration]);

  const protocolIds = useMemo(() => {
    const protocolDetails = protocolCalibration.protocol;
    const { categoryID, protocolID } = protocolDetails?.protocolIds || protocolDetails || {};
    return {
      categoryID,
      protocolID,
      levelID: protocolCalibration.level.levelID,
      family: protocolCalibration.level.family.id,
      rule: protocolCalibration.rule || null,
    };
  }, [protocolCalibration]);

  const [sessionStatus, setSessionStatus] = useState("loading");

  const protocolReady = useProtocolCalibration(loggedInUserId, protocolCalibration, sessionStatus === "completed");

  const saveCalibrationData = useMutation({
    mutationFn: (payload) => saveCalibration(payload),
  });

  useEffect(() => {
    if (protocolReady === true) {
      setSessionStatus("ready");
    }
    if (protocolReady === false) {
      toast.error("Calibration preparation failed.");
      onCancel?.();
    }
  }, [protocolReady]);

  const startStream = () => {
    const protocolId = protocolCalibration?.level.levelID;
    dispatch(getLiveSessionData(loggedInUserId, protocolId));
  };

  const sessionCompleted = async () => {
    const calibration = await dispatch(
      updateCalibratedProtocol(loggedInUserId, protocolIds.levelID),
    );
    if (calibration) {
      dispatch(changeGraphProtocol({
        categoryID: protocolIds.categoryID,
        level: protocolCalibration.level.level,
        levelID: protocolIds.levelID,
        protocol: protocolCalibration.protocol.protocolName,
        protocolID: protocolIds.protocolID,
      }));
      const { thresholds } = calibration;
      await saveCalibrationData.mutateAsync({
        baselineFile: thresholds.baselineFile,
        protocolName: calibration.name,
        level: thresholds.level,
        family: protocolCalibration.level.family,
        protocolIds,
        protocolRules: protocolCalibration.protocol.protocolRules,
        mean: thresholds.mean,
        SD: thresholds.SD,
        weights: thresholds.weights,
        thresholds: thresholds.thresholds,
        thresholdingFile: thresholds.thresholdingFile,
        thresholdingFileAverages: thresholds.thresholdingFileAverages,
      });
      await updateProtocolCertificationQuery(
        protocolIds.levelID,
        protocolIds.family,
        protocolIds.rule,
        queryClient,
      );
      await dispatch(saveUserProfile({
        selected_protocol: protocolIds,
        selected_protocol_name: protocolCalibration.protocol.protocolName,
        selected_protocol_level: protocolCalibration.level.level,
        saved_session_difficulty: -Number(thresholds?.thresholds[0]),
      }));
      onComplete();
    } else {
      toast.error("Protocol calibration failed.");
      onCancel?.();
    }
  };

  return (
    <CalibrationSession
      onComplete={sessionCompleted}
      onCancel={onCancel}
      onError={onError}
      sessionStatus={[sessionStatus, setSessionStatus]}
      sessionStartedText="Calibrating"
      startStream={startStream}
      initialTime={60}
      minSequences={50}
    >
      <div className="calibrationSession__protocol">
        <ProtocolSelectContent
          protocol={protocolCalibration.protocol}
          level={protocolCalibration.level.level}
          rule={ruleData?.descriptions?.name || ruleData?.title}
          size="md"
          centered
        />
      </div>
      <h2>Calibrating your Meditation Protocol</h2>
      {["ready", "started"].includes(sessionStatus) && (
        <div className="calibrationSession__text">
          <p>
            Press the
            {" "}
            <strong>Start button</strong>
            {" "}
            when you are ready to start calibrating your meditation protocol.
            If you prefer, you can do a few minutes of warm-up before pressing the
            {" "}
            <strong>Start button</strong>
            .
          </p>
          <p>
            We will play the sound of crickets and ring a bell when one minute
            is up.
          </p>
        </div>
      )}
      {["loading", "completed"].includes(sessionStatus) && (
        <div className="calibrationSession__loading">
          <div className="loader-wrapper">
            {sessionStatus === "loading" ? <Spinner /> : <Loader />}
          </div>
          <h3>
            {sessionStatus === "loading"
              ? "Preparing Protocol for Calibration"
              : "Saving Protocol Calibration"}
          </h3>
          <p className="text-muted">Please wait for a few seconds...</p>
        </div>
      )}
    </CalibrationSession>
  );
}

export default ProtocolCalibration;
