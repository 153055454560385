import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import {
  Route, Navigate, useLocation, useNavigationType, matchRoutes,
  createRoutesFromChildren, createBrowserRouter, RouterProvider, createRoutesFromElements,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";

import "styles/index.scss";

import store from "store";
import App from "routers/App/App";
import Coach from "routers/Coach/Coach";
import { RouteManager, MobileRoute } from "components";
import reportWebVitals from "reportWebVitals";

import LogsContainter from "features/LogsContainter/LogsContainter";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  environment: process.env.REACT_APP_SENTRY_ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration(
      {
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      },
    ),
  ],
  tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter(createRoutesFromElements(
  <Route element={<RouteManager />}>
    <Route index element={<Navigate to="/coach" />} exact />
    <Route path="coach/*" element={<Coach />} />
    <Route element={<MobileRoute />}>
      <Route path="app/*" element={<App />} />
    </Route>
  </Route>,
));

const logsEnabled = process.env.REACT_APP_CONSOLE_FEED === "true";

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ToastContainer closeOnClick />
      {logsEnabled && <LogsContainter />}
      <RouterProvider router={router} />
    </QueryClientProvider>
  </Provider>,

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
