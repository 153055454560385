import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import {
  GenericButton, LoadingOverlay,
} from "components";
import googleIcon from "assets/icons_google.svg";
import appleIcon from "assets/icons_apple.svg";
// import fbIcon from "assets/icons_facebook.svg";

import {
  socialLoginUser,
  loginUserStart,
  loginUserError,
} from "store/actions/authentication";
import { socialLoginController } from "utils/native-controllers";

export function SocialButtons() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isUserLogging = useSelector(
    ({ authenticationReducer }) => authenticationReducer.isUserLogging,
  );

  const isMobileApp = !!window.flutter_inappwebview || !!window.ReactNativeWebView;
  const isIOS = (/iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor)
  && !window.MSStream) && isMobileApp;

  window.socialLogin = ({ payload, social }) => {
    if (payload) {
      dispatch(socialLoginUser(navigate, social, payload));
    } else {
      dispatch(loginUserError());
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const payload = { access_token: tokenResponse?.access_token };
      dispatch(
        socialLoginUser(navigate, "google", payload),
      );
    },
  });

  // const fbLogin = (socialCredentials) => {
  //   dispatch(
  //     socialLoginUser(navigate, { ...socialCredentials, backend: "facebook" }),
  //   );
  // };

  const socialClick = (backend, onClick) => {
    if (isMobileApp) {
      // embedded social login is not allowed
      // we need to trigger native code in this case
      dispatch(loginUserStart());
      socialLoginController(backend);
    } else {
      onClick();
    }
  };

  const renderBtnGoogle = () => (
    <GenericButton
      text="Sign In with Google"
      className="button-login--social"
      iconElement={<img src={googleIcon} width="30" alt="Google icon" />}
      onButtonClick={() => socialClick("google", () => googleLogin())}
    />
  );
  // const BtnFacebook = ({ props }) => (
  //   <GenericButton
  //     text="Sign In with Facebook"
  //     className="button-login--social"
  //     iconElement={<img src={fbIcon} width="30" alt="Facebook icon" />}
  //     onButtonClick={() => socialClick("facebook", props?.onClick)}
  //   />
  // );

  return (
    <>
      {isUserLogging && <LoadingOverlay isTop />}
      <div className="login__social-login-container">
        {renderBtnGoogle()}
        {isIOS && (
        <GenericButton
          text="Sign In with Apple"
          className="button-login--social button-login--social-apple"
          iconElement={<img src={appleIcon} width="30" alt="Apple icon" />}
          onButtonClick={() => socialClick("apple")}
        />
        )}
        {/* {isFlutter ? (
          <>
            <BtnFacebook />
          </>
        ) : (
          <>
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              callback={() => fbLogin()}
              render={(props) => (
                <BtnFacebook props={props} />
              )}
            />
          </>
        )} */}
      </div>
    </>
  );
}

export default SocialButtons;
