import { Route, Routes } from "react-router-dom";

import MeditationTraining from "pages/App/Meditation/MeditationTraining/MeditationTraining";
import SessionLength from "pages/App/Meditation/SessionLength/SessionLength";
import SessionSound from "pages/App/Meditation/SessionSound/SessionSound";
import SessionSignal from "pages/App/Meditation/SessionSignal/SessionSignal";
import LiveSession from "pages/App/Meditation/LiveSession/LiveSession";
import LiveSessionSound from "pages/App/Meditation/LiveSessionSound/LiveSessionSound";
import LiveSessionReport from "pages/App/Meditation/LiveSessionReport/LiveSessionReport";
import ProtocolCalibration from "pages/App/Meditation/ProtocolCalibration/ProtocolCalibration";
import ProtocolDiagnostic from "pages/App/Meditation/ProtocolDiagnostic/ProtocolDiagnostic";
import ProtocolDetails from "pages/App/Meditation/SessionProtocol/ProtocolDetails/ProtocolDetails";
import ProtocolLevel from "pages/App/Meditation/SessionProtocol/ProtocolLevel/ProtocolLevel";
import ProtocolCalibrated from "pages/App/Meditation/ProtocolCalibrated/ProtocolCalibrated";
import ProtocolList from "pages/App/Meditation/ProtocolList/ProtocolList";
import ProtocolTrain from "pages/App/Meditation/SessionProtocol/ProtocolTrain/ProtocolTrain";
import CongratulationPage from "pages/App/Meditation/Congratulation/Congratulation";

function Meditation() {
  return (
    <Routes>
      <Route index element={<MeditationTraining />} exact />
      <Route path="protocol-calibrated" element={(<ProtocolCalibrated />)} />
      <Route path="protocol" element={(<ProtocolList />)} />
      <Route path="protocol-calibration" element={<ProtocolCalibration />} />
      <Route path="protocol-diagnostic" element={<ProtocolDiagnostic />} />
      <Route path="protocol-details/:categoryID/:protocolID" element={<ProtocolDetails />} />
      <Route path="protocol-level/:categoryID/:protocolID" element={<ProtocolLevel />} />
      <Route path="protocol-train/:categoryID/:band" element={<ProtocolTrain />} />
      <Route path="length" element={<SessionLength />} />
      <Route path="sound" element={<SessionSound />} />
      <Route path="signal" element={<SessionSignal />} />
      <Route path="live-session" element={<LiveSession />} />
      <Route path="live-sound" element={<LiveSessionSound />} />
      <Route path="pause-report" element={<LiveSessionReport />} />
      <Route path="congratulation" element={<CongratulationPage />} />
    </Routes>
  );
}

export default Meditation;
