import { DateRange as RDateRange } from "@iroomit/react-date-range";
import { GenericButton } from "components/ui/Button/Button";
import "./DateRange.scss";

export function DateRange({
  onChange,
  ranges,
  onClose,
}) {
  const selectionRange = [{
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    color: "#0574bc",
    showDateDisplay: false,
  }];

  return (
    <>
      <RDateRange
        className={ranges[0]?.startDate ? "rangeActive" : "rangeDisactive"}
        editableDateInputs={false}
        showDateDisplay={false}
        direction="vertical"
        showPreview={false}
        showSelectionPreview={false}
        showMonthAndYearPickers
        onChange={onChange}
        ranges={ranges[0]?.startDate ? [{ ...ranges[0], color: "#0574bc" }] : selectionRange}
        months={1}
        maxDate={new Date()}
      />
      <GenericButton text="Select" className="button--dark" onButtonClick={onClose} />
    </>
  );
}

export default DateRange;
