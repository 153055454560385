import { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, keepPreviousData } from "@tanstack/react-query";

import { Card } from "components";
import AudioGuide from "features/AudioGuide/AudioGuide";
import TimeChart from "features/ProgressGraphs/TimeChart/TimeChart";
import ChartStatus from "features/ProgressGraphs/ChartStatus";
import ChartHelp from "features/ProgressGraphs/ChartHelp";
import LifetimeChart from "features/ProgressGraphs/LifetimeChart/LifetimeChart";

import { getGoal, getTimeProgress } from "api/services/reporting";

function ProgressGoal() {
  const userId = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.pk,
  );
  const { period } = useSelector(({ reportingReducer }) => reportingReducer.graphStatus);
  const weeklyGoal = useSelector(
    ({ profileReducer }) => profileReducer.userProfile?.goal_time,
  );

  const [hiddenElement, setHiddenElement] = useState(false);

  const lifetimeData = useQuery({
    queryKey: ["lifetime-progress", userId],
    queryFn: () => getGoal(userId),
    staleTime: Infinity,
  });

  const timeData = useQuery({
    queryKey: ["time-progress", userId, period],
    queryFn: () => getTimeProgress(userId, period.toLowerCase(), 20),
    placeholderData: keepPreviousData,
    staleTime: Infinity,
  });

  return (
    <>
      <div className="header">
        <h3 style={{ opacity: hiddenElement ? 0 : 1 }}>Practice Time</h3>
        <AudioGuide track="coach-progress" setHiddenElement={setHiddenElement} detached />
      </div>
      <div className="progress-session-chart mb-4">
        <Card customClass="progress-session-chart__wrapper">
          <div className="progress-session-chart__wrapper-container">
            <TimeChart data={timeData} />
          </div>
          <div className="progress-session-chart__footer">
            <ChartStatus />
          </div>
          <ChartHelp
            text="Are you Hitting your Practice Goals?"
            help="progress-time"
          />
        </Card>
      </div>
      <div className="progress-session-chart">
        <LifetimeChart data={lifetimeData} goalData={weeklyGoal} />
      </div>
    </>
  );
}

export default ProgressGoal;
