import { useState } from "react";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

function CsvExportButton({ tableData, className }) {
  const [data, setData] = useState([]);

  const getCsvData = (table) => {
    const headers = table.getVisibleLeafColumns()
      .filter((item) => item.parent?.id !== "sticky" && item.parent?.id !== undefined)
      .map((item) => item.columnDef.title);

    const rows = table.getGroupedSelectedRowModel().rows
      .map((row) => row.getVisibleCells()
        .filter((item) => item.column.parent?.id !== "sticky" && item.getValue(item.column.id) !== undefined)
        .map((item) => item.getValue(item.column.id)));

    return rows.length > 0 ? [headers, ...rows] : null;
  };

  const csvSettings = {
    data,
    separator: ";",
    enclosingCharacter: "",
    filename: `report_${Date.now()}.csv`,
    target: "_blank",
    className,
    onClick: () => {
      if (!getCsvData(tableData)) {
        toast.warn("Select at least one row.");
        return false;
      }
      setData(getCsvData(tableData));
      return true;
    },
  };

  return <CSVLink {...csvSettings}>Export to CSV</CSVLink>;
}

export default CsvExportButton;
