import { format } from "date-fns";
import { InfoSection } from "components";
import pluralize from "pluralize";

import CERTIFICATION_REQ from "constants/certification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Star } from "assets/icons_promo_star.svg";

function CertificationInfo({ protocolCertification }) {
  const { global, user } = protocolCertification;

  const renderDot = (value, index) => {
    const content = {
      success: { icon: <FontAwesomeIcon icon={faCheck} />, class: "success" },
      empty: { icon: null, class: "empty" },
    };
    return (
      <div key={index.toString()} className={`dot is-${content[value].class}`}>
        {content[value].icon}
      </div>
    );
  };

  const isReset = user?.counter === 0 && user?.afterReset && user?.completeDate;

  if (global?.certified) return null;

  if (isReset) {
    return (
      <InfoSection icon={<Star />} type="warning">
        <p className="font-700 mb-0">
          The counter was reset because you did not reach 10 sessions by
          {" "}
          {format(new Date(user.completeDate), "MMM do")}
          !
        </p>
        <p className="mb-1">Start contributing again to earn free month.</p>
        <p className="text-muted text-xs">
          Your access will remain free next month if you contribute enough
          sessions to advance meditation science and help meditators go deeper, faster.
        </p>
      </InfoSection>
    );
  }

  return user && (
    <InfoSection icon={<Star />} customClass="mt-0 mb-0" shine>
      <>
        {user.counter === 0 && (
        <p className="font-700 mb-1">Contribute 10 sessions to get a free month!</p>
        )}
        {user.counter === 10 && (
          <>
            <p className="font-700 mb-0">You earned a free month!</p>
            <p className="mb-1">Start contributing again to get another one.</p>
          </>
        )}
        {user.counter > 0 && user.counter < 10 && (
          <p className="font-700 mb-1">
            Donate
            {" "}
            {CERTIFICATION_REQ.contributionsNeeded - user.counter}
            {" "}
            More
            {" "}
            {pluralize("Session", CERTIFICATION_REQ.contributionsNeeded - user.counter)}
            {" "}
            by
            {" "}
            {format(new Date(user.completeDate), "MMM do")}
          </p>
        )}
        <p className="text-muted text-xs">
          Your access will remain free next month if you contribute enough
          sessions to advance meditation science and help meditators go deeper, faster.
        </p>
        {user.counter > 0 && user.counter < 10 && (
          <div className="sessionComplete__counter-wrapper">
            <div className="sessionComplete__counter-dots">
              {[...Array(CERTIFICATION_REQ.contributionsNeeded)]
                .map((_value, index) => renderDot((index + 1 <= user.counter ? "success" : "empty"), index))}
            </div>
            <p className="text-sm text-dark ms-2">
              {user.counter}
              /
              {CERTIFICATION_REQ.contributionsNeeded}
            </p>
          </div>
        )}
      </>
    </InfoSection>
  );
}

export default CertificationInfo;
