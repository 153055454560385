import _ from "lodash";
import { format } from "date-fns";

const formatDuration = (duration, withLabel, zeroMinuteSkip) => {
  const time = new Date(0, 0, 0);
  time.setSeconds(duration / 1000);
  const hours = time.getHours();
  let minutes = time.getMinutes();
  let seconds = time.getSeconds();
  if (hours > 0 && minutes < 10) { minutes = `0${minutes}`; }
  if (seconds < 10) { seconds = `0${seconds}`; }
  if (hours === 0) return `${zeroMinuteSkip && !minutes ? "" : minutes}:${seconds}${withLabel ? " min" : ""}`;
  return `${hours}:${minutes}:${seconds}${withLabel ? " h" : ""}`;
};

const generateNumberArray = (from, to, step, isFormat = true) => {
  const range = _.range(from, to, step);
  if (isFormat) return range.map((i) => (i < 10 ? `0${i}` : `${i}`));
  return range;
};

const formatChartDate = (date) => {
  const dateArray = date.split("/");
  if (dateArray.length === 3) {
    const newDate = new Date(dateArray[2], dateArray[0] - 1, dateArray[1]);
    return format(newDate, "yyyy-MM-dd");
  }
  const newDate = new Date(`01 ${date}`);
  return format(newDate, "yyyy-MM-dd");
};

export { formatDuration, generateNumberArray, formatChartDate };
