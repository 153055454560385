import { Card } from "components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

import { formatDuration } from "utils/time-format";

import "./SessionProgressScores.scss";

export function SessionProgressScores({ report, noDiff }) {
  const findDifference = (previous, current) => (
    previous ? Math.round((Math.abs(current) - Math.abs(previous))) : null
  );

  const {
    prevDepthAvg, prevDepthMax, prevLongestRewardsStreak, prevZoneRewards,
  } = report?.compareAvg || {};

  const scores = [
    {
      key: "avg_depth",
      name: "Average Depth",
      value: report.depthAvg || 0,
      difference: findDifference(prevDepthAvg, report.depthAvg),
      format: (value) => Math.abs(value).toFixed(),
    },
    {
      key: "threshold_time",
      name: "Time Above Threshold",
      value: report.zonePercentage.rewards || 0,
      difference: findDifference(prevZoneRewards, report.zonePercentage.rewards),
      format: (value) => `${Math.abs(value).toFixed()}%`,
    },
    {
      key: "reward_streak",
      name: "Longest Reward Streak",
      value: report.longestRewardsStreak || 0,
      difference:
        findDifference(prevLongestRewardsStreak, report.longestRewardsStreak),
      format: (value) => formatDuration(Math.abs(value) * 1000, false, true),
    },
    {
      key: "max_depth",
      name: "Max Depth Score",
      value: report.depthMax || 0,
      difference: findDifference(prevDepthMax, report.depthMax),
      format: (value) => Math.abs(value).toFixed(),
    },
  ];

  return (
    <Card customClass="sessionProgressScores">
      <div className="sessionProgressScores__wrapper">
        {scores.map((score) => (
          <div className={`sessionProgressScores__item ${score.key}`} key={score.key}>
            <div className={`sessionProgressScores__item-score ${score.key}`}>
              {score.format(score.value)}
            </div>
            <div className="sessionProgressScores__item-label">
              <p className="sessionProgressScores__item-label-title">{score.name}</p>
              {score.difference
                ? (
                  <div className={`sessionProgressScores__item-difference ${score.difference > 0 ? "rise" : "fall"}`}>
                    <FontAwesomeIcon icon={faArrowUp} />
                    <p className="sessionProgressScores__item-difference--value">{score.format(score.difference)}</p>
                  </div>
                )
                : !noDiff && (
                  <div className="sessionProgressScores__item-difference flat">
                    {score.difference !== null && <FontAwesomeIcon icon={faArrowUp} />}
                    <p className={`sessionProgressScores__item-difference${score.difference !== null ? "--value" : " noData"}`}>
                      {score.difference !== null ? score.difference : "no previous data"}
                    </p>
                  </div>
                )}
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}

export default SessionProgressScores;
