import * as urls from "api/urls";
import { userApi } from "api/axios";
import errorHandler from "utils/error-handler";
import { setOnboardingTab } from "./onBoarding";

export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_USER_PROFILE_ERROR = "GET_USER_PROFILE_ERROR";
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";

export const SAVE_USER_PROFILE = "SAVE_USER_PROFILE";
export const SAVE_USER_PROFILE_ERROR = "SAVE_USER_PROFILE_ERROR";
export const SAVE_USER_PROFILE_SUCCESS = "SAVE_USER_PROFILE_SUCCESS";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";

export const gettingUserProfile = () => ({
  type: GET_USER_PROFILE,
});

export const getUserProfileSuccess = (payload) => ({
  type: GET_USER_PROFILE_SUCCESS,
  payload,
});

export const getUserProfileError = (err) => ({
  type: GET_USER_PROFILE_ERROR,
  payload: err,
});

export const getUserProfile = () => async (dispatch) => {
  dispatch(gettingUserProfile());
  try {
    const { data } = await userApi.get(urls.profileUrl());
    dispatch(getUserProfileSuccess(data));
  } catch (err) {
    errorHandler(err, dispatch, getUserProfileError);
  }
};

export const savingUserProfile = () => ({
  type: SAVE_USER_PROFILE,
});

export const saveUserProfileSuccess = (payload) => ({
  type: SAVE_USER_PROFILE_SUCCESS,
  payload,
});

export const saveUserProfileError = (err) => ({
  type: SAVE_USER_PROFILE_ERROR,
  payload: err,
});

export const saveUserProfile = (payload, options) => (dispatch) => Promise.resolve()
  .then(async () => {
    dispatch(savingUserProfile());
    try {
      const { data } = await userApi.patch(urls.profileUrl(), payload);
      dispatch(saveUserProfileSuccess(data));
      if (options?.nextTab) dispatch(setOnboardingTab(options.nextTab));
      if (options?.nextUrl) options.navigate(options.nextUrl);
      return data;
    } catch (err) {
      errorHandler(err, dispatch, saveUserProfileError);
      document.body.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      return false;
    }
  });

export const updateUserProfile = (payload) => ({
  type: UPDATE_USER_PROFILE,
  payload,
});
