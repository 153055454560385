import * as Sentry from "@sentry/react";

import * as urls from "api/urls";
import { userApi } from "api/axios";
import errorHandler from "utils/error-handler";
import { getAppOriginUrl } from "utils/app-url-origin";
import { authController } from "utils/native-controllers";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_ERROR = "LOGOUT_USER_ERROR";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const SOCIAL_LOGIN_USER = "SOCIAL_LOGIN_USER";
export const SOCIAL_LOGIN_USER_ERROR = "SOCIAL_LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_USER_INFO_ERROR = "GET_USER_INFO_ERROR";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const CHANGE_USER_PASSWORD_ERROR = "CHANGE_USER_PASSWORD_ERROR";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";

export const loginUserStart = () => ({
  type: LOGIN_USER,
});

export const loginUserSuccess = (payload) => {
  localStorage.setItem("userAuth", JSON.stringify(payload));
  return {
    type: LOGIN_USER_SUCCESS,
    payload,
  };
};

export const loginUserError = (err) => ({
  type: LOGIN_USER_ERROR,
  payload: err,
});

export const loginUser = (payload) => async (dispatch) => {
  dispatch(loginUserStart());
  try {
    const { data } = await userApi.post(urls.loginUserUrl(), payload);
    dispatch(loginUserSuccess(data));
    return data;
  } catch (err) {
    dispatch(loginUserError(err));
    return err;
  }
};

export const logoutUserStart = () => ({
  type: LOGOUT_USER,
});

export const logoutUserSuccess = (payload, queryClient) => {
  authController(null);
  localStorage.clear();
  queryClient?.removeQueries();
  Sentry.setUser(null);
  return {
    type: LOGOUT_USER_SUCCESS,
    payload,
  };
};

export const logoutUserError = (err) => ({
  type: LOGOUT_USER_ERROR,
  payload: err,
});

export const logoutUser = (navigate, refreshJWT, queryClient) => async (dispatch) => {
  dispatch(logoutUserStart());
  try {
    const { data } = await userApi.post(urls.logoutUserUrl(), { refresh: refreshJWT });
    dispatch(logoutUserSuccess(data, queryClient));
    navigate(getAppOriginUrl());
  } catch (err) {
    errorHandler(err, dispatch, logoutUserError);
  }
};

export const socialLoginUserStart = () => ({
  type: SOCIAL_LOGIN_USER,
});

export const socialLoginUserError = (err) => ({
  type: SOCIAL_LOGIN_USER_ERROR,
  payload: err,
});
export const socialLoginUser = (navigate, social, payload) => async (dispatch) => {
  dispatch(socialLoginUserStart());
  try {
    const { data } = await userApi.post(urls.convertTokenUrl(social), payload);
    dispatch(loginUserSuccess(data));
    navigate(getAppOriginUrl());
  } catch (err) {
    errorHandler(err, dispatch, socialLoginUserError);
    authController(null);
  }
};

export const registerUserStart = () => ({
  type: REGISTER_USER,
});

export const registerUserSuccess = (payload) => ({
  type: REGISTER_USER_SUCCESS,
  payload,
});

export const registerUserError = (err) => ({
  type: REGISTER_USER_ERROR,
  payload: err,
});

export const registerUser = (payload) => async (dispatch) => {
  dispatch(registerUserStart());
  try {
    const res = await userApi.post(urls.registrationUserUrl(), payload);
    localStorage.setItem("userAuth", JSON.stringify(res.data));
    dispatch(registerUserSuccess(res.data));
    return res;
  } catch (err) {
    dispatch(registerUserError(err.config));
    return err;
  }
};

export const updateUserStart = () => ({
  type: UPDATE_USER,
});

export const updateUserSuccess = (payload) => ({
  type: UPDATE_USER_SUCCESS,
  payload,
});

export const updateUserError = (err) => ({
  type: UPDATE_USER_ERROR,
  payload: err,
});

export const updateUser = (payload) => async (dispatch) => {
  dispatch(updateUserStart());
  try {
    const response = await userApi.put(urls.updateUserUrl(), payload);
    dispatch(updateUserSuccess(response.data));
    return response;
  } catch (err) {
    return err;
  }
};

export const getUserInfoStart = () => ({
  type: GET_USER_INFO,
});

export const getUserInfoSuccess = (payload) => ({
  type: GET_USER_INFO_SUCCESS,
  payload,
});

export const getUserInfoError = (err) => ({
  type: GET_USER_INFO_ERROR,
  payload: err,
});

export const getUserInfo = () => async (dispatch) => {
  dispatch(getUserInfoStart());
  try {
    const { data } = await userApi.get(urls.updateUserUrl());
    dispatch(getUserInfoSuccess(data));
  } catch (err) {
    errorHandler(err, dispatch, getUserInfoError);
  }
};

export const changeUserPasswordStart = () => ({
  type: CHANGE_USER_PASSWORD,
});

export const changeUserPasswordSuccess = (payload) => ({
  type: CHANGE_USER_PASSWORD_SUCCESS,
  payload,
});

export const changeUserPasswordError = (err) => ({
  type: CHANGE_USER_PASSWORD_ERROR,
  payload: err,
});

export const changeUserPassword = (payload) => async (dispatch) => {
  dispatch(getUserInfoStart());
  try {
    const response = await userApi.post(urls.changeUserPasswordUrl(), payload);
    dispatch(changeUserPasswordSuccess(response.data));
    return response;
  } catch (err) {
    return err;
  }
};
