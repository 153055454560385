export const ONBOARDING_TABS = [
  "Apps",
  "Techniques",
  "Training",
  "Brainwaves",
  "Goals",
  "Signal",
  "Baseline",
  "Diagnostic",
  "Protocol",
  "Confirm",
  "Expertise",
  "Calibration",
];

export const ONBORADING_PROTOCOL_TABS = [
  "Protocol",
  "Confirm",
  "Expertise",
  "Calibration",
];
