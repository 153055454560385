const FAMILY_COLORS = {
  FA: "#171d66",
  OM: "#3F94CB",
  OH: "#F45050",
  SL: "#CDDC39",
  AM: "#FE7E6D",
  AB: "#9878C2",
  ND: "#6E8595",
  TR: "#61CDBB",
};

export default FAMILY_COLORS;
