import { useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { SelectTile, ProtocolSelectContent } from "components";
import { HelpModal } from "features";
import arrowUp from "assets/icons_arrow-up.svg";
import {
  PROTOCOLS_SECTION_COLORS, PROTOCOLS_SECTION_TEXT, PROTOCOLS_TRAIN, PROTOCOLS_CATEGORY_ICONS,
} from "constants/onBoarding_protocols";
import { FRQ_COLOR } from "constants/brainwave_analyzer";

import "./ProtocolList.scss";
import { setProtocolsListCollapse } from "store/actions/protocols";

function ProtocolList({ onSelect, withTraining = true }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allProtocols, protocolsListCollapse } = useSelector(
    ({ protocolsReducer }) => protocolsReducer,
  );
  const { diagnostic } = useSelector(({ profileReducer }) => profileReducer.userProfile);

  const protocols = useMemo(() => (withTraining
    ? allProtocols
    : allProtocols.filter((protocol) => protocol.protocolCategory !== "Training Protocols")), [allProtocols]);

  const suggestedProtocolID = diagnostic?.protocols
    .reduce((prev, current) => (prev.sessionDepth > current.sessionDepth ? prev : current))
    .protocolIds.protocolID;

  const sections = useRef([]);

  const toggleCollapse = (section, index) => {
    dispatch(setProtocolsListCollapse(
      section === protocolsListCollapse ? null : section,
    ));
    if (section !== protocolsListCollapse) {
      setTimeout(() => {
        sections.current[index].scrollIntoView({ behavior: "smooth" });
      }, 10);
    }
  };

  return protocols && (
  <div className="protocolList">

    <div className="protocolList__addNew">
      <h2>Protocol Set-up</h2>
      <p className="text-sm text-muted">
        To set-up a protocol for your style of meditation and level of expertise,
        start by choosing a meditation style based on your goals,
        preferred meditation technique, and the diagnostic results.
      </p>
      <HelpModal toggleClass="link font-600 text-sm" location="protocols" helpIcon>
        Learn about Protocols
      </HelpModal>
    </div>

    {protocols.map((section, index) => (
      <div
        style={{ backgroundColor: PROTOCOLS_SECTION_COLORS[index] }}
        key={section.protocolCategory}
        className="protocolList__select-list"
      >
        <div
          ref={(el) => { sections.current[index] = el; }}
          className="protocolList__select-list-anchor"
        />
        <div
          role="presentation"
          onClick={() => toggleCollapse(section.protocolCategory, index)}
          className="protocolList__header-wrapper"
        >
          <div className="protocolList__header-text-wrapper">
            {PROTOCOLS_CATEGORY_ICONS.find(
              ({ name }) => name === section.protocolCategory,
            ).getIcon()}
            <div>
              <div className="protocolList__section-header-text">
                {section.protocolCategory}
              </div>
              <div className="protocolList__section-sub-header-description">
                {PROTOCOLS_SECTION_TEXT[index]}
              </div>
            </div>
          </div>
          <div className="protocolList__header-collapse-wrapper button">
            <img
              className={`protocolList__header-collapse-icon ${protocolsListCollapse === section.protocolCategory ? "is-collapsed" : ""}`}
              src={arrowUp}
              alt="arrow icon"
            />
          </div>
        </div>
        <div className={`protocolList__select-list-wrapper ${protocolsListCollapse === section.protocolCategory ? "is-collapsed" : ""}`}>
          {"bands" in section ? (
            Object.entries(PROTOCOLS_TRAIN).map(([band, content]) => (
              <div key={band} className="protocolList__select-wrapper">
                <SelectTile
                  onSelect={() => navigate(`/app/meditation/protocol-train/${section.categoryID}/${band}`)}
                  withButton
                >
                  <ProtocolSelectContent protocol={{
                    protocolName: content.title,
                    color: FRQ_COLOR[band],
                    icon: band,
                    description: content.description,
                  }}
                  />
                </SelectTile>
              </div>
            ))
          ) : (
            section.protocols.map((protocol) => (
              <div key={protocol.protocolName} className="protocolList__select-wrapper">
                <SelectTile
                  onSelect={() => onSelect(section.categoryID, protocol?.protocolID)}
                  customStyle={{ border: protocol.protocolID === suggestedProtocolID ? `1px solid ${protocol.color || "#171d66"}90` : "none" }}
                  withButton
                >
                  <div>
                    <ProtocolSelectContent protocol={protocol} />
                    {protocol.protocolID === suggestedProtocolID && (
                    <div
                      className="protocolList__suggested-label text-xs mt-3"
                      style={{ background: protocol.color || "#171d66" }}
                    >
                      Suggested by Diagnostic Results
                    </div>
                    )}
                  </div>
                </SelectTile>
              </div>
            ))
          )}
        </div>
      </div>
    ))}
  </div>
  );
}

export default ProtocolList;
