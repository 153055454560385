const USER_LVL = {
  BASIC: 1,
  SUPER: 2,
  COACH: 3,
  MASTER: 4,
  SUPER_COACH: 5,
};

export const isAllowed = (userLvl, permission) => userLvl >= USER_LVL[permission];

export default isAllowed;
