import { Container } from "react-bootstrap";

import Brainwaves from "pages/App/Common/Brainwaves/Brainwaves";
import { SubHeader } from "components";

function BrainwavesPage() {
  return (
    <Container>
      <div className="settingsPage">
        <SubHeader
          title="Brainwaves"
          text="Change information about your brainwaves details."
          audioGuide="coach-brainwaves"
        />
        <Brainwaves saveBtnText="Save" />
      </div>
    </Container>
  );
}

export default BrainwavesPage;
