import { useState } from "react";
import { toast } from "react-toastify";
import { Form } from "react-router-dom";

import {
  Dialog, ErrorValidation, GenericButton, InfoSection, InputText,
} from "components";
import useFormData from "hooks/useFormData";
import { REFER_USER_FORM } from "constants/forms_data";
import { useMutation } from "@tanstack/react-query";
import { saveReferral } from "api/services/protocols";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as StarCircleIcon } from "assets/icons_promo_star.svg";

function ReferModal() {
  const [show, setShow] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const {
    formData, formErrors, setFormValue, formValidate,
  } = useFormData(REFER_USER_FORM, {
    name: null,
    email: null,
  });

  const save = useMutation({
    mutationFn: () => saveReferral(formData),
    onSuccess: async (data) => {
      formValidate(data, () => {
        setIsSuccess(true);
      });
    },
  });

  const onSave = (e) => {
    e.preventDefault();
    save.mutate(formData);
  };
  const onCancel = () => {
    toast.dismiss();
    setShow(false);
  };

  return (
    <div className="sessionComplete__refer">
      {isSuccess ? (
        <InfoSection icon={<StarCircleIcon />}>
          <p className="mb-1 text-break">
            <strong>{formData.email || "joed@gmail.com"}</strong>
            {" "}
            was reffered!
          </p>
          <p className="text-xs text-muted">
            You will gain a free month when they
            complete the application form and do their first session.
          </p>
        </InfoSection>
      ) : (
        <GenericButton
          text="Refer a Friend & Get a Free Month"
          className="button--default text-sm"
          onButtonClick={() => setShow(true)}
        />
      )}
      <Dialog
        title="Refer a Friend"
        successText="Submit"
        show={show}
        onSuccess={isSuccess ? null : onSave}
        onCancel={onCancel}
        cancelText={isSuccess ? "Got it!" : "Cancel"}
      >
        <div className="sessionComplete__refer-modal">
          {isSuccess ? (
            <>
              <h3>
                Thank you for referring
                {" "}
                <strong className="text-capitalize text-break">{formData.name}</strong>
                .
              </h3>
              <FontAwesomeIcon icon={faClipboardCheck} />
              <p className="text-muted">
                Please ask them to fill out our IMBR beta tester application. When they
                complete the application and do their first session we will grant you a free month.
              </p>
            </>
          ) : (
            <>
              <p className="text-sm text-muted">
                They will be asked to complete the application form and do their first session.
              </p>
              <Form
                onSubmit={(e) => onSave(e)}
                autoComplete="on"
                noValidate
              >
                {REFER_USER_FORM.map((input) => (
                  <div key={input.key}>
                    <InputText
                      inputType={input.inputType}
                      placeholder={input.placeholder}
                      autocomplete={input.autocomplete}
                      defaultValue={formData[input.key]}
                      onChangeValue={(e) => setFormValue(e.target.value, input.key)}
                      isValid={!formErrors[input.key]}
                      inputMode={input.key}
                    />
                    <ErrorValidation errors={formErrors[input.key]} />
                  </div>
                ))}
                <button type="submit" hidden />
              </Form>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default ReferModal;
