import {
  useEffect, useMemo, useState, useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  saveCalibratedProtocol, deleteCalibratedProtocol,
  addCalibrationRule,
} from "store/actions/protocols";

export default function useProtocolCalibration(userId, protocol, sessionCompleted = false) {
  const dispatch = useDispatch();

  const { protocols } = useSelector(
    ({ protocolsReducer }) => protocolsReducer,
  );

  const calibrationExist = useMemo(() => protocols?.some(
    (calibration) => calibration.thresholds.protocolIds.levelID
    === protocol?.level.levelID,
  ), []);

  const [ready, setReady] = useState(calibrationExist ? true : null);
  const completed = useRef({ protocol: calibrationExist, session: sessionCompleted });

  useEffect(() => {
    completed.current.session = sessionCompleted;
  }, [sessionCompleted]);

  useEffect(() => {
    const saveProtocolCalibration = async () => {
      const levelID = protocol?.level.levelID;
      const response = await dispatch(saveCalibratedProtocol(userId, levelID));
      if (response) {
        if (protocol.rule) {
          await dispatch(
            // eslint-disable-next-line no-underscore-dangle
            addCalibrationRule(userId, response._id, protocol.rule),
          );
        }
        setTimeout(() => {
          setReady(true);
          completed.current.protocol = true;
        }, 1000);
      } else {
        setReady(false);
      }
    };

    if (!calibrationExist) {
      saveProtocolCalibration();
    }
    return () => {
      if (!calibrationExist && completed.current.protocol && !completed.current.session) {
        dispatch(deleteCalibratedProtocol(userId, protocol.level.levelID));
      }
    };
  }, []);

  return ready;
}
