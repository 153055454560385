import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import _ from "lodash";

import { Container } from "react-bootstrap";
import {
  SubNav, ProtocolSelectContent, ExpertiseCard,
} from "components";
import { setProtocolToCalibrate } from "store/actions/protocols";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faArrowUp, faArrowDown,
  faRotateBackward,
} from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/fontawesome-free-regular";

import { FRQ_COLOR } from "constants/brainwave_analyzer";
import { PROTOCOLS_TRAIN } from "constants/onBoarding_protocols";
import { LEVELS_TRAIN } from "constants/onBoarding_level";

import "./ProtocolTrain.scss";

function ProtocolTrain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { categoryID, band } = useParams();

  const allProtocols = useSelector(
    ({ protocolsReducer }) => protocolsReducer.allProtocols,
  );

  const titles = [
    "Reinforcement rate?",
    "Brain Location?",
    "Power Up or Down?",
  ];

  const [form, setForm] = useState({
    stage: 1,
    level: null,
    location: null,
    direction: null,
  });

  const setValue = (value, key) => {
    setForm({ ...form, [key]: value, stage: form.stage + 1 });
  };

  useEffect(() => {
    if (form.stage === 4) {
      const { level, location, direction } = form;
      const id = allProtocols.find(
        (category) => category.categoryID === categoryID,
      ).bands[band][location][direction];
      const levelID = `${id}/${level}`;
      const weights = Array(4).fill(direction.toLowerCase() === "up" ? 1 : -1);
      dispatch(
        setProtocolToCalibrate({
          level: { level, levelID, family: { tag: "TR", id: "training" } },
          protocol: {
            protocolName: `Training - ${_.startCase(`${location} ${band} ${direction}`)}`,
            color: FRQ_COLOR[band],
            icon: "training",
            description: PROTOCOLS_TRAIN[band].description,
            protocolID: levelID.split("/")[0],
            categoryID,
            protocolRules: [
              {
                rule: 1,
                title: _.startCase(`${location} ${band} ${direction}`),
                frequencyBandName: [band],
                direction,
                weights,
                location: [location],
                measure: "Power",
                descriptions: {
                  name: _.startCase(`${location} ${band} ${direction}`),
                },
              },
            ],
          },
        }),
      );
      navigate("/app/meditation/protocol-calibration");
    }
  }, [form.stage]);

  return (
    <Container>
      <SubNav title="Training Protocol" staticTitle>
        <ProtocolSelectContent
          protocol={{
            protocolName: `${PROTOCOLS_TRAIN[band].title}`,
            color: FRQ_COLOR[band],
            icon: "training",
            description: PROTOCOLS_TRAIN[band].description,
          }}
          size="lg"
          centered
        />
      </SubNav>
      <div className="trainPage">
        <p className="text-sm text-muted font-600">
          Choose brain location, whether you want the power
          to go up or down, and reinforcement rate.
        </p>
        <h2 className="trainPage__controls-title">{titles[form.stage - 1]}</h2>
        <div className="trainPage__controls-wrapper">
          {form.stage > 1 && (
          <button
            type="button"
            className="trainPage__controls-back"
            onClick={() => setForm((state) => ({ ...state, stage: form.stage - 1 }))}
          >
            <FontAwesomeIcon icon={faRotateBackward} />
          </button>
          )}
          <span className="trainPage__controls-bg">
            <span className="trainPage__controls-bg-circle" />
          </span>
          <div className={`trainPage__controls-grid ${form.stage === 1 ? "s-1" : ""}`}>
            {form.stage === 1 && LEVELS_TRAIN.map((level) => (
              level.value ? (
                <ExpertiseCard
                  key={level.value}
                  value={level.value}
                  title="Reinforcement Rate"
                  description={level.description}
                  LEVEL_OPTIONS_COLORS={level.color}
                  onItemSelect={() => setValue(level.value, "level")}
                />
              ) : (
                <span />
              )))}
            {form.stage >= 2 && (
              <>
                <span />
                {form.stage === 2 ? (
                  <div
                    role="presentation"
                    className="trainPage__controls-card"
                    onClick={() => setValue("front", "location")}
                  >
                    <FontAwesomeIcon icon={faChevronUp} />
                    Front
                  </div>
                ) : (
                  <div
                    role="presentation"
                    className="trainPage__controls-card is-circle"
                    onClick={() => setValue("up", "direction")}
                  >
                    <FontAwesomeIcon icon={faArrowUp} />
                    <span>Up</span>
                  </div>
                )}
                <span />
                {form.stage === 2 ? (
                  <>
                    <div
                      role="presentation"
                      className="trainPage__controls-card"
                      onClick={() => setValue("left", "location")}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                      Left
                    </div>
                    <div
                      role="presentation"
                      className="trainPage__controls-card"
                      onClick={() => setValue("whole", "location")}
                    >
                      <FontAwesomeIcon icon={faCircle} />
                      Whole
                    </div>
                    <div
                      role="presentation"
                      className="trainPage__controls-card"
                      onClick={() => setValue("right", "location")}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                      Right
                    </div>
                  </>
                ) : (
                  <>
                    <span />
                    <div className="trainPage__controls-line" />
                    <span />
                  </>
                )}
                <span />
                {form.stage === 2 ? (
                  <div
                    role="presentation"
                    className="trainPage__controls-card"
                    onClick={() => setValue("back", "location")}
                  >
                    <FontAwesomeIcon icon={faChevronDown} />
                    Back
                  </div>
                ) : (
                  <div
                    role="presentation"
                    className="trainPage__controls-card is-circle"
                    onClick={() => setValue("down", "direction")}
                  >
                    <span>Down</span>
                    <FontAwesomeIcon icon={faArrowDown} />
                  </div>
                )}
                <span />
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ProtocolTrain;
