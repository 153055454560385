import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as MeditateMenuIcon } from "assets/icons_menu_person.svg";
import { ReactComponent as ProgressMenuIcon } from "assets/icons_menu_bars.svg";
import { ReactComponent as SettingsMenuIcon } from "assets/icons_menu_slides.svg";
import useScrollIsTop from "hooks/useScrollIsTop";

import "./HeaderMenu.scss";

export function HeaderMenu({ isHidden }) {
  const location = useLocation();
  const navigate = useNavigate();

  const MENU_ITEMS = [
    {
      title: "Meditate",
      url: "/app/meditation",
      icon: "MeditateMenuIcon",
    },
    {
      title: "Progress",
      url: "/app/progress",
      icon: "ProgressMenuIcon",
    },
    {
      title: "Settings",
      url: "/app/settings",
      icon: "SettingsMenuIcon",
    },
  ];

  const goToUrl = (url) => {
    navigate(url);
  };

  const isActive = (url) => location.pathname === url;

  const isTop = useScrollIsTop();
  return (
    !isHidden && (
    <div className="navigation__wrapper">
      <div
        className={`navigation__container ${isTop ? "" : "is-sticky"}`}
      >
        {MENU_ITEMS.map(({ url, title, icon }) => (
          <div key={title} className="navigation__nav-item">
            <div
              role="presentation"
              onClick={() => goToUrl(url)}
              className={`navigation__nav-item-text ${isActive(url) ? "navigation__nav-item-text--active" : ""}`}
            >
              <div className="navigation__nav-button">
                {icon === "MeditateMenuIcon" && (
                <MeditateMenuIcon className="navigation__nav-button-icon" />
                )}
                {icon === "ProgressMenuIcon" && (
                <ProgressMenuIcon className="navigation__nav-button-icon" />
                )}
                {icon === "SettingsMenuIcon" && (
                <SettingsMenuIcon className="navigation__nav-button-icon" />
                )}
              </div>
              <span>{title}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
    )
  );
}

export default HeaderMenu;
