import { useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ResponsiveLine } from "@nivo/line";
import { patternLinesDef } from "@nivo/core";
import { format } from "date-fns";

import { changeGraphStatus } from "store/actions/reporting";
import usePinch from "hooks/usePinch";

import { CHART_COLORS } from "constants/profile_progress_charts";
import ChartLoader from "features/ProgressGraphs/ChartLoader";
import { useMediaQuery } from "react-responsive";

function ZoneChart({ data }) {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const dispatch = useDispatch();
  const { graphStatus } = useSelector(({ reportingReducer }) => reportingReducer);
  const { range, period, types } = graphStatus;
  const { isPending, isPlaceholderData, data: chartData } = data;

  const pinchRef = useRef(null);
  usePinch({
    element: pinchRef,
    min: 8,
    max: 20,
    current: range,
    onChange: (newRange) => dispatch(changeGraphStatus({ ...graphStatus, range: newRange })),
    rangeMode: true,
  });

  const filteredData = useMemo(() => {
    if (!isPending) {
      return chartData?.zone.filter((serie) => serie.id === "blue")
        ?.map((serie) => ({
          ...serie,
          data: serie.data.slice(-range),
        }));
    }
    return null;
  }, [chartData, range, types.zone]);

  const setColors = () => filteredData.map((serie) => (CHART_COLORS[serie.id]));

  const formatDate = (value) => {
    const date = value.split("/");
    const newDate = new Date(`${date[2]}-${date[1]}-${date[0]}`);
    return format((newDate), period === "Month" ? "MMM yy" : "d MMM");
  };

  return (
    <ChartLoader
      loading={isPending || isPlaceholderData}
      isData={isPending || filteredData?.[0].data.length > 0}
      pinchInfo
    >
      <div className="chart-container chart-container--zone" ref={pinchRef}>
        {!!filteredData?.length && (
        <ResponsiveLine
          data={filteredData}
          theme={{
            background: "transparent",
            text: {
              fontSize: 11,
              fontFamily: "Quicksand",
              fill: "#171d66",
            },
          }}
          colors={setColors()}
          margin={{
            top: 15, right: 40, bottom: 50, left: 60,
          }}
          enableGridX={false}
          enableArea
          areaOpacity={1}
          curve="stepAfter"
          xScale={{
            type: "point",
          }}
          yScale={{
            type: "linear",
            min: 0,
            max: 100,
            stacked: true,
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -90,
            format: (value) => formatDate(value),
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "% Time above Threshold",
            legendPosition: "middle",
            legendOffset: -50,
          }}
          pointLabelYOffset={-12}
          defs={[
            patternLinesDef("bluezone", {
              rotation: -45, spacing: 6, color: "rgba(51, 191, 159, 0.4)", background: "transparent",
            }),
            patternLinesDef("greenzone", {
              rotation: -45, spacing: 6, color: "rgba(221, 178, 36, 0.4)", background: "transparent",
            }),
            patternLinesDef("redezone", {
              rotation: -45, spacing: 6, color: "rgba(221, 65, 36, 0.4)", background: "transparent",
            }),
          ]}
          fill={[
            { match: { id: "blue" }, id: "bluezone" },
          ]}
          layers={[
            "areas",
            "crosshair",
            "grid",
            "markers",
            "lines",
            "slices",
            "axes",
            "points",
            "legends",
          ]}
          yFormat={(value) => `${value}%`}
          animate={false}
          isInteractive={!isMobile}
          enableSlices="x"
          enableCrosshair
        />
        )}
      </div>
    </ChartLoader>
  );
}

export default ZoneChart;
