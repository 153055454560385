import { useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import MultiPicker from "rmc-picker-scroll/lib/MultiPicker";
import Picker from "rmc-picker-scroll";
import "rmc-picker-scroll/assets/index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import DOMPurify from "dompurify";
import parse from "html-react-parser";
import Scrollbar from "react-perfect-scrollbar-z";

import { GenericButton } from "components/ui/Button/Button";
import "react-perfect-scrollbar-z/build/styles.css";
import "./SelectPicker.scss";

export function SelectPicker({
  options, values, initialValue, onSelect, disabled, buttonClass = "", buttonText,
  labels, icon, formClass = "", infoSection = null, children,
}) {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const refScroll = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(initialValue);

  const onToggle = (show) => {
    setIsOpen(show);
    if (show) setValue(initialValue);
  };
  const onClose = () => {
    setIsOpen(false);
    onSelect(value);
  };
  const onChange = (val) => {
    setValue(val);
    if (!isMobile) {
      setIsOpen(false);
      onSelect(val);
    }
  };

  const safeHtml = (html) => parse(DOMPurify.sanitize(html));

  return (
    <div className="selectPicker__wrapper">
      <Dropdown
        autoClose="outside"
        show={isOpen}
        onToggle={onToggle}
        className="selectPicker"
      >
        <div className="dropdown__backdrop" />
        {children ? (
          <div
            role="presentation"
            className="selectPicker__toggle"
            onClick={onToggle}
          >
            {children}
          </div>
        ) : (
          <Dropdown.Toggle className={`${children ? "self" : ""}`}>
            <>
              <span className={`selectPicker__button ${buttonClass}`}>
                { buttonText }
              </span>
              {icon && (
              <div className="selectPicker__icon">
                <FontAwesomeIcon
                  icon={icon === true ? faPen : icon}
                />
              </div>
              )}
            </>
          </Dropdown.Toggle>
        )}
        <Dropdown.Menu>
          {infoSection}
          {labels && (
            <div className="selectPicker__labels">
              {labels.map((label) => <div key={label} className="selectPicker__label">{label}</div>)}
            </div>
          )}
          {!isMobile
            ? (
              <Scrollbar refScroll={refScroll} always maxHeight="350px" className={`selectPicker__form ${formClass}`}>
                {options[0].map((option, key) => (
                  <Dropdown.Item
                    key={key}
                    eventKey={key}
                    active={value[0] === key}
                    onClick={() => onChange([key])}
                  >
                    {safeHtml(option.toString())}
                  </Dropdown.Item>
                ))}
              </Scrollbar>
            )

            : (
              <div className={`selectPicker__form ${formClass}`}>
                <MultiPicker
                  selectedValue={value}
                  onValueChange={onChange}
                >
                  {options.map((group, i0) => (
                    <Picker key={i0.toString()} indicatorClassName="my-picker-indicator">
                      {group.map((option, i1) => (
                        <Picker.Item
                          key={option}
                          className="my-picker-view-item"
                          value={values ? values[i0][i1] : option}
                        >
                          {safeHtml(option.toString())}
                        </Picker.Item>
                      ))}
                    </Picker>
                  ))}
                </MultiPicker>
                <GenericButton text="Select" className="button--dark" onButtonClick={onClose} disabled={disabled?.(value)} />
              </div>
            )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default SelectPicker;
