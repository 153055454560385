import { useMemo, useState } from "react";
import { ButtonSwitch, GenericButton } from "components";

import { ANALYZE_FILTER_DATA, ANALYZE_FILTERS_TOOLTIP } from "constants/brainwave_analyzer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faChartBar } from "@fortawesome/fontawesome-free-regular";
import useScrollIsTop from "hooks/useScrollIsTop";

import { BrainwaveFiltersTimerangeSlider } from "./BrainwaveFiltersTimerangeSlider";
import { BrainwaveFiltersTimerangeSelect } from "./BrainwaveFiltersTimerangeSelect";

import "./BrainwaveFilters.scss";

export function BrainwaveFilters({
  dataLength, timeline, setTimeline, filters, setFilters, scrollSync, setScrollSync,
  isCertified, isCoach, isCoachPanel,
}) {
  const [open, setOpen] = useState(false);
  const isTop = useScrollIsTop();

  const analyzeFilters = useMemo(() => {
    if (isCertified || isCoachPanel) return ANALYZE_FILTER_DATA;
    return ANALYZE_FILTER_DATA.filter((filter) => filter.preCertified === !isCertified);
  }, [isCertified]);

  return (
    <div className={`brainwaveGraphs__filters-main ${open ? "is-open" : ""}`}>
      <button
        type="button"
        className={`brainwaveGraphs__filters-main-toggle ${open || !isTop ? "is-shrink" : ""}`}
        onClick={() => setOpen((state) => !state)}
      >
        <span>
          <FontAwesomeIcon icon={faChartBar} />
          <strong>Graph Settings</strong>
        </span>
        <FontAwesomeIcon icon={faTimes} />
      </button>
      <div className="brainwaveGraphs__filters-main-wrapper">
        <p>Filter By Time</p>
        <BrainwaveFiltersTimerangeSlider
          dataLength={dataLength - 1}
          timeline={timeline}
          setTimeline={setTimeline}
          scrollSync={scrollSync}
          setScrollSync={setScrollSync(0)}
        />
        <div className="brainwaveGraphs__filters-row-content my-3">
          <BrainwaveFiltersTimerangeSelect
            dataLength={dataLength}
            timeline={timeline}
            setTimeline={setTimeline}
            scrollSync={scrollSync}
            setScrollSync={setScrollSync(0)}
          />
        </div>
        <p>
          Filter by
          {" "}
          <span className="font-700">
            {ANALYZE_FILTERS_TOOLTIP[filters.options]}
          </span>
        </p>
        <div className="brainwaveGraphs__analyze-actions">
          <div className="button-group">
            {analyzeFilters.map(({ key, text, iconElement }) => (
              <GenericButton
                key={key}
                text={text}
                iconElement={iconElement}
                className={filters.options === key ? "btn--active" : ""}
                id={key}
                onButtonClick={() => setFilters((state) => ({ ...state, options: key }))}
              />
            ))}
          </div>
        </div>
        {isCoach && (
        <div className="brainwaveGraphs__filters-row">
          <span className="brainwaveGraphs__filters-row-title">Metrics</span>
          <div className="brainwaveGraphs__filters-row-content">
            <ButtonSwitch pillsLayout>
              <GenericButton
                className={`btn-sm ${filters.metrics === "protocol" ? "btn--active" : ""}`}
                text="Protocol"
                onButtonClick={() => setFilters((state) => ({ ...state, metrics: "protocol" }))}
              />
              <GenericButton
                className={`btn-sm ${filters.metrics === "brain" ? "btn--active" : ""}`}
                text="Brain State"
                onButtonClick={() => setFilters((state) => ({ ...state, metrics: "brain" }))}
              />
            </ButtonSwitch>
          </div>
        </div>
        )}
        <div className="brainwaveGraphs__filters-row">
          <span className="brainwaveGraphs__filters-row-title">Graphs</span>
          <div className="brainwaveGraphs__filters-row-content">
            <div className="button-switch">
              <GenericButton
                className={filters.graphsType === "mean" ? "btn--active" : ""}
                text="Averages"
                onButtonClick={() => setFilters((state) => ({ ...state, graphsType: "mean" }))}
              />
              <GenericButton
                className={filters.graphsType === "timeline" ? "btn--active" : ""}
                text="Timeline"
                onButtonClick={() => setFilters((state) => ({ ...state, graphsType: "timeline" }))}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default BrainwaveFilters;
