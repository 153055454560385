const getCurrentZone = (score, thresholds) => {
  if (score >= thresholds[1]) return "red";
  if (score <= thresholds[0]) return "blue";
  return "green";
};

const getZoneValue = (zoneName, streakCount, streaks) => {
  if (zoneName === "blue") {
    if (streakCount >= streaks.reward) return 2;
    return 1;
  }
  if (zoneName === "red") {
    if (streakCount >= streaks.reminder) return -2;
    return -1;
  }
  return 0;
};

const getZoneData = (data, thresholds, streaks) => {
  const dataReduce = data.reduce((acc, value) => {
    const zoneName = getCurrentZone(value, thresholds);
    if (zoneName === acc.lastZone) {
      acc.count += 1;
    } else {
      acc.count = 1;
    }
    acc.zoneData.push(getZoneValue(zoneName, acc.count, streaks));
    acc.lastZone = zoneName;
    return acc;
  }, {
    count: 0,
    lastZone: "",
    zoneData: [],
  });
  return dataReduce.zoneData;
};

export { getCurrentZone, getZoneValue, getZoneData };
