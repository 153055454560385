import "./SessionTaps.scss";

export function SessionTaps({ tapsData }) {
  const initialData = [
    {
      type: 1,
      text: "Emerging from",
      name: "Depth",
      count: 0,
    },
    {
      type: 2,
      text: "Recovering from",
      name: "Distraction",
      count: 0,
    },
  ];
  const renderData = tapsData.reduce((acc, data) => {
    if (data.type !== 3) acc[data.type - 1].count += 1;
    return acc;
  }, initialData);

  return (
    <div className="sessionTaps">
      {renderData.map((tap) => (tap.type !== 3 || tap.count > 0) && (
        <div key={tap.type} className={`sessionTaps__item type-${tap.type}`}>
          <div className="sessionTaps__item-circle">
            {tap.count}
          </div>
          <div className={`tapIcon type-${tap.type}`}>
            {[...Array(tap.type)].map((_type, i) => <span key={i.toString()} />)}
          </div>
          <p className="sessionTaps__item-name">
            {tap.text}
            {" "}
            <strong className="text-uppercase">{tap.name}</strong>
          </p>
        </div>
      ))}
    </div>
  );
}

export default SessionTaps;
