import { useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Defs } from "@nivo/core";
import { ResponsiveLine } from "@nivo/line";
import { area, curveMonotoneX } from "d3-shape";
import { format } from "date-fns";

import { CHART_COLORS, CHART_TYPES_DEPTH_CHART } from "constants/profile_progress_charts";
import ChartType from "features/ProgressGraphs/ChartType";
import ChartLoader from "features/ProgressGraphs/ChartLoader";
import { useMediaQuery } from "react-responsive";
import usePinch from "hooks/usePinch";

import { changeGraphStatus } from "store/actions/reporting";

function AreaLayer({
  series, xScale, yScale, innerHeight,
}) {
  const blue = series.find(({ id }) => id === "blueThreshold");
  const red = series.find(({ id }) => id === "redThreshold");
  if (red && blue) {
    const areaData = [...blue.data].map((d, i) => ({
      x: d.data.x,
      y0: d.data.y,
      y1: red.data[i].data.y,
    })).filter(({ y0 }) => !!y0);
    const areaGenerator = area()
      .x((d) => xScale(d.x))
      .y0((d) => Math.min(innerHeight, yScale(d.y0)))
      .y1((d) => yScale(d.y1))
      .curve(curveMonotoneX);

    return (
      <>
        <Defs
          defs={[
            {
              id: "zoneNeutral",
              type: "patternLines",
              background: "transparent",
              color: "rgba(221, 178, 36, 0.8)",
              lineWidth: 1,
              spacing: 6,
              rotation: -45,
            },
          ]}
        />
        <path
          d={areaGenerator(areaData)}
          fill="url(#zoneNeutral)"
          fillOpacity={1}
          strokeWidth={0}
        />
      </>
    );
  }
  return null;
}

function DepthChart({ data }) {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { graphStatus } = useSelector(({ reportingReducer }) => reportingReducer);
  const { range, period, types } = graphStatus;
  const { isPending, isPlaceholderData, data: chartData } = data;

  const pinchRef = useRef(null);
  usePinch({
    element: pinchRef,
    min: 8,
    max: 20,
    current: range,
    onChange: (newRange) => dispatch(changeGraphStatus({ ...graphStatus, range: newRange })),
    rangeMode: true,
  });

  const filteredData = useMemo(() => {
    if (!isPending) {
      return chartData?.depth.filter((serie) => types.depth.includes(serie.id))
        ?.map((serie) => ({
          ...serie,
          data: serie.data.slice(-range),
        }));
    }
    return null;
  }, [chartData, range, types.depth]);

  const setColors = () => filteredData.map((serie) => (CHART_COLORS[serie.id]));

  const formatDate = (value) => {
    const date = value.split("/");
    const newDate = new Date(`${date[2]}-${date[1]}-${date[0]}`);
    return format((newDate), period === "Month" ? "MMM yy" : "d MMM");
  };

  return (
    <ChartLoader
      loading={isPending || isPlaceholderData}
      isData={isPending || filteredData?.[0].data.length > 0}
      pinchInfo
    >
      <div className="chart-container chart-container--depth" ref={pinchRef}>
        {!!filteredData?.length && (
        <ResponsiveLine
          data={filteredData}
          theme={{
            background: "transparent",
            text: {
              fontSize: 11,
              fontFamily: "Quicksand",
              fill: "#171d66",
            },
          }}
          colors={setColors()}
          margin={{
            top: 15, right: 40, bottom: 50, left: 60,
          }}
          enableGridX={false}
          curve="monotoneX"
          xScale={{
            type: "point",
          }}
          yScale={{
            type: "linear",
            min: 0,
            max: 1000,
            stacked: false,
          }}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -90,
            format: (value) => formatDate(value),
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Depth Score",
            legendPosition: "middle",
            legendOffset: -50,
          }}
          pointLabelYOffset={-12}
          layers={[
            "grid",
            "markers",
            "areas",
            "crosshair",
            AreaLayer,
            "lines",
            "slices",
            "axes",
            "points",
            "legends",
          ]}
          animate={false}
          isInteractive={!isMobile}
          enableSlices="x"
          enableCrosshair
        />
        )}
        <ChartType
          chartTypeData={CHART_TYPES_DEPTH_CHART}
          chartType="depth"
        />
      </div>
    </ChartLoader>
  );
}

export default DepthChart;
