import { useEffect, useRef } from "react";
import { Outlet, useLocation, useNavigationType } from "react-router-dom";
import { appViewController } from "utils/native-controllers";

export function RouteManager() {
  const { pathname } = useLocation();
  const type = useNavigationType();
  const scroll = useRef({ scrollY: 0, history: new Map() });

  useEffect(() => {
    const history = scroll.current.history.get(pathname);
    if (type === "POP" && history) {
      document.body.scrollTo(0, history);
    } else {
      document.body.scrollTo(0, 0);
    }

    if (pathname.split("/")[1] === "app") appViewController(pathname);

    const onScroll = () => {
      scroll.current.scrollY = document.body.scrollTop;
    };
    document.body.addEventListener("scroll", onScroll);

    return () => {
      document.body.removeEventListener("scroll", onScroll);
      scroll.current.history.set(pathname, scroll.current.scrollY);
    };
  }, [pathname]);

  return <Outlet />;
}

export default RouteManager;
