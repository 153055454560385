import { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { format } from "date-fns";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";

import { getSessionReport, submitSession, updateSessionJournal } from "api/services/reporting";
import { updateProtocolCertificationQuery } from "api/services/protocols";

import {
  SubNav, Spinner, GenericButton, ProtocolSelectInfo,
  BottomBarSticky, SessionTaps,
} from "components";
import { JournalForm } from "features";
import useProtocol from "hooks/useProtocol";
import useProtocolCertification from "hooks/useProtocolCertification";
import { formatDuration } from "utils/time-format";

import ContributionCard from "./components/ContributionCard";

import "./SessionComplete.scss";

function SessionCompletePage() {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: summary } = useQuery({
    queryKey: ["session-summary", sessionId],
    queryFn: () => getSessionReport(sessionId),
    gcTime: Infinity,
    staleTime: Infinity,
  });

  const calibrationEnabled = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.calibrationEnabled,
  );

  const [journalData, setJournalData] = useState({
    journalEntry: {
      graphExperiance: "",
      howDeepDidYouGo: "",
      notes: "",
      sessionFeeling: "",
    },
  });

  const protocolCertification = useProtocolCertification(
    summary?.protocolDetails.protocolIds.levelID,
    summary?.protocolDetails.family.id,
    summary?.protocolDetails.protocolIds.rule,
    true,
  );

  const isCertified = protocolCertification.global?.certified;
  const isValid = summary?.sessionData.valid;

  useEffect(() => {
    const staleQueries = ["past-protocols", "past-sessions", "time-progress", "lifetime-progress", "stats-progress", "metrics-progress"];
    queryClient.invalidateQueries({
      predicate: ({ queryKey }) => staleQueries.includes(queryKey[0]),
    });
  }, []);

  const journalUpdate = useMutation({
    mutationFn: (payload) => updateSessionJournal(sessionId, payload),
    onSuccess: async (_data, payload) => {
      queryClient.setQueryData(
        ["session-summary", sessionId],
        (state) => ({ ...state, journalEntry: { ...payload.journalEntry } }),
      );
      navigate(`/app/progress/session-summary/${sessionId}`, {
        state: {
          from: isValid && !calibrationEnabled && protocolCertification.user?.counter === 10
            ? "free-month"
            : "meditation",
        },
      });
    },
  });

  const sessionSubmit = useMutation({
    mutationFn: () => submitSession(sessionId),
    onSuccess: async () => {
      await updateProtocolCertificationQuery(
        summary?.sessionData?.protocol.levelID,
        summary?.sessionData?.protocol.family,
        summary?.sessionData?.protocol.rule,
        queryClient,
      );
      journalUpdate.mutate(journalData);
    },
  });

  const sessionProtocol = useProtocol(
    summary?.sessionData?.protocol.categoryID,
    summary?.sessionData?.protocol.protocolID,
    summary?.protocolDetails,
  );

  const sessionLevel = useMemo(() => {
    const sessionLevelId = summary?.sessionData.protocol.levelID;
    return sessionLevelId?.split("/")[1];
  }, [summary]);

  const sessionRule = useMemo(() => {
    const ruleNo = summary?.sessionData.protocol.rule
      || summary?.protocolDetails?.protocolIds?.rule;
    if (ruleNo && sessionProtocol) {
      return sessionProtocol.protocolRules.find(
        (rule) => rule?.rule === ruleNo,
      );
    }
    return null;
  }, [summary, sessionProtocol]);

  const journalValidation = useMemo(() => ({
    isTap: summary?.sessionData.taps.length > 0,
    empty: !Object.values(journalData.journalEntry).some((value) => !!value),
    valid: !!journalData.journalEntry.graphExperiance,
    continueText: isCertified ? "Skip" : "Continue",
  }), [journalData]);

  const onSubmit = () => {
    if (!isCertified && isValid) {
      sessionSubmit.mutate();
    } else if (!journalValidation.empty) {
      journalUpdate.mutate(journalData);
    } else {
      navigate(`/app/progress/session-summary/${sessionId}`, {
        state: { from: "meditation" },
      });
    }
  };

  const onCancel = () => {
    navigate(`/app/progress/session-summary/${sessionId}`, {
      state: { from: "meditation" },
    });
  };

  const isContinue = isCertified
    ? journalValidation.empty
    : !isValid;

  return (
    <Container>
      <div className="sessionComplete">
        <SubNav
          title="Session Complete"
          audioGuide={!isCertified && "coach-session-complete"}
          noBack
        >
          <h1 className="text-center">
            Session Complete
          </h1>
        </SubNav>
        {summary && protocolCertification.global ? (
          <>
            <div className="sessionSummaryPage__header">
              <div className="sessionSummaryPage__header-date">
                {`${format(new Date(summary.sessionData.date), "MMMM do, yyyy")}`}
                &nbsp;-&nbsp;
                {`${format(new Date(summary.sessionData.date), "hh:mma")}`}
                &nbsp;-&nbsp;
                {formatDuration(summary.sessionData.length, true)}
              </div>
              <ProtocolSelectInfo
                customClass="sessionSummaryPage__protocol"
                protocol={sessionProtocol}
                level={sessionLevel}
                rule={sessionRule?.descriptions?.name}
                size="lg"
                centered
              />
            </div>
            <SessionTaps tapsData={summary.sessionData.taps} />
            <JournalForm
              journalEntry={summary.journalEntry}
              setJournalData={setJournalData}
              isEditable
              isRequired={!isCertified}
              isTap={journalValidation.isTap}
              isCertified={isCertified}
              customClass="card"
            />
            {!isCertified && (
              <ContributionCard
                isValid={isValid}
                protocolCertification={protocolCertification}
              />
            )}
            {journalValidation && (
            <BottomBarSticky>
              {!isCertified && isValid && (
              <GenericButton
                className="button--danger"
                text="Cancel"
                onButtonClick={onCancel}
              />
              )}
              <GenericButton
                className="button--dark"
                text={isContinue ? journalValidation.continueText : "Submit"}
                onButtonClick={onSubmit}
                disabled={!(isCertified || isContinue || journalValidation.valid)}
              />
            </BottomBarSticky>
            )}
          </>
        )
          : <Spinner />}
      </div>
    </Container>
  );
}

export default SessionCompletePage;
