import { useState, useRef, useEffect } from "react";
import { useQuery, keepPreviousData } from "@tanstack/react-query";

import _ from "lodash";
import { toast } from "react-toastify";
import { useReactTable, getCoreRowModel, flexRender } from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye, faXmark, faThumbtack,
  faFilterCircleXmark, faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { GenericButton, Spinner, EmptyData } from "components";
import { Dropdown, Card, Container } from "react-bootstrap";
import Scrollbar from "react-perfect-scrollbar-z";

import { getCoachTable, modifyCoachTab } from "api/services/coach";
import getGroups from "./helpers/getGroups";
import CoachTabs from "./components/CoachTabs/CoachTabs";
import FilterMenu from "./components/FilterMenu/FilterMenu";
import CoachTablePagination from "./components/CoachTablePagination/CoachTablePagination";

import "react-perfect-scrollbar-z/build/styles.css";
import "./CoachTable.scss";

export function CoachTable({
  title,
  tableName,
  tableType,
  getdata,
  columnGroups,
  selectChoices,
  callbackId,
  callbackTotal,
  stickyColumn,
  tabsGroup,
}) {
  const refScroll = useRef(null);
  const initialLoad = useRef(true);

  const [pagination, setPagination] = useState({ perPage: 10, page: 1 });
  const [activeFilter, setActiveFilter] = useState({ filters: [], pagination, sortBy: {} });
  const [tableModel, setTableModel] = useState({ groups: [], groupNames: [] });
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [temporaryPin, setTemporaryPin] = useState(null);
  const [isTabSwitched, setIsTabSwitched] = useState(true);

  const getTableData = async (type) => {
    try {
      const data = await getCoachTable(type);
      const groupNames = columnGroups
        || data.fields?.map((item) => item.field.group)
          .filter((value, index, arr) => arr.indexOf(value) === index)
          .filter(Boolean);
      const groupedColumns = { groups: getGroups(data, groupNames, stickyColumn), groupNames };
      setTableModel(groupedColumns);
      return groupedColumns;
    } catch { return null; }
  };

  const { data: tableDefinitions, isFetchedAfterMount } = useQuery({
    queryKey: ["table-definitions", tableName, tableType],
    queryFn: () => getTableData(tableType),
  });

  const { isRefetching, isSuccess, data: tableData } = useQuery({
    queryKey: ["table-data", activeFilter, pagination, tableName],
    queryFn: () => getdata({ ...activeFilter, pagination }),
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    if (isSuccess) {
      if (callbackId) {
        callbackId.set(tableData.results.map((item) => item[callbackId.dataId]));
      }
      if (callbackTotal) {
        callbackTotal(tableData.count);
      }
    }
  }, [isSuccess, tableData]);

  const table = useReactTable({
    data: tableData?.results,
    columns: tableModel.groups,
    state: { columnVisibility, rowSelection },
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
  });

  const sendFilters = (settings) => {
    const newSort = settings.sortDirection
      ? {
        id: settings.id,
        name: settings.name,
        direction: settings.sortDirection,
      } : {};
    setActiveFilter({
      filters: (activeFilter.filters?.some((item) => item.id === settings.id)
        ? activeFilter.filters.map((item) => (item.id === settings.id
          ? { ...item, value: settings.value }
          : item))
        : [...activeFilter.filters,
          {
            id: settings.id,
            name: settings.name,
            value: settings.value,
          },
        ]).filter((item) => item.value),
      sortBy: !activeFilter.sortBy.id || settings.id === activeFilter.sortBy.id
        ? newSort
        : (settings.sortDirection && newSort) || activeFilter.sortBy,
    });
    setPagination({ ...pagination, page: settings.value ? 1 : pagination.page });
  };

  const getPinnedColumn = () => tableModel.groups.find((group) => group.id === "pinned").columns[0]?.id;

  const togglePinning = (pinnedColumn, isTabChanged) => {
    setTemporaryPin(pinnedColumn?.id === temporaryPin ? null : (pinnedColumn?.id || null));

    if (!pinnedColumn?.id || (!isTabChanged && getPinnedColumn() === pinnedColumn?.id)) {
      setTableModel(tableDefinitions);
      return;
    }
    const groups = tableDefinitions.groups.map((columnGroup) => {
      if (columnGroup.id === "pinned") {
        return { ...columnGroup, columns: [pinnedColumn] };
      }
      if (columnGroup.header === pinnedColumn.group) {
        return {
          ...columnGroup,
          columns: columnGroup.columns.filter((item) => item.id !== pinnedColumn.id),
        };
      }
      return columnGroup;
    });
    setTableModel((previousModel) => ({ ...previousModel, groups }));
  };

  const onTabChange = (tabSettings, tab) => {
    const storage = JSON.parse(sessionStorage.getItem(tableName));

    setActiveFilter({
      filters: tabSettings?.filters || [],
      sortBy: tabSettings?.sortBy || {},
    });
    setColumnVisibility(tabSettings?.hiddenColumns
      ? tabSettings.hiddenColumns.reduce((columns, key) => ({ ...columns, [key]: false }), {})
      : {});

    const pinnedColumn = tabSettings?.pinnedColumn
      ? table.getColumn(tabSettings?.pinnedColumn).columnDef
      : null;
    togglePinning(pinnedColumn, true);

    setPagination({
      perPage: storage?.pagination?.perPage || pagination.perPage,
      page: initialLoad.current ? (storage?.pagination?.page || 1) : 1,
    });

    setIsTabSwitched(true);
    if (initialLoad.current) initialLoad.current = false;

    sessionStorage.setItem(tableName, JSON.stringify(
      { ...storage, tab },
    ));
  };

  const onSave = (tab) => {
    modifyCoachTab(tab.id, {
      ...tab,
      configuration: {
        filters: activeFilter.filters,
        sortBy: activeFilter.sortBy,
        hiddenColumns: columnVisibility
          && Object.keys(columnVisibility).filter((key) => !columnVisibility[key]),
        pinnedColumn: getPinnedColumn(),
      },
    });
    toast.success("Preset successfully saved.");
  };

  useEffect(() => {
    if (!isFetchedAfterMount) return;
    if (isTabSwitched) setIsTabSwitched(false);
    refScroll.current.update();

    sessionStorage.setItem(tableName, JSON.stringify({
      ...JSON.parse(sessionStorage.getItem(tableName)),
      pagination,
    }));
  }, [pagination]);

  return (
    (isFetchedAfterMount && tableData) && (
      <Container className="coachTable">
        <h2>{title}</h2>
        <Card>
          <CoachTabs
            tableName={tableName}
            tabsGroup={tabsGroup}
            activeFilter={activeFilter}
            columnVisibility={columnVisibility}
            temporaryPin={temporaryPin}
            getPinnedColumn={getPinnedColumn}
            onSave={(tab) => onSave(tab)}
            onTabChange={onTabChange}
            isTableReady={isFetchedAfterMount}
            isTabSwitched={isTabSwitched}
          />
          <div className="coachTable__activeFilters">
            <div>
              {`Filters: ${(!activeFilter.sortBy.id && activeFilter.filters.length === 0) ? "none" : ""}`}
              <ul>
                {activeFilter.sortBy.id && (
                <li className="coachTable__activeFilters-filter">
                  {`Sorted by: ${activeFilter.sortBy?.name}`}
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => setActiveFilter((previousFilter) => ({
                      ...previousFilter, sortBy: {},
                    }))}
                  />
                </li>
                )}
                {activeFilter.filters?.map((item) => item.name && (
                <li key={item.id} className="coachTable__activeFilters-filter">
                  {item.name}
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => setActiveFilter((previousFilter) => ({
                      ...previousFilter,
                      filters: previousFilter.filters.filter((filter) => filter.id !== item.id),
                    }))}
                  />
                </li>
                ))}
                {(activeFilter.filters.length > 1
                  || (activeFilter.sortBy?.id && activeFilter.filters.length > 0)) && (
                  <li
                    className="coachTable__activeFilters-filter delete"
                    onClick={() => setActiveFilter(() => ({ filters: [], sortBy: {} }))}
                    role="presentation"
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className="coachTable__table">
            <Dropdown className="columnsMenu">
              <Dropdown.Toggle
                className={`columnsMenu__button button ${_.isEmpty(columnVisibility) ? "" : "occupied"}`}
              >
                Columns
              </Dropdown.Toggle>
              <Dropdown.Menu className="filterMenu menu columns">
                <button
                  type="button"
                  className="button hide"
                  onClick={() => setColumnVisibility(null)}
                >
                  Show all columns
                  <FontAwesomeIcon icon={faEye} />
                </button>
                <div className="columnsMenu__list">
                  <Scrollbar className="filterMenu__type-select" refScroll={refScroll} always maxHeight="400px">
                    {tableModel.groupNames.map((groupName) => (
                      <div key={groupName}>
                        <div className="columnsMenu__title">{groupName}</div>
                        <div className="columnsMenu__group">
                          {table.getAllLeafColumns().map((column) => (
                            column.columnDef.group === groupName) && (
                              <button
                                key={column.id}
                                type="button"
                                className={`button ${column.getIsVisible() ? "active" : ""}`}
                                onClick={() => {
                                  if (column.getIsVisible() && getPinnedColumn() === column.id) {
                                    togglePinning();
                                  }
                                  column.toggleVisibility(!column.getIsVisible());
                                }}
                              >
                                {column.columnDef.title}
                              </button>
                          ))}
                        </div>
                      </div>
                    ))}
                  </Scrollbar>
                </div>
              </Dropdown.Menu>
            </Dropdown>

            {!tableData.count && !isRefetching && (
              <EmptyData
                customClass="coachTable__emptyData"
                icon={faFilterCircleXmark}
                title="Empty"
                text="No data for selected filters."
              />
            )}

            <Scrollbar refScroll={refScroll} always className="tableScroll">
              {isRefetching && <Spinner className="chart-loader__spinner" />}
              <table {...{ style: { width: "fit-content" } }}>
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          {...{
                            key: header.id,
                            colSpan: header.colSpan,
                            style: {
                              width: (header.column.id === "select" || header.column.id === "pinned") ? 0 : header.getSize(),
                              padding: header.column.id === "pinned" ? 0 : "0 14px",
                            },
                          }}
                        >
                          {header.column.parent?.id === "pinned" && (
                            <FontAwesomeIcon icon={faThumbtack} className="svg-icon pin-icon" />
                          )}
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {(header.column.depth > 0 && header.column.parent?.id !== "sticky") && (
                            <FilterMenu
                              header={header}
                              selectChoices={selectChoices}
                              activeFilter={activeFilter}
                              sendFilters={sendFilters}
                              togglePinning={togglePinning}
                              getPinnedColumn={getPinnedColumn}
                            />
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          title={cell.column.id !== stickyColumn?.id ? cell.getValue() : ""}
                          {...{
                            key: cell.id,
                            style: {
                              width: (cell.column.id === "select" || cell.column.id === "pinned") ? 0 : cell.column.getSize(),
                              padding: cell.getValue() === undefined ? 0 : "0 14px",
                            },
                          }}
                        >
                          {cell.column.id === stickyColumn?.id
                            ? (
                              <GenericButton
                                className="sticky-button"
                                iconElement={stickyColumn.element.icon}
                                href={stickyColumn.element.path
                                  && stickyColumn.element.path(row.original.user, cell.getValue())}
                                onButtonClick={stickyColumn.element.action
                                  && (() => stickyColumn.element.action(cell.getValue()))}
                                disabled={stickyColumn.disabled
                                  && row.original[stickyColumn.disabled.key]
                                  === stickyColumn.disabled.value}
                              />
                            )
                            : flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Scrollbar>
          </div>
        </Card>

        <CoachTablePagination
          className="coachTable__pagination"
          totalPages={Math.ceil(tableData.count / pagination.perPage)}
          totalNumber={tableData.count}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Container>
    ));
}

export default CoachTable;
