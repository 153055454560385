import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { HeaderSensors } from "components/navs/HeaderSensors/HeaderSensors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";

import AudioGuide from "features/AudioGuide/AudioGuide";

import "./HeaderOnboarding.scss";

export function HeaderOnboarding({ completedSteps, allSteps, audioGuide }) {
  const progressBarWidth = (completedSteps / allSteps) * 100;
  const navigate = useNavigate();

  const [isSensorsBar, setIsSensorsBar] = useState(null);
  const [hiddenElement, setHiddenElement] = useState(false);

  return (
    <div className="onBoardingHeader" style={{ height: isSensorsBar && 110 }}>
      <div className="onBoardingHeader__wrapper" style={{ height: isSensorsBar && 110 }}>
        <div className="onBoardingHeader__content">
          <div className={`onBoardingHeader__title ${hiddenElement ? "hidden" : ""}`}>Your Profile</div>
          <div className="onBoardingHeader__progress-bar-container">
            <div
              className="onBoardingHeader__progress-bar"
              style={{ width: `${progressBarWidth}%` }}
            />
          </div>
          <div className="onBoardingHeader__progress-counter">
            {completedSteps}
            /
            {allSteps}
          </div>
          <div className="onBoardingHeader__settings">
            <FontAwesomeIcon
              className="onBoardingHeader__settings-icon"
              icon={faSliders}
              onClick={() => navigate("/app/settings")}
            />
            <AudioGuide
              track={audioGuide}
              setHiddenElement={setHiddenElement}
            />
          </div>
        </div>
        <HeaderSensors setIsSensorsBar={setIsSensorsBar} />
      </div>
    </div>
  );
}

export default HeaderOnboarding;
