import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";

import { generateNumberArray } from "utils/time-format";
import {
  SubHeader, GenericButton, SelectPicker, BottomBarSticky, SectionSeparator, InfoSection,
} from "components";
import useProtocolCertification from "hooks/useProtocolCertification";
import { saveUserProfile } from "store/actions/profile";
import LENGTH_SESSION_OPTIONS from "constants/meditation_session_length";
import { ReactComponent as Star } from "assets/icons_promo_star.svg";

import "./SessionLength.scss";

function SessionLengthPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userSavedLength = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.saved_session_length,
  );

  const mapLengthOptions = useMemo(() => ([
    generateNumberArray(0, 3, 1, false),
    generateNumberArray(0, 60, 1, false),
  ]), []);

  const userSavedProtocol = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.selected_protocol,
  );

  const [sessionHour, setSessionHour] = useState(userSavedLength.hours || 0);
  const [sessionMinutes, setSessionMinutes] = useState(userSavedLength.minutes || 0);

  const protocolCertification = useProtocolCertification(
    userSavedProtocol?.levelID,
    userSavedProtocol?.family,
    userSavedProtocol?.rule,
  );

  const onLengthSelect = (time) => {
    setSessionHour(time[0]);
    setSessionMinutes(time[1]);
  };

  const save = (selectedItemValue) => {
    const formattedLength = {
      hours: selectedItemValue ? 0 : sessionHour,
      minutes: selectedItemValue || sessionMinutes,
    };
    dispatch(saveUserProfile({
      saved_session_length: formattedLength,
    }, { navigate, nextUrl: "/app/meditation" }));
  };

  return (
    <Container>
      <SubHeader
        title="Session Length"
        text="Set length for your next session."
      />
      <div className="sessionLengthPage__select-content">
        <span className="sessionLengthPage__select-content-label">
          Custom:
        </span>
        <SelectPicker
          options={mapLengthOptions}
          initialValue={[sessionHour, sessionMinutes]}
          onSelect={onLengthSelect}
          buttonText={`${sessionHour} h ${sessionMinutes} mins` || "Pick Length"}
          labels={["hours", "minutes"]}
          icon
        />
      </div>
      <SectionSeparator text="OR" customClass="my-4" />
      <div className="sessionLengthPage__timer-options">
        {LENGTH_SESSION_OPTIONS.map(({ key, value }) => (
          <GenericButton
            key={key}
            text={key}
            className="button--white"
            onButtonClick={() => save(value)}
          />
        ))}
      </div>
      {!protocolCertification.global?.certified && protocolCertification.requirements && (
      <InfoSection icon={<Star />} customClass="mt-4">
        <p className="text-muted">
          <strong>Sessions</strong>
          {" "}
          need to be
          {" "}
          {protocolCertification.requirements.minLength}
          + minutes and include lots of accurate taps to count towards
          {" "}
          <strong className="text-primary">an additional free month</strong>
          .
        </p>
      </InfoSection>
      )}
      <BottomBarSticky>
        <GenericButton
          text="Save"
          className="button--success"
          onButtonClick={() => save(null)}
          disabled={!(sessionHour || sessionMinutes)}
        />
      </BottomBarSticky>
    </Container>
  );
}

export default SessionLengthPage;
