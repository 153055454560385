import { useRef, useEffect } from "react";
import _ from "lodash";
import { PROTOCOLS_ICONS } from "constants/onBoarding_protocols";
import { ReactComponent as SunIcon } from "assets/ic_protocols/ic_sun.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import "./ProtocolSelectContent.scss";

export function ProtocolSelectContent({
  protocol, level, rule, showDescription = true, date, centered = false, size = "sm", infoIcon, infoDisabled,
}) {
  const protocolColor = () => (protocol.color
    ? { color: protocol.color, fill: protocol.color }
    : { color: "#171d66", fill: "#171d66" });
  const protocolBg = () => (protocol.color
    ? { background: protocol.color }
    : { background: "#171d66" });

  const protocolIcon = (iconName) => PROTOCOLS_ICONS.find(({ name }) => name === iconName)?.getIcon() || <SunIcon className="protocol_icon" />;

  const titleRef = useRef();

  useEffect(() => {
    if (protocol && size === "md") {
      const text = titleRef.current.childNodes[0];
      const range = document.createRange();
      range.setStartBefore(text);
      range.setEndAfter(text);
      const clientRect = range.getBoundingClientRect();
      titleRef.current.style.width = `${clientRect.width}px`;
    }
  }, [protocol]);

  return protocol && (
    <div className={`protocolSelect__size--${size}`}>
      <div className={`${centered ? "protocolSelect__wrapper" : ""}`}>
        <div className="protocolSelect__content">
          <div className="protocolSelect__icon" style={protocolColor()}>
            {protocolIcon(protocol.icon)}
          </div>
          <div className={`protocolSelect__text-container ${!showDescription ? "justify-content-center" : ""}`}>
            <div className="protocolSelect__title" style={protocolColor()}>
              <div>
                <span ref={titleRef} className="protocolSelect__title-text">{_.unescape(protocol.protocolName)}</span>
                {infoIcon && size === "lg" && (
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className={`protocolSelect__title-info ${infoDisabled ? "is-muted" : ""}`}
                />
                )}
              </div>
              {level && (
              <span className="protocolSelect__title-lvl" style={protocolBg()}>
                {" "}
                Lv.
                {" "}
                { level }
              </span>
              )}
              {infoIcon && size !== "lg" && (
              <FontAwesomeIcon
                icon={faCircleInfo}
                className={`protocolSelect__title-info ${infoDisabled ? "is-muted" : ""}`}
              />
              )}
            </div>
            {rule && (
            <div className="protocolSelect__rule" style={protocolColor()}>
              {rule}
            </div>
            )}
            {protocol.description && showDescription && !centered && (
            <div className="protocolSelect__description">
              {date || _.unescape(protocol.description)}
            </div>
            )}
          </div>
        </div>
      </div>
      {protocol.description && showDescription && centered && (
      <div className="protocolSelect__description-outside">
        {date || _.unescape(protocol.description) }
      </div>
      )}
    </div>
  );
}

export default ProtocolSelectContent;
