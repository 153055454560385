import "./SensorsBar.scss";

export function SensorsBar({ signalCheck }) {
  const sensorsLocation = ["LE", "LF", "RF", "RE"];

  return (
    <div className="sensorsBar__signal-check">
      {signalCheck.map(
        (sensor, i) => sensor !== null && (
        <div key={i.toString()} className={`signalCheck__sensor bg-s${sensor}`}>
          {sensorsLocation[i]}
        </div>
        ),
      )}
    </div>
  );
}

export default SensorsBar;
