import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { Container } from "react-bootstrap";

import {
  HeaderMenu, Spinner, EmptyData, CustomTabs,
} from "components";
import { changeGraphProtocol, changeProgressView } from "store/actions/reporting";
import { getPastProtocols } from "api/services/reporting";

import { faChartLine } from "@fortawesome/free-solid-svg-icons";

import ProgressChartController from "./ProgressCharts/ProgressChartController";
import ProgressGoal from "./ProgressGoal/ProgressGoal";
import ProgressList from "./ProgressList/ProgressList";

import "./Progress.scss";

function ProgressPage() {
  const dispatch = useDispatch();

  const userProtocol = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.selected_protocol?.levelID,
  );
  const graphProtocol = useSelector(
    ({ reportingReducer }) => reportingReducer.graphProtocol,
  );
  const activeTab = useSelector(
    ({ reportingReducer }) => reportingReducer.progressView,
  );

  const pastProtocols = useQuery({
    queryKey: ["past-protocols"],
    queryFn: () => getPastProtocols(),
    staleTime: Infinity,
  });

  useEffect(() => {
    if (pastProtocols.isSuccess) {
      if (!graphProtocol) {
        const protocol = pastProtocols.data?.find(({ levelID }) => levelID === userProtocol);
        dispatch(changeGraphProtocol(protocol || pastProtocols.data[0]));
      }
    }
  }, [pastProtocols.isSuccess]);

  const onTabChange = (tab) => {
    dispatch(changeProgressView(tab));
  };

  window.nativeBack = () => false;

  return (
    <>
      <HeaderMenu />
      <Container>
        {pastProtocols.isPending && (
          <Spinner className="chart-loader__spinner" />
        )}
        {pastProtocols.isSuccess && pastProtocols.data.length > 0 && (
          <div className="progress-container">
            <CustomTabs
              tabs={["Practice", "Protocols", "Sessions"]}
              defaultTabKey={activeTab}
              onTabChange={(tab) => onTabChange(tab)}
              activeTab={activeTab}
              activeScroll
            />
            {activeTab === "Practice" && (
              <ProgressGoal />
            )}
            {activeTab === "Protocols" && (
              <ProgressChartController pastProtocols={pastProtocols} />
            )}
            {activeTab === "Sessions" && (
              <ProgressList pastProtocols={pastProtocols} />
            )}
          </div>
        )}
        {pastProtocols.isSuccess && pastProtocols.data.length === 0 && (
          <EmptyData
            icon={faChartLine}
            title="No sessions yet"
            text="Start meditation to track your progress."
          />
        )}
      </Container>
    </>
  );
}

export default ProgressPage;
