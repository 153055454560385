export default function percentageHalfZones(fullData, thresholds, zoneCounter) {
  const [rewardThreshold, reminderThreshold] = thresholds;
  const half = Math.ceil(fullData.length / 2);
  const firstHalfMarkers = fullData.slice(0, half);
  const secondHalfMarkers = fullData.slice(half);

  const halfesZoneCounter = (type, total, threshold) => {
    const countMarkers = (data) => {
      const counter = data.filter((marker) => {
        if (type === "reminders" && marker >= threshold) {
          return true;
        }
        if (type === "rewards" && marker <= threshold) {
          return true;
        }
        return false;
      }).length;
      return counter;
    };

    const findPercentage = (halfMarkers, totalMarkers) => {
      const percentage = Number(((100 * halfMarkers) / totalMarkers).toFixed()) || 0;
      return percentage;
    };

    const firstHalf = countMarkers(firstHalfMarkers);
    const firstHalfPercentage = findPercentage(firstHalf, total);
    const secondHalf = countMarkers(secondHalfMarkers);
    const secondHalfPercentage = findPercentage(secondHalf, total);

    return {
      firstHalf: firstHalfPercentage,
      secondHalf: secondHalfPercentage,
    };
  };

  return {
    reminders: halfesZoneCounter("reminders", zoneCounter.reminders, reminderThreshold),
    rewards: halfesZoneCounter("rewards", zoneCounter.rewards, rewardThreshold),
  };
}
