import { Card, BluetoothDevice } from "components";
import { HelpModal } from "features";

import { ReactComponent as SuccessIcon } from "assets/icons_signal_success.svg";

import SignalInfo from "./components/SignalInfo";
import SignalSequences from "./components/SignalSequences";
import useSignalCheck from "./useSignalCheck";

import "./SignalCheck.scss";

function SignalCheck({
  onComplete, sessionPaused,
}) {
  const {
    sequences, streakRequired, streakInfo, isCompleted,
  } = useSignalCheck(onComplete, sessionPaused);

  return (
    <div className="signalCheck">
      <Card customClass="signalCheck__card">
        <h3>
          Signal Check
          {" "}
          <HelpModal toggleClass="link font-600" location="signal-check" helpIcon />
        </h3>
        <div className={`signalCheck__card-wrapper ${isCompleted ? "is-completed" : ""}`}>
          {isCompleted && (
            <div className="signalCheck__completed">
              <div className="signalCheck__completed-icon">
                <SuccessIcon />
              </div>
              <p>
                {streakRequired === 10
                  ? "Your signal is good!"
                  : "Signal quality still good!"}
              </p>
            </div>
          )}
          <SignalSequences sequences={sequences} />
          <SignalInfo
            completed={isCompleted}
            info={streakInfo}
          />
        </div>
      </Card>
      <BluetoothDevice showBattery />
      <div className="signalGuide">
        <ol>
          <li>
            <strong>Turn on your Muse headset</strong>
            {" "}
            and place it on your head.
          </li>
          <li>
            Bluetooth status above should be
            <strong> green and connected</strong>
            .
          </li>
          <li>
            <strong>Fit the headband snuggly</strong>
            {" "}
            on your forehead and behind your ears.
            Make sure your glasses and hair are out of the way so all sensors are touching skin.
            {" "}
            <strong>Be still because muscle movement drowns out EEG signals</strong>
            .
          </li>
          <li>
            <strong>Get ten sequences</strong>
            {" "}
            of strong signal to proceed.
            {" "}
            <HelpModal toggleClass="link font-600" location="signal-check">
              Tips for Getting a Good Signal
            </HelpModal>
            .
          </li>
        </ol>
      </div>
    </div>
  );
}

export default SignalCheck;
