import { useMediaQuery } from "react-responsive";
import { Outlet } from "react-router-dom";

import logo from "assets/logo2.png";

export function MobileRoute() {
  return (useMediaQuery({ maxWidth: 1024 }) ? <Outlet /> : (
    <div className="login--screen-center">
      <div>
        <img src={logo} alt="GoDeeper" />
      </div>
      <div className="login--description">
        Please run on mobile device...
      </div>
    </div>
  ));
}

export default MobileRoute;
