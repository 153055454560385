import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSignalStreamSuccess, setSignalCheckCompleted } from "store/actions/session";
import { museController } from "utils/native-controllers";

export default function useSessionError(sessionData, startTimer, sessionCompleted, remainingTime) {
  const dispatch = useDispatch();

  const {
    appActive, btDevice, streamError, signalCheck, signalCheckSeq,
  } = useSelector(({ sessionReducer }) => sessionReducer);

  const [error, setError] = useState(null);
  const timer = useRef({ stream: null, bt: null });
  const errorData = useRef({ seq: null, seqStreak: 0, signalStreak: 0 });

  useEffect(() => () => {
    if (sessionCompleted) {
      clearTimeout(timer.current.stream);
      clearTimeout(timer.current.bt);
    }
  }, [sessionCompleted]);

  useEffect(() => {
    const signalData = signalCheck.filter((sensor) => sensor !== null);
    if (
      signalData.some((sensor) => [0, 1].includes(sensor))
      || signalData.filter((sensor) => sensor === 2).length > 1
      || sessionData.length === errorData.current.length
    ) {
      if (signalData.every((sensor) => sensor === 0)) museController("restart");
      errorData.current.signalStreak += signalData.every((sensor) => sensor <= 1) ? 2 : 1;
    } else {
      errorData.current.signalStreak = 0;
    }
    if (errorData.current.seq === signalCheckSeq) {
      errorData.current.seqStreak += 1;
    } else {
      errorData.current.seqStreak = 1;
    }
    errorData.current.seq = signalCheckSeq;
    if (errorData.current.seqStreak === 4 && !sessionCompleted) {
      dispatch(getSignalStreamSuccess({
        sequenceNumber: signalCheckSeq, signalCheck: [0, 0, 0, 0],
      }));
    }
    if (errorData.current.signalStreak >= 8 && !sessionCompleted) {
      setError("Signal quality has dropped.");
    }
  }, [remainingTime, sessionCompleted]);

  useEffect(() => {
    if (!appActive && startTimer) {
      setError("Application state has changed.");
    }
  }, [appActive]);

  useEffect(() => {
    if (btDevice.status === 5) {
      clearTimeout(timer.current.bt);
    } else if (btDevice.status === 1 && !sessionCompleted) {
      timer.current.bt = setTimeout(() => {
        setError("Muse device has disconnected.");
      }, 3000);
    }
  }, [btDevice, sessionCompleted]);

  useEffect(() => {
    if (streamError?.rc && !sessionCompleted) {
      const err = { ...streamError, timeout: 0 };
      if (streamError.rc === -12) {
        err.msg = "No Signal form Muse Device.";
        err.timeout = 1000;
      }
      if (streamError.rc === -10) {
        err.msg = "Insufficient data to produce record. Stay still and try again.";
      }
      timer.current.stream = setTimeout(() => {
        setError(err?.msg || "Unknown error.");
      }, err.timeout);
    } else if (!streamError) {
      clearTimeout(timer.current.stream);
    }
  }, [streamError]);

  useEffect(() => {
    if (error) {
      dispatch(setSignalCheckCompleted(null));
      clearTimeout(timer.current.stream);
      clearTimeout(timer.current.bt);
    }
  }, [error]);

  return error;
}
