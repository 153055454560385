import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card, GenericButton, BottomBarSticky, CompletedStep, BluetoothDevice,
} from "components";
import { setOnboardingTab } from "store/actions/onBoarding";
import SignalCheck from "pages/App/Common/SignalCheck/SignalCheck";

function SignalCheckPage({ tabIndex, isCompleted }) {
  const dispatch = useDispatch();

  const { btDevice } = useSelector(
    ({ sessionReducer }) => sessionReducer,
  );

  const [stage, setStage] = useState("preparation");

  const onContinue = () => {
    dispatch(setOnboardingTab(tabIndex + 1));
  };

  return (
    !isCompleted
      ? (
        <>
          {stage === "preparation" && (
            <>
              <Card customClass="card-text mb-3">
                <h3>Check Signal Quality</h3>
                <p>
                  Turn on your Muse headset and place it on your head.
                </p>
                <p>
                  It is important to stay still during the signal check and while meditating.
                  Muscles make electrical noises that drown out brainwaves.
                </p>
                <p>
                  If you are ready, hit
                  {" "}
                  <strong>Continue</strong>
                  .
                </p>
              </Card>
              <BluetoothDevice customClass="mb-2" />
              <BottomBarSticky>
                <GenericButton
                  className="button--dark"
                  text="Continue"
                  onButtonClick={() => setStage("signal")}
                  disabled={btDevice.status !== 5}
                />
              </BottomBarSticky>
            </>
          )}
          {stage === "signal" && (
          <SignalCheck onComplete={onContinue} />)}
        </>
      )
      : (
        <>
          <CompletedStep text="Your Signal Check is completed" />
          <BottomBarSticky>
            <GenericButton
              className="button--dark"
              text="Continue"
              onButtonClick={onContinue}
            />
          </BottomBarSticky>
        </>
      )
  );
}

export default SignalCheckPage;
