/* eslint-disable camelcase */
import {
  useState, useRef, useEffect, useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import useProtocolCertification from "hooks/useProtocolCertification";
import { saveUserProfile } from "store/actions/profile";
import { audioController } from "utils/native-controllers";

import AUDIO_FILES from "constants/profile_audio";

export default function useAudioFeedback(nextUrl) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const audioFeedbackOptions = useSelector(
    ({ onBoardingReducer }) => onBoardingReducer.onBoardingChoices?.audio_feedback,
  );
  const userProfile = useSelector(
    ({ profileReducer }) => profileReducer.userProfile,
  );

  const protocolCertification = useProtocolCertification(
    userProfile.selected_protocol.levelID,
    userProfile.selected_protocol.family,
    userProfile.selected_protocol.rule,
  );

  const [selectedOption, setSelectedOption] = useState({
    background_sound: userProfile.background_sound,
    movement_sound: userProfile.movement_sound,
    reward_sound: userProfile.reward_sound,
    wondering_reminder_sound: userProfile.wondering_reminder_sound,
    tap_sound: userProfile.tap_sound,
    tap_haptic: userProfile.tap_haptic,
  });
  const [selectedTimeOption, setSelectedTimeOption] = useState({
    sounds_after_seconds_deep: userProfile.sounds_after_seconds_deep,
    sounds_after_seconds_wondering: userProfile.sounds_after_seconds_wondering,
  });
  const [selectedSoundVolume, setSelectedSoundVolume] = useState({
    blue_background_volume: userProfile.blue_background_volume,
    green_background_volume: userProfile.green_background_volume,
    movement_volume: userProfile.movement_volume,
    red_background_volume: userProfile.red_background_volume,
    reward_volume: userProfile.reward_volume,
    wondering_reminder_volume: userProfile.wondering_reminder_volume,
    tap_volume: userProfile.tap_volume,
  });
  const noSound = ["Silence", "No Sound"];

  const audioPlayer = useRef({ timer: null, file: null });
  const [activeSound, setActiveSound] = useState();

  useEffect(() => () => {
    audioController("stopAll");
  }, []);

  const stopSound = () => {
    clearTimeout(audioPlayer.current.timer);
    audioPlayer.current.timer = setTimeout(() => {
      setActiveSound(null);
      audioController("stop", audioPlayer.current.file);
      audioPlayer.current = { timer: null, file: null };
    }, 3000);
  };

  const playSound = (category, key, val, volume) => {
    if (!noSound.includes(key)) {
      setActiveSound(key);
      const fileName = AUDIO_FILES[category].find(({ value }) => value === val).file;
      clearTimeout(audioPlayer.current.timer);
      if (audioPlayer.current.file !== fileName) {
        audioController("stop", audioPlayer.current.file);
        audioController(category === "tap_sound" ? "play" : "loop", fileName, volume);
        audioPlayer.current.file = fileName;
      } else if (category === "tap_sound") {
        audioController("stop", audioPlayer.current.file);
        audioController("play", fileName, volume);
      } else {
        audioController("volume", fileName, volume);
      }
    } else {
      setActiveSound(null);
      clearTimeout(audioPlayer.current.timer);
      audioController("stop", audioPlayer.current.file);
      audioPlayer.current = { timer: null, file: null };
    }
  };

  const onOptionsSelect = (fieldName, key, selectedItemValue) => {
    setSelectedOption({
      ...selectedOption,
      [fieldName]: selectedItemValue,
    });
    if (key && !noSound.includes(key)) {
      playSound(fieldName, key, selectedItemValue, 1);
      stopSound();
      if (fieldName === "tap_sound" && selectedOption.tap_haptic) audioController("haptic", "impactMedium");
    }
  };

  const onTimeOptionsSelect = (selectedItemValue, fieldName) => {
    setSelectedTimeOption({
      ...selectedTimeOption,
      [fieldName]: selectedItemValue[0],
    });
  };

  const onRangeOptionChange = useCallback((volumeVal, fieldName, field, options) => {
    setSelectedSoundVolume((state) => ({
      ...state,
      [fieldName]: volumeVal,
    }));
    const soundOption = options.find(({ value }) => value === selectedOption[field]);
    if (soundOption) playSound(field, soundOption.key, soundOption.value, volumeVal / 10);
  }, [selectedOption]);

  const onSave = () => {
    const options = nextUrl ? { navigate, nextUrl } : null;
    dispatch(saveUserProfile({
      ...selectedSoundVolume,
      ...selectedTimeOption,
      ...selectedOption,
    }, options));
  };

  const soundScapeLabels = {
    blue_background_volume: "Soundscape Volume Above Reward Threshold:",
    green_background_volume: "Volume Between Baseline and Reward Threshold:",
    red_background_volume: "Soundscape Volume Below Baseline:",
  };

  const isVolumeSection = (fieldName, options) => {
    const { key } = options.find(({ value }) => value === selectedOption[fieldName]);
    return !noSound.includes(key);
  };

  return {
    isCertified: protocolCertification.global?.certified,
    audioFeedbackOptions,
    soundScapeLabels,
    selectedOption,
    selectedTimeOption,
    selectedSoundVolume,
    activeSound,
    stopSound,
    onOptionsSelect,
    onTimeOptionsSelect,
    onRangeOptionChange,
    onSave,
    isVolumeSection,
  };
}
