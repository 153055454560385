import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";

import {
  SubHeader,
  InputText,
  GenericButton,
  ErrorValidation,
} from "components";
import { registerUser } from "store/actions/authentication";
import useFormData from "hooks/useFormData";
import { REGISTRATION_FORM } from "constants/forms_data";

import "./Registration.scss";

function Registration() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isUserRegistering } = useSelector(({ authenticationReducer }) => authenticationReducer);

  const {
    formData, formErrors, setFormValue, formValidate,
  } = useFormData(REGISTRATION_FORM);

  const onRegister = async (e) => {
    e.preventDefault();
    if (window.ReactNativeWebView) {
      const response = await dispatch(registerUser(formData));
      formValidate(response, () => navigate("/app"));
    } else {
      toast.error("You can sign up only within mobile app!");
    }
  };

  window.nativeBack = () => {
    navigate(-1);
  };

  return (
    <Container className="login__container">
      <SubHeader
        title="Sign Up"
        text="Fill out your user information."
      />
      <Form onSubmit={(e) => onRegister(e)} autoComplete="on" noValidate>
        {REGISTRATION_FORM.map((input) => (
          <div key={input.key}>
            <InputText
              inputType={input.inputType}
              placeholder={input.placeholder}
              autocomplete={input.autocomplete}
              defaultValue={formData[input.key]}
              onChangeValue={(e) => setFormValue(e.target.value, input.key)}
              isValid={!formErrors[input.key]}
              inputMode={input.key}
            />
            <ErrorValidation errors={formErrors[input.key]} />
          </div>
        ))}
        <div className="login__button-container">
          <GenericButton
            type="submit"
            text="Sign Up with Email"
            className="button--success"
            disabled={isUserRegistering}
          />
        </div>
      </Form>
      <div className="login__registration-container">
        <div>
          If you already have an account,
          <GenericButton
            text="Sign In"
            onButtonClick={() => navigate(-1)}
            buttonType="text"
            className="button-link"
          />
        </div>
      </div>
    </Container>
  );
}

export default Registration;
