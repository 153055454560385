const REGISTRATION_FORM = [
  {
    key: "email",
    placeholder: "Your Email",
    inputType: "email",
    autocomplete: "email",
    inputMode: "email",
  },
  {
    key: "password1",
    placeholder: "Enter Password",
    inputType: "password",
  },
  {
    key: "password2",
    placeholder: "Confirm Password",
    inputType: "password",
  },
  {
    key: "first_name",
    placeholder: "First Name",
    inputType: "text",
    autocomplete: "given-name",
  },
  {
    key: "last_name",
    placeholder: "Last Name",
    inputType: "text",
    autocomplete: "family-name",
  },
];

const LOGIN_FORM = [
  {
    key: "email",
    placeholder: "Your Email",
    inputType: "email",
    autocomplete: "email",
    inputMode: "email",
  },
  {
    key: "password",
    placeholder: "Enter Password",
    inputType: "password",
  },
];

const RESET_PASS_FORM = [
  {
    key: "email",
    placeholder: "Your Email",
    inputType: "email",
    autocomplete: "email",
    inputMode: "email",
  },
];

const CHANGE_PASS_FORM = [
  {
    key: "old_password",
    placeholder: "Old password",
    inputType: "password",
  },
  {
    key: "new_password1",
    placeholder: "New Password",
    inputType: "password",
  },
  {
    key: "new_password2",
    placeholder: "Confirm New Password",
    inputType: "password",
  },
];

const CHANGE_USER_FORM = [
  {
    key: "first_name",
    placeholder: "First Name",
    inputType: "text",
    autocomplete: "given-name",
  },
  {
    key: "last_name",
    placeholder: "Last Name",
    inputType: "text",
    autocomplete: "family-name",
  },
  {
    key: "email",
    placeholder: "Your Email",
    inputType: "email",
    autocomplete: "email",
    inputMode: "email",
  },
];

const BRAINWAVE_FORM = [
  {
    key: "handedness",
    placeholder: "Select your handedness:",
    size: (i) => (i === 2 ? 12 : 6),
  },
  {
    key: "sex",
    placeholder: "Select your gender:",
    size: 4,
  },
  {
    key: "birthday",
    placeholder: "Select your birthday:",
    size: 12,
  },
  {
    key: "eyes",
    placeholder: "Do you meditate with:",
    size: 12,
  },
  {
    key: "region",
    placeholder: "Select your region:",
    size: 6,
  },
];

const CALCULATOR_FORM = [
  {
    title: "I usually meditate:",
    inputs: [{
      key: "minutes",
      label: "minutes per sessions",
    }, {
      key: "sessions",
      label: "sessions per day",
    }, {
      key: "days",
      label: "days per week",
    }],
  }, {
    title: "I have been meditating for:",
    inputs: [{
      key: "years",
      label: "years",
    }, {
      key: "months",
      label: "months",
    }],
  }, {
    title: "I have been meditating in retreats for:",
    inputs: [{
      key: "retreats",
      label: "days",
    }],
  },
];

const REFER_USER_FORM = [
  {
    key: "name",
    placeholder: "Name",
    inputType: "text",
    autocomplete: "name",
  },
  {
    key: "email",
    placeholder: "Email",
    inputType: "email",
    autocomplete: "email",
    inputMode: "email",
  },
];

const CALCULATOR_LIMITS = {
  minutes: 240,
  sessions: 24,
  days: 7,
  years: 70,
  months: 11,
  retreats: 365,
};

export {
  REGISTRATION_FORM, LOGIN_FORM, RESET_PASS_FORM, CHANGE_PASS_FORM, REFER_USER_FORM,
  CHANGE_USER_FORM, BRAINWAVE_FORM, CALCULATOR_FORM, CALCULATOR_LIMITS,
};
