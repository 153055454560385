import { useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ResponsiveBar } from "@nivo/bar";
import { line, curveStepBefore } from "d3-shape";
import { format } from "date-fns";

import { changeGraphStatus } from "store/actions/reporting";
import ChartLoader from "features/ProgressGraphs/ChartLoader";
import { useMediaQuery } from "react-responsive";
import usePinch from "hooks/usePinch";

import FAMILY_COLORS from "constants/family_colors";

function GoalLayer({
  bars, goals, yScale, innerWidth,
}) {
  const extendedGoals = [
    { x: "first", y: goals[0].y },
    ...goals,
    { x: "last", y: goals[goals.length - 1].y }];

  const barPositionX = (goal) => {
    if (goal === "first") return 0;
    if (goal === "last") return innerWidth;
    const bar = bars.find((item) => item.data.data.x === goal);
    return bar ? bar.x + bar.width / 2 : 0;
  };
  const lineGenerator = line()
    .x((goal) => barPositionX(goal.x))
    .y((goal) => yScale(goal.y))
    .curve(curveStepBefore);

  return (
    <>
      <text
        x={innerWidth - 50}
        y={yScale(goals[goals.length - 1].y) - 5}
        fontSize={11}
        fill="#61BCA0"
        stroke="white"
        strokeWidth={1}
        paintOrder="stroke fill"
      >
        Your Goal
      </text>
      <path
        d={lineGenerator(extendedGoals)}
        fill="none"
        stroke="#61BCA0"
        strokeWidth={2}
        strokeDasharray={2}
        pointerEvents="none"
      />
    </>
  );
}

function TimeChart({ data }) {
  const dispatch = useDispatch();
  const { graphStatus } = useSelector(({ reportingReducer }) => reportingReducer);

  const pinchRef = useRef(null);
  usePinch({
    element: pinchRef,
    min: 8,
    max: 20,
    current: graphStatus.range,
    onChange: (range) => dispatch(changeGraphStatus({ ...graphStatus, range })),
    rangeMode: true,
  });

  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const { range, period } = useSelector(
    ({ reportingReducer }) => reportingReducer.graphStatus,
  );

  const { isPending, isPlaceholderData, data: chartData } = data;

  const filteredData = useMemo(() => {
    if (!isPending) {
      return chartData?.data?.map((serie) => ({
        ...serie,
        data: serie.data.slice(-range),
      }));
    }
    return null;
  }, [chartData, range]);

  const [time, goal] = filteredData || [];

  const maxScale = useMemo(() => {
    if (!filteredData) return null;
    const seriesMax = filteredData.map((serie) => Math.max(...serie.data.map(({ y }) => y)));
    const max = Math.max(...seriesMax);
    return max + (max * 0.15);
  }, [filteredData]);

  const formatDate = (date) => {
    const reversedDate = new Date(date.split("/").reverse().join("-"));
    return format(reversedDate, period === "Month" ? "LLL" : "d LLL");
  };

  return (
    <ChartLoader
      loading={isPending || isPlaceholderData}
      isData={isPending || filteredData?.[0].data.length > 0}
      pinchInfo
    >
      <div className="chart-container chart-container--timechart" ref={pinchRef}>
        {!!filteredData?.length && (
          <ResponsiveBar
            data={time.data}
            keys={chartData?.family}
            indexBy="x"
            theme={{
              background: "transparent",
              text: {
                fontSize: 11,
                fontFamily: "Quicksand",
                fill: "#171d66",
              },
            }}
            colorBy="id"
            colors={chartData?.family.map((family) => FAMILY_COLORS[family])}
            margin={{
              top: 15, right: 20, bottom: 50, left: 60,
            }}
            padding={0.4}
            borderRadius={3}
            innerPadding={1}
            minValue={0}
            maxValue={maxScale}
            axisBottom={{
              tickValues: range,
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -90,
              format: (date) => formatDate(date),
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              legend: "Meditation Time in Minutes",
              legendPosition: "middle",
              legendOffset: -50,
            }}
            layers={[
              "grid",
              "bars",
              (dataLayer) => GoalLayer({ ...dataLayer, goals: goal.data }),
              "axes",
            ]}
            isInteractive={!isMobile}
            enableLabel={false}
          />
        )}
      </div>
    </ChartLoader>
  );
}

export default TimeChart;
