import { useState } from "react";
import { Container } from "react-bootstrap";
import { CoachNav, DownloadButton, CustomTabs } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faChartLine } from "@fortawesome/free-solid-svg-icons";
import { CoachTable } from "features";
import { getTestBatches, getTestSessions, getTestReport } from "api/services/coach";

import BatchDetailsModal from "features/BatchDetailsModal/BatchDetailsModal";

import "pages/Coach/Dashboard/Dashboard.scss";

function Tests() {
  const [batchId, setBatchId] = useState(null);
  const [activeFilter, setActiveFilter] = useState({});
  const [activeTable, setActiveTable] = useState("Batches");
  const [showModal, setShowModal] = useState(false);

  const onShowDetails = (id) => {
    setBatchId(id);
    setShowModal(true);
  };

  const getSessions = async (filter) => {
    setActiveFilter(filter);
    return getTestSessions(filter);
  };

  const batchTable = {
    title: "Test batches",
    tableName: "test-batches",
    tableType: 4,
    getdata: (filter) => getTestBatches(filter),
    stickyColumn: {
      title: "Details",
      id: "details",
      data: "id",
      element: {
        icon: <FontAwesomeIcon icon={faList} />,
        action: (id) => onShowDetails(id),
      },
    },
  };
  const resultsTable = {
    title: "Test Results",
    tableName: "test-sessions",
    tableType: 7,
    getdata: (filter) => getSessions(filter),
    tabsGroup: "test_sessions",
    stickyColumn: {
      title: "Summary",
      id: "summary",
      data: "sessionId",
      element: {
        icon: <FontAwesomeIcon icon={faChartLine} />,
        path: (clientId, sessionId) => `/coach/clients/${clientId}/session-summary/${sessionId}`,
      },
    },
  };

  return (
    <div className="coach-panel">
      <CoachNav />
      <div className="dashboard">
        <Container className="dashboard__header">
          <h1>Dashboard</h1>
          <div className="dashboard__tabs">
            <CustomTabs
              tabs={["Batches", "Results"]}
              defaultTabKey={activeTable}
              onTabChange={(tab) => setActiveTable(tab)}
              activeTab={activeTable}
            />
          </div>
        </Container>
        {activeTable === "Results" && (
          <Container>
            <div className="dashboard__actions">
              <DownloadButton
                fileName="sessions_report.zip"
                className="dashboard__actions-button text-sm"
                text="Download Reports"
                pendingText="Generating..."
                getDownloadUrl={() => getTestReport(activeFilter)}
              />
            </div>
          </Container>
        )}
        {activeTable === "Results" && <CoachTable {...resultsTable} />}
        {activeTable === "Batches" && (
          <>
            <CoachTable {...batchTable} />
            <BatchDetailsModal
              showModal={showModal}
              setShowModal={setShowModal}
              batchId={batchId}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default Tests;
