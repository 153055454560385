import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { ProtocolSelect, SelectPicker } from "components";
import { changeGraphProtocol } from "store/actions/reporting";

import "./ChartProtocol.scss";

function ChartProtocol({ data }) {
  const dispatch = useDispatch();

  const graphProtocol = useSelector(({ reportingReducer }) => reportingReducer.graphProtocol);

  const { isPending, data: pastProtocols } = data;

  const [selectedProtocol, setSelectedProtocol] = useState([0]);

  const sortedProtocols = useMemo(
    () => (_.sortBy(pastProtocols, ["protocol", "level"])),
    [pastProtocols],
  );

  const pickerData = useMemo(() => {
    const options = sortedProtocols.map(
      (protocol) => `${protocol.protocol}  <span class="level is-${protocol.level}">Lv. ${protocol.level}</span>`,
    );
    const values = sortedProtocols.map((_protocol, i) => i);
    return { options, values };
  }, [sortedProtocols]);

  useEffect(() => {
    const index = sortedProtocols?.findIndex(
      ({ levelID }) => levelID === graphProtocol?.levelID,
    );
    setSelectedProtocol(index >= 0 ? [index] : [0]);
  }, [graphProtocol]);

  const onProtocolSelect = (index) => {
    dispatch(changeGraphProtocol(sortedProtocols[index[0]]));
  };

  return (
    <div className="chart-protocol-wrapper">
      {!isPending && (
      <SelectPicker
        options={[pickerData.options]}
        values={[pickerData.values]}
        initialValue={selectedProtocol}
        onSelect={(value) => onProtocolSelect(value)}
        buttonText={`${graphProtocol?.protocol} Lv. ${graphProtocol?.level}` || "Pick Protocol"}
        labels={["protocol"]}
      >
        <ProtocolSelect
          protocolID={sortedProtocols[selectedProtocol].protocolID}
          categoryID={sortedProtocols[selectedProtocol].categoryID}
          level={graphProtocol?.level}
        />
      </SelectPicker>
      )}
    </div>
  );
}

export default ChartProtocol;
