import { useState, useEffect } from "react";

import "./Select.scss";

export function Select({
  text, selectedItem, customClass = "", defaultActive, size = "md", isRadio, disabled = false,
}) {
  const [isActive, setIsActive] = useState(defaultActive);

  const setActiveSelect = (isAcive) => {
    if (disabled) return;
    if (isRadio) {
      setIsActive(true);
      selectedItem(true);
    } else {
      setIsActive(!isAcive);
      selectedItem(!isAcive);
    }
  };

  useEffect(() => {
    setIsActive(defaultActive);
  }, [defaultActive]);

  return (
    <div
      onClick={() => setActiveSelect(isActive)}
      className={`${customClass} select select--${size} ${isActive ? "select--active" : ""} ${disabled ? "select--disabled" : ""}`}
      role="presentation"
    >
      <div className="select-text">{text}</div>
      <div className="select-check--default">
        <div className="select-check--active"> </div>
      </div>
    </div>
  );
}

export default Select;
