import {
  useState, useEffect, useMemo, useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format, sub } from "date-fns";

import { CustomTabs, Card } from "components";
import { Modal } from "react-bootstrap";
import useProtocol from "hooks/useProtocol";
// import { getTotalProtocolCertifications } from "api/services/protocols";
import {
  getProtocolMetrics, getProtocolProgress,
} from "api/services/reporting";
import { changeGraphStatus } from "store/actions/reporting";
import { isAllowed } from "utils/user-permissions";
import { addCalibrationRule, setProtocolToCalibrate } from "store/actions/protocols";
import {
  PROGRESS_GRAPH_TABS,
  PROGRESS_GRAPH_TABS_KEYS,
  UNCERTIFIED_PROGRESS_TABS_KEYS,
} from "constants/profile_progress_charts";
import UNLOCKABLE_CONTENT from "constants/unlockable_content";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import AudioGuide from "features/AudioGuide/AudioGuide";
import ZoneChart from "features/ProgressGraphs/ZoneChart/ZoneChart";
import StatsChart from "features/ProgressGraphs/StatsChart/StatsChart";
import DepthChart from "features/ProgressGraphs/DepthChart/DepthChart";
import MetricsChart from "features/ProgressGraphs/MetricsChart/MetricsChart";
import TapsChart from "features/ProgressGraphs/TapsChart/TapsChart";
import ChartHelp from "features/ProgressGraphs/ChartHelp";
import ChartStatus from "features/ProgressGraphs/ChartStatus";
import ChartProtocol from "features/ProgressGraphs/ChartProtocol";
import InfoRules from "features/BrainwaveInterpretation/BrainwaveInfo/InfoRules";
import useProtocolCertification from "hooks/useProtocolCertification";
import useMetrics from "./hooks/useMetrics";

import "./ProgressChartController.scss";

function ProgressChartController({ pastProtocols }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const dataLength = 20;

  const userId = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.pk,
  );
  const userLevel = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.subscription_level_id,
  );
  const graphProtocol = useSelector(
    ({ reportingReducer }) => reportingReducer.graphProtocol,
  );
  const graphStatus = useSelector(({ reportingReducer }) => reportingReducer.graphStatus);

  const { activeTab, period } = graphStatus;

  const isSuper = isAllowed(userLevel, "SUPER");

  const protocolDetails = useProtocol(graphProtocol?.categoryID, graphProtocol?.protocolID);

  // TODO: restore when needed
  // const { data: totalCerifications } = useQuery({
  //   queryKey: ["total-certification-progress"],
  //   queryFn: () => getTotalProtocolCertifications(),
  //   staleTime: Infinity,
  //   refetchOnMount: "always",
  // });

  const protocols = useSelector(
    ({ protocolsReducer }) => protocolsReducer.protocols,
  );

  const calibration = useMemo(() => (
    protocols?.find(
      (protocol) => protocol.thresholds.protocolIds.protocolID === graphProtocol?.protocolID
        && protocol.level === Number(graphProtocol?.level),
    )
  ), [protocols, graphProtocol]);

  const calibrateRule = useCallback(
    async (rule) => {
      if (calibration) {
        // eslint-disable-next-line no-underscore-dangle
        const success = await dispatch(addCalibrationRule(userId, calibration._id, rule));
        if (success) toast.success("Rule added to existing protocol calibration.");
      } else {
        dispatch(setProtocolToCalibrate({
          protocol: protocolDetails,
          level: { level: Number(graphProtocol.level), levelID: graphProtocol.levelID },
          rule,
        }));
        navigate("/app/meditation/protocol-calibration");
      }
    },
    [protocols, graphProtocol],
  );

  const statsData = useQuery({
    queryKey: ["stats-progress", userId, period, graphProtocol?.levelID],
    queryFn:
      () => getProtocolProgress(userId, period.toLowerCase(), dataLength, graphProtocol?.levelID),
    placeholderData: keepPreviousData,
    staleTime: Infinity,
    enabled: !!graphProtocol,
  });
  const metricsQuery = useQuery({
    queryKey: ["metrics-progress", userId, period, graphProtocol?.levelID],
    queryFn:
      () => getProtocolMetrics(userId, period.toLowerCase(), dataLength, graphProtocol?.levelID),
    placeholderData: keepPreviousData,
    staleTime: Infinity,
    enabled: isSuper && !!graphProtocol,
  });

  useEffect(() => {
    if (userLevel === 1 && activeTab === "Metrics") {
      dispatch(changeGraphStatus({ ...graphStatus, activeTab: "Depth" }));
    }
  }, []);

  const metricsData = useMetrics(
    metricsQuery,
    graphProtocol?.level,
  );

  const metricsRange = useMemo(
    () => {
      if (!graphStatus.metrics) return null;
      const date = graphStatus.metrics.split("/");
      const endDate = new Date(`${date[2]}-${date[1]}-${date[0]}`);
      if (graphStatus.period === "Day") return `from ${format(endDate, "MMM do, yyyy")}`;
      if (graphStatus.period === "Month") return `from ${format(endDate, "MMMM yyyy")}`;
      const startDate = sub(endDate, { weeks: 1 });
      return `from ${format(startDate, "MMM do")} to ${format(endDate, "MMM do, yyyy")}`;
    },
    [graphStatus.metrics, graphStatus.period],
  );

  // TODO change to family from graphProcotol (add to api)
  const protocolCertification = useProtocolCertification(
    graphProtocol?.levelID,
    protocolDetails?.levels
      ? protocolDetails?.levels.find((level) => level.levelID === graphProtocol?.levelID)?.family.id
      : "training",
    null,
    true,
  );
  const isCertified = protocolCertification.global?.certified;

  const [showModal, setShowModal] = useState(false);
  const [hiddenElement, setHiddenElement] = useState(false);
  const [hiddenRules, setHiddenRules] = useState([]);

  const renderChart = () => {
    switch (activeTab) {
      case PROGRESS_GRAPH_TABS_KEYS.METRICS:
        return isSuper && (
        <MetricsChart
          data={metricsData}
          hiddenRules={hiddenRules}
        />
        );
      case PROGRESS_GRAPH_TABS_KEYS.TAPS:
        return <TapsChart data={statsData} />;
      case PROGRESS_GRAPH_TABS_KEYS.DEPTH:
        return <DepthChart data={statsData} />;
      case PROGRESS_GRAPH_TABS_KEYS.ZONE:
        return <ZoneChart data={statsData} />;
      case PROGRESS_GRAPH_TABS_KEYS.STATS:
        return <StatsChart data={statsData} />;
      default:
        return <div className="progress-session-chart__wrapper-empty">Empty</div>;
    }
  };

  const renderHelp = () => {
    switch (activeTab) {
      case PROGRESS_GRAPH_TABS_KEYS.ZONE:
        return (
          <ChartHelp
            text="Time to Update Thresholds?"
            help="progress-zone"
          />
        );
      case PROGRESS_GRAPH_TABS_KEYS.STATS:
        return (
          <ChartHelp
            text="Are you getting more Trophies & Points?"
            help="progress-stats"
          />
        );
      case PROGRESS_GRAPH_TABS_KEYS.DEPTH:
        return (
          <ChartHelp
            text="Are you Going Deeper?"
            help="progress-depth"
          />
        );
      default:
        return null;
    }
  };

  const getAudioFile = () => {
    switch (activeTab) {
      case PROGRESS_GRAPH_TABS_KEYS.ZONE:
        return isCertified && "coach-progress-reward";
      case PROGRESS_GRAPH_TABS_KEYS.STATS:
        return isCertified && "coach-progress-stats";
      case PROGRESS_GRAPH_TABS_KEYS.DEPTH:
        return isCertified && "coach-progress-depth";
      case PROGRESS_GRAPH_TABS_KEYS.METRICS:
        return "coach-progress-metrics";
      default:
        return null;
    }
  };

  return (
    <div className="progress-sessions-charts">
      <div className="header">
        <h3 style={{ opacity: hiddenElement ? 0 : 1 }}>Progress by Protocol</h3>
        <AudioGuide track={getAudioFile()} setHiddenElement={setHiddenElement} detached />
      </div>
      <ChartProtocol data={pastProtocols} protocolDetails={protocolDetails} />
      {/* TODO: restore when needed */}
      {/* {totalCerifications && (
        <InfoProgress current={totalCerifications.certified} max={totalCerifications.total}>
          <p className="text-sm">
            With help from people like you, the IMBR has certified
            {" "}
            <span className="text-primary font-700">
              {`${totalCerifications.certified}/${totalCerifications.total} protocols!`}
            </span>
          </p>
        </InfoProgress>
      )} */}
      <div className="progress-sessions__graphs-tabs">
        <CustomTabs
          tabs={isSuper ? PROGRESS_GRAPH_TABS : PROGRESS_GRAPH_TABS.filter((tab) => tab !== "Metrics")}
          defaultTabKey={activeTab}
          onTabChange={(tab) => dispatch(changeGraphStatus({ ...graphStatus, activeTab: tab }))}
          activeTab={activeTab}
        />
      </div>
      <div className="progress-session-chart">
        <Card customClass="progress-session-chart__wrapper">
          {!UNCERTIFIED_PROGRESS_TABS_KEYS.includes(activeTab)
            && !isCertified && (
            <div className="progress-session-chart__certification">
              <h3 className="mb-0 font-700">
                This progress graphs is not available until
                a protocol has been certified as reliable.
              </h3>
              <p className="text-sm">
                We need at least 200 sessions from 30 people to certify a protocol.
                Help us get more protocols certified faster by sending experienced friends
                to IMBR.ORG to sign up to donate their brainwaves to meditation science.
              </p>
              <div
                onClick={() => setShowModal(true)}
                role="presentation"
                className="text-sm font-700 text-decoration-underline help-toggle"
              >
                <FontAwesomeIcon icon={faQuestionCircle} />
                What Happens When a Protocol Gets Certified?
              </div>
            </div>
          )}
          <div className="progress-session-chart__wrapper-container">
            {renderChart()}
          </div>
          <div className="progress-session-chart__footer">
            <ChartStatus />
          </div>
          {renderHelp()}
        </Card>
        {isSuper && activeTab === PROGRESS_GRAPH_TABS_KEYS.METRICS && metricsData.isMetrics && (
          <div className="progress-session-metrics">
            <h3 className="mt-4 mb-0">Detailed Metrics Report Card</h3>
            <p className="text-muted text-sm">
              Scores
              {" "}
              {metricsRange}
            </p>
            <InfoRules
              protocolDetails={protocolDetails}
              rulesContent={metricsData.rulesContent}
              calibration={calibration}
              calibrateRule={calibrateRule}
              hiddenRules={hiddenRules}
              setHiddenRules={setHiddenRules}
              sort={false}
              isCollapsed
            />
          </div>
        )}
      </div>
      <Modal
        animation
        dialogClassName="dialog dialog-bg"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <h1 className="text-center">What Happens When a Protocol Gets Certified?</h1>
          <p className="text-center text-sm my-4">
            When a protocol is certified it means we have found the right combination of
            metrics to reliably measure depth for a particular style and level of
            meditation practice. This makes it possible to offer super-accurate audio
            feedback to help people go deeper, faster. We call it “GPS for meditation”.
          </p>
          {UNLOCKABLE_CONTENT.map((element) => (
            <Card
              key={element.title}
              customClass="pb-0 mb-4 text-center"
            >
              <h2>{element.title}</h2>
              <p className="text-sm mt-4">{element.caption}</p>
              <img src={element.img} alt={element.title} />
            </Card>
          ))}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ProgressChartController;
