import { useState, useEffect } from "react";

import {
  StarsRating, FeelSelector, Textarea,
} from "components";

import "./JournalForm.scss";

export function JournalForm({
  journalEntry, isEditable, isCertified, isRequired, setJournalData, isEdited, isTap, customClass = "",
}) {
  if (!journalEntry) return null;

  const initialData = {
    graphExperiance: Number(journalEntry.graphExperiance) || "",
    howDeepDidYouGo: Number(journalEntry.howDeepDidYouGo) || "",
    sessionFeeling: Number(journalEntry.sessionFeeling) || "",
    notes: journalEntry.notes || "",
  };

  const [formData, setFormData] = useState(initialData);

  const setInput = (value, key) => {
    setFormData({ ...formData, [key]: value });
  };

  useEffect(() => {
    if (!isEdited) setFormData(initialData);
  }, [isEdited]);

  useEffect(() => {
    setJournalData?.({ journalEntry: formData });
  }, [formData]);

  if (isTap || !isRequired) {
    return (
      <div className={`JournalForm ${customClass}`}>
        {isTap && (
          <div className="JournalForm__section">
            <StarsRating
              label="How reliable would you say your taps were for this session?"
              value={formData.graphExperiance}
              onChange={(value) => setInput(value, "graphExperiance")}
              readonly={!isEditable}
            />
          </div>
        )}
        {!isRequired && (
          <>
            {isCertified && (
            <div className="JournalForm__section">
              <StarsRating
                label="How well did the sound feedback reflect your experience?"
                value={formData.howDeepDidYouGo}
                onChange={(value) => setInput(value, "howDeepDidYouGo")}
                readonly={!isEditable}
              />
            </div>
            )}
            <div className="JournalForm__section">
              <h4>How did you feel about this session?</h4>
              <FeelSelector
                value={formData.sessionFeeling}
                onChange={(value) => setInput(value, "sessionFeeling")}
                readonly={!isEditable}
              />
            </div>
            <div className="JournalForm__section">
              <h4>What do you want to remember about this session?</h4>
              {isEditable ? (
                <Textarea
                  defaultValue={formData.notes}
                  onChangeValue={(e) => setInput(e.target.value, "notes")}
                  rows={5}
                />
              ) : (
                <p>{formData.notes ? formData.notes : (<span className="text-muted">No entry</span>)}</p>
              )}
            </div>
          </>
        )}
      </div>
    );
  }
  return null;
}

export default JournalForm;
