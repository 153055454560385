import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateProtocolCertificationQuery } from "api/services/protocols";
import { useQueryClient } from "@tanstack/react-query";

import {
  EmptyData, GenericButton, LoadingOverlay, ProtocolSelect,
} from "components";
import { saveUserProfile } from "store/actions/profile";
import { changeGraphProtocol } from "store/actions/reporting";
import { deleteCalibratedProtocol, deleteCalibrationRule } from "store/actions/protocols";
import { ReactComponent as IconEdit } from "assets/icons_edit.svg";
import { ReactComponent as IconClose } from "assets/icons_close.svg";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

import "./ProtocolCalibrated.scss";
import _ from "lodash";

function SessionProtocol({ isEditable = true }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const userSavedSelectedProtocol = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.selected_protocol,
  );
  const protocols = useSelector(
    ({ protocolsReducer }) => protocolsReducer.protocols,
  );
  const loggedInUserId = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.pk,
  );

  const [showDelete, setShowDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sortedProtocols = useMemo(() => _.orderBy(protocols, ["name", "level"], ["desc", "desc"]), [protocols]);

  const resetSavedProtocol = () => {
    dispatch(saveUserProfile({
      selected_protocol: {
        categoryID: null,
        protocolID: null,
        levelID: null,
        family: null,
        rule: null,
      },
      selected_protocol_name: "",
      selected_protocol_level: null,
    }));
  };

  const onDelete = (protocolToDelete, ruleToDelete = null) => {
    const { protocolIds } = protocolToDelete.thresholds;
    const { protocolID, levelID, rule } = userSavedSelectedProtocol;
    if (ruleToDelete) {
      if (protocolID === protocolIds.protocolID
        && levelID === protocolIds.levelID
        && rule === ruleToDelete) {
        resetSavedProtocol();
      }
      // eslint-disable-next-line no-underscore-dangle
      dispatch(deleteCalibrationRule(protocolToDelete._id, ruleToDelete));
    } else {
      if (protocolID === protocolIds.protocolID
        && levelID === protocolIds.levelID) {
        resetSavedProtocol();
      }
      dispatch(deleteCalibratedProtocol(loggedInUserId, protocolIds.levelID));
    }
  };

  const onSelectedItem = async (protocol, rule = null) => {
    setIsLoading(true);
    const protocolIds = {
      ...protocol.thresholds?.protocolIds,
      family: protocol.family.id,
      rule,
    };
    dispatch(changeGraphProtocol({
      categoryID: protocolIds.categoryID,
      level: protocol.level,
      levelID: protocolIds.levelID,
      protocol: protocol.name,
      protocolID: protocolIds.protocolID,
    }));
    await updateProtocolCertificationQuery(
      protocolIds.levelID,
      protocolIds.family,
      protocolIds.rule,
      queryClient,
    );
    await dispatch(saveUserProfile({
      selected_protocol: protocolIds,
      selected_protocol_name: protocol.name,
      selected_protocol_level: protocol.level,
      saved_session_difficulty: -Number(protocol.thresholds?.thresholds[0]),
    }, { navigate, nextUrl: "/app/meditation" }));
  };

  return protocols?.length > 0 ? (
    <>
      {isLoading && (
      <LoadingOverlay
        loadingText="Setting up protocol..."
      />
      )}
      <div className="protocolPage__list-edit">
        <h3>{!showDelete ? "Choose Calibrated Protocol" : "Delete Calibrated Protocol"}</h3>
        {isEditable && (
          <GenericButton
            onButtonClick={() => setShowDelete(!showDelete)}
            className="btn-sm"
            iconElement={!showDelete ? <IconEdit /> : <IconClose className="icon-close" />}
          />
        )}
      </div>
      <div className="protocolPage__select-list">
        {sortedProtocols.map((protocol) => protocol.name && (
        <div key={protocol.thresholds?.protocolIds?.levelID} className="protocolPage__select-wrapper">
          <ProtocolSelect
            protocol={protocol}
            showDelete={showDelete}
            onSelect={(_protocol, rule) => onSelectedItem(_protocol, rule)}
            onDelete={(_protocol, rule) => onDelete(_protocol, rule)}
          />
        </div>
        ))}
      </div>
    </>
  ) : (
    <EmptyData
      title="No calibration found"
      text="You need to calibrate at least one protocol before selecting it for session."
      icon={faArrowDown}
      customClass="protocolPage__list-empty mt-0"
    />
  );
}

export default SessionProtocol;
