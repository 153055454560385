import {
  useState, useMemo, useRef, useCallback,
} from "react";
import { Container } from "react-bootstrap";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { getSessionReport, deleteSessionReport } from "api/services/reporting";

import {
  SubNav, SessionScores, Spinner, GenericButton, Dialog,
  SessionProgressScores, Card, ProtocolSelectInfo,
} from "components";
import {
  SessionChart, SessionBadges, HelpModal,
} from "features";

import { getDepthData } from "features/BrainwaveInterpretation/helpers/helper";
import { DepthBarChart } from "features/BrainwaveInterpretation/DepthBarChart/DepthBarChart";
import useProtocol from "hooks/useProtocol";
import useProtocolCertification from "hooks/useProtocolCertification";
import { formatDuration } from "utils/time-format";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/fontawesome-free-regular";

import { SessionJournal } from "./components/SessionJournal";

import "./SessionSummary.scss";
import SessionMetrics from "./components/SessionMetrics";

function SessionSummaryPage() {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [activeChartType, setActiveChartType] = useState("timeline");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const scrollSync = useRef({ scrollX: 0, wrappers: [], zoom: 0 });

  const setScrollSync = useCallback((i) => ({
    setWrapper: (wrapperRef) => { scrollSync.current.wrappers[i] = wrapperRef; },
    setScrollX: (x) => { scrollSync.current.scrollX = x; },
    setZoom: (zoomLevel) => { scrollSync.current.zoom = zoomLevel; },
  }), []);

  const { data: summary } = useQuery({
    queryKey: ["session-summary", sessionId],
    queryFn: () => getSessionReport(sessionId),
    gcTime: Infinity,
    staleTime: Infinity,
  });

  const deleteSession = useMutation({
    mutationFn: () => deleteSessionReport(sessionId),
    onSuccess: async () => {
      toast.success("Session has been deleted.");
      const staleQueries = ["past-protocols", "past-sessions", "time-progress", "lifetime-progress", "stats-progress", "metrics-progress"];
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => staleQueries.includes(queryKey[0]),
      });
      navigate("/app/progress");
    },
    onError: async () => {
      setShowDeleteDialog(false);
    },
  });

  const sessionProtocol = useProtocol(
    summary?.sessionData?.protocol.categoryID,
    summary?.sessionData?.protocol.protocolID,
    summary?.protocolDetails,
  );

  const sessionLevel = useMemo(() => {
    const sessionLevelId = summary?.sessionData.protocol.levelID;
    return sessionLevelId?.split("/")[1];
  }, [summary]);

  const sessionRule = useMemo(() => {
    const ruleNo = summary?.sessionData.protocol.rule
      || summary?.protocolDetails?.protocolIds?.rule;
    if (ruleNo && sessionProtocol) {
      return sessionProtocol.protocolRules.find(
        (rule) => rule?.rule === ruleNo,
      );
    }
    return null;
  }, [summary, sessionProtocol]);

  const protocolCertification = useProtocolCertification(
    summary?.protocolDetails.protocolIds.levelID,
    summary?.protocolDetails.family.id,
    summary?.protocolDetails.protocolIds.rule,
    true,
  );
  const isCertified = protocolCertification.global?.certified;

  const depthData = useMemo(
    () => getDepthData(summary?.sessionData.fullData),
    [summary?.sessionData.fullData],
  );

  const goBack = () => {
    if (location.state.from === "progress") {
      navigate(-1);
    } else if (location.state.from === "free-month") {
      navigate("/app/meditation/congratulation");
    } else {
      navigate("/app/progress");
    }
  };

  return (
    <Container>
      <div className="sessionSummaryPage">
        <SubNav
          title="Session Summary"
          onBack={goBack}
          audioGuide={isCertified ? "coach-session-summary" : "coach-session-summary-precert"}
          backText={location.state.from === "progress" ? "Back" : "Done"}
        >
          <h1 className="text-center">
            Session Summary
            <HelpModal location="session-summary" helpIcon />
          </h1>
        </SubNav>
        {summary ? (
          <>
            <div className="sessionSummaryPage__header">
              <div className="sessionSummaryPage__header-date">
                {`${format(new Date(summary.sessionData.date), "MMMM do, yyyy")}`}
                &nbsp;-&nbsp;
                {`${format(new Date(summary.sessionData.date), "hh:mma")}`}
                &nbsp;-&nbsp;
                {formatDuration(summary.sessionData.length, true)}
              </div>
              <ProtocolSelectInfo
                customClass="sessionSummaryPage__protocol"
                protocol={sessionProtocol}
                level={sessionLevel}
                rule={sessionRule?.descriptions?.name}
                size="lg"
                centered
              />
            </div>
            <div className="sessionSummaryPage__section">
              {isCertified && <SessionProgressScores report={summary.report} />}
              {isCertified && (
              <div className="sessionSummaryPage__chart">
                <Card customClass="graph-card">
                  <h3 className="mb-3">Depth Score</h3>
                  {activeChartType === "timeline" && (
                    <SessionChart
                      sessionData={summary.sessionData}
                      depthAvg={summary.report.depthAvg}
                      showMarks
                      scrollSync={scrollSync}
                      setScrollSync={setScrollSync(0)}
                      initialScale={scrollSync.current.zoom}
                    />
                  )}
                  {activeChartType === "mean" && (
                    <>
                      <DepthBarChart chartData={depthData.barData} />
                      <div className="brainwaveGraphs__depth-footer">
                        <div className="brainwaveGraphs__depth-avg">
                          <span>AVG</span>
                          {" "}
                          <strong>{depthData.avg ? depthData.avg.toFixed(0) : "-"}</strong>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="brainwaveGraphs__filters-row">
                    <div className="button-switch nav-pills">
                      <GenericButton
                        className={activeChartType === "timeline" ? "btn--active" : ""}
                        text="Timeline"
                        onButtonClick={() => setActiveChartType("timeline")}
                      />
                      <GenericButton
                        className={activeChartType === "mean" ? "btn--active" : ""}
                        text="Average"
                        onButtonClick={() => setActiveChartType("mean")}
                      />
                    </div>
                  </div>
                </Card>
              </div>
              )}
              <SessionMetrics
                summary={summary}
                sessionProtocol={sessionProtocol}
                sessionLevel={sessionLevel}
                sessionRule={sessionRule}
              />
              {isCertified && (
              <div className="sessionSummaryPage__scores">
                <SessionScores
                  points={summary.report.points}
                  rewards={summary.report.rewards}
                  reminders={summary.report.reminders}
                  length={summary.sessionData.length}
                />
              </div>
              )}
              <div className="sessionSummaryPage__journal">
                <SessionJournal
                  journalEntry={summary.journalEntry}
                  sessionId={summary.sessionData.sessionId}
                  isCertified={isCertified}
                  isTap={summary.sessionData.taps.length > 0}
                />
              </div>
              <div className="sessionSummaryPage__badges">
                <SessionBadges
                  fullData={summary.sessionData.fullData}
                  length={summary.sessionData.length}
                  thresholds={summary.sessionData.thresholds}
                  report={summary.report}
                  isCertified={isCertified}
                />
              </div>
              <div className="sessionSummaryPage__footer">
                <GenericButton
                  className="button--danger"
                  text="Delete Session"
                  onButtonClick={() => setShowDeleteDialog(true)}
                  iconElement={<FontAwesomeIcon icon={faTrashAlt} />}
                  iconSide="right"
                />
                <Dialog
                  show={showDeleteDialog}
                  title="Delete session"
                  onSuccess={() => deleteSession.mutate()}
                  onCancel={() => setShowDeleteDialog(false)}
                  loading={deleteSession.isPending}
                >
                  <p>
                    This action will delete the sesssion and journal entry
                    permanently.
                  </p>
                </Dialog>
              </div>
            </div>
          </>
        )
          : <Spinner />}
      </div>
    </Container>
  );
}

export default SessionSummaryPage;
