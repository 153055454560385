import { useState } from "react";
import arrowUp from "assets/icons_arrow-up.svg";

import "./Card.scss";

export function Card({
  children, customClass = "", collapseHeader, imgUrl, collapsed = true,
}) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  return (
    <div className={`card ${imgUrl ? "card-w-img" : ""} ${customClass}`}>
      {imgUrl && (
      <div className="card__img">
        <img src={imgUrl} alt="card header" />
      </div>
      )}
      {collapseHeader && (
        <div
          role="presentation"
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="card__header"
        >
          <div className="card__header-text">
            {collapseHeader}
          </div>
          {children && (
          <div className={`card__header-collapse ${isCollapsed ? "is-collapsed" : ""}`}>
            <img
              src={arrowUp}
              alt="arrow icon"
            />
          </div>
          )}
        </div>
      )}
      {isCollapsed && children && (
      <div className="card__content">
        {children}
      </div>
      )}
    </div>
  );
}

export default Card;
