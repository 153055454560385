import { Route, Routes } from "react-router-dom";

import ProgressPage from "pages/App/Progress/Progress/Progress";
import SessionCompletePage from "pages/App/Meditation/SessionComplete/SessionComplete";
import SessionSummaryPage from "pages/App/Progress/SessionSummary/SessionSummary";
import BrainwaveAnalyzerPage from "pages/App/Progress/BrainwaveAnalyzer/BrainwaveAnalyzer";

function Progress() {
  return (
    <Routes>
      <Route index element={<ProgressPage />} exact />
      <Route path="session-complete/:sessionId" element={<SessionCompletePage />} />
      <Route path="session-summary/:sessionId" element={<SessionSummaryPage />} />
      <Route path="brainwave-analyzer/:sessionId" element={<BrainwaveAnalyzerPage />} />
    </Routes>
  );
}

export default Progress;
