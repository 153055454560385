import { useState } from "react";
import { Modal } from "react-bootstrap";
import _ from "lodash";

import {
  Card, GenericButton, ProtocolDetails,
} from "components";

import { PROTOCOLS_ICONS } from "constants/onBoarding_protocols";
import { ReactComponent as SunIcon } from "assets/ic_protocols/ic_sun.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle, faMinus, faPlus,
} from "@fortawesome/free-solid-svg-icons";

import { LEVEL_CARDS_COLORS } from "constants/onBoarding_level";
import { FRQ_KEYS, FRQ_COLOR } from "constants/brainwave_analyzer";

import "./ProtocolTable.scss";

function ProtocolTable({ protocol }) {
  const levels = [1, 2, 3, 4];

  const [collapsed, setCollapsed] = useState(false);
  const [protocolLvl, setProtocolLvl] = useState(null);
  const [showProtocolDetails, setShowProtocolDetails] = useState(false);

  const getProtocolIcon = (iconName) => PROTOCOLS_ICONS.find(({ name }) => name === iconName)?.getIcon() || <SunIcon className="protocol_icon" />;
  const getBand = (band) => FRQ_KEYS.find((frq) => band.toLowerCase().includes(frq));

  return (
    <>
      <Card customClass="protocols-table">
        <div key={protocol.protocolID}>
          <div className="protocols-table__header">
            <div className="protocols-table__protocol">
              <div className="protocols-table__protocol-icon" style={{ fill: protocol.color }}>{getProtocolIcon(protocol.icon)}</div>
              <h3 style={{ color: protocol.color }}>{protocol.protocolName}</h3>
              <button
                type="button"
                className="protocols-table__protocol-details"
                onClick={() => setShowProtocolDetails(true)}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </button>
            </div>
            {collapsed && (
              <div className="protocols-table__levels">
                Lv.
                {levels.map((level) => (
                  <button
                    key={level}
                    className={`protocols-table__levels-btn ${protocolLvl === level ? "active" : ""}`}
                    style={{ backgroundColor: LEVEL_CARDS_COLORS[level - 1] }}
                    type="button"
                    onClick={() => setProtocolLvl(
                      (state) => (!state || state !== level ? level : null),
                    )}
                  >
                    {level}
                  </button>
                ))}
              </div>
            )}
            <GenericButton
              text={`${collapsed ? "Hide" : "Show"} Rules`}
              className="button--default btn-xs protocols-table__collapse"
              iconSide="right"
              iconElement={<FontAwesomeIcon icon={collapsed ? faMinus : faPlus} />}
              onButtonClick={() => setCollapsed((state) => !state)}
            />
          </div>
          {collapsed && (
            <table className="protocols-table__table table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Location</th>
                  <th>Band</th>
                  <th>Frq</th>
                  <th>Measure</th>
                  <th>Direction</th>
                  <th>Name</th>
                  <th>Weights</th>
                </tr>
              </thead>
              <tbody>
                {protocol.protocolRules.map((rule) => !(rule.weights[protocolLvl - 1] === 0) && (
                  <tr key={rule.ruleId}>
                    <td>{rule.rule}</td>
                    <td>
                      {rule.location.map((location) => (
                        <span key={location}>{location}</span>))}
                    </td>
                    <td>
                      {rule.frequencyBandName.map((band) => (
                        <span
                          key={band}
                          style={{ backgroundColor: FRQ_COLOR[getBand(band)] }}
                          className="text-white"
                        >
                          {band}
                        </span>
                      ))}
                    </td>
                    <td>
                      {rule.frequencyBandRange.map((range) => (
                        <span key={range.join("-")}>{range.join("-")}</span>))}
                    </td>
                    <td>{rule.measure}</td>
                    <td>{rule.direction}</td>
                    <td>{rule.title}</td>
                    <td>
                      {rule.weights.map((weight, index) => (
                        <span
                          key={index.toString()}
                          className={`table-weight ${index + 1 === protocolLvl || !protocolLvl ? "active" : ""}`}
                        >
                          {weight}
                        </span>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </Card>
      <Modal
        animation
        dialogClassName="dialog"
        show={showProtocolDetails}
        onHide={() => setShowProtocolDetails(false)}
      >
        <Modal.Header closeButton>
          <h2 className="protocolDetails__protocol-title m-0" style={{ color: protocol.color }}>
            {_.unescape(protocol.protocolName)}
          </h2>
        </Modal.Header>
        <Modal.Body>
          <ProtocolDetails protocol={protocol} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProtocolTable;
