import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { Container } from "react-bootstrap";

import { CoachNav, CustomTabs } from "components";

import { getProtocols } from "store/actions/protocols";

import { getProtocolCertificationList } from "api/services/protocols";

import ProtocolTable from "./components/ProtocolTable";
import CertificationTable from "./components/CertificationTable";

function ProtocolsList() {
  const dispatch = useDispatch();

  const { allProtocols } = useSelector(
    ({ protocolsReducer }) => protocolsReducer,
  );

  const { data: certifications } = useQuery({
    queryKey: ["certifications"],
    queryFn: () => getProtocolCertificationList(),
  });

  const [activeTab, setActiveTab] = useState("Rules");

  useEffect(() => {
    dispatch(getProtocols());
  }, []);

  return (
    <div className="coach-panel">
      <CoachNav />
      <div className="dashboard">
        <Container className="dashboard__header">
          <h1>Protocols</h1>
          <div className="dashboard__tabs">
            <CustomTabs
              tabs={["Rules", "Certification"]}
              defaultTabKey={activeTab}
              onTabChange={(tab) => setActiveTab(tab)}
              activeTab={activeTab}
            />
          </div>
        </Container>
        <Container>
          {allProtocols && allProtocols.map((category) => category.protocolCategory !== "Training Protocols" && (
            <div key={category.categoryID} className="protocols-table__category">
              <h2>{category.protocolCategory}</h2>
              {category.protocols.map((protocol) => (activeTab === "Rules"
                ? <ProtocolTable protocol={protocol} key={protocol.protocolID} />
                : (
                  <CertificationTable
                    protocol={protocol}
                    certifications={certifications
                      .filter((item) => item.protocolID === protocol.protocolID)}
                    key={protocol.protocolID}
                  />
                )))}
            </div>
          ))}
        </Container>
      </div>
    </div>
  );
}

export default ProtocolsList;
