import { useQuery } from "@tanstack/react-query";

import { getProtocolCertification } from "api/services/protocols";

export default function useProtocolCertification(levelId, family, rule, fetch = false) {
  const isTraining = (family && family === "training") || rule;

  const { data } = useQuery({
    queryKey: ["protocol-certification", levelId],
    queryFn: () => getProtocolCertification(levelId),
    enabled: !!levelId && !!family && !isTraining && fetch,
    gcTime: Infinity,
    staleTime: 20 * 60000,
  });

  return {
    ...isTraining
      ? { global: { certified: true } }
      : data,
  };
}
