import { useState } from "react";

import { Dialog, InfoBox, TapsList } from "components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import "./TapsInfo.scss";

function TapsInfo() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        role="presentation"
        className="brainwaveAnalyzer__taps-box"
        onClick={() => setShowModal(true)}
      >
        <InfoBox>
          <p className="text-primary cursor-pointer">
            <strong>No taps recorded for this session.</strong>
            {" "}
            Click here to learn more.
          </p>
        </InfoBox>
      </div>
      <Dialog
        animation
        dialogClassName="dialog"
        className="dialog-info"
        cancelText="Got it"
        title={<FontAwesomeIcon className="icon-primary" icon={faInfoCircle} />}
        show={showModal}
        onCancel={() => setShowModal(false)}
      >
        <div className="brainwaveAnalyzer__taps-dialog">
          <h2 className="mb-4">Tap for Advanced Tracking</h2>
          <p className="text-sm text-muted">
            Noting your mind states can help you get familiar with what depth
            feels like. Use tapping to not only deepen your practice, but leave
            markers that you can review in your graphs.
          </p>
          <TapsList />
        </div>
      </Dialog>
    </>
  );
}

export default TapsInfo;
