import { useNavigate } from "react-router-dom";
import "./ButtonLink.scss";

export function ButtonLink({ children, customClass = "", to }) {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      onClick={() => navigate(to)}
      className={`button__link ${customClass}`}
    >
      {children}
    </button>
  );
}

export default ButtonLink;
