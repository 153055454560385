import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";

import { SubNav } from "components";

import { setActiveStream } from "store/actions/session";

import SignalCheck from "pages/App/Common/SignalCheck/SignalCheck";
import Diagnostic from "pages/App/Common/Diagnostic/Diagnostic";

function ProtocolDiagnostic() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [stage, setStage] = useState("signal");

  useEffect(() => {
    if (stage === "diagnostic") dispatch(setActiveStream("status"));
    return () => dispatch(setActiveStream(null));
  }, [stage]);

  const toProtocols = () => {
    navigate("/app/meditation/protocol");
  };

  return (
    <Container>
      {stage === "signal" && (
        <>
          <SubNav title="Meditation Diagnostic" onBack={toProtocols} staticTitle noSensors />
          <SignalCheck
            onComplete={() => setStage("diagnostic")}
            onCancel={toProtocols}
          />
        </>
      )}
      {stage === "diagnostic" && (
        <>
          <SubNav title="Meditation Diagnostic" onBack={toProtocols} staticTitle audioGuide="coach-mediation-diagnostic" />
          <Diagnostic
            onComplete={toProtocols}
            onCancel={toProtocols}
            onError={() => setStage("signal")}
          />
        </>
      )}
    </Container>
  );
}

export default ProtocolDiagnostic;
