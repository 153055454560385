import { useState, useRef, useEffect } from "react";
import { Form } from "react-bootstrap";

import "./InputText.scss";

export function InputMinMax({
  id,
  type,
  min,
  max,
  step,
  label,
  placeholder,
  onChangeValue,
  onBlurValue,
  onFocusValue,
  defaultValue,
  isValid = true,
}) {
  const [value, setValue] = useState(defaultValue);
  const input = useRef();

  const onFocus = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onFocusValue?.(e);
    document.body.scrollBy(0, 0);
  };

  const validateMinMax = (event) => {
    const inputValue = Number(event.target.value);
    let properValue = inputValue;
    if (id === "min" && max && inputValue > max) {
      properValue = max;
    } else if (id === "max" && min && inputValue && inputValue < min) {
      properValue = min;
    } else if (min && inputValue < min) {
      properValue = min;
    } else if (max && inputValue > max) {
      properValue = max;
    }
    setValue(properValue);
    onBlurValue?.(properValue);
  };

  const handleChange = (inputValue) => {
    setValue(inputValue);
    onChangeValue(inputValue);
  };

  useEffect(() => setValue(defaultValue), [defaultValue]);

  return (
    <div className="input-container">
      <Form.Group>
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Control
          id={id || label}
          step={step || 1}
          className="input input--text"
          type={type || "number"}
          inputMode="numeric"
          placeholder={placeholder}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          onBlur={min !== "" || max !== ""
            ? (e) => validateMinMax(e, min, max)
            : onBlurValue}
          onFocus={(e) => onFocus(e)}
          isValid={isValid}
          ref={input}
          min={min}
          max={max}
        />
      </Form.Group>
    </div>
  );
}

export default InputMinMax;
