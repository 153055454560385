import * as actionType from "store/actions/reporting/types";
import * as authActions from "store/actions/authentication";
import {
  GRAPH_PERIODS_TABS, CHART_TYPES, CHART_TYPES_STATS_CHART, PROGRESS_GRAPH_TABS,
} from "constants/profile_progress_charts";
import {
  PROGRESS_SESSION_SETTINGS,
} from "constants/profile_progress_sessions";

const initialState = {
  pastSessionReportsCurrentPage: PROGRESS_SESSION_SETTINGS.BEGIN_PAGE,
  pastSessionReportsFilters: PROGRESS_SESSION_SETTINGS.FILTERS,
  pastSessionReportsOrder: PROGRESS_SESSION_SETTINGS.ORDER,
  progressView: "Practice",
  graphStatus: {
    activeTab: PROGRESS_GRAPH_TABS[0],
    period: GRAPH_PERIODS_TABS[0],
    range: undefined,
    metrics: undefined,
    types: {
      time: undefined,
      depth: [CHART_TYPES.AVG],
      zone: [CHART_TYPES.BLUE_ZONE, CHART_TYPES.GREEN_ZONE],
      stats: CHART_TYPES_STATS_CHART,
      lifeTime: undefined,
      taps: [CHART_TYPES.TAPS_VALUE],
    },
  },
  graphModelStatus: false,
  graphProtocol: null,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case authActions.LOGOUT_USER_SUCCESS:
      return initialState;
    case actionType.CHANGE_PAST_SESSION_REPORTS_ORDER:
      return {
        ...state,
        pastSessionReportsOrder: action.payload,
      };
    case actionType.CHANGE_PAST_SESSION_REPORTS_FILTERS:
      return {
        ...state,
        pastSessionReportsFilters: action.payload,
      };
    case actionType.GO_TO_SPECIFIC_PAGINATION_PAGE:
      return {
        ...state,
        pastSessionReportsCurrentPage: action.payload.pageNumber,
      };
    case actionType.CHANGE_GRAPH_STATUS:
      return { ...state, graphStatus: action.payload };
    case actionType.CHANGE_GRAPH_MODAL_STATUS:
      return { ...state, graphModelStatus: action.payload };
    case actionType.CHANGE_GRAPH_PROTOCOL:
      return {
        ...state,
        graphStatus: { ...state.graphStatus, metrics: undefined },
        graphProtocol: action.payload,
      };
    case actionType.CHANGE_PROGRESS_VIEW:
      return { ...state, progressView: action.payload };
    default:
  }
  return state;
};

export default reducer;
