import "./Textarea.scss";
import { Form } from "react-bootstrap";

export function Textarea({
  label, placeholder, rows, onChangeValue, onBlurValue, onFocusValue, defaultValue, isValid,
}) {
  return (
    <div className="textarea-container">
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control
        as="textarea"
        rows={rows}
        placeholder={placeholder}
        value={defaultValue}
        className="textarea"
        onChange={onChangeValue}
        onBlur={onBlurValue}
        onFocus={onFocusValue}
        isValid={isValid}
      />
    </div>
  );
}

export default Textarea;
