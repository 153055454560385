import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Container from "react-bootstrap/Container";
import { format } from "date-fns";

import {
  SubNav, GenericButton, BottomBarSticky, Card, BluetoothDevice,
} from "components";
import { btMenuController } from "utils/native-controllers";

function NewBaselinePage() {
  const navigate = useNavigate();
  const { btDevice } = useSelector(({ sessionReducer }) => sessionReducer);
  const { baseline } = useSelector(({ profileReducer }) => profileReducer.userProfile);

  const onContinue = () => {
    if (btDevice.status === 5) {
      navigate("/app/settings/calibration");
    } else {
      btMenuController("toggle");
      toast.error("Muse device not connected.");
    }
  };

  return (
    <Container>
      <SubNav
        title="Record New Baseline"
        showBt
      />
      <div className="BaselinePage">
        <Card customClass="card-text mb-3">
          <h3>Record New Baseline</h3>
          <h4 className="text-dark mb-1">Current Baseline</h4>
          <p className="text-sm text-muted">
            {format(new Date(baseline.date), "MMMM do, yyyy - hh:mma")}
          </p>
          <p>
            If you are not getting good meditation feedback, it could be
            because you have a poor baseline. You can record a new five
            minute baseline to fix that.
          </p>
          <p className="font-600">
            After recording new baseline you should recalibrate all your protocols.
          </p>
        </Card>
        <BluetoothDevice customClass="mb-2" />
        <BottomBarSticky>
          <GenericButton text="Continue" className="button--dark" onButtonClick={onContinue} />
        </BottomBarSticky>
      </div>
    </Container>
  );
}

export default NewBaselinePage;
