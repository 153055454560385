import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const GUIDES = {
  protocols: [
    () => (
      <>
        <p>
          If you have trained in any of the traditional meditations, by
          all means choose the styles of mediation you have already been
          trained in.
        </p>
        <p>
          For deeper information about the protocols click
          {" "}
          <FontAwesomeIcon icon={faQuestionCircle} />
          {" "}
          <strong>Learn about Protocols</strong>
          {" "}
          or choose one of them below.
        </p>
      </>
    ),
    () => (
      <>
        <p>
          If you are relatively new to meditation or curious about
          different styles of meditation, we can analyze your brainwaves
          during meditation and recommend which of the modern
          meditations would fit you best.
        </p>
        <ol>
          <li>Tap Help Me Choose</li>
          <li>Do your version of meditation for five minutes.</li>
          <li>
            GoDeeper will analyze your session against all five modern
            meditation protocols and tell you which one would have given
            you the highest and lowest average depth score.
          </li>
        </ol>
      </>
    ),
  ],
  liveSession: [
    () => (
      <p>
        You can change more than just volume. Tap the speaker icon to
        enter audio settings. This action will pause session.
      </p>
    ),
    () => (
      <p>
        Tweak this slider to change how often you&apos;re rewarded. Find
        your flow in the sweet spot between too easy and too hard.
      </p>
    ),
    () => (
      <p>
        Tap anywhere on your timeline graph to place a marker.
      </p>
    ),
  ],
};

export default GUIDES;
