import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Container } from "react-bootstrap";
import { format } from "date-fns";

import { SubNav, ProtocolSelectInfo } from "components";

import { BrainwaveInterpretation, HelpModal } from "features";
import { getSessionReport } from "api/services/reporting";
import { formatDuration } from "utils/time-format";
import useProtocol from "hooks/useProtocol";

import "./BrainwaveAnalyzer.scss";

function BrainwaveAnalyzer() {
  const { sessionId } = useParams();

  const { data: summary } = useQuery({
    queryKey: ["session-summary", sessionId],
    queryFn: () => getSessionReport(sessionId),
    staleTime: Infinity,
  });

  const sessionProtocol = useProtocol(
    summary?.sessionData.protocol.categoryID,
    summary?.sessionData.protocol.protocolID,
    summary?.protocolDetails,
  );

  const sessionLevel = useMemo(() => {
    const sessionLevelId = summary?.sessionData.protocol.levelID;
    return sessionLevelId?.split("/")[1];
  }, [summary]);

  const sessionRule = useMemo(() => {
    const ruleNo = summary?.sessionData.protocol.rule;
    if (ruleNo && sessionProtocol) {
      return sessionProtocol.protocolRules.find(
        (rule) => rule?.rule === ruleNo,
      );
    }
    return null;
  }, [summary, sessionProtocol]);

  const dateData = useMemo(() => {
    const date = format(new Date(summary.sessionData.date), "MMMM do, yyyy");
    const time = format(new Date(summary.sessionData.date), "hh:mma");
    const duration = formatDuration(summary.sessionData.length, true);
    return `${date} - ${time} - ${duration}`;
  }, [summary]);

  return (
    <Container>
      <div className="brainwaveAnalyzer">
        <div className="header text-center">
          <SubNav
            title="Brainwave Analyzer"
            audioGuide="coach-brainwave-analyzer"
            size="sm"
          >
            <h1>
              Brainwave Analyzer
              <HelpModal location="brainwave-analyzer" helpIcon />
            </h1>
          </SubNav>
        </div>
        <ProtocolSelectInfo
          customClass="brainwaveAnalyzer__protocol"
          protocol={sessionProtocol}
          level={sessionLevel}
          rule={sessionRule?.descriptions?.name}
          date={dateData}
          size="sm"
          centered
        />
        <BrainwaveInterpretation
          sessionData={summary.sessionData}
          sessionId={sessionId}
          sessionProtocol={sessionProtocol}
          sessionLevel={sessionLevel}
          sessionRule={sessionRule}
        />
      </div>
    </Container>
  );
}

export default BrainwaveAnalyzer;
