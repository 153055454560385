import { useState, useEffect } from "react";
import { Console, Hook, Unhook } from "console-feed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/fontawesome-free-regular";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";

import "./LogsContainter.scss";

function LogsContainter() {
  const [logs, setLogs] = useState([]);
  const [openLogs, setOpenLogs] = useState(false);

  useEffect(() => {
    const hookedConsole = Hook(
      window.console,
      (log) => setLogs((currLogs) => [log, ...currLogs]),
      false,
    );
    return () => Unhook(hookedConsole);
  }, []);

  return (
    <div className="logsContainter">
      <div
        role="presentation"
        className={`logsContainter__switch ${openLogs ? "open" : ""}`}
        onClick={() => setOpenLogs(!openLogs)}
      >
        {logs.length > 0 && <span className="logsContainter__switch-errors">!</span>}
        {" "}
        Console Logs
        <FontAwesomeIcon icon={!openLogs ? faAngleUp : faAngleDown} />
      </div>
      <div className={`logsContainter__wrapper ${openLogs ? "open" : ""}`}>
        <div className="logsContainter__topBar">
          <div
            role="presentation"
            className="logsContainter__topBar-clear"
            onClick={() => setLogs([])}
          >
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="delete"
            />
            Console Clear
          </div>
        </div>
        <Console logs={logs} variant="dark" />
      </div>
    </div>
  );
}

export default LogsContainter;
