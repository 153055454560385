import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dropInside } from "utils/format-array";
import _ from "lodash";

import { FRQ_COLOR } from "constants/brainwave_analyzer";
import { changeGraphStatus } from "store/actions/reporting";

export default function useMetrics(metricsData, protocolLevel) {
  const dispatch = useDispatch();

  const graphStatus = useSelector(
    ({ reportingReducer }) => reportingReducer.graphStatus,
  );

  const { isPending, isPlaceholderData, data } = metricsData;

  const chartData = data?.data.filter(({ id }) => !!id);

  useEffect(() => {
    if (!graphStatus.metrics && chartData?.length && !isPlaceholderData) {
      dispatch(changeGraphStatus({ ...graphStatus, metrics: chartData[0].data.at(-1).x }));
    }
  }, [chartData]);

  const rulesContent = useMemo(() => {
    if (!chartData?.length) return null;
    return chartData.map((rule) => {
      if (rule.id === -1) return null;
      const weights = protocolLevel && rule.weights?.[protocolLevel - 1];
      const direction = weights < 0 ? -1 : 1;
      const frqName = rule.frequencyBandName[0].replace(/[0-9]/g, "").toLowerCase();
      const value = rule.data.find(({ x }) => x === graphStatus.metrics)?.y ?? null;
      const lastValue = rule.data.findLast(({ y }) => y !== null)?.y ?? null;
      return {
        ...rule,
        frequencyBandName: frqName,
        frequencyBand: rule.frequencyBandName,
        direction: rule.direction.toLowerCase(),
        color: rule.frequencyBandName.length === 1 ? FRQ_COLOR[frqName] : "#171d66",
        label: `${rule.frequencyBandName.map((band) => band[0]).join("")}${rule.measure[0]}`,
        weights,
        value,
        score: _.round(value * direction, 2),
        lastScore: lastValue && _.round(lastValue * direction, 2),
      };
    });
  }, [chartData, graphStatus.metrics]);

  const filteredData = useMemo(() => {
    if (chartData && rulesContent) {
      const getData = (serieData, index) => {
        const direction = rulesContent[index]?.weights < 0 ? -1 : 1;
        return serieData.map((serie) => ({
          x: serie.x,
          y: serie.y !== null ? serie.y * direction : null,
        }));
      };
      return chartData.map((serie, index) => ({
        ...serie,
        data: dropInside(
          getData(serie.data, index).slice(-graphStatus.range),
          (el) => el.y !== null,
        ),
      }));
    }
    return null;
  }, [chartData, graphStatus.range]);

  const isData = filteredData && filteredData[0]?.data.some(({ y }) => !!y);
  const isMetrics = isData && rulesContent.some((rule) => !!rule);

  return {
    isPending, isPlaceholderData, isData, isMetrics, filteredData, rulesContent,
  };
}
