/* eslint-disable no-underscore-dangle */
import * as actionType from "store/actions/protocols/types";

const intialState = {
  protocols: null,
  protocolsError: null,
  protocolsLoading: false,
  allProtocols: null,
  allProtocolsError: null,
  protocolsListCollapse: "Modern Meditations",
  gettingAllProtocols: false,
  deletingProtocol: false,
  protocolCalibration: null,
  protocolGuide: true,
};

const reducer = (state = intialState, action = {}) => {
  switch (action.type) {
    case actionType.CALIBRATED_PROTOCOLS_START: {
      return {
        ...state,
        protocolsError: null,
        protocolsLoading: true,
      };
    }
    case actionType.CALIBRATED_PROTOCOLS_SUCCESS:
      return {
        ...state,
        protocols: action.payload,
        protocolsLoading: false,
      };
    case actionType.CALIBRATED_PROTOCOLS_ERROR: {
      return {
        ...state,
        protocolsError: action.payload,
        protocolsLoading: false,
      };
    }
    case actionType.GET_PROTOCOLS:
      return {
        ...state,
        gettingAllProtocols: true,
      };
    case actionType.GET_PROTOCOLS_SUCCESS:
      return {
        ...state,
        gettingAllProtocols: false,
        allProtocols: [...action.payload],
      };
    case actionType.GET_PROTOCOLS_ERROR:
      return {
        ...state,
        gettingAllProtocols: false,
        allProtocolsError: { ...action.payload },
      };
    case actionType.SET_PROTOCOLS_LIST_COLLAPSE:
      return {
        ...state,
        protocolsListCollapse: action.payload,
      };
    case actionType.SAVE_CALIBRATED_PROTOCOL_SUCCESS:
      return {
        ...state,
        protocols: [
          ...state.protocols.filter(
            (protocol) => protocol.thresholds.protocolIds.levelID
          !== action.payload.thresholds.protocolIds.levelID,
          ),
          action.payload,
        ],
      };
    case actionType.DELETE_CALIBRATED_PROTOCOL:
      return {
        ...state,
        deletingProtocol: true,
      };
    case actionType.DELETE_CALIBRATED_PROTOCOL_SUCCESS:
      return {
        ...state,
        protocols: state.protocols.filter(
          (protocol) => protocol.thresholds.protocolIds.levelID !== action.payload,
        ),
        deletingProtocol: false,
      };
    case actionType.DELETE_CALIBRATED_PROTOCOL_ERROR:
      return {
        ...state,
        deletingProtocol: false,
        deletingProtocolError: { ...action.payload },
      };
    case actionType.ADD_CALIBRATION_RULE:
    {
      const protocols = [...state.protocols];
      const index = protocols.findIndex(
        (protocol) => protocol._id === action.payload._id,
      );
      protocols[index] = action.payload;
      return {
        ...state,
        protocols,
      };
    }
    case actionType.DELETE_CALIBRATION_RULE:
    {
      const protocols = [...state.protocols];
      const index = protocols.findIndex(
        (protocol) => protocol._id === action.payload.calibrationId,
      );
      protocols[index].rules = protocols[index].rules.filter(
        (rule) => Number(rule.split(".")[1]) !== action.payload.rule,
      );
      return {
        ...state,
        protocols,
      };
    }
    case actionType.RESET_PROTOCOL_SYNC:
      return {
        ...state,
        protocolCalibration: null,
      };
    case actionType.SET_PROTOCOL_TO_CALIBRATE:
      return {
        ...state,
        protocolCalibration: { ...action.payload },
      };
    case actionType.SET_PROTOCOL_GUIDE:
      return {
        ...state,
        protocolGuide: action.payload,
      };

    default:
  }
  return state;
};

export default reducer;
