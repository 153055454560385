import { useState } from "react";
import { useSelector } from "react-redux";
import { isAllowed } from "utils/user-permissions";
import { Container } from "react-bootstrap";
import { CoachNav, DownloadButton } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

import { CoachTable } from "features";
import { getDiagnosticSessions, getDiagnosticSessionsReport } from "api/services/coach";

import "pages/Coach/Dashboard/Dashboard.scss";

function Diagnostics() {
  const userLevel = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.subscription_level_id,
  );
  const isSuperCoach = isAllowed(userLevel, "SUPER_COACH");

  const [activeFilter, setActiveFilter] = useState({});

  const getSessions = async (filter) => {
    setActiveFilter(filter);
    return getDiagnosticSessions(filter);
  };

  const tableSettings = {
    title: "Diagnostic Sessions",
    tableName: "diagnostic",
    tableType: 6,
    getdata: (filter) => getSessions(filter),
    tabsGroup: "diagnostic",
    stickyColumn: {
      title: "Progress",
      id: "progress",
      data: "id",
      element: {
        icon: <FontAwesomeIcon icon={faChartLine} />,
        path: (clientId) => `/coach/clients/${clientId}/progress`,
      },
    },
  };

  return (
    <div className="coach-panel">
      <CoachNav />
      <div className="dashboard">
        <Container className="dashboard__header">
          <h1>Dashboard</h1>
        </Container>
        {isSuperCoach && (
        <Container>
          <div className="dashboard__actions">
            <DownloadButton
              fileName="diagnostics_report.zip"
              className="dashboard__actions-button text-sm"
              text="Download Reports"
              pendingText="Generating..."
              getDownloadUrl={() => getDiagnosticSessionsReport(activeFilter)}
            />
          </div>
        </Container>
        )}
        <CoachTable {...tableSettings} />
      </div>
    </div>
  );
}

export default Diagnostics;
