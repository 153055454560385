import { useSelector } from "react-redux";
import { MeditationStyleForm } from "features";

import "./Training.scss";

function TrainingPage({
  tabIndex, saveBtnText,
}) {
  const trainingOptions = useSelector(
    ({ onBoardingReducer }) => onBoardingReducer.onBoardingChoices?.lineage_practices,
  );
  const userSavedTrainingOptions = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.meditation_styles,
  );

  return (
    <div className="trainingPage">
      <h2>
        Which Trainings have you done?
      </h2>
      <p className="text-muted">
        Choose all that apply
      </p>
      <MeditationStyleForm
        stepIndex={tabIndex}
        saveBtnText={saveBtnText}
        stepOptions={trainingOptions}
        stepSavedOptions={userSavedTrainingOptions}
        stepType={2}
        inputPlaceholder="Add another training"
      />
    </div>
  );
}

export default TrainingPage;
