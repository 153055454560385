import { Range } from "react-range";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlassMinus, faMagnifyingGlassPlus } from "@fortawesome/free-solid-svg-icons";

import "./ZoomSlider.scss";
import { useRef } from "react";

export function ZoomSlider({
  zoom, setZoom, min = 0, max, onChangeEnd,
}) {
  const timer = useRef();

  const onZoomClick = (newZoom) => {
    if (newZoom <= max && newZoom >= min) {
      clearTimeout(timer.current);
      setZoom(newZoom);
      timer.current = setTimeout(() => onChangeEnd?.(newZoom), 500);
    }
  };

  return (
    <div className="zoom-slider">
      <div className="zoom-slider__icons-wrapper">
        <div role="presentation" className="zoom-slider__icon" onClick={() => onZoomClick(zoom - 1)}>
          <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
        </div>
        <div role="presentation" className="zoom-slider__icon" onClick={() => onZoomClick(zoom + 1)}>
          <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
        </div>
      </div>
      <Range
        onChange={(values) => {
          clearTimeout(timer.current);
          setZoom(values[0]);
        }}
        onFinalChange={(values) => onChangeEnd?.(values[0])}
        values={[zoom]}
        min={min}
        max={max}
        step={1}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className="track"
          >
            <div
              ref={props.ref}
              style={{
                width: `${(zoom / max) * 100}%`,
              }}
            />
            {children}
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            key={props.key}
            style={{ ...props.style }}
            className={`thumb ${isDragged ? "is-dragged" : ""}`}
          />
        )}
      />
    </div>
  );
}

export default ZoomSlider;
