import _ from "lodash";
import {
  FRQ_KEYS, ZONE_KEYS, ANALYZE_CONDITIONS, FRQ_COLOR,
} from "constants/brainwave_analyzer";

export const getRulesContent = (sessionScores, sessionRules, sessionLevel, sessionRule) => {
  const rulesContent = sessionRules.map((rule, index) => {
    if (!rule) return null;
    const value = sessionScores[index];
    const weights = sessionLevel && rule.weights?.[sessionLevel - 1];
    // if (!(typeof value === "number") || weights === 0) return null;
    const direction = weights < 0 ? -1 : 1;
    const frqName = FRQ_KEYS.find((frq) => rule.frequencyBandName[0].toLowerCase().includes(frq));
    return {
      ...rule,
      ruleIndex: index,
      weights,
      frequencyBandName: frqName,
      frequencyBand: rule.frequencyBandName,
      color: rule.frequencyBandName.length === 1 ? FRQ_COLOR[frqName] : "#171d66",
      value,
      score: _.round(value * direction, 2),
    };
  }).filter((rule) => rule !== null);
  if (sessionRule) {
    return rulesContent.filter((rule) => rule.rule === sessionRule.rule);
  }
  return rulesContent;
};

export const getFilterData = (data, filtersData, type, time) => {
  if (type === "all" && !time?.start && !time?.end) return null;
  const isFilterTaps = ["1", "2", "3"].includes(type);
  const typeData = isFilterTaps
    ? filtersData.tapsData.filter((tap) => tap.type === Number(type))?.map((tap) => tap.timestamp)
    : filtersData.zoneData;
  return data?.map((entry, index) => {
    if (time?.start !== null && time?.end) {
      if (!(index >= time.start && index <= time.end)) return false;
    }
    if (isFilterTaps) {
      return typeData.some((tapIndex) => tapIndex - index >= 2 && tapIndex - index <= 12);
    }
    return ANALYZE_CONDITIONS.find(({ key }) => key === type).condition(typeData[index]);
  });
};

export const getDepthData = (data, filterData) => {
  if (!data) return null;
  const meanArray = Array(11).fill(0);
  data.forEach((score, index) => {
    if (!filterData || filterData?.[index]) meanArray[Math.round(-score / 100)] += 1;
  });
  const filteredData = filterData ? data.filter((_value, index) => filterData[index]) : data;
  const sumArray = _.sum(meanArray);
  const barData = meanArray.map((value, index) => ({
    value: index * 100,
    percent: (value / sumArray) * 100,
  }));
  return {
    avg: filteredData.length > 0 ? -_.mean(filteredData) : null,
    barData,
  };
};

const processData = (
  data,
  sessionProtocol,
  sessionLevel,
  sessionRule,
  filterData,
) => {
  if (!data?.details || data?.rules.length === 0 || !sessionProtocol) return null;

  const getBrainData = () => {
    const brainData = {
      delta: {}, theta: {}, alpha: {}, beta: {}, gamma: {},
    };
    const brainBarChartsData = () => {
      const newData = { wholeBrain: [], frontBack: [], leftRight: [] };
      FRQ_KEYS.forEach((frq) => {
        const color = FRQ_COLOR[frq];
        const defaults = { frq, color };
        const mean = {};
        ZONE_KEYS.forEach((zone) => {
          const filteredData = filterData
            ? data.details[zone][frq].filter((_entry, index) => filterData[index])
            : data.details[zone][frq];
          mean[zone] = _.mean(filteredData);
          brainData[frq][zone] = mean[zone].toFixed(2);
        });
        newData.wholeBrain.push({
          ...defaults,
          value: _.round(mean.whole, 2),
        });
        newData.frontBack.push({
          ...defaults,
          frontValue: _.round(mean.frontal, 2),
          backValue: _.round(mean.back, 2),
        });
        newData.leftRight.push({
          ...defaults,
          leftValue: _.round(mean.left, 2),
          rightValue: _.round(mean.right, 2),
        });
      });
      return newData;
    };
    const brainLineChartsData = () => {
      const newData = { wholeBrain: [], frontBack: [], leftRight: [] };
      FRQ_KEYS.forEach((frq) => {
        const color = FRQ_COLOR[frq];
        const defaults = { frq, color };
        const values = {};
        ZONE_KEYS.forEach((zone) => {
          values[zone] = data.details[zone][frq].map(
            (entry, index) => ({
              x: index,
              y: entry,
            }),
          );
        });
        newData.wholeBrain.unshift({
          ...defaults,
          id: frq,
          data: values.whole,
        });
        ["frontal", "back"].forEach((type) => {
          newData.frontBack.unshift({
            ...defaults,
            id: `${type === "frontal" ? "front" : type} ${frq}`,
            data: values[type],
          });
        });
        ["left", "right"].forEach((type) => {
          newData.leftRight.unshift({
            ...defaults,
            id: `${type} ${frq}`,
            data: values[type],
          });
        });
      });
      return newData;
    };

    return {
      barData: brainBarChartsData(),
      lineData: brainLineChartsData(),
      brainData,
    };
  };

  const getProtocolData = () => {
    if (!sessionProtocol.protocolRules?.length) {
      return {
        rulesContent: null, barData: null, lineData: null,
      };
    }

    const rulesFilteredData = filterData
      ? data.rules.filter((_values, index) => filterData[index] === true)
      : data.rules;

    const rulesData = rulesFilteredData.length > 0
      ? rulesFilteredData[0]?.map(
        (_value, index) => (sessionProtocol.calibration.weights[index] !== 0
          ? _.round(_.mean(rulesFilteredData.map((entry) => entry[index])), 2)
          : false),
      )
      : sessionProtocol.calibration.weights.map((weight) => (weight === 0 ? false : null));

    const rulesContent = getRulesContent(
      rulesData,
      sessionProtocol.protocolRules,
      sessionLevel,
      sessionRule,
    );

    const rulesBarChartData = () => rulesContent.map((rule, index) => ({
      rule: index + 1,
      ruleId: rule.ruleId,
      title: rule.descriptions?.name || rule.title,
      value: rule.score,
      color: rule.color,
    }));

    const rulesLineChartData = () => rulesContent.map((rule) => {
      const direction = rule.weights < 0 ? -1 : 1;
      const getData = () => data.rules.map((serie, timeIndex) => ({
        x: timeIndex,
        y: _.clamp(serie[rule.ruleIndex] * direction, -3, 3),
      }));
      return {
        id: rule.descriptions?.name || rule.title,
        ruleId: rule.ruleId,
        color: rule.color,
        data: getData(),
      };
    });

    return {
      rulesContent,
      barData: rulesBarChartData(),
      lineData: rulesLineChartData(),
    };
  };

  return {
    brain: getBrainData(),
    protocol: getProtocolData(),
  };
};

export { processData };
