import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import {
  CoachNav, Breadcrumbs, CustomTabs, Spinner, EmptyData,
} from "components";

import { getClient, getClientProtocols, getClientSessions } from "api/services/coach";
import { changeGraphProtocol } from "store/actions/reporting";

import { COACH_PROGRESS_GRAPH_TABS } from "constants/profile_progress_charts";
import { CoachTable } from "features";
import ClientInfo from "./ClientInfo";

import ProgressChartController from "./ProgressCharts/ProgressChartController";

import "./Progress.scss";

function ProgressPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { clientId } = useParams();

  const [activeTab, setActiveTab] = useState(COACH_PROGRESS_GRAPH_TABS[0]);

  const client = useQuery({
    queryKey: ["client", clientId],
    queryFn: () => getClient(clientId),
  });

  const pastProtocols = useQuery({
    queryKey: ["client-protocols", clientId],
    queryFn: () => getClientProtocols(clientId),
    enabled: client.isSuccess,
  });

  useEffect(() => {
    if (pastProtocols.isSuccess) {
      const protocol = pastProtocols.data?.find(
        ({ levelID }) => levelID === client.data.selected_protocol?.levelID,
      );
      dispatch(changeGraphProtocol(protocol || pastProtocols.data[0]));
    }
  }, [pastProtocols.isSuccess]);

  useEffect(() => {
    if (client.isError || pastProtocols.isError) navigate("*", { replace: true });
  }, [client.isError, pastProtocols.isError]);

  const stickyColumn = {
    title: "Summary",
    id: "summary",
    data: "sessionId",
    element: {
      icon: <FontAwesomeIcon icon={faChartLine} />,
      path: (id, sessionId) => `/coach/clients/${id}/session-summary/${sessionId}`,
    },
  };

  return (
    <div className="coach-panel">
      <CoachNav />
      <div className="progressPage">
        {(client.isSuccess && !pastProtocols.isPending) ? (
          <>
            <Container>
              <div className="page-title">
                <h1>
                  Progress:
                  {" "}
                  {client.data?.first_name}
                  {" "}
                  {client.data?.last_name}
                </h1>
                <Breadcrumbs
                  active="Progress"
                  urls={[
                    ["/coach/clients", "Clients"],
                  ]}
                />
              </div>
              <ClientInfo data={client.data} />
            </Container>
            <div className="progressPage__chart-wrapper">
              <Container>
                <div className="progressPage__chart-header">
                  <h2>Progress Charts</h2>
                  {pastProtocols.isSuccess && pastProtocols.data.length > 0 && (
                    <>
                      <hr />
                      <div className="progress-session-tabs">
                        <CustomTabs
                          tabs={COACH_PROGRESS_GRAPH_TABS}
                          defaultTabKey={activeTab}
                          onTabChange={(tab) => setActiveTab(tab)}
                          activeTab={activeTab}
                        />
                      </div>
                    </>
                  )}
                </div>
                {pastProtocols.isSuccess && pastProtocols.data.length > 0 && (
                  <ProgressChartController
                    client={client}
                    clientId={clientId}
                    pastProtocols={pastProtocols}
                    weeklyGoal={client.data.goal_time}
                    activeTab={activeTab}
                  />
                )}
                {pastProtocols.isSuccess && pastProtocols.data.length === 0 && (
                <EmptyData
                  icon={faChartLine}
                  title="No sessions yet"
                  text="This user has not started meditating."
                />
                )}
              </Container>
            </div>
            <CoachTable
              title="Sessions"
              tableName="user-sessions"
              tableType={3}
              getdata={(filter) => getClientSessions(clientId, filter)}
              tabsGroup="sessions"
              stickyColumn={stickyColumn}
            />
          </>
        ) : <Spinner />}
      </div>
    </div>
  );
}

export default ProgressPage;
