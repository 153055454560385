import { useSelector } from "react-redux";
import "./BluetoothBattery.scss";

export function BluetoothBattery() {
  const { btDeviceBattery } = useSelector(({ sessionReducer }) => sessionReducer);
  const batteryBars = Math.ceil(btDeviceBattery / 25);

  return btDeviceBattery && (
    <div className="bt-battery__wrapper">
      <div className="bt-battery__level">
        {Math.round(btDeviceBattery)}
        %
      </div>
      <div className="bt-battery">
        {[...Array(batteryBars)].map((_bar, i) => <span key={i.toString()} />)}
      </div>
    </div>
  );
}

export default BluetoothBattery;
