import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { Container } from "react-bootstrap";
import { CoachNav } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

import { CoachTable, ClientsChart } from "features";
import { getOnboardingChoices, getClients } from "api/services/coach";

import "pages/Coach/Dashboard/Dashboard.scss";

function Clients() {
  const [clients, setClients] = useState("");
  const { data: onboardingChoices } = useQuery({
    queryKey: ["onboarding-choices"],
    queryFn: () => getOnboardingChoices(),
  });

  const tableSettings = {
    title: "Clients",
    tableName: "clients",
    tableType: 1,
    getdata: (filter) => getClients(filter),
    tabsGroup: "basics",
    selectChoices: onboardingChoices,
    callbackId: { set: setClients, dataId: "id" },
    stickyColumn: {
      title: "Progress",
      id: "progress",
      data: "id",
      element: {
        icon: <FontAwesomeIcon icon={faChartLine} />,
        path: (clientId) => `/coach/clients/${clientId}/progress`,
      },
    },
  };

  return (
    <div className="coach-panel">
      <CoachNav />
      <div className="dashboard">
        <Container className="dashboard__header">
          <h1>Dashboard</h1>
        </Container>
        <ClientsChart clients={clients} />
        <CoachTable {...tableSettings} />
      </div>
    </div>
  );
}

export default Clients;
