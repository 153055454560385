import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Loader, Card, Dialog, GenericButton,
} from "components";
import { HelpModal } from "features";
import CalibrationSession from "features/CalibrationSession/CalibrationSession";

import { deleteBaseline, getCallibrationData } from "store/actions/session";
import { saveUserProfile } from "store/actions/profile";

import AUDIO_FILES from "constants/profile_audio";

import "./BaselineCalibration.scss";

function BaselineCalibration({ onComplete, onCancel, onError }) {
  const dispatch = useDispatch();

  const loggedInUserId = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.pk,
  );
  const onboardingComplete = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.onboarding_complete,
  );
  const sessionId = useSelector(({ sessionReducer }) => sessionReducer.liveSessionId);

  const [sessionStatus, setSessionStatus] = useState("ready");
  const [showResetDialog, setShowResetDialog] = useState(false);
  const calibrationData = useRef({ streamid: null, delete: false });

  useEffect(() => () => {
    if (calibrationData.current.delete) dispatch(deleteBaseline(loggedInUserId));
  }, []);

  const startStream = () => {
    dispatch(getCallibrationData(loggedInUserId));
  };

  const sessionCompleted = async () => {
    calibrationData.current.delete = true;
    calibrationData.current.streamid = sessionId;
    setTimeout(() => {
      setSessionStatus("results");
    }, 2000);
  };

  const finishCalibration = async () => {
    calibrationData.current.delete = false;
    await dispatch(saveUserProfile({
      baseline: {
        date: new Date().toISOString(),
        streamid: calibrationData.current.streamid,
      },
    }));
    onComplete();
  };

  const cancelCalibration = async () => {
    if (onboardingComplete) {
      onCancel?.();
    } else {
      setShowResetDialog(false);
      setSessionStatus("ready");
    }
  };

  return sessionStatus === "results" ? (
    <div className="baselineCalibration">
      <div className="baselineCalibration__card-wrapper">
        <Card customClass="card-text baselineCalibration__card">
          <h3>Baseline Recording QC Check</h3>
          <p>
            How did it go? Were you still? Were you able to avoid meditating?
          </p>
          <p>
            If everything went well, hit
            {" "}
            <strong className="text-primary">Save Baseline</strong>
            .
          </p>
          <p>
            If something went weird, hit
            {" "}
            <strong className="text-danger">
              {onboardingComplete ? "Cancel" : "Record New Baseline"}
            </strong>
            .
          </p>
          {onboardingComplete && (
          <HelpModal toggleClass="link text-sm font-600" location="calibration" helpIcon>
            Learn more about Muse Calibration vs. GoDeeper calibration
          </HelpModal>
          )}
        </Card>
      </div>
      <div className="baselineCalibration__actions">
        <GenericButton
          text="Save Baseline"
          onButtonClick={finishCalibration}
        />
        <GenericButton
          className="button--danger"
          text={onboardingComplete ? "Cancel" : "Record New Baseline"}
          onButtonClick={() => setShowResetDialog(true)}
        />
        <Dialog
          show={showResetDialog}
          title="Are you sure?"
          onSuccess={cancelCalibration}
          onCancel={() => setShowResetDialog(false)}
          loading={false}
        >
          <p>
            {onboardingComplete
              ? "This action will discard your new calibration and cancel the process."
              : "This action will discard your calibration and restart the process."}
          </p>
        </Dialog>
      </div>
    </div>
  ) : (
    <CalibrationSession
      onComplete={sessionCompleted}
      onCancel={onCancel}
      onError={onError}
      sessionStatus={[sessionStatus, setSessionStatus]}
      startStream={startStream}
      audioFile={AUDIO_FILES.baseline}
    >
      <h2>Recording your Baseline</h2>
      {["ready", "started"].includes(sessionStatus) && (
      <div className="calibrationSession__text">
        <p>
          We are going to make a baseline recording of your brainwaves for five
          minutes. All of your depth scores are calculated by comparing your
          meditation brainwaves to your baseline brainwaves, so it is very
          important that you
          {" "}
          <strong>don’t meditate</strong>
          .
        </p>
        <p>
          We will play muffled sounds of
          people talking to help you not meditate.
        </p>
        <p>
          Press the
          {" "}
          <strong>Start</strong>
          {" "}
          button, close your eyes, and think of something that will
          help you not meditate - like your to-do list.
        </p>
        <p>
          We will ring a bell when five minutes are up.
        </p>
      </div>
      )}
      {["completed"].includes(sessionStatus) && (
      <div className="calibrationSession__loading">
        <div className="loader-wrapper">
          <Loader />
        </div>
        <h3>Analyzing your Recording</h3>
        <p className="text-muted">Please wait for a few seconds...</p>
      </div>
      )}
    </CalibrationSession>
  );
}

export default BaselineCalibration;
