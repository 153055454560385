import { useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ResponsiveLine } from "@nivo/line";
import { format } from "date-fns";

import ChartLoader from "features/ProgressGraphs/ChartLoader";

import { changeGraphStatus } from "store/actions/reporting";
import usePinch from "hooks/usePinch";

import {
  faArrowDown, faArrowUp, faHandsClapping, faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./MetricsChart.scss";

function MetricsChart({ data, hiddenRules }) {
  const dispatch = useDispatch();
  const { graphStatus } = useSelector(({ reportingReducer }) => reportingReducer);
  const { range, period } = graphStatus;

  const {
    isPending, isPlaceholderData, isData, filteredData, rulesContent,
  } = data;

  const pinchRef = useRef(null);
  usePinch({
    element: pinchRef,
    min: 8,
    max: 20,
    current: range,
    onChange: (newRange) => dispatch(changeGraphStatus({ ...graphStatus, range: newRange })),
    rangeMode: true,
  });

  const filteredRules = useMemo(
    () => rulesContent?.filter((rule) => !hiddenRules?.includes(rule?.ruleId)),
    [rulesContent, hiddenRules],
  );

  const setMetrics = (point) => {
    const metrics = point?.points[0]?.data.x;
    if (!graphStatus.metrics || metrics !== graphStatus.metrics) {
      dispatch(changeGraphStatus({ ...graphStatus, metrics }));
    }
    return null;
  };

  const getYposition = (value) => `${((value + 3) / 6) * 100}%`;

  const getMarkers = () => {
    if (graphStatus.metrics) {
      const xValues = filteredData[0].data.map(({ x }) => x);
      return xValues.includes(graphStatus.metrics)
        && [{
          axis: "x",
          value: graphStatus.metrics,
          legendOrientation: "vertical",
          legendOffsetY: 0,
          legendOffsetX: 0,
          lineStyle: {
            strokeDasharray: "6, 6",
            strokeOpacity: 0.75,
            strokeWidth: 1,
          },
        }];
    }
    return null;
  };

  const setColors = () => filteredRules?.map((rule) => rule?.color || "rgba(0,0,0,0)");

  const formatDate = (value) => {
    const date = value.split("/");
    const newDate = new Date(`${date[2]}-${date[1]}-${date[0]}`);
    return format((newDate), period === "Month" ? "MMM" : "d MMM");
  };

  return (
    <ChartLoader
      loading={isPending || isPlaceholderData}
      isData={isPending || filteredData?.length > 0}
      pinchInfo
    >
      <div className="chart-container chart-container--metrics" ref={pinchRef}>
        {!!filteredData?.length && (
          <>
            <div className="metrics-overlay__wrapper">
              <div className="metrics-overlay" />
              <div className="metrics-overlay__label-good">
                <FontAwesomeIcon icon={faHandsClapping} />
                Great
              </div>
              <div className="metrics-overlay__label-bad">
                <FontAwesomeIcon icon={faThumbsDown} />
                Poor
              </div>
              <div className="metrics-legend">
                {filteredRules.map((rule) => rule?.lastScore !== null && isData && (
                  <div
                    key={rule.ruleId}
                    className="metrics-legend__rule"
                    style={{ bottom: getYposition(rule.lastScore) }}
                  >
                    <div
                      className="metrics-legend__rule-content"
                      style={{ background: rule.color }}
                    >
                      {rule.label}
                      <FontAwesomeIcon icon={rule.direction === "up" ? faArrowUp : faArrowDown} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <ResponsiveLine
              data={filteredData.filter((rule) => !hiddenRules?.includes(rule?.ruleId))}
              theme={{
                background: "transparent",
                text: {
                  fontSize: 11,
                  fontFamily: "Quicksand",
                  fill: "#171d66",
                },
              }}
              colors={setColors()}
              margin={{
                top: 15, right: 40, bottom: 50, left: 60,
              }}
              enableGridX={false}
              gridYValues={7}
              curve="monotoneX"
              xScale={{
                type: "point",
              }}
              yScale={{
                type: "linear",
                min: -3,
                max: 3,
                stacked: false,
              }}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -90,
                format: (value) => formatDate(value),
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Session Rules Metrics",
                legendPosition: "middle",
                legendOffset: -50,
                tickValues: 6,
              }}
              pointLabelYOffset={-12}
              layers={[
                "grid",
                "lines",
                "markers",
                "axes",
                "slices",
                "points",
              ]}
              animate={false}
              markers={getMarkers()}
              enableSlices="x"
              sliceTooltip={() => false}
              onClick={(point) => setMetrics(point)}
              isInteractive
            />
          </>
        )}
      </div>
    </ChartLoader>
  );
}

export default MetricsChart;
