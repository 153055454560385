import { clientApi } from "api/axios";
import * as urls from "api/urls";
import errorHandler from "utils/error-handler";
import * as actionTypes from "./types";

export const getCalibratedProtocolsStart = () => ({
  type: actionTypes.CALIBRATED_PROTOCOLS_START,
});
export const getCalibratedProtocolsSuccess = (payload) => ({
  type: actionTypes.CALIBRATED_PROTOCOLS_SUCCESS,
  payload,
});
export const getCalibratedProtocolsError = (payload) => ({
  type: actionTypes.CALIBRATED_PROTOCOLS_ERROR,
  payload,
});

export const getCalibratedProtocols = (userId) => async (dispatch) => {
  dispatch(getCalibratedProtocolsStart());
  try {
    const res = await clientApi.get(urls.calibratedProtocols(userId));
    dispatch(getCalibratedProtocolsSuccess(res.data));
  } catch (err) {
    errorHandler(err, dispatch, getCalibratedProtocolsError);
  }
};

export const saveCalibratedProtocolSuccess = (payload) => ({
  type: actionTypes.SAVE_CALIBRATED_PROTOCOL_SUCCESS,
  payload,
});

export const saveCalibratedProtocolError = (err) => ({
  type: actionTypes.SAVE_CALIBRATED_PROTOCOL_ERROR,
  payload: err,
});

export const saveCalibratedProtocol = (userId, levelId) => (dispatch) => Promise.resolve()
  .then(async () => {
    try {
      const { data } = await clientApi.get(urls.saveCalibratedProtocolUrl(userId, levelId));
      dispatch(saveCalibratedProtocolSuccess(data));
      return data;
    } catch (err) {
      errorHandler(err, dispatch, saveCalibratedProtocolError);
      return false;
    }
  });

export const updateCalibratedProtocol = (userId, levelId) => (dispatch) => Promise.resolve()
  .then(async () => {
    try {
      const { data } = await clientApi.get(urls.updateCalibratedProtocolUrl(userId, levelId));
      dispatch(saveCalibratedProtocolSuccess(data));
      return data;
    } catch (err) {
      errorHandler(err, dispatch, saveCalibratedProtocolError);
      return false;
    }
  });

export const addCalibrationRuleSuccess = (payload) => ({
  type: actionTypes.ADD_CALIBRATION_RULE,
  payload,
});

export const addCalibrationRule = (userId, calibrationId, rule) => (dispatch) => Promise.resolve()
  .then(async () => {
    try {
      const { data } = await clientApi.get(urls.addCalibrationRuleUrl(userId, calibrationId, rule));
      dispatch(addCalibrationRuleSuccess(data));
      return true;
    } catch (err) {
      errorHandler(err);
      return false;
    }
  });

export const deleteCalibrationRuleSuccess = (payload) => ({
  type: actionTypes.DELETE_CALIBRATION_RULE,
  payload,
});

export const deleteCalibrationRule = (calibrationId, rule) => (dispatch) => Promise.resolve()
  .then(async () => {
    try {
      await clientApi.delete(urls.deleteCalibrationRuleUrl(calibrationId, rule));
      dispatch(deleteCalibrationRuleSuccess({ calibrationId, rule }));
      return true;
    } catch (err) {
      errorHandler(err);
      return false;
    }
  });

export const gettingProtocols = () => ({
  type: actionTypes.GET_PROTOCOLS,
});

export const getProtocolsSuccess = (payload) => ({
  type: actionTypes.GET_PROTOCOLS_SUCCESS,
  payload,
});

export const getProtocolsError = (err) => ({
  type: actionTypes.GET_PROTOCOLS_ERROR,
  payload: err,
});

export const getProtocols = () => async (dispatch) => {
  dispatch(gettingProtocols());
  try {
    const { data } = await clientApi.get(urls.getProtocolsUrl());
    dispatch(getProtocolsSuccess(data));
  } catch (err) {
    errorHandler(err, dispatch, getProtocolsError);
  }
};

export const startDeleteCalibratedProtocol = () => ({
  type: actionTypes.DELETE_CALIBRATED_PROTOCOL,
});

export const deleteCalibratedProtocolSuccess = (payload) => ({
  type: actionTypes.DELETE_CALIBRATED_PROTOCOL_SUCCESS,
  payload,
});

export const deleteCalibratedProtocolError = (err) => ({
  type: actionTypes.DELETE_CALIBRATED_PROTOCOL_ERROR,
  payload: err,
});

export const deleteCalibratedProtocol = (userId, levelId) => async (dispatch) => {
  dispatch(startDeleteCalibratedProtocol());
  try {
    await clientApi.delete(urls.deleteProtocolByIdUrl(userId, levelId));
    dispatch(deleteCalibratedProtocolSuccess(levelId));
  } catch (err) {
    errorHandler(err, dispatch, deleteCalibratedProtocolError);
  }
};

export const resetProtocolSync = () => ({
  type: actionTypes.RESET_PROTOCOL_SYNC,
});

export const setProtocolToCalibrate = (payload) => ({
  type: actionTypes.SET_PROTOCOL_TO_CALIBRATE,
  payload,
});

export const setProtocolGuide = (payload) => ({
  type: actionTypes.SET_PROTOCOL_GUIDE,
  payload,
});

export const setProtocolsListCollapse = (payload) => ({
  type: actionTypes.SET_PROTOCOLS_LIST_COLLAPSE,
  payload,
});
