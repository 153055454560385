import { useState } from "react";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import { Collapse } from "react-bootstrap";

import { Card } from "components";
import { FRQ_COLOR } from "constants/brainwave_analyzer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus, faMinus, faArrowUp, faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { ruleResult } from "./helpers/helper";

import "./InfoCategory.scss";

export function InfoCategory({
  activeState, filters, content, isCoach,
}) {
  const [collapse, setCollapse] = useState(true);

  const safeHtml = (html) => {
    if (!html) return null;
    const quotes = html.replaceAll(" [(", "<span class=\"interpretation-quote\"> (").replaceAll(")]", ")</span>");
    return parse(DOMPurify.sanitize(quotes));
  };

  return (
    <div className={`brainwaveReport__category-wrapper is-${activeState}`}>
      <div className="brainwaveReport__category-intro">
        { (filters.intro || activeState === "brain") ? (
          <button
            type="button"
            className="intro-toggle"
            style={{ color: FRQ_COLOR[content.frq] }}
            onClick={() => setCollapse(!collapse)}
          >
            <span className="text">{content.title}</span>
            <span className="toggle">
              {collapse ? "less" : "more"}
              <FontAwesomeIcon icon={collapse ? faMinus : faPlus} />
            </span>
          </button>
        ) : (
          <h3 style={{ color: FRQ_COLOR[content.frq] }}>
            {content.title}
          </h3>
        ) }
        { (filters.intro || activeState === "brain") && (
        <Collapse in={collapse}>
          <div>
            <div className="intro-content">
              {safeHtml(content.intro_content)}
              {filters.coach && isCoach && safeHtml(content.coach_content)}
            </div>
          </div>
        </Collapse>
        )}
      </div>
      {activeState === "brain" ? (
        <div className="brainwaveReport__zone-wrapper">
          {content.brain && Object.entries(content.brain).map(
            ([location, value]) => value !== false && (
              <Card
                key={location}
                customClass="brainwaveReport__rule-card"
              >
                <div className="rule-header">
                  <h4>
                    {" "}
                    {content.frq}
                    {" "}
                    {location}
                  </h4>
                  <span
                    className="rule-value text-white"
                    style={{ backgroundColor: FRQ_COLOR[content.frq] }}
                  >
                    {value > 0 ? "+" : ""}
                    {value.toFixed(2)}
                    {" "}
                    SD
                  </span>
                </div>
              </Card>
            ),
          )}
        </div>
      ) : (
        <div className="brainwaveReport__zone-wrapper">
          {content.rules && content.rules.map((rule) => (
            <Card
              key={rule.rule}
              customClass="brainwaveReport__rule-card"
              collapseHeader={(
                <div className="rule-header">
                  <h4>
                    {rule.descriptions?.name || rule.title}
                  </h4>
                  {rule.value !== null && (
                  <span
                    className="rule-value"
                    style={{ backgroundColor: ruleResult(rule.score).color }}
                  >
                      {rule.value !== 0
                      && <FontAwesomeIcon icon={rule.value > 0 ? faArrowUp : faArrowDown} />}
                    {Math.abs(rule.score).toFixed(2)}
                  </span>
                  )}
                </div>
              )}
            >
              {rule.descriptions?.rationale && (
              <div className="rule-content">
                {rule.definition ? (
                  <>
                    {safeHtml(rule.definition.content)}
                    {filters.coach && isCoach
                      && safeHtml(rule.definition.coach_content)}
                  </>
                ) : safeHtml(rule.descriptions?.rationale)}
              </div>
              )}
            </Card>
          ))}
        </div>
      )}
    </div>
  );
}

export default InfoCategory;
