import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loader } from "components/ui/Loader/Loader";
import "./EmptyData.scss";

export function EmptyData({
  title, text, icon, customClass, isLoading, children,
}) {
  return (
    <div className={`emptyData ${customClass || ""}`}>
      {children || (
        <>
          <div className="icon-wrapper">
            {isLoading ? (
              <Loader />
            ) : (
              <FontAwesomeIcon
                icon={icon}
              />
            )}
          </div>
          <h3>{title}</h3>
          <p className="text-muted text-sm">{text}</p>
        </>
      )}
    </div>
  );
}

export default EmptyData;
