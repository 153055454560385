import { useSelector } from "react-redux";
import { BluetoothBattery } from "components/eeg/BluetoothBattery/BluetoothBattery";
import { Spinner } from "components/ui/Spinner/Spinner";
import { btMenuController } from "utils/native-controllers";

import { ReactComponent as BtIcon } from "assets/icons_bt.svg";
import { ReactComponent as ArrowIcon } from "assets/icons_arrow.svg";

import "./BluetoothDevice.scss";

export function BluetoothDevice({ showBattery, customClass = "" }) {
  const { btDevice, btDeviceStream } = useSelector(({ sessionReducer }) => sessionReducer);

  const toggleBTMenu = () => {
    btMenuController("toggle");
  };

  // Status:
  // 0 = disabled
  // 1 = disconnected
  // 2 = searching
  // 3 = found more than one
  // 4 = connecting
  // 5 = connected

  return (
    <div
      role="presentation"
      className={`bluetooth-device bt-status-${btDevice.status} ${customClass} ${btDeviceStream ? "streaming" : ""}`}
      onClick={toggleBTMenu}
    >
      <BtIcon />
      {btDevice.status === 0 && (
        <p>
          <strong>Disabled.</strong>
            {" "}
          Bluetooth
            {" "}
          <strong>not available</strong>
          .
        </p>
      )}
      {btDevice.status === 1 && (
        <p>
          <strong>Disconnected.</strong>
            {" "}
          Turn on
            {" "}
          <strong>Muse device</strong>
          .
        </p>
      )}
      {btDevice.status === 2 && (
      <>
        <p>
          <strong>Looking for your Muse.</strong>
          {" "}
          Is it turned on?
        </p>
        <Spinner />
      </>
      )}
      {btDevice.status === 3 && (
      <>
        <p>
          <strong>More Muses found.</strong>
          {" "}
          Tap to select one.
        </p>
        <ArrowIcon />
      </>
      )}
      {btDevice.status === 4 && (
      <>
        <p>
          Connecting to
          {" "}
          <strong>{ btDevice.name }</strong>
        </p>
        <Spinner />
      </>
      )}
      {btDevice.status === 5 && (
        <>
          <p>
            Connected to
            {" "}
            <strong>{ btDevice.name }</strong>
          </p>
          { showBattery && (
          <BluetoothBattery />
          )}
        </>
      )}
    </div>
  );
}

export default BluetoothDevice;
