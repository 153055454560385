import {
  Route, Routes, Navigate,
} from "react-router-dom";

import { AuthProvider, AuthRoute } from "components";
import Registration from "pages/App/Authentication/Registration/Registration";
import ResetPassword from "pages/App/Authentication/ResetPassword/ResetPassword";
import Login from "pages/App/Authentication/Login/Login";
import Logout from "pages/Common/Logout/Logout";
import Loader from "pages/App/Authentication/Loader/Loader";
import RouteNotFound from "pages/Common/404/404";

import Onboarding from "./routes/Onboarding";
import Meditation from "./routes/Meditation";
import Progress from "./routes/Progress";
import Settings from "./routes/Settings";

export function Router() {
  return (
    <Routes>
      <Route index element={<Navigate to="/app/loader" />} exact />
      <Route element={<AuthProvider />}>
        <Route path="login" element={<Login />} />
        <Route path="registration" element={<Registration />} />
        <Route path="reset-password" element={<ResetPassword />} />
      </Route>
      <Route path="loader" element={<Loader />} />
      <Route element={<AuthRoute />}>
        <Route path="logout" element={<Logout />} />
        <Route path="onboarding/*" element={<Onboarding />} />
        <Route path="meditation/*" element={<Meditation />} />
        <Route path="progress/*" element={<Progress />} />
        <Route path="settings/*" element={<Settings />} />
      </Route>
      <Route path="*" element={<RouteNotFound />} />
    </Routes>
  );
}

export default Router;
