import { useRef, useState, useEffect } from "react";
import { ReactComponent as Star } from "assets/icons_promo_star.svg";

import useIsVisible from "hooks/useIsVisible";

import "./InfoProgress.scss";

export function InfoProgress({ current, max, children }) {
  const [shown, setShown] = useState(false);

  const bar = useRef();
  const isVisible = useIsVisible(bar, 100);

  const progress = (current / max) * 100;

  useEffect(() => {
    if (isVisible && !shown) setShown(true);
  }, [isVisible]);

  return (
    <div className="infoProgress">
      <div className="infoProgress__content">{children}</div>
      <div className="infoProgress__progress">
        <div className="infoProgress__progress-bar">
          <div
            className="infoProgress__progress-bar--progress"
            ref={bar}
            style={{ width: `${shown ? progress : 0}%` }}
          />
        </div>
        <Star />
      </div>
    </div>
  );
}

export default InfoProgress;
