import logo from "assets/goDeeper_logo.png";
import "./HeaderLogo.scss";
import useScrollIsTop from "hooks/useScrollIsTop";

export function HeaderLogo({ headerLogo }) {
  const isTop = useScrollIsTop();

  return (
    <div className="authenticationHeader">
      <div className={`authenticationHeader__content ${isTop ? "is-top" : ""}`}>
        <img
          className="authenticationHeader__logo"
          src={headerLogo || logo}
          height="100"
          alt="GoDeeper"
        />
      </div>
    </div>
  );
}

export default HeaderLogo;
