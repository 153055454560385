/* eslint-disable no-param-reassign */
import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ExpertiseCard, ProtocolSelectContent, BottomBarSticky, GenericButton,
} from "components";
import { Container } from "react-bootstrap";
import { setProtocolToCalibrate } from "store/actions/protocols";
import usePracticeTime from "hooks/usePracticeTime";
import { ALL_LEVELS, LEVEL_CARDS_COLORS } from "constants/onBoarding_level";

import "./ProtocolLevel.scss";

function ProtocolLevel({ protocol, onSelect, showSuggest }) {
  const dispatch = useDispatch();

  const userPractice = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.experience_practice,
  );
  const appPractice = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.experience_app_hours,
  );

  const { categoryID } = protocol;

  const levelOptions = useMemo(() => {
    const activeLvls = protocol
      ? protocol.levels.map(({ level }) => level) : [];
    return ALL_LEVELS.map((level) => {
      level.isActive = activeLvls.includes(level.value);
      return level;
    });
  }, [protocol]);

  const { suggestedLevel } = usePracticeTime(userPractice, appPractice);

  const [activeLevel, setActiveLevel] = useState(null);

  const onItemSelect = (value, isActive) => {
    const selectedLevel = protocol.levels.find(({ level }) => level === value);
    if (isActive) setActiveLevel(selectedLevel);
  };

  const onNext = () => {
    dispatch(
      setProtocolToCalibrate({
        level: activeLevel,
        protocol: { ...protocol, categoryID },
      }),
    );
    onSelect?.();
  };

  return (
    <div className="levelPage">
      <ProtocolSelectContent protocol={protocol} size="md" centered />
      <h2 className="text-center">Choose your experience</h2>
      <div className="expertiseCard__wrapper">
        {levelOptions && levelOptions.map(({
          key, value, description, isActive,
        }) => (
          <ExpertiseCard
            key={value}
            value={value}
            title={key}
            description={description}
            onItemSelect={() => onItemSelect(value, isActive)}
            disabled={!isActive}
            suggestedItem={showSuggest && key === suggestedLevel}
            activeItem={activeLevel?.level === value}
          />
        ))}
      </div>
      <Container>
        <h2>Choose Carefully</h2>
        <p>
          The brainwaves at each level are distinctly different. GoDeeper uses a
          different set of metrics at each level to measure depth based on
          academic research, so this choice will have a big influence on your
          experience.
        </p>
        <div className="levelDescription__wrapper">
          <div className="levelDescription__level">
            <h3 style={{ color: LEVEL_CARDS_COLORS[0] }}>
              <span>Level 1: </span>
              Relaxing into Calm
            </h3>
            <p className="mt-3">
              <strong>
                The first goal in learning any kind of meditation is to settle in and relax.
                We live in a very stressful world, so relieving stress is often the goal.
              </strong>
              {" "}
              Level 1 is like base camp for a mountain climber, learning how to dribble
              for a basketball player, or trying to find a consistent sound from a violin player.
              We graduate from level 1 when we’re able to find some inner peace and start
              diligently practicing technique.
            </p>
            <p>
              Practice time:
              {" "}
              <strong>10-100’s of hours</strong>
            </p>
          </div>
          <div className="levelDescription__level">
            <h3 style={{ color: LEVEL_CARDS_COLORS[1] }}>
              <span>Level 2: </span>
              Efforting to Master Technique
            </h3>
            <p className="mt-3">
              <strong>
                This level involves concentrated, consistent, and sometimes grueling work.
                This is the active state of working to master a technique.
              </strong>
              {" "}
              Level 2 is like mastering your climbing technique, working on your shot accuracy,
              or mastering specific fingering on a violin. We graduate from level 2
              when we find ourselves able to stay with our technique more naturally,
              without using much effort.
            </p>
            <p>
              Practice time:
              {" "}
              <strong>100-1,000’s of hours</strong>
            </p>
          </div>
          <div className="levelDescription__level">
            <h3 style={{ color: LEVEL_CARDS_COLORS[2] }}>
              <span>Level 3: </span>
              Stabilizing Expansiveness
            </h3>
            <p className="mt-3">
              <strong>
                After all the hard work, meditation becomes more of an automatic habit.
              </strong>
              {" "}
              It’s sort of like muscle memory, where feelings of love, joy, equanimity and clarity
              start to arise naturally. This is like a climber who can “read the mountain”,
              an athlete who can take in the whole court and effortlessly execute technique
              or a musician expressing themselves through the music without thinking about
              technique. Only the rare few graduate from level 3 when their thoughts and
              sense of self begin to disappear.
            </p>
            <p>
              Practice time:
              {" "}
              <strong>1,000’s of hours </strong>
              <br />
              <span className="text-sm text-muted">1,000 hours = ~20min per day for &gt; one year</span>
            </p>
          </div>
          <div className="levelDescription__level">
            <h3 style={{ color: LEVEL_CARDS_COLORS[3] }}>
              <span>Level 4: </span>
              Being Non-Dual
            </h3>
            <p className="mt-3">
              <strong>
                With extended practice, meditation styles start to
                converge like trails on the top of a mountain.
              </strong>
              {" "}
              Techniques and thoughts fall away as we continue to surrender and stabilize.
              As the distinction between subject and object falls away, one experiences
              reality directly, without the filter of a self. This is the peak of the mountain,
              an athlete in flow, or a musician becoming the music.
            </p>
            <p>
              Practice time:
              {" "}
              <strong>Many years, many retreats</strong>
            </p>
          </div>
        </div>
        <p className="text-sm text-muted mb-5">
          <strong>Source:</strong>
          {" "}
          MEDI framework from Harald Piron, 1999
        </p>
      </Container>
      {activeLevel && (
        <BottomBarSticky>
          <GenericButton
            className="button--dark"
            text="Start Calibration"
            onButtonClick={onNext}
          />
        </BottomBarSticky>
      )}
    </div>
  );
}

export default ProtocolLevel;
