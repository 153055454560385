import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  GenericButton,
  Card,
  InputText,
  BottomBarSticky,
  PracticeSummary,
} from "components";
import usePracticeTime from "hooks/usePracticeTime";
import { saveUserProfile } from "store/actions/profile";

import "./Expertise.scss";
import { CALCULATOR_FORM, CALCULATOR_LIMITS } from "constants/forms_data";

function ExpertisePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userPractice = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.experience_practice,
  );
  const appPractice = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.experience_app_hours,
  );

  const [practice, setPractice] = useState({
    minutes: userPractice?.minutes || 0,
    sessions: userPractice?.sessions || 0,
    days: userPractice?.days || 0,
    years: userPractice?.years || 0,
    months: userPractice?.months || 0,
    retreats: userPractice?.retreats || 0,
  });

  const { regularHours, totalHours } = usePracticeTime(practice, appPractice);

  const isInputValid = (key) => practice[key] >= 0 && practice[key] <= CALCULATOR_LIMITS[key];

  const setPracticeValue = (value, key) => {
    const parsedValue = value === "" ? "" : parseInt(value, 10);
    setPractice({
      ...practice, [key]: parsedValue,
    });
  };

  const onSave = () => {
    const practiceValidate = { ...practice };
    Object.entries(practiceValidate).forEach(([key, value]) => {
      if (value === "") practiceValidate[key] = 0;
    });
    dispatch(saveUserProfile({
      experience_practice: practiceValidate,
    }, { navigate, nextUrl: "/app/settings" }));
  };

  const onFocus = (e, key) => {
    if (!practice[key]) {
      setPracticeValue("", key);
    } else {
      const input = e.target;
      input.type = "text";
      input.setSelectionRange(input.value.length, input.value.length);
      input.type = "number";
    }
  };
  const onBlur = (key) => {
    const isValid = isInputValid(key);
    if (!practice[key] || !isValid) setPracticeValue(0, key);
  };

  return (
    <div className="expertisePage">
      <h2 className="mt-4">
        Practice Calculator
      </h2>
      <p className="text-muted font-600">
        Please only calculate from when you began practicing at least once a week.
      </p>
      <Card customClass="expertisePage__calculator-card">
        <div className="expertisePage__calculator">
          {CALCULATOR_FORM.map((section) => (
            <div key={section.title} className="expertisePage__calculator-question">
              <p>{section.title}</p>
              {section.inputs.map((input) => (
                <div key={input.key} className="expertisePage__calculator-row">
                  <InputText
                    inputType="number"
                    inputMode="numeric"
                    defaultValue={practice[input.key]}
                    onChangeValue={(e) => setPracticeValue(e.target.value, input.key)}
                    onBlurValue={() => onBlur(input.key)}
                    onFocusValue={(e) => onFocus(e, input.key)}
                    isValid={isInputValid(input.key)}
                  />
                  {input.label}
                </div>
              ))}
            </div>
          ))}
        </div>
      </Card>
      <PracticeSummary
        appPractice={appPractice}
        regularHours={regularHours}
        totalHours={totalHours}
      />
      <BottomBarSticky>
        <GenericButton
          className="button--success"
          text="Save"
          onButtonClick={onSave}
        />
      </BottomBarSticky>
    </div>
  );
}

export default ExpertisePage;
