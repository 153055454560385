import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { loginUserSuccess } from "store/actions/authentication";

/**
 * Redirect if user do not have access
 * @param {number} level - permission level: 1/2/3/4
 */
export function ProtectedRoute({ level }) {
  const dispatch = useDispatch();

  const userAuth = localStorage.getItem("userAuth");
  const { isUserLoggedIn, user } = useSelector(
    ({ authenticationReducer }) => authenticationReducer,
  );

  if (isUserLoggedIn && !user) dispatch(loginUserSuccess(JSON.parse(userAuth)));

  if (!isUserLoggedIn || !userAuth) return <Navigate to="/coach/login" />;
  if (user?.subscription_level_id < level) return <Navigate to="/coach/denied" />;
  return <Outlet />;
}

export default ProtectedRoute;
