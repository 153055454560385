import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GenericButton, BottomBarSticky, CompletedStep } from "components";

import useSkipSignalCheck from "hooks/useSkipSignalCheck";
import { setOnboardingTab } from "store/actions/onBoarding";
import { setActiveStream } from "store/actions/session";

import SignalCheck from "pages/App/Common/SignalCheck/SignalCheck";
import Calibration from "pages/App/Common/BaselineCalibration/BaselineCalibration";

function BaselineCalibrationPage({ tabIndex, isCompleted }) {
  const dispatch = useDispatch();
  const skipSignalCheck = useSkipSignalCheck();

  const [stage, setStage] = useState(skipSignalCheck ? "calibration" : "signal");

  useEffect(() => {
    if (stage === "calibration" && !isCompleted) dispatch(setActiveStream("status"));
    return () => dispatch(setActiveStream(null));
  }, [stage]);

  const startCalibration = () => {
    setStage("calibration");
  };
  const onContinue = () => {
    dispatch(setOnboardingTab(tabIndex + 1));
  };
  const onCancel = () => {
    dispatch(setOnboardingTab(tabIndex - 1));
  };
  const onError = () => {
    setStage("signal");
  };

  const stages = {
    signal: <SignalCheck onComplete={startCalibration} onCancel={onCancel} />,
    calibration: <Calibration onComplete={onContinue} onCancel={onCancel} onError={onError} />,
  };

  return (
    !isCompleted
      ? stages[stage]
      : (
        <>
          <CompletedStep text="Your Baseline Recording is completed" />
          <BottomBarSticky>
            <GenericButton
              className="button--dark"
              text="Continue"
              onButtonClick={onContinue}
            />
          </BottomBarSticky>
        </>
      )
  );
}

export default BaselineCalibrationPage;
