import { StreakCounter as StreakCounterComponent, Card } from "components";
import ChartLoader from "features/ProgressGraphs/ChartLoader";

import "./LifetimeChart.scss";

function LifetimeChart({ data, goalData }) {
  const {
    isPending, isPlaceholderData, data: lifetimeData,
  } = data;

  const getTime = (time) => {
    let hours;
    let minutes;
    if (typeof time === "string") {
      [hours, minutes] = time?.split(":") || [];
    } else {
      const date = new Date(0, 0, 0);
      date.setSeconds(time);
      hours = date.getHours();
      minutes = date.getMinutes();
    }
    return `${Number(hours)}h ${Number(minutes)}min`;
  };

  return (
    <ChartLoader
      loading={isPending || isPlaceholderData}
      isData={isPending || lifetimeData}
    >
      <Card>
        <div className="progress-streak__content">
          {!isPending && (
            <>
              <div className="progress-streak__counter">
                <p>
                  This week:&nbsp;
                  <strong>
                    {getTime(lifetimeData?.goalProgress)}
                &nbsp;/&nbsp;
                    {getTime(goalData)}
                  </strong>
                </p>
                <StreakCounterComponent
                  currentStreak={lifetimeData?.currentStreak}
                  longestStreak={lifetimeData?.longestStreak}
                  progress
                />
              </div>
              <div className="progress-streak__total-sessions">
                <p>
                  <strong>
                    {lifetimeData?.lifetimeSessions}
                  </strong>
          &nbsp;life time sessions
                </p>
              </div>
            </>
          )}
        </div>
      </Card>
    </ChartLoader>
  );
}

export default LifetimeChart;
