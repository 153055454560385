import { useMemo, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import useProtocol from "hooks/useProtocol";

import { format } from "date-fns";
import { formatDuration } from "utils/time-format";

import { Container } from "react-bootstrap";
import {
  CoachNav, ProtocolSelectContent, Spinner, Breadcrumbs,
} from "components";

import { getClient, getClientSessionDetails } from "api/services/coach";
import { BrainwaveInterpretation } from "features";

import "./BrainwaveAnalyzer.scss";

function BrainwaveAnalyzer() {
  const navigate = useNavigate();
  const { clientId, sessionId } = useParams();

  const client = useQuery({
    queryKey: ["client", clientId],
    queryFn: () => getClient(clientId),
  });

  const { isError, data: summary } = useQuery({
    queryKey: ["session-summary", sessionId],
    queryFn: () => getClientSessionDetails(sessionId),
  });

  useEffect(() => {
    if (client.isError || isError) navigate("*", { replace: true });
  }, [client.isError, isError]);

  const sessionProtocol = useProtocol(
    summary?.sessionData?.protocol.categoryID,
    summary?.sessionData?.protocol.protocolID,
    summary?.protocolDetails,
  );

  const sessionLevel = useMemo(() => {
    const sessionLevelId = summary?.sessionData.protocol.levelID;
    return sessionLevelId?.split("/")[1];
  }, [summary]);

  const sessionRule = useMemo(() => {
    const ruleNo = summary?.sessionData.protocol.rule;
    if (ruleNo && sessionProtocol) {
      return sessionProtocol.protocolRules.find(
        (rule) => rule?.rule === ruleNo,
      );
    }
    return null;
  }, [summary, sessionProtocol]);

  return (
    <div className="coach-panel">
      <CoachNav />
      <div className="brainwaveAnalyzer">
        {summary && client.data && sessionProtocol ? (
          <>
            <Container>
              <div className="page-title">
                <h1>Brainwave Analyzer</h1>
                <Breadcrumbs
                  active="Brainwave Analyzer"
                  urls={[
                    ["/coach/clients", "Clients"],
                    [`/coach/clients/${clientId}/progress`, "Progress"],
                    [`/coach/clients/${clientId}/session-summary/${sessionId}`, "Session Summary"],
                  ]}
                />
              </div>
              <div className="sessionSummaryPage__header">
                <ProtocolSelectContent
                  protocol={sessionProtocol}
                  level={sessionLevel}
                  rule={sessionRule?.descriptions?.name}
                />
                <div className="sessionSummaryPage__header-client">
                  <p className="text-muted">Client Name</p>
                  <Link
                    className="h2"
                    to={`/coach/clients/${clientId}/progress`}
                  >
                    {client.data?.first_name}
                    {" "}
                    {client.data?.last_name}
                  </Link>
                  <div className="sessionSummaryPage__header-date">
                    {`${format(new Date(summary.sessionData.date), "MMMM do, yyyy")}`}
                    &nbsp;-&nbsp;
                    {`${format(new Date(summary.sessionData.date), "hh:mma")}`}
                    &nbsp;-&nbsp;
                    <strong>{formatDuration(summary.sessionData.length, true)}</strong>
                  </div>
                </div>
              </div>
            </Container>
            <Container>
              <BrainwaveInterpretation
                sessionData={summary.sessionData}
                sessionId={sessionId}
                sessionProtocol={sessionProtocol}
                sessionLevel={sessionLevel}
                sessionRule={sessionRule}
              />
            </Container>
          </>
        ) : <Spinner />}
      </div>
    </div>
  );
}

export default BrainwaveAnalyzer;
