import { useState, useRef } from "react";
import {
  Collapse, OverlayTrigger, Popover,
} from "react-bootstrap";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import _ from "lodash";
import useProtocol from "hooks/useProtocol";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus, faMinus,
} from "@fortawesome/free-solid-svg-icons";

import "./ProtocolDetails.scss";

export function ProtocolDetails({ protocol, initCollapse = true }) {
  const [collapse, setCollapse] = useState({
    goal: initCollapse,
    basics: initCollapse,
    technique: initCollapse,
    meditation: initCollapse,
    info: initCollapse,
  });

  const wrapper = useRef();

  const protocolDetails = "basics" in protocol
    ? protocol
    : useProtocol(
      protocol.protocolIds?.categoryID || protocol.categoryID,
      protocol.protocolIds?.protocolID || protocol.protocolID,
    );

  const safeHtml = (html) => {
    if (!html) return null;
    const quotes = html.replaceAll(" [(", "<span class=\"interpretation-quote\"> (").replaceAll(")]", ")</span>");
    return parse(DOMPurify.sanitize(quotes));
  };

  const meditationPopover = (
    <Popover body>
      To use the app guided meditations with GoDeeper
      <ol className="mt-2 mb-1">
        <li>
          Set up your meditation protocol in GoDeeper, do the signal check,
          but don’t press the Start button.
        </li>
        <li>Set-up the guided meditation app and press play.</li>
        <li>Go back to GoDeeper and tap on the Start button.</li>
      </ol>
    </Popover>
  );

  return (
    <>
      <div ref={wrapper} className="protocolDetails__protocol-row">
        <button
          type="button"
          onClick={() => setCollapse((state) => ({ ...state, goal: !collapse.goal }))}
        >
          <span className="text">Goal</span>
          <span className="toggle">
            {collapse.goal ? "less" : "more"}
            <FontAwesomeIcon icon={collapse.goal ? faMinus : faPlus} />
          </span>
        </button>
        <Collapse in={collapse.goal}>
          <div>
            <div className="row-content">
              {safeHtml(protocolDetails?.goal)}
            </div>
          </div>
        </Collapse>
      </div>
      <div className="protocolDetails__protocol-row">
        <button
          type="button"
          onClick={() => setCollapse((state) => ({ ...state, basics: !collapse.basics }))}
        >
          <span className="text">Basics</span>
          <span className="toggle">
            {collapse.basics ? "less" : "more"}
            <FontAwesomeIcon icon={collapse.basics ? faMinus : faPlus} />
          </span>
        </button>
        <Collapse in={collapse.basics}>
          <div>
            <div className="row-content">
              {safeHtml(protocolDetails?.basics)}
            </div>
          </div>
        </Collapse>
      </div>
      <div className="protocolDetails__protocol-row">
        <button
          type="button"
          onClick={() => setCollapse((state) => ({ ...state, technique: !collapse.technique }))}
        >
          <span className="text">Technique</span>
          <span className="toggle">
            {collapse.technique ? "less" : "more"}
            <FontAwesomeIcon icon={collapse.technique ? faMinus : faPlus} />
          </span>
        </button>
        <Collapse in={collapse.technique}>
          <div>
            <div className="row-content">
              {safeHtml(protocolDetails?.technique)}
            </div>
          </div>
        </Collapse>
      </div>
      {protocolDetails?.guidedMeditations && (
      <div className="protocolDetails__protocol-row">
        <button
          type="button"
          onClick={() => setCollapse((state) => ({ ...state, meditation: !collapse.meditation }))}
        >
          <span className="text">Guided Meditations</span>
          <span className="toggle">
            {collapse.basics ? "less" : "more"}
            <FontAwesomeIcon icon={collapse.basics ? faMinus : faPlus} />
          </span>
        </button>
        <Collapse in={collapse.meditation}>
          <div className="row-content html-content protocolDetails__guided-meditations">
            <p>
              You can run these during your GoDeeper session using your laptop or from a
              {" "}
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={meditationPopover}
                container={wrapper}
                rootClose
              >
                <span className="text-decoration-underline text-primary">Phone App</span>
              </OverlayTrigger>
              .
            </p>
            {safeHtml(protocolDetails?.guidedMeditations)}
          </div>
        </Collapse>
      </div>
      )}
      <div className="protocolDetails__protocol-row">
        <button
          type="button"
          onClick={() => setCollapse((state) => ({ ...state, info: !collapse.info }))}
        >
          <span className="text">More Information</span>
          <span className="toggle">
            {collapse.info ? "less" : "more"}
            <FontAwesomeIcon icon={collapse.info ? faMinus : faPlus} />
          </span>
        </button>
        <Collapse in={collapse.info}>
          <div>
            <div className="row-content">
              {safeHtml(protocol?.info)}
              {protocolDetails?.links.length > 0 && (
              <>
                <p className="font-600 text-sm text-dark">
                  Check out these articles and videos on the benefits and
                  techniques of
                  {" "}
                  {_.unescape(protocolDetails.protocolName)}
                  {" "}
                  Meditations:
                </p>
                <ul className="protocolDetails__info">
                  {protocolDetails.links.map(({ url, text, type }, i) => (
                    <li key={i.toString()} className={`protocolDetailsPage__link is-${type}`}>
                      <a href={url} target="_blank" rel="noreferrer">
                        { _.unescape(text) }
                      </a>
                    </li>
                  ))}
                </ul>
              </>
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
}

export default ProtocolDetails;
