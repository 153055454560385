import { Route, Routes } from "react-router-dom";

import SettingsMenuPage from "pages/App/Settings/MainMenu/MainMenu";
import UserLoginPage from "pages/App/Settings/UserLogin/UserLogin";
import SubscriptionsPage from "pages/App/Settings/Subscriptions/Subscriptions";
import CoachPermissionsPage from "pages/App/Settings/CoachPermissions/CoachPermissions";
import CommunicationsPage from "pages/App/Settings/Communications/Communications";
import AudioFeedbackPage from "pages/App/Settings/AudioFeedback/AudioFeedback";
import NewBaselinePage from "pages/App/Settings/NewBaseline/NewBaseline";
import CalibrationPage from "pages/App/Settings/BaselineCalibration/BaselineCalibration";
import GoalsPage from "pages/App/Settings/Goals/Goals";
import ExpertisePage from "pages/App/Settings/Expertise/Expertise";
import TechniquesPage from "pages/App/Settings/Techniques/Techniques";
import TrainingPage from "pages/App/Settings/Training/Training";
import AppsPage from "pages/App/Settings/Apps/Apps";
import BrainwavesPage from "pages/App/Settings/Brainwaves/Brainwaves";
import ResetPasswordPage from "pages/App/Settings/ResetPassword/ResetPassword";
import HelpListPage from "pages/App/Settings/Help/HelpList";
import HelpPage from "pages/App/Settings/Help/Help";
import AboutPage from "pages/App/Settings/About/About";
import ManageData from "pages/App/Settings/ManageData/ManageData";

function Settings() {
  return (
    <Routes>
      <Route index element={<SettingsMenuPage />} exact />
      <Route path="user" element={<UserLoginPage />} />
      <Route path="changepassword" element={<ResetPasswordPage />} />
      <Route path="subscriptions" element={<SubscriptionsPage />} />
      <Route path="coachpermissions" element={<CoachPermissionsPage />} />
      <Route path="managedata" element={<ManageData />} />
      <Route path="communications" element={<CommunicationsPage />} />
      <Route path="experience" element={<ExpertisePage />} />
      <Route path="goals" element={<GoalsPage />} />
      <Route path="baseline" element={<NewBaselinePage />} />
      <Route path="calibration" element={<CalibrationPage />} />
      <Route path="audiofeedback" element={<AudioFeedbackPage />} />
      <Route path="techniques" element={<TechniquesPage />} />
      <Route path="training" element={<TrainingPage />} />
      <Route path="apps" element={<AppsPage />} />
      <Route path="brainwaves" element={<BrainwavesPage />} />
      <Route path="about" element={<AboutPage />} />
      <Route path="help" element={<HelpListPage />} />
      <Route path="help/:helpId" element={<HelpPage />} />
    </Routes>
  );
}

export default Settings;
