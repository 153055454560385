import { HelpModal } from "features";

function ChartHelp({ text, help }) {
  return (
    <div className="chart-help">
      { help && (
        <HelpModal toggleClass="font-600" location={help} helpIcon>
          {text}
        </HelpModal>
      )}
    </div>
  );
}

export default ChartHelp;
