import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen, faTags, faClipboardCheck, faSignOutAlt, faFlagCheckered, faComments,
  faBrain, faVolumeDown, faWindowRestore, faMountain, faToolbox, faWaveSquare, faGraduationCap,
  faQuestionCircle, faDatabase, faInfoCircle, faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";

const ACCOUNT_MENU_ITEMS = [
  { name: "Edit User", url: "/app/settings/user", icon: <FontAwesomeIcon icon={faPen} /> },
  { name: "Change Password", url: "/app/settings/changepassword", icon: <FontAwesomeIcon icon={faUnlockAlt} /> },
  { name: "Subscriptions", url: "/app/settings/subscriptions", icon: <FontAwesomeIcon icon={faTags} /> },
  { name: "Coach Permissions", url: "/app/settings/coachpermissions", icon: <FontAwesomeIcon icon={faClipboardCheck} /> },
];

const DATA_MENU_ITEMS = [
  { name: "Manage Data", url: "/app/settings/managedata", icon: <FontAwesomeIcon icon={faDatabase} /> },
  { name: "Log Out", url: "/app/settings/logout", icon: <FontAwesomeIcon icon={faSignOutAlt} /> },
];

const PROFILE_MENU_ITEMS = [
  { name: "Audio Feedback", url: "/app/settings/audiofeedback", icon: <FontAwesomeIcon icon={faVolumeDown} /> },
  { name: "Record New Baseline", url: "/app/settings/baseline", icon: <FontAwesomeIcon icon={faWaveSquare} /> },
  { name: "Meditation Goals", url: "/app/settings/goals", icon: <FontAwesomeIcon icon={faFlagCheckered} /> },
  { name: "Experience", url: "/app/settings/experience", icon: <FontAwesomeIcon icon={faMountain} /> },
  { name: "Apps", url: "/app/settings/apps", icon: <FontAwesomeIcon icon={faWindowRestore} /> },
  { name: "Techniques", url: "/app/settings/techniques", icon: <FontAwesomeIcon icon={faToolbox} /> },
  { name: "Training", url: "/app/settings/training", icon: <FontAwesomeIcon icon={faGraduationCap} /> },
  { name: "Brainwaves", url: "/app/settings/brainwaves", icon: <FontAwesomeIcon icon={faBrain} /> },
];

const ABOUT_ITEMS = [
  { name: "Contact", url: "/app/settings/communications", icon: <FontAwesomeIcon icon={faComments} /> },
  { name: "Help", url: "/app/settings/help", icon: <FontAwesomeIcon icon={faQuestionCircle} /> },
  { name: "About", url: "/app/settings/about", icon: <FontAwesomeIcon icon={faInfoCircle} /> },
];

export {
  ACCOUNT_MENU_ITEMS, DATA_MENU_ITEMS, PROFILE_MENU_ITEMS, ABOUT_ITEMS,
};
