import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export function AuthRoute() {
  const userAuth = localStorage.getItem("userAuth");
  const { isUserLoggedIn } = useSelector(({ authenticationReducer }) => authenticationReducer);
  const { userProfile } = useSelector(({ profileReducer }) => profileReducer);

  if (!isUserLoggedIn || !userAuth) return <Navigate to="/app/login" />;
  if (!userProfile) return <Navigate to="/app/loader" />;
  return <Outlet />;
}

export default AuthRoute;
