import { Calendar } from "@iroomit/react-date-range";
import { GenericButton } from "components/ui/Button/Button";

export function DatePicker({
  date,
  minDate,
  maxDate,
  onChange,
  onSubmit,
}) {
  return (
    <>
      <Calendar
        editableDateInputs
        direction="vertical"
        showPreview={false}
        showSelectionPreview={false}
        onChange={onChange}
        date={date}
        months={1}
        showMonthAndYearPickers
        showMonthArrow
        showDateDisplay={false}
        dragSelectionEnabled={false}
        preventSnapRefocus
        fixedHeight
        maxDate={maxDate}
        minDate={minDate}
      />
      <GenericButton text="Select" className="button--dark" onButtonClick={onSubmit} />
    </>
  );
}

export default DatePicker;
