import { useState } from "react";
import { Container } from "react-bootstrap";
import { CoachNav } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";

import { CoachTable } from "features";
import {
  getResearchLibrary,
} from "api/services/coach";
import ResearchDetails from "./components/ResearchDetails";

import "pages/Coach/Dashboard/Dashboard.scss";

function ResearchLibrary() {
  const [researchId, setResearchId] = useState("");
  const [showModal, setShowModal] = useState(false);

  const onShowComments = (id) => {
    setResearchId(id);
    setShowModal(true);
  };

  const tableSettings = {
    tableName: "research-library",
    tableType: 5,
    getdata: (filter) => getResearchLibrary(filter),
    tabsGroup: "",
    stickyColumn: {
      title: "Details",
      id: "details",
      data: "id",
      element: {
        icon: <FontAwesomeIcon icon={faList} />,
        action: (id) => onShowComments(id),
      },
    },
  };

  return (
    <div className="coach-panel">
      <CoachNav />
      <div className="dashboard">
        <Container className="dashboard__header">
          <h1>Research Library</h1>
        </Container>
        <CoachTable {...tableSettings} />
        <ResearchDetails
          showModal={showModal}
          setShowModal={setShowModal}
          researchId={researchId}
        />
      </div>
    </div>
  );
}

export default ResearchLibrary;
