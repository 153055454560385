const ONBOARDING_AUDIO_GUIDES = [
  "coach-apps",
  "coach-techniques",
  "coach-trainings",
  "coach-brainwaves",
  "coach-goals",
  "coach-signal-check",
  "coach-baseline",
  "coach-mediation-diagnostic",
  "coach-diagnostic-and-protocol-selection",
  "coach-protocol-explanation",
  "coach-protocol-expertise",
  "coach-protocol-calibration",
];

export default ONBOARDING_AUDIO_GUIDES;
