const LENGTH_SESSION_OPTIONS = [
  { key: "5 Mins", value: 5 },
  { key: "10 Mins", value: 10 },
  { key: "15 Mins", value: 15 },
  { key: "20 Mins", value: 20 },
  { key: "25 Mins", value: 25 },
  { key: "30 Mins", value: 30 },
];

export default LENGTH_SESSION_OPTIONS;
