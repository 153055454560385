import streakLogo from "assets/streak_logo.png";
import "./StreakCounter.scss";

export function StreakCounter({
  currentStreak,
  longestStreak,
}) {
  return (
    <div className="streakCounter">
      <div className="streakCounter__current-streak">
        <div className="streakCounter__current-streak__number">
          <h2>{currentStreak}</h2>
        </div>
        <div className="streakCounter__current-streak__title">
          <p>current</p>
        </div>
      </div>
      <div className="streakCounter__logo">
        <div className="streakCounter__logo-container">
          <div className="streakCounter__logo-container__inner">
            <div className="streakCounter__logo-container__wrapper">
              <img src={streakLogo} className="streakCounter__logo-container__inner-img" alt="Streak Logo" />
              <h3 className="streakCounter__logo-container__inner-title">Streak</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="streakCounter__longest-streak">
        <div className="streakCounter__longest-streak__number">
          <h2>{longestStreak}</h2>
        </div>
        <div className="streakCounter__longest-streak__title">
          <p>longest</p>
        </div>
      </div>
    </div>
  );
}

export default StreakCounter;
