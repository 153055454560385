const ALL_LEVELS = [
  {
    value: 1,
    key: "Relaxing into Calm",
    hours: "0 - 100 hrs",
    description: "10’s of hours",
    isActive: false,
  },
  {
    value: 2,
    key: "Efforting to Master Technique",
    hours: "10 - 1,000 hrs",
    description: "100’s of hours",
    isActive: false,
  },
  {
    value: 3,
    key: "Stabilizing Expansiveness",
    hours: "100 - 10,000 hrs",
    description: "1000’s of hours",
    isActive: false,
  },
  {
    value: 4,
    key: "Being Non-Dual",
    hours: "1,000 - 10,000+ hrs",
    description: "Many 1000’s of hours",
    isActive: false,
  },
];

const LEVEL_CARDS_COLORS = [
  "rgba(46, 148, 214, 0.8)",
  "rgba(51, 191, 159, 0.8)",
  "rgba(255, 92, 95, 0.8)",
  "rgba(179, 128, 244, 0.8)",
];

const LEVELS_TRAIN = [
  {
    value: 1,
    color: "rgba(46, 148, 214, 0.8)",
    description: "80%",
  },
  {
    value: 2,
    color: "rgba(51, 191, 159, 0.8)",
    description: "70%",
  },
  {
    value: 3,
    color: "rgba(255, 92, 95, 0.8)",
    description: "60%",
  },
  {
    value: 4,
    color: "rgba(179, 128, 244, 0.8)",
    description: "50%",
  },
];

export { LEVEL_CARDS_COLORS, ALL_LEVELS, LEVELS_TRAIN };
