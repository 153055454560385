import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";

import { InputMinMax, ToggleSwitch, ToggleVisible } from "components";
import { useMediaQuery } from "react-responsive";
import { FILTERS_MENU } from "constants/brainwave_analyzer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars, faTimes,
} from "@fortawesome/free-solid-svg-icons";

import "./InfoFilters.scss";

export function InfoFilters({
  activeState, filters, setFilters,
}) {
  const permissionLevel = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user.subscription_level_id,
  );

  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const wrapper = useRef();

  useEffect(() => {
    if (wrapper) {
      if (filters.show_menu) {
        const offset = wrapper.current.getBoundingClientRect().top + document.body.scrollTop - 82;
        document.body.scrollTo({ top: offset, behavior: "smooth" });
        setTimeout(() => {
          document.body.style.overflow = "hidden";
        }, 300);
      } else {
        setTimeout(() => {
          document.body.style = "";
        }, 300);
      }
    }
    return () => {
      document.body.style = "";
    };
  }, [filters.show_menu]);

  const toggleFilter = (key) => {
    setFilters({ ...filters, [key]: !filters[key] });
  };

  const onInputFocus = (e) => {
    const input = e.target;
    input.type = "text";
    input.setSelectionRange(input.value.length, input.value.length);
    input.type = "number";
  };

  const renderStateFilter = () => (activeState === "protocol" ? (
    <div className="filter-row">
      <span className="filter-title">Brain Frequency Introductions</span>
      <ToggleSwitch
        onClick={() => toggleFilter("intro")}
        isActive={filters.intro}
      />
    </div>
  ) : (
    <div className="filter-row">
      <span className="filter-title">Any Change Greater Than</span>
      <div className="filter-input">
        <InputMinMax
          min={0}
          max={3}
          step={0.1}
          onChangeValue={(value) => setFilters({
            ...filters, sd_value: value < 0 ? 0 : value,
          })}
          onBlurValue={(value) => setFilters({
            ...filters, sd_value: value || 0,
          })}
          onFocusValue={(e) => onInputFocus(e)}
          defaultValue={filters.sd_value}
        />
        <span>SD</span>
      </div>
    </div>
  ));

  return (
    <div className={`brainwaveReport__filters ${filters.show_menu ? "show" : ""}`}>
      {isMobile && (
        <div
          role="presentation"
          className="filter-backdrop"
          onClick={() => toggleFilter("show_menu")}
        />
      )}
      <div ref={wrapper} className="filters-content">
        <div className="filters-top">
          <ToggleVisible
            text="Citations"
            onToggle={() => toggleFilter("quotes")}
            isActive={filters.quotes}
          />
          {!isMobile && (
            <div className="filters-state">
              {renderStateFilter()}
            </div>
          )}
          <button
            type="button"
            className={`btn btn-settings ${filters.show_menu ? "is-active" : ""}`}
            onClick={() => toggleFilter("show_menu")}
          >
            Settings
            <FontAwesomeIcon icon={filters.show_menu ? faTimes : faBars} />
          </button>
        </div>
        <div className="filters-wrapper">
          {isMobile && (
          <div className="filters-section">
            <hr />
            {renderStateFilter()}
          </div>
          )}
          {FILTERS_MENU.map((section, i) => (
            <div key={i.toString()} className="filters-section">
              <hr />
              {section.map((row) => (
                <div key={row.key} className="filter-row">
                  <span className="filter-title">{row.text}</span>
                  <ToggleSwitch
                    onClick={() => toggleFilter(row.key)}
                    isActive={filters[row.key]}
                  />
                </div>
              ))}
            </div>
          ))}
          {permissionLevel >= 3 && (
          <div className="filters-section">
            <hr />
            <div className="filter-row">
              <span className="filter-title">Advanced Coach Material</span>
              <ToggleSwitch
                onClick={() => toggleFilter("coach")}
                isActive={filters.coach}
              />
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default InfoFilters;
