import { OverlayTrigger, Popover } from "react-bootstrap";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import _ from "lodash";
import { Card } from "components";
import { HelpModal } from "features";

import "./ProtocolDetails.scss";

function ProtocolDetails({ protocol }) {
  const safeHtml = (html) => parse(DOMPurify.sanitize(html));

  const meditationPopover = (
    <Popover body>
      To use the app guided meditations with GoDeeper
      <ol className="mt-2 mb-1">
        <li>
          Set up your meditation protocol in GoDeeper, do the signal check,
          but don’t press the Start button.
        </li>
        <li>Set-up the guided meditation app and press play.</li>
        <li>Go back to GoDeeper and tap on the Start button.</li>
      </ol>
    </Popover>
  );

  return (
    <div className="protocolDetailsPage__content">
      <Card collapseHeader={(<h3>The Goal</h3>)} customClass="card-text mb-3">
        <div className="html-content">
          {safeHtml(protocol?.goal)}
        </div>
      </Card>
      <Card collapseHeader={(<h3>The Basics</h3>)} customClass="card-text mb-3">
        <div className="html-content">
          {safeHtml(protocol?.basics)}
        </div>
      </Card>
      <Card collapseHeader={(<h3>The Technique</h3>)} customClass="card-text mb-3">
        <div className="html-content">
          {safeHtml(protocol?.technique)}
        </div>
      </Card>
      {protocol?.guidedMeditations && (
      <Card
        collapseHeader={(<h3>Guided Meditations</h3>)}
        customClass="card-text mb-3"
      >
        <div className="html-content protocolDetails__guided-meditations">
          <p>
            You can run these during your GoDeeper session using your laptop or from a
            {" "}
            <OverlayTrigger trigger="click" placement="bottom" overlay={meditationPopover} rootClose>
              <span className="text-decoration-underline text-primary">Phone App</span>
            </OverlayTrigger>
            .
          </p>
          {safeHtml(protocol?.guidedMeditations)}
        </div>
      </Card>
      )}

      <Card collapseHeader={(<h3>For more information</h3>)} customClass="card-text mb-3">
        {protocol?.info && (
        <div className="html-content">
          {safeHtml(protocol?.info)}
        </div>
        )}
        {protocol?.links.length > 0 && (
        <>
          <p className="font-600 text-sm text-dark">
            Check out these articles and videos on the benefits and
            techniques of
            {" "}
            {_.unescape(protocol.protocolName)}
            {" "}
            Meditations:
          </p>
          <ul className="protocolDetailsPage__info">
            {protocol.links.map(({ url, text, type }, i) => (
              <li key={i.toString()} className={`protocolDetailsPage__link is-${type}`}>
                <a href={url} target="_blank" rel="noreferrer">
                  { _.unescape(text) }
                </a>
              </li>
            ))}
          </ul>
        </>
        )}
      </Card>

      <Card customClass="card-text mb-3">
        <div className="html-content">
          <p>
            Please take a few minutes to familiarize yourself with the technique
            for the meditation style you chose. GoDeeper is designed to work in
            tandem with your current source of meditation instruction.
          </p>
          <div>
            If you don’t have a favorite meditation teacher, we highly recommend
            a quest to find one. Check out our thoughts on what to look for
            in
            {" "}
            <HelpModal toggleClass="text-decoration-underline text-primary d-inline" location="meditation-teachers">
              great meditation teachers
            </HelpModal>
            .
          </div>
        </div>
      </Card>
    </div>
  );
}

export default ProtocolDetails;
