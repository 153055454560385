import {
  useState, useEffect, useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import useSkipSignalCheck from "hooks/useSkipSignalCheck";
import {
  getDataStream, stopStreamData, getSignalCheck, setSignalCheckCompleted, setActiveStream,
} from "store/actions/session";
import { audioController, museController } from "utils/native-controllers";
import AUDIO_FILES from "constants/profile_audio";
import useMuse from "hooks/useMuse";

export default function useSignalCheck(onComplete, sessionPaused) {
  const dispatch = useDispatch();

  const loggedInUserId = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.pk,
  );
  const { btDevice, signalCheck } = useSelector(
    ({ sessionReducer }) => sessionReducer,
  );

  const skipSignalCheck = useSkipSignalCheck();
  useMuse();

  const [sequences, setSequences] = useState([
    btDevice.status === 5 ? [4, 4, 4, 4] : [0, 0, 0, 0],
  ]);
  const streak = useRef({
    success: 0,
    required: skipSignalCheck ? 1 : 10,
    info: btDevice.status === 5 ? 4 : 0,
  });
  const completed = useRef({ timer: null, status: false });
  const connected = useRef(btDevice.status === 5);

  const startStream = () => {
    dispatch(getSignalCheck(loggedInUserId));
  };

  useEffect(() => () => {
    clearTimeout(completed.current.timer);
    setSequences([]);
    if (!sessionPaused) {
      dispatch(getDataStream(false));
      if (!completed.current.status) {
        dispatch(stopStreamData(loggedInUserId));
      }
    }
  }, []);

  useEffect(() => {
    const checkSuccessStreak = (sensorsData) => {
      if (sensorsData.every((sensor) => sensor === 3)) {
        streak.current.success += 1;
      } else {
        streak.current.success = 0;
        if (sequences.length === 1) {
          dispatch(setSignalCheckCompleted(null));
          streak.current.required = 10;
        }
      }
    };
    if (!completed.current.status && signalCheck.length) {
      const signalData = [...signalCheck].filter((sensor) => sensor !== null);
      checkSuccessStreak(signalData);
      streak.current.info = Math.min(...signalData);
      if (streak.current.info === 0) museController("restart");
      setSequences([...sequences, signalCheck]);
    }
  }, [signalCheck]);

  useEffect(() => {
    if (streak.current.success >= streak.current.required) {
      completed.current.status = true;
      dispatch(setSignalCheckCompleted(Date.now()));
      if (!sessionPaused) {
        dispatch(getDataStream(false));
        dispatch(stopStreamData(loggedInUserId));
      }
      audioController("play", AUDIO_FILES.done_brring);
      completed.current.timer = setTimeout(() => {
        completed.current.timer = null;
        onComplete?.();
      }, 3000);
    }
  }, [sequences]);

  useEffect(() => {
    if (!completed.current.status) {
      if (btDevice.status === 5) {
        if (sessionPaused) {
          dispatch(setActiveStream("signal-quality"));
        } else {
          dispatch(getDataStream(true, loggedInUserId, "signal-quality", () => startStream()));
        }
        setSequences([[4, 4, 4, 4]]);
        streak.current.info = 4;
        streak.current.success = 0;
        connected.current = true;
      } else if (connected.current) {
        if (btDevice.status === 0) toast.error("Bluetooth not available.");
        if (btDevice.status === 1) toast.error("Muse device not connected.");
        connected.current = false;
      }
    }
  }, [btDevice]);

  return {
    sequences,
    streakRequired: streak.current.required,
    streakInfo: streak.current.info,
    isCompleted: completed.current.status,
  };
}
