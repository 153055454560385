import { useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GenericButton, Card, Dropdown, SubHeader, BottomBarSticky,
} from "components";
import { saveUserProfile } from "store/actions/profile";

import "./Subscriptions.scss";

function SubscriptionsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedSubscription = useSelector(
    ({ profileReducer }) => profileReducer.userProfile?.subscription_level,
  );
  const subscriptionOptions = useSelector(
    ({ onBoardingReducer }) => onBoardingReducer.onBoardingChoices?.subscription_level,
  );

  const [activeSubscription, setActiveSubscription] = useState(selectedSubscription);

  const onSubsChange = (activeSubs) => {
    setActiveSubscription(activeSubs);
  };

  const save = () => {
    const userAuth = JSON.parse(localStorage.getItem("userAuth"));
    const level = Number(activeSubscription);
    const name = subscriptionOptions.find(({ value }) => value === level).key;
    localStorage.setItem("userAuth", JSON.stringify({
      ...userAuth,
      user: {
        ...userAuth.user,
        subscription_level_id: level,
        subscription_level_name: name,
      },
    }));
    dispatch(saveUserProfile({
      subscription_level: activeSubscription,
    }, { navigate, nextUrl: "/app/settings" }));
  };

  return (
    <Container>
      <div className="settingsPage">
        <SubHeader
          title="Subscriptions"
          text="Change your account subscription."
        />
        <div className="subscriptionPage__form">
          <Dropdown
            activeItem={activeSubscription}
            selectedItem={onSubsChange}
            items={subscriptionOptions}
            icon
          />
        </div>
        <div className="subscriptionPage__card-section">
          <Card customClass="card-text">
            <p className="text-sm">
              The GoDeeper trial version is free for the first 30 days and
              you get a SuperUser license! After 30 days, you will be charged $10/month.
              The SuperUser subscription has extra features:
            </p>
            <ul>
              <li>
                A brainwave reporter that shows you graphs of your brainwaves and
                interprets them.
              </li>
              <li>
                The ability to create custom protocols to train on specific rules,
                further enhancing your ability to GoDeeper.
              </li>
            </ul>
            <p className="text-sm">
              You can come back to this screen at any time to change your
              subscription level. The Basic User subscription is $5/month.
            </p>
            <p>
              For information on Coach, Master Coach and Scientist licences contact us.
            </p>
          </Card>
        </div>
        <BottomBarSticky>
          <GenericButton className="button--success" text="Save" onButtonClick={save} />
        </BottomBarSticky>
      </div>
    </Container>
  );
}

export default SubscriptionsPage;
