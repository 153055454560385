import * as onBoardingActions from "store/actions/onBoarding";

const initialState = {
  gettingOnBoardingChoices: false,
  onBoardingChoices: null,
  allProtocols: null,
  allProtocolsError: null,
  onBoardingChoicesError: null,
  gettingAllProtocols: false,
  activeTab: null,
};

const onBoardingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case onBoardingActions.GET_ON_BOARDING_CHOICES:
      return {
        ...state,
        gettingOnBoardingChoices: true,
      };
    case onBoardingActions.GET_ON_BOARDING_CHOICES_SUCCESS:
      return {
        ...state,
        gettingOnBoardingChoices: false,
        onBoardingChoices: action.payload,
      };
    case onBoardingActions.GET_ON_BOARDING_CHOICES_ERROR:
      return {
        ...state,
        gettingOnBoardingChoices: false,
        onBoardingChoicesError: action.payload,
      };
    case onBoardingActions.SET_ON_BOARDING_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };

    default:
      return state;
  }
};

export default onBoardingReducer;
