import { useDispatch, useSelector } from "react-redux";
import { Range, getTrackBackground } from "react-range";

import { CustomTabs } from "components";
import { changeGraphStatus } from "store/actions/reporting";
import { GRAPH_PERIODS_TABS } from "constants/profile_progress_charts";

import "./ChartStatus.scss";
import { useEffect } from "react";

function ChartStatus({ dataMin = 8, dataLength = 20 }) {
  const dispatch = useDispatch();

  const { graphStatus } = useSelector(({ reportingReducer }) => reportingReducer);

  useEffect(() => {
    dispatch(changeGraphStatus({ ...graphStatus, range: dataMin }));
  }, []);

  const changePeriod = (period) => {
    dispatch(changeGraphStatus({ ...graphStatus, period, metrics: undefined }));
  };
  const changeRange = (value) => {
    const range = value.values[0];
    dispatch(changeGraphStatus({ ...graphStatus, range }));
  };

  return (
    <div className="chart-status-wrapper">
      <div className="chart-status-wrapper__period">
        <CustomTabs
          tabs={GRAPH_PERIODS_TABS}
          defaultTabKey={GRAPH_PERIODS_TABS[0]}
          onTabChange={(tab) => changePeriod(tab)}
          activeTab={graphStatus.period}
        />
      </div>
      <div className="chart-status-wrapper__range">
        {graphStatus.range && (
        <Range
          onChange={(values) => changeRange({ values })}
          values={[graphStatus.range]}
          min={dataMin}
          max={dataLength}
          step={1}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              className="track"
            >
              <div
                ref={props.ref}
                style={{
                  background: getTrackBackground({
                    values: [graphStatus.range],
                    colors: ["#3F94CB", "transparent"],
                    min: dataMin,
                    max: dataLength,
                  }),
                }}
              />
              {children}
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              key={props.key}
              style={{ ...props.style }}
              className={`thumb ${isDragged ? "is-dragged" : ""}`}
            />
          )}
        />
        )}
      </div>
    </div>

  );
}

export default ChartStatus;
