export const CHANGE_PAST_SESSION_REPORTS_ORDER = "CHANGE_PAST_SESSION_REPORTS_ORDER";
export const CHANGE_PAST_SESSION_REPORTS_FILTERS = "CHANGE_PAST_SESSION_REPORTS_FILTERS";

export const GET_INDIVIDUAL_SESSION_REPORT_SUCCESS = "GET_INDIVIDUAL_SESSION_REPORT_SUCCESS";

export const GO_TO_SPECIFIC_PAGINATION_PAGE = "GO_TO_SPECIFIC_PAGINATION_PAGE";

export const CHANGE_GRAPH_STATUS = "CHANGE_GRAPH_STATUS";
export const CHANGE_GRAPH_MODAL_STATUS = "CHANGE_GRAPH_MODAL_STATUS";
export const CHANGE_GRAPH_PROTOCOL = "CHANGE_GRAPH_PROTOCOL";
export const CHANGE_PROGRESS_VIEW = "CHANGE_PROGRESS_VIEW";
