import { memo, useMemo } from "react";
import { BarChart } from "./BarChart";
import { LineChart } from "./LineChart";
import { RuleBarChart } from "./RuleBarChart";

const ChartsWrapper = memo(({
  chartsData, filterData, tapsData, hiddenFrq, activeState, activeChartType,
  scrollSync, setScrollSync, zoom, hiddenRules,
}) => {
  const brainGraphs = useMemo(() => [
    { name: "Whole Brain", key: "wholeBrain", values: ["value"] },
    { name: "Front-Back", key: "frontBack", values: ["frontValue", "backValue"] },
    { name: "Left-Right", key: "leftRight", values: ["leftValue", "rightValue"] },
  ], []);

  return (
    <>
      {activeState === "protocol" && activeChartType === "timeline" && (
      <div className="brainwaveGraphs__single-chart-container">
        <LineChart
          chartData={chartsData?.protocol.lineData}
          tapsData={tapsData}
          filterData={filterData}
          hiddenRules={hiddenRules}
          zoom={zoom}
          scrollSync={scrollSync}
          setScrollSync={setScrollSync(2)}
          overlay
        />
      </div>
      )}
      {activeState === "protocol" && activeChartType === "mean" && (
      <div className="brainwaveGraphs__single-chart-container">
        <RuleBarChart
          chartData={chartsData?.protocol.barData.slice().reverse()}
          keys={["value"]}
          hiddenRules={hiddenRules}
        />
      </div>
      )}
      {activeState === "brain" && activeChartType === "timeline" && (
      <div className="brainwaveGraphs__line-charts-wrapper">
        {brainGraphs.map((graph, index) => (
          <div key={graph.key}>
            <div className="brainwaveGraphs__chart-header">
              {graph.name}
            </div>
            <div className="brainwaveGraphs__single-chart-container">
              <LineChart
                chartData={chartsData?.brain.lineData[graph.key]}
                tapsData={tapsData}
                filterData={filterData}
                zoom={zoom}
                hiddenFrq={hiddenFrq}
                scrollSync={scrollSync}
                setScrollSync={setScrollSync(index + 3)}
                dashedLines
              />
            </div>
          </div>
        ))}
      </div>
      )}
      {activeState === "brain" && activeChartType === "mean" && (
      <div className="brainwaveGraphs__bar-charts-wrapper">
        {brainGraphs.map((graph) => (
          <div key={graph.key}>
            <div className="brainwaveGraphs__chart-header">
              {graph.name}
            </div>
            <div className="brainwaveGraphs__single-chart-container">
              <BarChart
                chartData={chartsData?.brain.barData[graph.key]}
                hiddenFrq={hiddenFrq}
                keys={graph.values}
              />
            </div>
          </div>
        ))}
      </div>
      )}
    </>
  );
});

export default ChartsWrapper;
