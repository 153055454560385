import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { getHelpFromLocation } from "api/services/settings";
import { Spinner } from "components/ui/Spinner/Spinner";

import "./HelpModal.scss";

export function HelpModal({
  location, children, toggleClass = "", helpIcon, infoIcon, modalIcon, compact,
}) {
  const [show, setShow] = useState(false);

  const { isPending, data, refetch } = useQuery({
    queryKey: ["help-content", location],
    queryFn: () => getHelpFromLocation(location),
    enabled: show,
    staleTime: Infinity,
  });

  const safeHtml = (html) => parse(DOMPurify.sanitize(html));

  const showHelp = () => {
    if (!data) refetch();
    setShow(!show);
  };

  return (
    <>
      <div
        role="presentation"
        className={`help-toggle ${toggleClass}`}
        onClick={showHelp}
      >
        {helpIcon && (<FontAwesomeIcon icon={faQuestionCircle} />)}
        {infoIcon && (<FontAwesomeIcon icon={faCircleInfo} />)}
        {children}
      </div>
      <Modal
        animation
        dialogClassName={`dialog${compact && "-compact"}`}
        show={show}
        onHide={() => setShow(false)}
        className={`help-modal ${compact && "modal-compact"}`}
        backdropClassName={compact ? "backdrop-compact" : ""}
      >
        {isPending ? (
          <>
            <Spinner />
            <p className="text-center">Loading...</p>
          </>
        ) : data && (
          <>
            <Modal.Header closeButton>
              {modalIcon || <FontAwesomeIcon icon={faQuestionCircle} />}
              <h3 className="text-primary m-0">
                {_.unescape(data?.name)}
              </h3>
            </Modal.Header>
            <Modal.Body>
              <div className="html-content">
                {safeHtml(data?.content)}
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>
    </>
  );
}

export default HelpModal;
