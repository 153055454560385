import _ from "lodash";

const dropOutside = (array, condition) => {
  const newArray = _.dropRightWhile(array, condition);
  return _.dropWhile(newArray, condition);
};

const dropInside = (array, condition) => {
  const start = array.findIndex(condition);
  const end = array.findLastIndex(condition);
  return array.filter((el, index) => condition(el) || (index < start || index > end));
};

export { dropOutside, dropInside };
