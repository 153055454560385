import * as profileActions from "store/actions/profile";
import * as authActions from "store/actions/authentication";

const initialState = {
  userProfile: null,
  gettingUserProfile: false,
  gettingUserProfileError: null,
  savingUserProfile: false,
  savingUserProfileError: null,
};

const profileReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case authActions.LOGOUT_USER_SUCCESS:
      return initialState;
    case profileActions.GET_USER_PROFILE:
      return {
        ...state,
        gettingUserProfile: true,
      };
    case profileActions.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        gettingUserProfile: false,
        userProfile: action.payload,
      };
    case profileActions.GET_USER_PROFILE_ERROR:
      return {
        ...state,
        gettingUserProfile: false,
        gettingUserProfileError: action.payload,
      };
    case profileActions.SAVE_USER_PROFILE:
      return {
        ...state,
        savingUserProfile: true,
      };
    case profileActions.SAVE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        savingUserProfile: false,
        userProfile: action.payload,
      };
    case profileActions.SAVE_USER_PROFILE_ERROR:
      return {
        ...state,
        savingUserProfile: false,
        savingUserProfileError: action.payload,
      };
    case profileActions.UPDATE_USER_PROFILE:
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default profileReducer;
