import { toast } from "react-toastify";
import { generateClientAuthTokenUrl, refreshTokenUrl } from "./urls";

export const refreshClientAuthToken = async (api) => {
  const userAuth = JSON.parse(localStorage.getItem("userAuth"));
  try {
    const { data } = await api.get(generateClientAuthTokenUrl(userAuth.user.pk));
    localStorage.setItem("clientAuth", JSON.stringify(data));
  } catch (err) {
    toast.error(`Authentication error / ${err.message}`);
    localStorage.removeItem("clientAuth");
  }
};

export const refreshUserAuthToken = async (api) => {
  const userAuth = JSON.parse(localStorage.getItem("userAuth"));
  const payload = { refresh: userAuth?.refresh_token };
  try {
    const { data } = await api.post(refreshTokenUrl(), payload);
    localStorage.setItem("userAuth", JSON.stringify({
      ...userAuth,
      access_token: data.access,
      refresh_token: data.refresh,
    }));
  } catch (err) {
    localStorage.removeItem("userAuth");
  }
};
