import { ResponsiveBar } from "@nivo/bar";

import "./DepthBarChart.scss";

export function DepthBarChart({
  chartData,
}) {
  return chartData && (
    <div className="depth-bar-chart-wrapper">
      <ResponsiveBar
        theme={{
          background: "transparent",
          text: {
            fontSize: 11,
            fontFamily: "Quicksand",
            fill: "#171d66",
          },
          tooltip: {
            container: {
              background: "#ffffff",
              color: "#171d66",
              fontSize: 11,
            },
          },
        }}
        layout="horizontal"
        enableGridX
        gridXValues={10}
        enableGridY={false}
        data={chartData}
        keys={["percent"]}
        indexBy="value"
        margin={{
          top: 15, right: 20, bottom: 55, left: 40,
        }}
        padding={0.5}
        innerPadding={5}
        borderRadius={4}
        borderWidth={5}
        borderColor={{
          from: "color",
          modifiers: [
            ["opacity", "0"],
          ],
        }}
        groupMode="grouped"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        minValue={0}
        maxValue={100}
        colorBy="indexValue"
        colors={["#3F94CB"]}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: 10,
          legend: "Time %",
          legendPosition: "middle",
          legendOffset: 40,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        label={null}
        isInteractive={false}
        role="application"
      />
    </div>

  );
}

export default DepthBarChart;
