import { toast } from "react-toastify";
import { userApi, clientApi } from "api/axios";
import * as urls from "api/urls";
import errorHandler from "utils/error-handler";

export const resetPassword = async (payload) => {
  try {
    const { data } = await userApi.post(urls.resetPasswordUrl(), payload);
    return data;
  } catch (err) {
    return err;
  }
};

export const getHelpDetails = async (id) => {
  try {
    const { data } = await userApi.get(urls.getHelpDetailsUrl(id));
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getHelpFromLocation = async (id) => {
  try {
    const { data } = await userApi.get(urls.getHelpFromLocationUrl(id));
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getHelpList = async () => {
  try {
    const { data } = await userApi.get(urls.getHelpListUrl());
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getHelpListFilters = async () => {
  try {
    const { data } = await userApi.get(urls.getHelpListFiltersUrl());
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getCoachesPersmissions = async () => {
  try {
    const { data } = await userApi.get(urls.coachesPersmissionsUrl());
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const updateCoachesPersmissions = async (payload) => {
  try {
    const { data } = await userApi.put(urls.bulkUpdateCoachesPersmissionsUrl(), payload);
    toast.success("Coach Permission Successfully Saved.");
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const resetOnboarding = async () => {
  try {
    const { data } = await userApi.post(urls.resetOnboardingUrl());
    toast.success("Onboarding data has been reset successfully.");
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const clearUserAccount = async () => {
  try {
    const { data } = await userApi.post(urls.clearUserAccountUrl());
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const clearClientAccount = async (userId) => {
  try {
    const { data } = await clientApi.delete(urls.clearClientAccountUrl(userId));
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const deleteUserAccount = async () => {
  try {
    const { data } = await userApi.delete(urls.deleteUserAccountUrl());
    toast.success("Account has been deleted successfully.");
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};
