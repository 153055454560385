import {
  useEffect, useMemo, useState,
} from "react";

import { ToggleVisible, ZoomSlider } from "components";
import { GRAPH_ZOOM } from "constants/session_graph";

import { ReactComponent as TapIcon } from "assets/icons_tap.svg";

import getMarkers from "./helpers/charts";
import { LayersChart } from "./LayersChart/LayersChart";

import "./SessionChart.scss";

export function SessionChart({
  sessionData, filterData, depthAvg, initialScale, zoomState, timeline, onTap,
  isColored, showCurrentScore, showMarks, showTapInfo, analyze, scrollSync, setScrollSync,
  isCertified = true,
}) {
  const [globalZoom, setGlobalZoom] = zoomState || [];
  const [zoom, setZoom] = useState(initialScale || globalZoom || 0);
  const [marksVisible, setMarksVisible] = useState(isColored);
  const [tapsInfoVisible, setTapsInfoVisible] = useState(true);

  useEffect(() => {
    if (globalZoom >= 0 && globalZoom === scrollSync.current.zoom) setZoom(globalZoom);
  }, [globalZoom]);

  const handleSetZoom = (zoomLevel) => {
    setScrollSync?.setZoom?.(zoomLevel);
    setZoom(zoomLevel);
  };

  if (sessionData) {
    const markersData = useMemo(
      () => getMarkers(sessionData.zoneData, zoom),
      [sessionData.zoneData, zoom],
    );
    const zone = useMemo(() => {
      const [rewardThreshold, reminderThreshold] = sessionData.thresholds;
      const reward = (1000 + rewardThreshold) / 10;
      const reminder = -reminderThreshold / 10;
      return {
        reward,
        reminder,
        neutral: 100 - reward - reminder,
      };
    }, [sessionData.thresholds]);

    return (
      <div
        role="presentation"
        className="sessionChart"
      >
        <div role="presentation" className="sessionChart__wrapper" onTouchStart={() => onTap?.()}>
          <LayersChart
            data={sessionData.fullData}
            filterData={filterData}
            thresholds={sessionData.thresholds}
            zone={zone}
            rewardMarkers={markersData.reward}
            reminderMarkers={markersData.reminder}
            scale={GRAPH_ZOOM[zoom].scale}
            graphBottom={-1000}
            graphTop={0}
            taps={sessionData.taps}
            avgScore={depthAvg?.toFixed(0)}
            timeline={timeline}
            isColored={isColored}
            marksVisible={marksVisible}
            showCurrentScore={showCurrentScore}
            analyze={analyze}
            scrollSync={scrollSync}
            setScrollSync={setScrollSync}
            isCertified={isCertified}
          />
          {showTapInfo && tapsInfoVisible && isCertified && (
            <div className="sessionChart__taps-info">
              <TapIcon />
              <div>
                <p>
                  <strong>TAP ONCE </strong>
                  after a deep moment
                </p>
                <p>
                  <strong>TAP TWICE </strong>
                  after a distracting moment
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="sessionChart__bottom">
          {(isCertified && !zoomState) && (
          <ZoomSlider
            zoom={zoom}
            setZoom={handleSetZoom}
            onChangeEnd={(value) => setGlobalZoom?.(value)}
            max={GRAPH_ZOOM.length - 1}
          />
          )}
          {isCertified && showMarks && (
          <ToggleVisible
            text="Marks"
            onToggle={() => setMarksVisible((state) => !state)}
            isActive={marksVisible}
          />
          )}
          {isCertified && showTapInfo && (
          <ToggleVisible
            text="Tap Info"
            onToggle={() => setTapsInfoVisible((state) => !state)}
            isActive={tapsInfoVisible}
          />
          )}
        </div>
      </div>
    );
  }
  return null;
}

export default SessionChart;
