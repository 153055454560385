import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { GenericButton } from "components";
import { CHART_TYPES, CHART_COLORS } from "constants/profile_progress_charts";
import { ReactComponent as Diamond } from "assets/icons_diamond.svg";
import { ReactComponent as Trophy } from "assets/icons_trophy.svg";
import { ReactComponent as Warning } from "assets/icons_warning.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpToLine, faArrowsDownToLine, faGripLines, faArrowTurnUp, faArrowTurnDown,
} from "@fortawesome/free-solid-svg-icons";

import { changeGraphStatus } from "store/actions/reporting";

import "./ChartType.scss";

function ChartType({ chartTypeData = [], chartType, isSingleSelect }) {
  const dispatch = useDispatch();
  const { graphStatus } = useSelector(({ reportingReducer }) => reportingReducer);

  const checkActiveClass = (type) => (
    graphStatus.types[chartType].includes(type) ? "active" : ""
  );
  const activeStyle = (type) => (
    graphStatus.types[chartType].includes(type) ? { backgroundColor: CHART_COLORS[type] || "#167cbe" } : null
  );

  const onTypeChange = (type) => {
    const filteredTypes = graphStatus.types[chartType].includes(type)
      ? _.without(graphStatus.types[chartType], type)
      : [...graphStatus.types[chartType], type];
    if (filteredTypes.length === 0) return;
    dispatch(changeGraphStatus({
      ...graphStatus,
      types: { ...graphStatus.types, [chartType]: isSingleSelect ? [type] : filteredTypes },
    }));
  };

  const renderButton = ({
    type, text, iconElement,
  }) => (
    <GenericButton
      key={String(type)}
      onButtonClick={() => onTypeChange(type)}
      className={`chart-type__buttons-button type-${type} ${checkActiveClass(type)}`}
      style={activeStyle(type)}
      text={text}
      iconElement={iconElement}
    />
  );

  const renderButtons = () => (
    chartTypeData.map((type) => {
      switch (type) {
        case CHART_TYPES.REWARDS:
          return renderButton({ type, iconElement: <Trophy /> });
        case CHART_TYPES.REMINDERS:
          return renderButton({ type, iconElement: <Warning /> });
        case CHART_TYPES.POINTS:
          return renderButton({ type, iconElement: <Diamond /> });
        case CHART_TYPES.MAX:
          return renderButton({ type, text: "Max" });
        case CHART_TYPES.MIN:
          return renderButton({ type, text: "Min" });
        case CHART_TYPES.AVG:
          return renderButton({ type, text: "Avg" });
        case CHART_TYPES.RED_THRESHOLD:
          return renderButton({ type, iconElement: <FontAwesomeIcon icon={faArrowsDownToLine} /> });
        case CHART_TYPES.BLUE_THRESHOLD:
          return renderButton({ type, iconElement: <FontAwesomeIcon icon={faArrowsUpToLine} /> });
        case CHART_TYPES.GREEN_THRESHOLD:
          return renderButton({ type, text: "N" });
        case CHART_TYPES.RED_ZONE:
          return renderButton({ type, iconElement: <FontAwesomeIcon icon={faArrowTurnDown} /> });
        case CHART_TYPES.BLUE_ZONE:
          return renderButton({ type, iconElement: <FontAwesomeIcon icon={faArrowTurnUp} /> });
        case CHART_TYPES.GREEN_ZONE:
          return renderButton({
            type,
            iconElement: <FontAwesomeIcon icon={faGripLines} />,
          });
        case CHART_TYPES.TAPS_VALUE:
          return renderButton({ type, text: "#" });
        case CHART_TYPES.TAPS_PERCENT:
          return renderButton({ type, text: "%" });
        default:
          return null;
      }
    })
  );

  return chartTypeData && (
    <div className="chart-type">
      <div className="chart-type__buttons">
        {renderButtons()}
      </div>
    </div>
  );
}

export default ChartType;
