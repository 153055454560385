import { ResponsiveBar } from "@nivo/bar";

import "./DiagnosticChart.scss";

export function DiagnosticChart({ protocols }) {
  const getTooltip = (data) => {
    const formatLabel = (label) => label.replace("threshold -", " ");
    return (
      <div className="custom-tooltip">
        <div className="custom-tooltip__color" style={{ background: data.color }} />
        <span>{formatLabel(data.label)}</span>
        <strong>{data.formattedValue}</strong>
      </div>
    );
  };
  const getMaxScore = (data) => Math.max(...data.map(
    (protocol) => protocol.sessionDepth || protocol.depth,
  ));
  return protocols ? (
    <div className="diagnosticChart">
      <h3>Average Depth by Protocol</h3>
      <div className="diagnosticChart__wrapper">
        <ResponsiveBar
          theme={{
            background: "transparent",
            text: {
              fontSize: 11,
              fontFamily: "Quicksand",
              fill: "#171d66",
            },
            tooltip: {
              container: {
                background: "#ffffff",
                color: "#171d66",
                fontSize: 11,
              },
            },
          }}
          data={protocols}
          keys={protocols?.[0].sessionDepth ? ["sessionDepth"] : ["depth"]}
          indexBy="protocolName"
          margin={{
            top: 0, right: 10, bottom: 30, left: 110,
          }}
          maxValue={getMaxScore(protocols)}
          minValue={0}
          padding={0.3}
          innerPadding={5}
          borderRadius={4}
          borderWidth={5}
          borderColor={{
            from: "color",
            modifiers: [
              ["opacity", "0"],
            ],
          }}
          groupMode="grouped"
          layout="horizontal"
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colorBy="indexValue"
          colors={protocols.map((data) => data.color)}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            tickValues: 5,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            renderTick: ({ value, x, y }) => (
              <foreignObject x={x} y={y - 15} width={100} height={30}>
                <span>{value}</span>
              </foreignObject>
            ),
          }}
          tooltip={(data) => getTooltip(data)}
          labelTextColor="white"
          labelSkipHeight={14}
          enableLabel
          role="application"
        />
      </div>
    </div>
  ) : null;
}

export default DiagnosticChart;
