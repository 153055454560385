import "./ToggleButton.scss";

export function ToggleButton({ isActive, onClick, children }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`button__toggle ${isActive ? "button__toggle--active" : ""}`}
    >
      {children}
    </button>
  );
}

export default ToggleButton;
