import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IconClose } from "assets/icons_close.svg";
import GUIDES from "constants/guides";

import "./GuideWrapper.scss";

export function GuideWrapper({
  guide,
  step,
  content,
  showContent,
  popoverPositionY,
  pointerPositionX,
  pointerPlacement = "top",
  onClose,
  children,
  scrollTop,
}) {
  const anchor = useRef();

  const isActiveStep = guide.active && guide.activeStep === step;
  const isVisible = isActiveStep || showContent;

  useEffect(() => {
    if (isActiveStep) {
      if (scrollTop) {
        document.body.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        anchor.current?.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [guide]);

  return (
    <div className={`guide-wrapper ${isVisible ? "is-active" : ""}`}>
      {isActiveStep && GUIDES[content][step - 1] && (
        <div className="guide-anchor">
          <div
            ref={anchor}
            className="popover__container"
            style={
              pointerPlacement === "top" ? { top: popoverPositionY } : { bottom: popoverPositionY }
            }
          >
            <div className="popover__container-card" onClick={onClose} role="presentation">
              <div
                className={`popover__container-card-pointer ${pointerPlacement === "bottom" ? "bottom" : ""}`}
                style={{ left: pointerPositionX }}
              >
                <svg width="50" height="25" viewBox="-50 0 300 300">
                  <polygon className="arrow" strokeLinejoin="round" points="100,50 -150, 275 350,275" />
                </svg>
              </div>
              <div className="popover__container-card-close-button">
                <IconClose />
              </div>
              <div className="popover__container-card-info">
                <div className="popover__container-card-info-icon">
                  <FontAwesomeIcon icon={faInfoCircle} />
                </div>
                <div className="popover__container-card-info-text">
                  {GUIDES[content][step - 1]()}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isActiveStep && (
        <div className={`guide-overlay ${step === 1 ? "fadein" : ""}`} onClick={onClose} role="presentation" />
      )}
      <div className="guide-content">
        {children}
      </div>
    </div>
  );
}

export default GuideWrapper;
