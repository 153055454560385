import {
  useEffect, useMemo, useState, useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  SessionScores, GenericButton, LoadingOverlay, Card, ProtocolSelectInfo,
  SessionProgressScores, SessionTaps, HeaderSensors, InfoBox,
} from "components";
import { SessionChart } from "features";
import {
  clearLiveSessionData, getDataStream, cancelStreamData, stopStreamData, setStreamError,
  setSignalCheckCompleted, setActiveStream,
} from "store/actions/session";
import { createSession } from "api/services/reporting";
import { formatDuration } from "utils/time-format";
import useProtocol from "hooks/useProtocol";
import useProtocolCertification from "hooks/useProtocolCertification";

import { ReactComponent as IconPlay } from "assets/icons_play.svg";
import { ReactComponent as IconSuccess } from "assets/icons_check.svg";
import { ReactComponent as IconAudio } from "assets/icons_sound.svg";

import "./LiveSessionReport.scss";
import { saveUserProfile } from "store/actions/profile";
import { updateProtocolCertificationQuery } from "api/services/protocols";
import useMuse from "hooks/useMuse";

function LiveSessionReport() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const loggedInUserId = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.pk,
  );
  const userProfile = useSelector(
    ({ profileReducer }) => profileReducer.userProfile,
  );
  const {
    btDevice, sessionLength, remainingSessionTime, pausedSessionData,
    streamError, signalCheck, signalCheckSeq, gettingLiveSessionData,
  } = useSelector(({ sessionReducer }) => sessionReducer);

  const protocolDetails = useProtocol(
    userProfile.selected_protocol.categoryID,
    userProfile.selected_protocol.protocolID,
  );

  const protocolCertification = useProtocolCertification(
    userProfile.selected_protocol.levelID,
    userProfile.selected_protocol.family,
    userProfile.selected_protocol.rule,
  );

  const isCertified = protocolCertification.global?.certified;

  const sessionLevel = useMemo(() => {
    const { levelID } = userProfile.selected_protocol;
    return levelID?.split("/")[1];
  }, [userProfile.selected_protocol]);

  const sessionRule = useMemo(() => {
    const { rule: ruleNo } = userProfile.selected_protocol;
    if (ruleNo && protocolDetails) {
      const ruleDetails = protocolDetails.protocolRules.find(
        (rule) => rule?.rule === ruleNo,
      );
      return ruleDetails?.descriptions?.name || ruleDetails?.title || null;
    }
    return null;
  }, [userProfile.selected_protocol, protocolDetails]);

  const initSessionLength = useMemo(
    () => (sessionLength.hours * 60 * 60) + (sessionLength.minutes * 60),
    [sessionLength],
  );

  const [isResuming, setIsResuming] = useState(false);
  const [isSessionCompleted, setIsSessionCompleted] = useState(false);
  const [isSessionCanceled, setIsSessionCanceled] = useState(false);
  const resuming = useRef({ timer: null, seq: null });
  const ending = useRef(false);

  useMuse(ending);

  const saveSession = async () => {
    const { sessionData, protocol } = pausedSessionData;
    const summary = await createSession({ report: sessionData, protocol });
    if (summary.sessionData) {
      dispatch(stopStreamData(loggedInUserId));
      if (userProfile.live_session_guide === 0 && isCertified) {
        await dispatch(saveUserProfile({
          live_session_guide: 1,
        }));
      }
      if (!isCertified) {
        await updateProtocolCertificationQuery(
          userProfile.selected_protocol.levelID,
          userProfile.selected_protocol.family,
          userProfile.selected_protocol.rule,
          queryClient,
        );
      }
      navigate(`/app/progress/session-complete/${summary.sessionData.sessionId}`);
    } else {
      dispatch(cancelStreamData(loggedInUserId));
      toast.error("Sorry, session could not be saved.");
      navigate("/app/meditation");
    }
  };

  const onResumeSession = () => {
    setIsResuming(true);
  };
  const onRestartSession = () => {
    dispatch(cancelStreamData(loggedInUserId));
    dispatch(getDataStream(false));
    ending.current = true;
    navigate("/app/meditation");
  };
  const onEndSession = () => {
    setIsSessionCompleted(true);
    dispatch(getDataStream(false));
    ending.current = true;
    saveSession();
  };
  const onAudioSettings = () => {
    navigate("/app/meditation/live-sound");
  };

  useEffect(() => {
    dispatch(setActiveStream(btDevice.status === 5 && gettingLiveSessionData ? "sensors" : "status"));
    const checkSignal = () => {
      if (!gettingLiveSessionData) {
        navigate("/app/meditation/live-session");
      } else if (signalCheckSeq !== resuming.current.seq) {
        if (signalCheck.some((sensor) => [0, 1].includes(sensor))) {
          dispatch(setSignalCheckCompleted(null));
          navigate("/app/meditation/signal");
          toast.info("Signal quality has dropped. Please redo Signal Check to resume session.");
        } else {
          navigate("/app/meditation/live-session");
        }
      }
    };

    if (isResuming) {
      if (btDevice.status === 5) {
        dispatch(setStreamError(null));
        if (!resuming.current.seq) resuming.current.seq = signalCheckSeq;
        checkSignal();
      }
      if (!resuming.current.timer) {
        resuming.current.timer = setTimeout(() => {
          setIsResuming(false);
          toast.error("Muse device not connected or data stream lost.");
          resuming.current.timer = null;
        }, 6000);
      }
    }
    return () => {
      if (btDevice.status === 5) {
        clearTimeout(resuming.current.timer);
      }
    };
  }, [isResuming, signalCheck, btDevice.status]);

  useEffect(() => {
    if (streamError && !remainingSessionTime && !gettingLiveSessionData) {
      dispatch(getDataStream(false));
      ending.current = true;
      navigate("/app/meditation");
    }
    if (streamError?.EOF || streamError?.rc === -6) {
      toast.warning("Session stream ended.");
      dispatch(getDataStream(false));
      setIsSessionCanceled(true);
    }
  }, [streamError]);

  useEffect(() => () => {
    if (ending.current) dispatch(clearLiveSessionData());
  }, []);

  window.nativeBack = () => {
    onResumeSession?.();
  };

  return (
    <div className="liveSessionReport">
      { (isSessionCompleted || isResuming) && (
        <LoadingOverlay
          loadingText={isSessionCompleted
            ? "Analyzing session..."
            : "Resuming session..."}
          darkMode
        />
      )}
      <div className="liveSessionPage__header-wrapper">
        <div className="liveSessionPage__header">
          <div
            role="presentation"
            className={`liveSessionPage__timer ${remainingSessionTime !== null && remainingSessionTime <= 0 ? "extended" : ""}`}
            onClick={isSessionCanceled ? onEndSession : onResumeSession}
          >
            <div className="liveSessionPage__header-text">
              <span>+</span>
              {formatDuration(Math.abs(remainingSessionTime ?? initSessionLength) * 1000)}
            </div>
            <div className="liveSessionPage__pause">
              { isSessionCanceled
                ? <IconSuccess /> : <IconPlay /> }
            </div>
          </div>
          <HeaderSensors />
          <button type="button" className="audio-button" onClick={onAudioSettings}>
            <IconAudio />
          </button>
        </div>
      </div>
      <Container>
        <div className="liveSessionReport__actions-container">
          <GenericButton className="button--danger" onButtonClick={onRestartSession} text="Cancel" />
          { remainingSessionTime !== null && <GenericButton onButtonClick={onEndSession} text="Finish" />}
          { !isSessionCanceled && <GenericButton onButtonClick={onResumeSession} text="Resume" /> }
        </div>
        <div className="liveSessionReport__session-timer">
          <h1 className="font-700">
            { remainingSessionTime === 0 ? ("Session Finished") : ("Session Paused")}
          </h1>
          <h2 className="font-600 mb-0">
            { formatDuration(pausedSessionData.sessionData.length ?? 0)}
          </h2>
          {pausedSessionData.sessionError && (
            <InfoBox type="danger">
              <p className="text-danger">{pausedSessionData.sessionError}</p>
            </InfoBox>
          )}
        </div>
        {pausedSessionData && (
        <>
          <ProtocolSelectInfo
            customClass="liveSessionReport__protocol"
            protocol={protocolDetails}
            level={sessionLevel}
            rule={sessionRule}
            centered
          />
          <SessionTaps tapsData={pausedSessionData.sessionData.taps} />
          {isCertified && (
            <>
              {pausedSessionData.report && (
              <SessionProgressScores
                report={pausedSessionData.report}
                noDiff
              />
              )}
              <div className="liveSessionReport__session-chart">
                <Card customClass="graph-card">
                  <h2 className="mb-3">Depth Score</h2>
                  <SessionChart
                    sessionData={pausedSessionData.sessionData}
                    depthAvg={pausedSessionData.report?.depthAvg}
                    initialScale={1}
                    isColored
                  />
                </Card>
              </div>
              <div className="liveSessionReport__scores">
                <SessionScores
                  points={pausedSessionData.report?.points}
                  rewards={pausedSessionData.report?.rewards}
                  reminders={pausedSessionData.report?.reminders}
                  length={pausedSessionData.sessionData?.length}
                />
              </div>
            </>
          )}
        </>
        )}
      </Container>
    </div>
  );
}

export default LiveSessionReport;
