import { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

import { Select, GenericButton, DateRangeFromTo } from "components";
import { PROGRESS_SESSIONS_FILTER_DATE_RANGE } from "constants/profile_progress_sessions";
import * as reportingAction from "store/actions/reporting";

import "./ProgressListFilters.scss";

function ProgressListFilters({
  pastProtocols,
  show,
  onHide,
}) {
  const { pastSessionReportsFilters } = useSelector(({ reportingReducer }) => reportingReducer);

  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState(
    pastSessionReportsFilters?.dateRange || PROGRESS_SESSIONS_FILTER_DATE_RANGE,
  );
  const [selectedProtocols, setSelectedProtocols] = useState(
    pastSessionReportsFilters?.protocol || [],
  );
  const [toggleAllFilterOptionsName, setToggleAllFilterOptionsName] = useState("Select all");
  const uniqProtocols = _.uniqBy((pastProtocols.data || []), "protocolID");

  const resetFilters = () => {
    setDateRange(pastSessionReportsFilters?.dateRange || PROGRESS_SESSIONS_FILTER_DATE_RANGE);
    setSelectedProtocols(pastSessionReportsFilters?.protocol || []);
    setToggleAllFilterOptionsName("Select all");
  };

  const updateFilters = () => {
    const payload = {};
    if (dateRange && (dateRange.startDate || dateRange.endDate)) {
      payload.dateRange = dateRange;
    }
    if (selectedProtocols && selectedProtocols.length) {
      payload.protocol = selectedProtocols;
    }
    dispatch(reportingAction.changePastSessionReportsFilters(payload));
    dispatch(reportingAction.goToSpecificPaginationPage(1));
  };

  const toggleAllFilterOptions = (isSelectActive) => {
    if (isSelectActive) {
      const allProtocols = uniqProtocols.map(({ protocolID }) => protocolID);
      setSelectedProtocols([...allProtocols, "all"]);
      setToggleAllFilterOptionsName("Deselect all");
    } else {
      setSelectedProtocols([]);
      setToggleAllFilterOptionsName("Select all");
    }
  };

  const onSubmit = () => {
    updateFilters();
    onHide();
  };

  useEffect(() => {
    if (show) resetFilters();
  }, [show]);

  return (
    <Modal
      animation
      dialogClassName="dialog"
      show={show}
      centered={false}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="filters">
          <div className="filters__wrapper">
            <div className="filters__item">
              <p>Date range</p>
              <DateRangeFromTo
                ranges={dateRange}
                onChange={(range) => setDateRange(range)}
              />
            </div>
            <div className="filters__item">
              <p>My Protocols</p>
              <div className="filters__selects">
                <div className="filters__selects-item">
                  <Select
                    text={toggleAllFilterOptionsName}
                    size="xs"
                    defaultActive={selectedProtocols.includes("all")}
                    selectedItem={(isSelectActive) => toggleAllFilterOptions(isSelectActive)}
                  />
                </div>
                {uniqProtocols.map(({ protocol, protocolID }) => (
                  <div key={protocol} className="filters__selects-item">
                    <Select
                      text={protocol}
                      id={protocol}
                      size="xs"
                      defaultActive={selectedProtocols.includes(protocolID)}
                      selectedItem={() => {
                        const selected = selectedProtocols.includes(protocolID)
                          ? _.without(selectedProtocols, protocolID)
                          : [...selectedProtocols, protocolID];
                        setSelectedProtocols(selected);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="filters__footer" />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <GenericButton
          text="Filter"
          className="button--dark"
          onButtonClick={onSubmit}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ProgressListFilters;
