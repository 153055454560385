/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import axios from "axios";
import * as urls from "./urls";
import { refreshUserAuthToken, refreshClientAuthToken } from "./auth";

// For common config
axios.defaults.headers.post["Content-Type"] = "application/json";

// For User API
export const userApi = axios.create({
  baseURL: urls.USER_API,
});
userApi.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem("userAuth"))?.access_token;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});
userApi.refresh = null;
userApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response ? error.response.status : null;
    const originalRequest = error.config;
    if (status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (!userApi.refresh) {
        userApi.refresh = refreshUserAuthToken(userApi)
          .then(() => { userApi.refresh = null; });
      }
      await userApi.refresh;
      return userApi(originalRequest);
    }
    return Promise.reject(error);
  },
);

// For Client API
export const clientApi = axios.create({
  baseURL: urls.CLIENT_API,
});
clientApi.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem("clientAuth"))?.token;
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});
clientApi.refresh = null;
clientApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const status = error.response ? error.response.status : null;
    const originalRequest = error.config;
    if (status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (!clientApi.refresh) {
        clientApi.refresh = refreshClientAuthToken(clientApi)
          .then(() => { clientApi.refresh = null; });
      }
      await clientApi.refresh;
      return clientApi(originalRequest);
    }
    return Promise.reject(error);
  },
);
