import {
  useMemo, memo, useState, useEffect,
} from "react";
import DOMPurify from "dompurify";
import parse from "html-react-parser";

import { Card } from "components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle, faArrowUp, faArrowDown, faCheckCircle, faAngleUp, faAngleDown, faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IconDeeper } from "assets/ic_zscore/icon_deeper.svg";
import { ReactComponent as IconDeep } from "assets/ic_zscore/icon_deep.svg";
import { ReactComponent as IconBaseline } from "assets/ic_zscore/icon_baseline.svg";
import { ReactComponent as IconShallow } from "assets/ic_zscore/icon_shallow.svg";
import { ReactComponent as IconTrain } from "assets/icons_train.svg";
import { isApp } from "utils/app-url-origin";
import { ruleResult } from "./helpers/helper";

import "./InfoRules.scss";

const InfoRules = memo(({
  protocolDetails, rulesContent, calibration, calibrateRule, hiddenRules, setHiddenRules,
  isCoach, sort = true, isCollapsed, showTips = true,
}) => {
  const sortedRules = useMemo(
    () => (sort
      ? rulesContent.filter((rule) => !!rule).sort((a, b) => b.score - a.score)
      : rulesContent),
    [rulesContent],
  );

  const initCollapse = useMemo(() => sortedRules.map(
    (_rule, index) => isApp && sort && (index === sortedRules.length - 1),
  ), [sortedRules, sort]);

  const [collapse, setCollapse] = useState(initCollapse);

  useEffect(() => {
    if (sort) setCollapse(initCollapse);
  }, [sortedRules, sort]);

  const ruleCollapse = (index) => {
    const rules = new Array(sortedRules.length).fill(false);
    rules[index] = !collapse[index];
    setCollapse(rules);
  };

  const renderLabel = (value) => {
    const { name, color } = ruleResult(value);
    const getIcon = () => {
      switch (name) {
        case "Deeper":
          return <IconDeeper />;
        case "Deep":
          return <IconDeep />;
        case "Baseline":
          return <IconBaseline />;
        default:
          return <IconShallow />;
      }
    };
    return (
      <div className="rule-score" style={{ background: color }}>
        <div className="rule-score__icon">
          {getIcon()}
        </div>
        {name}
      </div>
    );
  };

  const safeHtml = (html) => {
    const quotes = html?.replaceAll(" [(", "<span class=\"interpretation-quote\"> (").replaceAll(")]", ")</span>");
    return parse(DOMPurify.sanitize(quotes));
  };

  const toggleHide = (ruleId) => {
    if (!isCollapsed && sortedRules.length < 1) return;
    const ruleIndex = hiddenRules.indexOf(ruleId);
    const editedRules = [...hiddenRules];
    if (ruleIndex < 0) {
      if (hiddenRules.length + 1 === sortedRules.length) return;
      editedRules.push(ruleId);
    } else {
      editedRules.splice(ruleIndex, 1);
    }
    setHiddenRules(editedRules);
  };

  const isRuleHidden = (ruleId) => hiddenRules?.includes(ruleId);
  const isRuleCalibrated = (ruleNo) => calibration?.rules.map(
    (calibrationRule) => Number(calibrationRule.split(".")[1]),
  ).includes(Number(ruleNo));

  return sortedRules && protocolDetails && (
    <Card customClass={`infoRules__protocol ${isCoach ? "is-coach" : ""}`}>
      <ol className="infoRules__protocol-rules">
        {sortedRules.map((rule, index) => rule && (
          <li
            key={rule.ruleId}
            className={`${isRuleHidden(rule.ruleId) ? "is-hidden" : ""}`}
          >
            <div className="rule-wrapper">
              <div className="rule-title">
                <div
                  role="presentation"
                  className="rule-name"
                  onClick={() => hiddenRules && toggleHide(rule.ruleId)}
                >
                  {hiddenRules && (
                  <span className="rule-check"><FontAwesomeIcon icon={isRuleHidden(rule.ruleId) ? faTimesCircle : faCheckCircle} /></span>
                  )}
                  <strong style={{ color: rule.color }}>
                    {rule.frequencyBand.join("/")}
                    {" "}
                    {rule.measure}
                  </strong>
                  <span className="rule-location">
                    {rule.direction.toLowerCase() === "up" ? "Increase" : "Decrease"}
                    {" "}
                    in
                    {" "}
                    {rule.location.join("/")}
                  </span>
                </div>

                {rule.value !== null && (
                <>
                  {renderLabel(rule.score)}
                  {isCollapsed && (
                    <span className={`rule-value ${rule.score < 0 ? "is-down" : ""} ${rule.score > 0 ? "is-up" : ""}`}>
                      {rule.value !== 0
                      && <FontAwesomeIcon icon={rule.value > 0 ? faArrowUp : faArrowDown} />}
                      {Math.abs(rule.score).toFixed(2)}
                    </span>
                  )}
                </>
                )}
                {isCollapsed && protocolDetails.icon !== "training" && (
                <div
                  role="presentation"
                  className="toggle"
                  onClick={() => ruleCollapse(index)}
                >
                  <FontAwesomeIcon icon={collapse[index] ? faAngleUp : faAngleDown} />
                </div>
                )}
              </div>
              {(collapse[index] || !isCollapsed) && protocolDetails.icon !== "training" && (
              <div className="rule-content">
                {safeHtml(rule.descriptions?.rationale)}
                {showTips && rule.descriptions?.notes && (
                <div className="rule-content__train">
                  <IconTrain />
                  <div>{safeHtml(rule.descriptions?.notes)}</div>
                </div>
                )}
                {calibrateRule && isApp && (
                <>
                  {ruleResult(rule.value) === "poor" && (
                  <p className="text-md font-700 text-dark">
                    Frustrated? Try a single-rule protocol:
                  </p>
                  )}
                  {isRuleCalibrated(rule.rule) ? (
                    <button
                      type="button"
                      disabled
                    >
                      <FontAwesomeIcon icon={faCheckCircle} />
                      <span>
                        Calibrated as Protocol to train
                      </span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => calibrateRule(rule.rule, rule.ruleId)}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} />
                      <span>
                        Calibrate as Protocol to train
                      </span>
                    </button>
                  )}
                </>
                )}
              </div>
              )}
            </div>
          </li>
        ))}
      </ol>
    </Card>
  );
});

export default InfoRules;
