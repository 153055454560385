export const CHART_TYPES = {
  POINTS: "points",
  REWARDS: "rewards",
  REMINDERS: "reminders",
  MAX: "max",
  MIN: "min",
  AVG: "avg",
  BLUE_THRESHOLD: "blueThreshold",
  RED_THRESHOLD: "redThreshold",
  GREEN_THRESHOLD: "greenThreshold",
  RED_ZONE: "red",
  BLUE_ZONE: "blue",
  GREEN_ZONE: "green",
  TAPS_VALUE: "tapsValue",
  TAPS_PERCENT: "tapsPercent",
};

export const CHART_DATA = {
  points: "points",
  rewards: "rewards",
  reminders: "reminders",
  max: "maxValue",
  min: "minValue",
  avg: "averageValue",
  blueThreshold: "blueThreshold",
  redThreshold: "redThreshold",
  greenThreshold: "greenThreshold",
  red: "red",
  blue: "blue",
  green: "green",
};

export const CHART_COLORS = {
  points: "#167cbe",
  rewards: "#33bf9f",
  reminders: "#DD4124",
  max: "#2a2ca3",
  min: "#9313a5",
  avg: "#167cbe",
  blueThreshold: "#33bf9f",
  redThreshold: "#DD4124",
  greenThreshold: "#ddb224",
  red: "#DD4124",
  blue: "#33bf9f",
  green: "#ddb224",
  tapsDeep: "#3B76F6",
  tapsDistracted: "#FF8975",
};

export const GRAPH_PERIODS_TABS_KEYS = {
  DAY: "Day",
  WEEK: "Week",
  MONTH: "Month",
};

export const GRAPH_PERIODS_TABS = Object.values(GRAPH_PERIODS_TABS_KEYS);

export const PROGRESS_GRAPH_TABS_KEYS = {
  METRICS: "Metrics",
  TAPS: "Taps",
  DEPTH: "Depth",
  ZONE: "%Reward",
  STATS: "Stats",
};

export const UNCERTIFIED_PROGRESS_TABS_KEYS = [
  PROGRESS_GRAPH_TABS_KEYS.METRICS,
  PROGRESS_GRAPH_TABS_KEYS.TAPS,
];

export const PROGRESS_GRAPH_TABS_AUDIO = {
  METRICS: "metrics",
  TAPS: "taps",
  DEPTH: "depth",
  ZONE: "reward",
  STATS: "stats",
};

export const PROGRESS_GRAPH_TABS = Object.values(PROGRESS_GRAPH_TABS_KEYS);

export const COACH_PROGRESS_GRAPH_TABS_KEYS = {
  METRICS: "Metrics",
  DEPTH: "Depth",
  ZONE: "%Reward",
  STATS: "Stats",
  TIME: "Time",
  LIFETIME: "Lifetime",
};

export const COACH_PROGRESS_GRAPH_TABS = Object.values(COACH_PROGRESS_GRAPH_TABS_KEYS);

export const CHART_TYPES_DEPTH_CHART = [
  CHART_TYPES.MAX,
  CHART_TYPES.MIN,
  CHART_TYPES.AVG,
  CHART_TYPES.BLUE_THRESHOLD,
  CHART_TYPES.RED_THRESHOLD,
];

export const CHART_TYPES_STATS_CHART = [
  CHART_TYPES.POINTS,
  CHART_TYPES.REWARDS,
  CHART_TYPES.REMINDERS,
];

export const CHART_TYPES_ZONE_CHART = [
  CHART_TYPES.BLUE_ZONE,
  CHART_TYPES.GREEN_ZONE,
  CHART_TYPES.RED_ZONE,
];

export const CHART_TYPES_TAPS_CHART = [
  CHART_TYPES.TAPS_VALUE,
  CHART_TYPES.TAPS_PERCENT,
];
