import { useState } from "react";

export default function useGuide(steps) {
  const [guide, setGuide] = useState({
    active: false,
    activeStep: 1,
    steps,
  });

  const nextStep = () => {
    if (!guide.active) {
      setGuide((prevState) => ({
        ...prevState,
        activeStep: 1,
        active: true,
      }));
    } else if (guide.activeStep < guide.steps) {
      setGuide((prevState) => ({
        ...prevState,
        activeStep: guide.activeStep + 1,
      }));
    } else {
      setGuide((prevState) => ({
        ...prevState,
        active: false,
      }));
    }
  };

  return {
    guide,
    nextStep,
  };
}
