import { useState, useEffect } from "react";

import { ALL_LEVELS } from "constants/onBoarding_level";

export default function usePracticeTime(practice, appPractice) {
  const [regularHours, setRegularHours] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [suggestedLevel, setSuggestedLevel] = useState("");

  const calculateHours = () => {
    if (!practice) return 0;
    const weekly = practice.sessions * practice.minutes * practice.days;
    const duration = (practice.years * 12) + practice.months;
    const total = (weekly * 4 * duration) / 60;
    return total + (practice.retreats * 10);
  };

  const getLevel = (total) => ALL_LEVELS.find(({ hours }) => {
    const range = hours.replace(/[^\d-]/g, "").split("-");
    if (hours.includes("+")) return total > range[1];
    return total >= range[0] && total < range[1];
  });

  useEffect(() => {
    setRegularHours(calculateHours);
  }, [practice]);

  useEffect(() => {
    const total = regularHours + appPractice;
    setTotalHours(total);
    setSuggestedLevel(getLevel(total)?.key);
  }, [regularHours, appPractice]);

  return {
    regularHours,
    totalHours,
    suggestedLevel,
  };
}
