import { userApi, clientApi } from "api/axios";
import * as urls from "api/urls";
import errorHandler from "utils/error-handler";
import { PROGRESS_SESSION_SETTINGS } from "constants/profile_progress_sessions";

export const createSession = async ({ report, protocol }) => {
  try {
    const { data } = await userApi.post(urls.createSessionUrl(), {
      report, protocol,
    });
    return data;
  } catch (err) {
    errorHandler(err);
    return false;
  }
};

export const createReport = async (payload) => {
  try {
    const { data } = await userApi.post(urls.createReportUrl(), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    return false;
  }
};

export const importSessionReport = async (sessionId) => {
  try {
    await userApi.get(urls.importSessionUrl(sessionId));
    return true;
  } catch (err) {
    errorHandler(err);
    return false;
  }
};

export const getPastSessionsList = async (payload) => {
  const filters = {
    pageNumber: payload.pageNumber,
    limit: PROGRESS_SESSION_SETTINGS.LIMIT,
  };
  try {
    const { data } = await userApi.post(`${urls.sessionsListUrl()}`, {
      protocol: payload.protocol,
      dateRange: payload.dateRange,
      order: payload.order,
    }, {
      params: filters,
    });
    return {
      pastSessionReports: data.reports,
      pastSessionReportsTotalNumber: data.totalNumberOfReports,
      pastSessionReportsTotalPages: (
        Math.ceil(data.totalNumberOfReports / PROGRESS_SESSION_SETTINGS.LIMIT)
      ),
    };
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const updateSessionJournal = async (sessionId, payload) => {
  try {
    const { data } = await userApi.post(urls.updateSessionJournalUrl(sessionId), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const submitSession = async (sessionId) => {
  try {
    const { data } = await userApi.post(urls.updateSessionSubmitUrl(sessionId), {});
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const updateSessionNote = async (payload) => {
  try {
    const { data } = await userApi.post(urls.updateSessionNoteUrl(), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const deleteSessionNote = async (sessionId, timestamp) => {
  try {
    const { data } = await userApi.delete(urls.deleteSessionNoteUrl(sessionId, timestamp));
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const updateSessionTap = async (payload) => {
  try {
    const { data } = await userApi.post(urls.updateSessionTapUrl(), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const deleteSessionTap = async (sessionId, timestamp) => {
  try {
    const { data } = await userApi.delete(urls.deleteSessionTapUrl(sessionId, timestamp));
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const getSessionReport = async (id) => {
  try {
    const { data } = await userApi.get(urls.getSessionDetailsUrl(id));
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const deleteSessionReport = async (id) => {
  try {
    await userApi.delete(urls.deleteSessionUrl(id));
    return true;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const getPastProtocols = async () => {
  try {
    const { data } = await userApi.get(urls.allHistoricProtocolsUrl());
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const updatePastProtocolsQuery = async (queryClient) => {
  if (!queryClient) return;
  const pastProtocols = await getPastProtocols();
  if (pastProtocols) {
    queryClient.setQueryData(["past-protocols"], pastProtocols);
  }
};

export const getGoal = async (id) => {
  try {
    const { data } = await userApi.get(urls.getGoalUrl(id));
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getTimeProgress = async (id, period, last) => {
  try {
    const { data } = await userApi.get(urls.getTimeProgressUrl(id, period, last));
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getProtocolProgress = async (id, period, last, protocol) => {
  try {
    const { data } = await userApi.get(urls.getProtocolProgressUrl(id, period, protocol, last));
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getProtocolMetrics = async (id, period, last, protocol) => {
  try {
    const { data } = await userApi.get(urls.getProtocolMetricsUrl(id, period, protocol, last));
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getBrainwaveReport = async (id) => {
  try {
    const { data } = await userApi.get(urls.getBrainwaveReportUrl(id));
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const getProtocolRules = async (id) => {
  try {
    const { data } = await clientApi.get(urls.getProtocolRulesUrl(id));
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getAnalyzerDeeperContent = async (payload) => {
  try {
    const { data } = await userApi.get(urls.getAnalyzerDeeperContentUrl(), {
      params: {
        anatomy: payload.anatomy.join(","),
        teachings: payload.teachings.join(","),
        category: payload.category.join(","),
      },
    });
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getRules = async (payload) => {
  try {
    const { data } = await userApi.get(urls.getRulesUrl(), {
      params: {
        definitions: payload.join(","),
      },
    });
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};
