import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { GenericButton, BottomBarSticky } from "components";

import { updateUserProfile } from "store/actions/profile";

import "./Congratulation.scss";

function CongratulationPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateUserProfile({
      calibrationEnabled: true,
    }));
  }, []);

  return (
    <div className="congratulationPage">
      <div className="congratulationPage__fireworks">
        <h1 className="text-center">CONGRATULATIONS!</h1>
      </div>
      <Container className="congratulationPage__container">
        <h2 className="text-center mb-4">
          Thank you for contributing
          your meditation to science!
        </h2>
        <p>
          As a token of our appreciation, you now have access to:
        </p>
        <ol>
          <li>One more free month of GoDeeper</li>
          <li>Audio neurofeedback to optimize your practice</li>
          <li>Brainwave training protocols to target specific regions of your brain</li>
        </ol>
      </Container>
      <BottomBarSticky>
        <GenericButton
          text="Try them out!"
          className="button--white"
          onButtonClick={() => navigate("/app/meditation/protocol-calibrated")}
        />
      </BottomBarSticky>
    </div>
  );
}

export default CongratulationPage;
