import { SubNav } from "components/navs/SubNav/SubNav";

export function SubHeader({
  customClass, title, text, goBack, helpModal, setGuide, audioGuide,
}) {
  return (
    <div className={`header ${customClass || ""}`}>
      <SubNav
        title={title}
        onBack={goBack}
        setGuide={setGuide}
        audioGuide={audioGuide}
      >
        <h1>
          {title}
          {helpModal}
        </h1>
      </SubNav>
      {text && (
      <p className="text-muted font-600">
        {text}
      </p>
      )}
    </div>
  );
}

export default SubHeader;
