import * as authActions from "store/actions/authentication";
import * as profileActions from "store/actions/profile";

const userAuth = JSON.parse(localStorage.getItem("userAuth"));

const initialState = {
  isUserLogging: false,
  isUserLoggedIn: !!userAuth,
  userLogoutError: null,
  userLoginError: null,
  user: null,
  isUserRegistering: false,
  registerUserError: null,
  updateUserPending: false,
  updateUserError: false,
  isUserInfoPending: false,
  getUserError: false,
  changeUserPasswordPending: false,
  changeUserPasswordError: null,
};

const authenticationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case authActions.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        isUserLoggedIn: false,
        user: null,
        userLogoutError: null,
      };
    case authActions.LOGOUT_USER_ERROR:
      return {
        ...state,
        userLogoutError: { ...action.payload },
      };
    case authActions.LOGIN_USER:
    case authActions.SOCIAL_LOGIN_USER:
      return {
        ...state,
        isUserLogging: true,
        userLoginError: null,
        updateUserError: false,
      };
    case authActions.LOGIN_USER_SUCCESS:
    case authActions.REGISTER_USER_SUCCESS:
      return {
        ...state,
        user: { ...action.payload.user },
        isUserLoggedIn: true,
        isUserRegistering: false,
        isUserLogging: false,
      };
    case authActions.LOGIN_USER_ERROR:
    case authActions.SOCIAL_LOGIN_USER_ERROR:
      return {
        ...state,
        isUserLoggedIn: false,
        isUserLogging: false,
        userLoginError: { ...action.payload },
      };
    case authActions.REGISTER_USER:
      return {
        ...state,
        isUserRegistering: true,
        registerUserError: null,
      };
    case authActions.REGISTER_USER_ERROR:
      return {
        ...state,
        isUserRegistering: false,
        registerUserError: { ...action.payload },
      };
    case authActions.UPDATE_USER:
      return {
        ...state,
        updateUserPending: true,
      };
    case authActions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        user: { ...action.payload },
        updateUserPending: false,
      };
    case authActions.UPDATE_USER_ERROR:
      return {
        ...state,
        updateUserError: { ...action.payload },
        updateUserPending: false,
      };
    case authActions.GET_USER_INFO:
      return {
        ...state,
        isUserInfoPending: true,
      };
    case authActions.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
        isUserInfoPending: false,
      };
    case authActions.GET_USER_INFO_ERROR:
      return {
        ...state,
        updateUserError: { ...action.payload },
        isUserInfoPending: false,
      };
    case authActions.CHANGE_USER_PASSWORD:
      return {
        ...state,
        changeUserPasswordPending: true,
      };
    case authActions.CHANGE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        changeUserPasswordPending: false,
      };
    case authActions.CHANGE_USER_PASSWORD_ERROR:
      return {
        ...state,
        updateUserError: { ...action.payload },
        changeUserPasswordPending: false,
      };
    case profileActions.SAVE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        user: { ...state.user, subscription_level_id: action.payload.subscription_level },
      };
    default:
      return state;
  }
};

export default authenticationReducer;
