import "./ProgressTrack.scss";

function ProgressTrack({ progress }) {
  const strokeLenght = Math.PI * (14 * 2);
  const strokeOffset = strokeLenght * ((100 - progress) / 100);

  return (
    <div className="progressTrack">
      <svg id="svg" width="32" height="32">
        <circle
          className="rail"
          r="14"
          cx="16"
          cy="16"
          fill="transparent"
          strokeDasharray={strokeLenght}
          strokeDashoffset="0"
          strokeWidth="2"
        />
        <circle
          className="progress"
          r="14"
          cx="16"
          cy="16"
          fill="transparent"
          strokeDasharray={strokeLenght}
          strokeDashoffset={strokeOffset}
          strokeWidth="2"
        />
      </svg>
    </div>
  );
}

export default ProgressTrack;
