import "./TapsList.scss";

export function TapsList({ darkMode }) {
  const tapsData = [
    {
      type: 1,
      typeText: "Once",
      text: "when emerging from",
      name: "Depth",
    },
    {
      type: 2,
      typeText: "Twice",
      text: "after recovering from",
      name: "Distraction",
    },
    // {
    //   type: 3,
    //   name: "Thrice",
    //   text: "to flag special moment",
    // },
  ];
  return (
    <div className={`tapsList ${darkMode ? "is-dark" : ""}`}>
      {tapsData.map((tap) => (
        <div className={`tapsList__item type-${tap.type}`} key={tap.type}>
          <div className="tapsList__item-icon">
            {tap.type}
            <div className={`tapIcon type-${tap.type}`}>
              {[...Array(tap.type)].map((_type, i) => <span key={i.toString()} />)}
            </div>
          </div>
          <div className="tapsList__item-text">
            <strong>{tap.typeText}</strong>
            {" "}
            {tap.text}
            {" "}
            <span className="font-700 text-uppercase">{tap.name}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TapsList;
