import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { SubNav, GenericButton, BottomBarSticky } from "components";
import ProtocolCalibrated from "pages/App/Common/ProtocolCalibrated/ProtocolCalibrated";

import { isAllowed } from "utils/user-permissions";
import { getUserProfile } from "store/actions/profile";
import useProtocolCertification from "hooks/useProtocolCertification";
import CalibrationInfo from "./components/CalibrationInfo";

function ProtocolCalibratedPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLevel = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.subscription_level_id,
  );
  const userSavedSelectedProtocol = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.selected_protocol,
  );
  const calibrationEnabled = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.calibrationEnabled,
  );

  const isCoach = isAllowed(userLevel, "COACH");

  const protocolCertification = useProtocolCertification(
    userSavedSelectedProtocol?.levelID,
    userSavedSelectedProtocol?.family,
    userSavedSelectedProtocol?.rule,
    true,
  );

  useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  return (
    <Container>
      <SubNav
        customClass="mb-0"
        title="Your Feedback Protocols"
        onBack={() => navigate("/app/meditation")}
        audioGuide="coach-your-feedback-protocols"
        staticTitle
      />
      <ProtocolCalibrated isEditable={calibrationEnabled || isCoach} />
      <Container className="text-muted text-sm">
        <p>
          This list includes the feedback protocols you have already calibrated.
          GoDeeper’s feedback protocols are algorithms that interpret your brainwaves
          to calculate a depth score. Each protocol is made up of a combination of
          brainwave metrics that we measure during meditation vs your baseline.
        </p>
        <p>
          To add a new protocol, you’ll need to calibrate it first. Calibration
          compares your meditation session to your baseline session to figure out
          the optimal setting for the reward threshold.
        </p>
      </Container>
      {(!calibrationEnabled && !isCoach) && (
      <CalibrationInfo protocolCertification={protocolCertification} />
      )}
      <BottomBarSticky>
        <GenericButton
          className="button--dark"
          text="Calibrate New Feedback Protocol"
          onButtonClick={() => navigate("/app/meditation/protocol")}
          disabled={!calibrationEnabled && !isCoach}
        />
      </BottomBarSticky>
    </Container>
  );
}

export default ProtocolCalibratedPage;
