import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { format } from "date-fns";
import { ReactComponent as IconClose } from "assets/icons_close.svg";
import { DatePicker } from "./DatePicker";

import "./DatePickerForm.scss";

export function DatePickerForm({
  formatType = "MMM d, yyyy",
  placeholder,
  selectedDate,
  maxDate,
  minDate,
  onChange,
  withClear,
}) {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [currentDate, setCurrentDate] = useState(selectedDate);

  const onToggle = (show) => {
    if (show) setCurrentDate(selectedDate);
    setIsMenuVisible(show);
  };

  const onSubmit = () => {
    onChange(currentDate);
    setIsMenuVisible(false);
  };

  const onClear = (e) => {
    e.stopPropagation();
    onChange(null);
  };

  return (
    <div className="datepicker-form">
      <Dropdown autoClose="outside" show={isMenuVisible} onToggle={onToggle}>
        <div className="dropdown__backdrop" />
        <Dropdown.Toggle>
          <div
            className={`datepicker-form__input ${!selectedDate ? "text-muted" : ""}`}
            onClick={() => setIsMenuVisible(true)}
            aria-hidden="true"
          >
            {selectedDate ? format(selectedDate, formatType) : (placeholder || "Choose date")}
            {withClear && selectedDate && (
            <div
              role="presentation"
              onClick={onClear}
              className="datepicker-form__input-close"
            >
              <IconClose />
            </div>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <DatePicker
            date={currentDate}
            maxDate={maxDate}
            minDate={minDate}
            onChange={(item) => setCurrentDate(item)}
            onSubmit={onSubmit}
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default DatePickerForm;
