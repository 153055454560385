import { Container } from "react-bootstrap";
import { SubHeader, InfoBox } from "components";

import "./About.scss";

function AboutPage() {
  return (
    <Container>
      <div className="settingsPage aboutPage">
        <SubHeader
          title="About"
          text="Check application details."
        />
        <InfoBox customClass="aboutPage__app-version">
          <p>
            <span>App version: </span>
            <span className="aboutPage__app-version-number">{window?.appInfo?.version}</span>
          </p>
        </InfoBox>
        <div className="aboutPage__links">
          <a href="https://alpha.godeeper.com/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
          <a href="https://alpha.godeeper.com/terms-of-use/" target="_blank" rel="noreferrer">Terms of Use</a>
        </div>
        <p className="text-sm text-muted">
          GoDeeper uses many sounds from freesound.org, for the full list
          {" "}
          <a href="https://alpha.godeeper.com/credits/" target="_blank" rel="noreferrer">click here</a>
          .
        </p>
      </div>
    </Container>
  );
}

export default AboutPage;
