import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { formatDuration } from "utils/time-format";

import "./TapNote.scss";

export function TapNote({ activeNote, setActiveNote }) {
  return (
    <div className="tapNote">
      <div className="tapNote__wrapper">
        <div className="tapNote__header">
          <h4>
            Note
            <span>{formatDuration(activeNote.timestamp * 1000)}</span>
          </h4>
          <button
            type="button"
            onClick={() => setActiveNote(null)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className="tapNote__content">
          {activeNote.notes}
        </div>
      </div>
    </div>
  );
}

export default TapNote;
