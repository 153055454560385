import {
  Route, Routes, Navigate,
} from "react-router-dom";

import { AuthProvider, ProtectedRoute } from "components";
import Clients from "pages/Coach/Dashboard/Clients/Clients";
import Sessions from "pages/Coach/Dashboard/Sessions/Sessions";
import Diagnostics from "pages/Coach/Dashboard/Diagnostics/Diagnostics";
import Recalculations from "pages/Coach/Dashboard/Recalculations/Recalculations";
import Tests from "pages/Coach/Dashboard/Tests/Tests";
import ResearchLibrary from "pages/Coach/Dashboard/ResearchLibrary/ResearchLibrary";
import Progress from "pages/Coach/Clients/Progress/Progress";
import SessionSummary from "pages/Coach/Clients/SessionSummary/SessionSummary";
import BrainwaveAnalyzer from "pages/Coach/Clients/BrainwaveAnalyzer/BrainwaveAnalyzer";
import Login from "pages/Coach/Authentication/Login/CoachLogin";
import Logout from "pages/Common/Logout/Logout";
import Denied from "pages/Coach/Authentication/Denied/Denied";
import RouteNotFound from "pages/Common/404/404";
import ProtocolsList from "pages/Coach/Dashboard/ProtocolList/ProtocolsList";

export function Router() {
  return (
    <Routes>
      <Route index element={<Navigate to="/coach/clients" />} exact />
      <Route element={<AuthProvider />}>
        <Route path="login" element={<Login />} />
      </Route>
      <Route path="denied" element={<Denied />} />
      <Route element={<ProtectedRoute level={1} />}>
        <Route path="logout" element={<Logout />} />
      </Route>
      <Route element={<ProtectedRoute level={3} />}>
        <Route path="protocols-list" element={<ProtocolsList />} />
        <Route path="clients" element={<Clients />} />
        <Route path="sessions" element={<Sessions />} />
        <Route path="diagnostics" element={<Diagnostics />} />
        <Route path="recalculations" element={<Recalculations />} />
        <Route path="tests" element={<Tests />} />
        <Route path="research-library" element={<ResearchLibrary />} />
        <Route path="clients/:clientId/progress" element={<Progress />} />
        <Route path="clients/:clientId/session-summary/:sessionId" element={<SessionSummary />} />
        <Route path="clients/:clientId/brainwave-analyzer/:sessionId" element={<BrainwaveAnalyzer />} />
      </Route>
      <Route path="*" element={<RouteNotFound />} />
    </Routes>
  );
}

export default Router;
