import "./404.scss";

function RouteNotFound() {
  return (
    <div className="pageNotFound__container">
      <h1> 404 </h1>
      <div> Sorry page not found </div>
    </div>
  );
}

export default RouteNotFound;
