import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GenericButton, BottomBarSticky, DiagnosticChart } from "components";

import useSkipSignalCheck from "hooks/useSkipSignalCheck";
import { setOnboardingTab } from "store/actions/onBoarding";
import { setActiveStream } from "store/actions/session";
import { format } from "date-fns";

import SignalCheck from "pages/App/Common/SignalCheck/SignalCheck";
import Diagnostic from "pages/App/Common/Diagnostic/Diagnostic";

function DiagnosticPage({ tabIndex, isCompleted }) {
  const dispatch = useDispatch();
  const skipSignalCheck = useSkipSignalCheck();
  const { diagnostic } = useSelector(({ profileReducer }) => profileReducer.userProfile);

  const [stage, setStage] = useState(skipSignalCheck ? "diagnostic" : "signal");

  useEffect(() => {
    if (stage === "diagnostic" && !isCompleted) dispatch(setActiveStream("status"));
    return () => dispatch(setActiveStream(null));
  }, [stage]);

  const startDiagnostic = () => {
    document.body.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setStage("diagnostic");
  };
  const onContinue = () => {
    dispatch(setOnboardingTab(tabIndex + 1));
  };
  const onCancel = () => {
    dispatch(setOnboardingTab(tabIndex - 1));
  };
  const onError = () => {
    setStage("signal");
  };

  const stages = {
    signal: <SignalCheck onComplete={startDiagnostic} onCancel={onCancel} />,
    diagnostic: <Diagnostic onCancel={onCancel} onError={onError} />,
  };

  return (
    !diagnostic?.date
      ? stages[stage]
      : (
        <>
          <h2 className="mb-1">Diagnostic Results</h2>
          <p className="text-sm text-muted mb-4">
            {format(new Date(diagnostic.date), "MMMM do, yyyy - hh:mma")}
          </p>
          <DiagnosticChart protocols={diagnostic.protocols} />
          <BottomBarSticky>
            <GenericButton
              className="button--dark"
              text="Continue"
              onButtonClick={onContinue}
            />
          </BottomBarSticky>
        </>
      )
  );
}
export default DiagnosticPage;
