import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";

import { SubNav } from "components";
import SignalCheck from "pages/App/Common/SignalCheck/SignalCheck";

function SessionSignal() {
  const navigate = useNavigate();
  const { remainingSessionTime } = useSelector(({ sessionReducer }) => sessionReducer);

  const onComplete = () => {
    navigate("/app/meditation/live-session");
  };

  const onCancel = () => {
    const url = remainingSessionTime
      ? "/app/meditation/pause-report"
      : "/app/meditation";
    navigate(url);
  };

  return (
    <Container>
      <SubNav title="Meditation Session" onBack={onCancel} staticTitle noSensors />
      <SignalCheck
        onComplete={onComplete}
        sessionPaused={remainingSessionTime}
      />
    </Container>
  );
}

export default SessionSignal;
