import { useState } from "react";
import { Button } from "react-bootstrap";
import "./Button.scss";

export function DownloadButton({
  className = "",
  style = null,
  type = "button",
  text,
  pendingText,
  disabled = false,
  id,
  url,
  fileName,
  getDownloadUrl,
}) {
  const [pending, setPending] = useState(false);

  const saveFile = (generatedUrl) => {
    const link = document.createElement("a");
    link.href = generatedUrl || url;
    link.download = fileName;
    link.target = "_self";
    link.click();
  };

  const getFileUrl = async () => {
    setPending(true);
    const fileUrl = await getDownloadUrl();
    setPending(false);
    saveFile(fileUrl);
  };

  return (
    <Button
      onClick={getDownloadUrl ? getFileUrl : saveFile}
      variant="link"
      type={type}
      className={`button ${className} ${pending ? "pending" : ""}`}
      style={style}
      id={id}
      disabled={disabled}
    >
      <span className="button-text">
        {!pending ? text : pendingText || text}
      </span>
    </Button>
  );
}

export default DownloadButton;
