import { formatDuration } from "utils/time-format";

import { ReactComponent as Warning } from "assets/icons_warning.svg";
import { ReactComponent as Trophy } from "assets/icons_trophy.svg";

import { GRAPH_TIMESCALE } from "constants/session_graph";

function LayersChartBody({
  data,
  filterData,
  rewardMarkers,
  reminderMarkers,
  taps,
  graphBottom,
  graphTop,
  scale,
  analyze,
  timeline,
  isCertified,
  marksVisible,
  activeNote,
  setActiveNote,
}) {
  const setClassName = (i) => {
    const className = [];
    if (i === timeline?.start) className.push("filter-start");
    if (i === timeline?.end) className.push("filter-end");
    if (filterData?.[i]) className.push(!filterData[i + 1] ? "last-active" : "active");
    if (i === activeNote?.timestamp) className.push("note-active");
    return className.join(" ");
  };

  const isStep = (i) => {
    const timeStep = GRAPH_TIMESCALE.find((option) => option.scale === scale);
    return i % timeStep.steps === 0;
  };

  const onNoteShow = (index) => {
    if (!timeline) return;
    if (activeNote?.timestamp === index) {
      setActiveNote(null);
    } else {
      setActiveNote(taps.find(({ timestamp }) => timestamp === index) || { timestamp: index });
    }
  };

  const renderMarker = (marker, iconType, score) => {
    const icon = iconType === "trophy" ? <Trophy /> : <Warning />;
    if (scale <= 29) {
      return (
        <div className={`marker-icon marker-icon--large ${iconType}`}>
          {icon}
          <p>{marker.number}</p>
        </div>
      );
    }
    const positionTop = (-score / (-graphBottom - graphTop)) * 100;
    return (
      <div className={`marker-icon ${iconType}`} style={{ bottom: `${positionTop}%` }}>
        {icon}
      </div>
    );
  };

  const renderTap = (index) => {
    const tap = taps.find(({ timestamp }) => timestamp === index);
    if (tap) {
      const getAnalyze = () => {
        if (["1", "2", "3"].includes(analyze)) {
          return Number(analyze[0]) === tap.type ? "" : "is-muted";
        }
        return "";
      };
      return (
        <div className={`marker-tap tapIcon type-${tap.type} ${getAnalyze()}`}>
          {[...Array(tap.type)].map((_type, i) => <span key={i.toString()} />)}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="LayersChart__time-filter">
      {data.map((score, i) => (
        <div
          role="presentation"
          key={`time_${i.toString()}`}
          style={{ width: scale }}
          className={setClassName(i)}
          onClick={() => onNoteShow(i)}
        >
          {isCertified && (
          <>
            {(marksVisible || analyze === "trophy") && rewardMarkers[i]
          && renderMarker(
            rewardMarkers[i],
            "trophy",
            score,
          )}
            {(marksVisible || analyze === "warning") && reminderMarkers[i]
          && renderMarker(
            reminderMarkers[i],
            "warning",
            score,
          )}
          </>
          )}
          {renderTap(i)}
          {isStep(i) && (
          <div
            key={i.toString()}
            className="timestamp"
          >
            <span>
              {formatDuration(i * 1000, false)}
            </span>
          </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default LayersChartBody;
