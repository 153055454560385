import {
  useState, useEffect, useRef, useMemo,
} from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Container } from "react-bootstrap";
import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import {
  LoadingOverlay, Card, GuideWrapper, Spinner,
  ProtocolSelectInfo, GenericButton, HeaderSensors, InfoSection,
  TapsList,
} from "components";
import { SessionChart } from "features";
import AudioGuide from "features/AudioGuide/AudioGuide";

import useInterval from "hooks/useInterval";
import useGuide from "hooks/useGuide";
import useProtocol from "hooks/useProtocol";
import useSessionError from "hooks/useSessionError";
import useMuse from "hooks/useMuse";
import useProtocolCertification from "hooks/useProtocolCertification";
import {
  getLiveSessionData, setTimmer, pauseSession, getDataStream, stopStreamData, clearLiveSessionData,
  setSignalCheckCompleted, setActiveStream, cancelStreamData,
} from "store/actions/session";
import { saveUserProfile } from "store/actions/profile";
import { createSession } from "api/services/reporting";
import { updateProtocolCertificationQuery } from "api/services/protocols";
import { audioController } from "utils/native-controllers";
import { formatDuration } from "utils/time-format";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Star } from "assets/icons_promo_star.svg";
import { ReactComponent as IconAudio } from "assets/icons_sound.svg";
import { ReactComponent as TapIcon } from "assets/icons_hand.svg";
import AUDIO_FILES from "constants/profile_audio";

import useLiveChart from "./hooks/useLiveChart";
import RewardSlider from "./components/RewardSlider";
import TapGuide from "./components/TapGuide";

import "./LiveSession.scss";

function LiveSessionPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const userProfile = useSelector(
    ({ profileReducer }) => profileReducer.userProfile,
  );
  const loggedInUserId = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.pk,
  );
  const guideStatus = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.live_session_guide,
  );
  const {
    sessionLength, liveSessionData, liveSessionId, remainingSessionTime,
    signalCheck, gettingLiveSessionData, btDevice,
  } = useSelector(({ sessionReducer }) => sessionReducer);
  const protocols = useSelector(({ protocolsReducer }) => protocolsReducer.protocols);

  // SESSION DATA
  const selectedProtocol = useMemo(() => {
    const { levelID } = userProfile.selected_protocol;
    return protocols?.find(
      (protocol) => protocol.thresholds?.protocolIds?.levelID === levelID,
    );
  }, [protocols]);

  const protocolDetails = useProtocol(
    userProfile.selected_protocol.categoryID,
    userProfile.selected_protocol.protocolID,
  );

  const protocolCertification = useProtocolCertification(
    userProfile.selected_protocol.levelID,
    userProfile.selected_protocol.family,
    userProfile.selected_protocol.rule,
  );

  const isCertified = protocolCertification.global?.certified;

  const sessionLevel = useMemo(() => {
    const { levelID } = userProfile.selected_protocol;
    return levelID?.split("/")[1];
  }, [userProfile.selected_protocol, protocolDetails]);

  const sessionRule = useMemo(() => {
    const { rule: ruleNo } = userProfile.selected_protocol;
    if (!protocolDetails || !ruleNo) return null;
    const ruleDetails = protocolDetails.protocolRules.find(
      (rule) => rule?.rule === ruleNo,
    );
    return ruleDetails?.descriptions?.name || ruleDetails?.title || null;
  }, [userProfile.selected_protocol, protocolDetails]);

  const initSessionLength = useMemo(
    () => (sessionLength.hours * 60 * 60) + (sessionLength.minutes * 60),
    [sessionLength],
  );

  // STATE
  const [difficulty, setDifficulty] = useState(
    userProfile.saved_session_difficulty || -Number(selectedProtocol?.thresholds?.thresholds[0]),
  );
  const thresholds = useMemo(() => {
    const selectedThresholds = selectedProtocol?.thresholds?.thresholds;
    const warning = selectedThresholds[0]
      ? _.round(selectedThresholds[1] * -(difficulty / selectedThresholds[0])) : 0;
    return [-difficulty, _.clamp(warning, -1000, 0)];
  }, [selectedProtocol, difficulty]);
  const [startTimer, setStartTimer] = useState(gettingLiveSessionData);
  const [extendedTimer, setExtendedTimer] = useState(remainingSessionTime < 0);
  const [hiddenTimer, setHiddenTimer] = useState(false);
  const [remainingTime, setRemainingTime] = useState(remainingSessionTime);
  const [isLoading, setIsLoading] = useState(false);
  const sessionCompleted = useRef(false);

  const { chartSessionData, onTap } = useLiveChart(remainingTime, thresholds, isCertified);

  const sessionError = useSessionError(
    liveSessionData,
    startTimer,
    sessionCompleted.current,
    remainingTime,
  );

  // LOGIC
  useMuse(sessionCompleted);

  const interval = useInterval(() => {
    if (liveSessionData.length && startTimer) {
      setRemainingTime(remainingTime === null ? initSessionLength : remainingTime - 1);
    }
  }, 1000);

  useEffect(() => {
    if (remainingTime === 0) {
      audioController("play", AUDIO_FILES.test_bell);
      setExtendedTimer(true);
    }
  }, [remainingTime]);

  const startStream = () => {
    const { rule, levelID } = userProfile.selected_protocol;
    dispatch(getLiveSessionData(
      loggedInUserId,
      rule ? `${levelID}.${rule}` : levelID,
    ));
  };

  const startSession = () => {
    if (btDevice.status === 5) {
      setStartTimer(true);
    } else {
      toast.error("Muse device not connected.");
    }
  };

  useEffect(() => {
    dispatch(setActiveStream(gettingLiveSessionData ? "live-session" : "status"));
    return () => {
      if (sessionCompleted.current) dispatch(clearLiveSessionData());
      if (!signalCheck.some((sensor) => [0, 1, 2].includes(sensor))) {
        dispatch(setSignalCheckCompleted(Date.now()));
      }
    };
  }, []);

  useEffect(() => {
    if (startTimer && selectedProtocol && !gettingLiveSessionData) {
      dispatch(getDataStream(true, loggedInUserId, "live-session", () => startStream()));
    }
  }, [startTimer]);

  const saveSession = async ({ sessionData, protocol }) => {
    const summary = await createSession({ report: sessionData, protocol });
    if (summary.sessionData) {
      dispatch(stopStreamData(loggedInUserId));
      if (guideStatus === 0 && isCertified) {
        await dispatch(saveUserProfile({
          live_session_guide: 1,
        }));
      }
      if (!isCertified) {
        await updateProtocolCertificationQuery(
          userProfile.selected_protocol.levelID,
          userProfile.selected_protocol.family,
          userProfile.selected_protocol.rule,
          queryClient,
        );
      }
      navigate(`/app/progress/session-complete/${summary.sessionData.sessionId}`);
    } else {
      dispatch(cancelStreamData(loggedInUserId));
      toast.error("Sorry, session could not be saved.");
      navigate("/app/meditation");
    }
  };

  const stopSession = (save, nextUrl) => {
    clearInterval(interval);
    setIsLoading(true);
    audioController("stopAll", true);

    const {
      fullData, timeData, zoneData, distractionData, taps, secondsForAReminder, secondsForAReward,
    } = chartSessionData;

    const sessionData = {
      userId: loggedInUserId,
      meditationStreamId: liveSessionId,
      thresholds,
      protocol: userProfile.selected_protocol,
      length: remainingTime === null ? 0 : (initSessionLength - remainingTime) * 1000,
      secondsForAReminder,
      secondsForAReward,
      fullData,
      timeData,
      zoneData,
      distractionData,
      taps,
    };

    const protocol = {
      protocolName: selectedProtocol.name,
      ruleName: sessionRule,
      categoryName: protocolDetails.categoryName,
      family: selectedProtocol.family || null,
      description: protocolDetails.description,
      icon: selectedProtocol.icon,
      color: protocolDetails.color,
      protocolIds: userProfile.selected_protocol,
      level: sessionLevel,
      calibration: {
        mean: selectedProtocol.thresholds.mean,
        SD: selectedProtocol.thresholds.SD,
        weights: selectedProtocol.thresholds.weights,
        minMax: selectedProtocol.thresholds.minMax,
        thresholds: selectedProtocol.thresholds.thresholds,
        thresholdingFile: selectedProtocol.thresholds.thresholdingFile,
      },
      protocolRules: protocolDetails.protocolRules.map(
        (rule, i) => (selectedProtocol.thresholds.weights[i] === 0 ? null : rule),
      ),
    };

    dispatch(saveUserProfile({
      saved_session_difficulty: difficulty,
    }));

    if (save) {
      saveSession({ sessionData, protocol });
    } else {
      dispatch(setTimmer(remainingTime));
      dispatch(pauseSession({ navigate, nextUrl }, { sessionData, protocol, sessionError }));
    }
  };

  const completeSession = () => {
    sessionCompleted.current = true;
    dispatch(getDataStream(false));
    stopSession(true);
  };

  useEffect(() => {
    if (sessionError) {
      stopSession(false, "/app/meditation/pause-report");
      toast.error(`Session ${liveSessionData.length ? "Paused" : "Cancelled"}: ${sessionError}`);
    }
  }, [sessionError]);

  const { guide, nextStep } = useGuide(3);
  useEffect(() => {
    if (guideStatus === 1 && remainingSessionTime === null && !sessionCompleted.current) nextStep();
  }, [guideStatus]);
  useEffect(() => {
    if (guideStatus === 1 && !guide.active && guide.activeStep === 3
      && remainingSessionTime === null) {
      dispatch(saveUserProfile({
        live_session_guide: 2,
      }));
    }
  }, [guide.active]);

  const onAudioSettings = () => {
    stopSession(false, "/app/meditation/live-sound");
  };

  window.nativeBack = () => {
    stopSession(false, "/app/meditation/pause-report");
  };

  return (
    <div className="liveSessionPage">
      {isLoading && (
        <LoadingOverlay
          loadingText={sessionCompleted.current ? "Analyzing session..." : ""}
          darkMode
        />
      )}
      <div className="liveSessionPage__header-wrapper">
        <div className="liveSessionPage__header">
          <div
            role="presentation"
            className={`liveSessionPage__timer ${hiddenTimer ? "hidden" : ""} ${extendedTimer ? "extended" : ""}`}
            onClick={() => stopSession(false, "/app/meditation/pause-report")}
          >
            <div className="liveSessionPage__header-text">
              <span>+</span>
              {formatDuration(Math.abs(remainingTime ?? initSessionLength) * 1000)}
            </div>
            <div className="liveSessionPage__pause">
              <div className="liveSessionPage__pause-elem" />
              <div className="liveSessionPage__pause-elem" />
            </div>
          </div>
          <HeaderSensors />
          {isCertified && (
            <>
              <button type="button" className="guide-button" onClick={null}>
                <FontAwesomeIcon icon={faInfo} onClick={nextStep} />
              </button>
              <GuideWrapper
                guide={guide}
                step={1}
                content="liveSession"
                popoverPositionY="-28px"
                pointerPositionX="-80%"
                pointerPlacement="top"
                onClose={nextStep}
                scrollTop
              >
                <button
                  type="button"
                  className="audio-button"
                  onClick={onAudioSettings}
                >
                  <IconAudio />
                </button>
              </GuideWrapper>
            </>
          )}
          <AudioGuide
            track={isCertified ? "coach-meditation-session" : "coach-meditation-session-precert"}
            setHiddenElement={setHiddenTimer}
          />
        </div>
      </div>
      <Container>
        {isCertified && (
        <GuideWrapper
          guide={guide}
          step={2}
          content="liveSession"
          popoverPositionY="150px"
          pointerPlacement="top"
          onClose={nextStep}
        >
          <RewardSlider
            difficulty={difficulty}
            setDifficulty={setDifficulty}
            calibration={selectedProtocol?.thresholds?.thresholds[0]}
          />
        </GuideWrapper>
        )}
        <GuideWrapper
          guide={guide}
          step={3}
          content="liveSession"
          showContent={guide.activeStep === 2}
          popoverPositionY="-90px"
          pointerPlacement="bottom"
          onClose={nextStep}
        >
          <Card customClass={`graph-card ${isCertified ? "is-certified" : ""}`}>
            {extendedTimer ? (
              <div className="liveSessionPage__extended">
                <GenericButton
                  className="button--dark"
                  text="I'm Done"
                  onButtonClick={() => completeSession()}
                />
                <p className="text-xs text-center text-muted mt-2">*Feel free to continue to meditate and stay in session.</p>
              </div>
            ) : (
              <ProtocolSelectInfo
                customClass="liveSessionPage__protocol"
                protocol={protocolDetails}
                level={sessionLevel}
                rule={sessionRule}
                isActive={!startTimer}
              />
            )}

            <div className="liveSessionPage__chart">
              {!(guide?.active && guide?.activeStep !== 1) && (
                <div className={`liveSessionPage__taps ${startTimer && liveSessionData.length ? "" : "is-active"}`}>
                  <div className="liveSessionPage__taps-head">
                    <div className="layersChartTaps__taps-description">
                      <h3 className="text-uppercase">Tap Zone</h3>
                      <p className="text-sm">
                        Get in your meditation posture while gently holding your device.
                        As you practice, tap the screen:
                      </p>
                    </div>
                  </div>
                  <div className="liveSessionPage__taps-list">
                    <TapsList />
                  </div>
                  {!!liveSessionData.length && !isCertified && (
                  <div className="liveSessionPage__taps-icon">
                    <TapIcon />
                  </div>
                  )}
                </div>
              )}
              {guide?.active && guide?.activeStep === 3 && (
                <TapGuide onClick={() => nextStep()} />
              )}
              <SessionChart
                sessionData={chartSessionData}
                initialScale={2}
                onTap={() => onTap(initSessionLength - remainingTime)}
                showCurrentScore
                showTapInfo={guideStatus >= 2 && (guide.activeStep === 1 || !guide.active)}
                isCertified={isCertified}
                isColored
              />
            </div>
            {!liveSessionData.length && (
              <div className="liveSessionPage__chart-start">
                <div className="liveSessionPage__chart-start-text">
                  <p>
                    Get comfortable, and if you’d like to start a guided meditation from
                    another app, do that now.
                  </p>
                </div>
                <div className="liveSessionPage__chart-start-wrapper">
                  <GenericButton
                    className="button--dark"
                    text="I'm Ready to Meditate"
                    onButtonClick={startSession}
                    disabled={startTimer}
                  />
                  {startTimer && <Spinner />}
                </div>
              </div>
            )}
          </Card>
          {!isCertified && (
          <InfoSection
            icon={<Star />}
            customClass="mt-4"
          >
            <p className="text-muted">
              Sessions need to include
              {" "}
              <strong className="text-primary">
                at least
                {" "}
                {protocolCertification.requirements?.minTaps}
                {" "}
                taps
              </strong>
              {" "}
              to count towards your free month.
            </p>
          </InfoSection>
          )}
        </GuideWrapper>
      </Container>
    </div>
  );
}

export default LiveSessionPage;
