import { Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  GenericButton,
  InputText,
  SubHeader,
  BottomBarSticky,
  ErrorValidation,
} from "components";
import { updateUser } from "store/actions/authentication";
import { CHANGE_USER_FORM } from "constants/forms_data";
import useFormData from "hooks/useFormData";

function UserLoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user,
  );

  const {
    formData, formErrors, setFormValue, formValidate,
  } = useFormData(CHANGE_USER_FORM, {
    first_name: user?.first_name,
    last_name: user?.last_name,
    email: user?.email,
  });

  const onSave = async () => {
    const response = await dispatch(updateUser(formData));
    formValidate(response, () => {
      toast.success("New profile info saved.");
      navigate("/app/settings");
    });
  };

  return (
    <Container>
      <div className="settingsPage">
        <SubHeader
          title="Edit User"
          text="Change your user information."
        />
        <div className="userLogin__form">
          <Form autoComplete="on">
            {CHANGE_USER_FORM.map((input) => (
              <div key={input.key}>
                <InputText
                  inputType={input.inputType}
                  placeholder={input.placeholder}
                  autocomplete={input.autocomplete}
                  defaultValue={formData[input.key]}
                  onChangeValue={(e) => setFormValue(e.target.value, input.key)}
                  isValid={!formErrors[input.key]}
                  inputMode={input.key}
                />
                <ErrorValidation errors={formErrors[input.key]} />
              </div>
            ))}
          </Form>
        </div>
        <BottomBarSticky>
          <GenericButton className="button--success" text="Save" onButtonClick={onSave} />
        </BottomBarSticky>
      </div>
    </Container>
  );
}

export default UserLoginPage;
