import { memo } from "react";

function LayersChartWrapper({
  data, zone, analyze, thresholds, avgScore, graphTop, graphBottom, showCurrentScore,
}) {
  const getY = (score) => (-score / (-graphBottom - graphTop)) * 100;
  const getZone = (score) => {
    const [rewardThreshold, reminderThreshold] = thresholds;
    if (score <= rewardThreshold) return "reward";
    if (score >= reminderThreshold) return "reminder";
    return "neutral";
  };

  return (
    <div className="LayersChart__wrapper">
      <div className="LayersChart__header" />
      <div className="LayersChart__bars">
        { zone.reward >= 0 && (
          <div className={`LayersChart__bars-bar--reward ${analyze === "blue" ? "is-active" : ""}`} style={{ height: `${zone.reward}%` }}>
            <span>Reward Threshold</span>
          </div>
        )}
        { zone.neutral >= 0 && (
          <div className={`LayersChart__bars-bar--neutral ${analyze === "green" ? "is-active" : ""}`} style={{ height: `${zone.neutral}%` }} />
        )}
        { zone.reminder >= 0 && (
          <div className={`LayersChart__bars-bar--reminder ${analyze === "red" ? "is-active" : ""}`} style={{ height: `${zone.reminder}%` }} />
        )}
      </div>
      <div className="LayersChart__lines">
        <div className="LayersChart__lines-wrapper">
          <span>1000</span>
          {[...Array(10).keys()].reverse().map((value) => (
            <div
              key={value}
              className={`LayersChart__lines-line ${value === 5 ? "base" : ""}`}
            >
              <span>{value * 100}</span>
            </div>
          ))}
        </div>
        {!showCurrentScore ? (
          <>
            <div
              className="LayersChart__threshold is-reward"
              style={{ top: `${zone.reward}%` }}
            >
              {-thresholds[0]}
            </div>
            <div
              className="LayersChart__threshold is-reminder"
              style={{ bottom: `${zone.reminder}%` }}
            >
              {-thresholds[1]}
            </div>
            { avgScore && (
              <div
                className="LayersChart__threshold-score"
                style={{ bottom: `${getY(avgScore)}%` }}
              >
                <span>AVG</span>
              </div>
            )}
          </>
        ) : data.length > 0 && (
          <div
            className={`LayersChart__score is-${getZone(data.at(-1))}`}
            style={{ bottom: `${getY(data.at(-1))}%` }}
          >
            { -data.at(-1).toFixed(0) }
          </div>
        )}
      </div>
      <div className="LayersChart__footer" />
    </div>
  );
}

export default memo(LayersChartWrapper);
