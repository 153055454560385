import { useState } from "react";
import formValidator from "utils/form-validator";

export default function useFormData(initialForm, initialData) {
  const [formData, setFormData] = useState(
    initialForm.reduce((acc, cur) => ({ ...acc, [cur.key]: initialData?.[cur.key] || "" }), {}),
  );
  const [formErrors, setFormErrors] = useState(
    initialForm.reduce((acc, cur) => ({ ...acc, [cur.key]: null }), {}),
  );

  const setFormValue = (value, key) => {
    setFormData({ ...formData, [key]: value });
  };
  const formValidate = (data, onSuccess) => formValidator(
    data,
    setFormErrors,
    onSuccess,
  );
  const isError = (id) => (formErrors
    ? Object.keys(formErrors).includes(id)
    : null);

  return {
    formData,
    formErrors,
    setFormValue,
    setFormData,
    formValidate,
    isError,
  };
}
