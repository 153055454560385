import {
  useEffect, useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";

import { HeaderOnboarding, CustomTabs } from "components";
import { setOnboardingTab } from "store/actions/onBoarding";
import { saveUserProfile } from "store/actions/profile";
import AppsPage from "pages/App/Common/Apps/Apps";
import TechniquesPage from "pages/App/Common/Techniques/Techniques";
import TrainingPage from "pages/App/Common/Training/Training";
import BrainwavesPage from "pages/App/Common/Brainwaves/Brainwaves";
import GoalsPage from "pages/App/Common/Goals/Goals";
import SignalCheckPage from "pages/App/OnBoarding/SignalCheck/SignalCheck";
import BaselinePage from "pages/App/OnBoarding/BaselineCalibration/BaselineCalibration";
import DiagnosticPage from "pages/App/OnBoarding/Diagnostic/Diagnostic";
import ProtocolPage from "pages/App/OnBoarding/Protocol/Protocol";

import { ONBOARDING_TABS, ONBORADING_PROTOCOL_TABS } from "constants/onBoarding_tabs";
import ONBOARDING_AUDIO_GUIDES from "constants/onBoarding_audio_guides";

import "./OnboardingSteps.scss";
import { useSearchParams } from "react-router-dom";

function OnboardingSteps() {
  const dispatch = useDispatch();

  const tabs = ONBOARDING_TABS;
  const protocolTabs = ONBORADING_PROTOCOL_TABS;

  const completedSteps = useSelector(
    ({ profileReducer }) => profileReducer.userProfile?.onboarding_tab,
  );
  const activeTabIndex = useSelector(
    ({ onBoardingReducer }) => onBoardingReducer.activeTab,
  );
  const activeTab = tabs[activeTabIndex];
  const protocolStage = protocolTabs.includes(activeTab) ? protocolTabs.indexOf(activeTab) : 0;
  const [searchParams] = useSearchParams();

  const isCompleted = useMemo(
    () => (activeTabIndex >= 0 ? (activeTabIndex < completedSteps) : true),
    [activeTabIndex, completedSteps],
  );

  useEffect(() => {
    if (searchParams.size === 0) dispatch(setOnboardingTab(completedSteps));
  }, []);

  useEffect(() => {
    document.body.scrollTo({ top: 0, left: 0, behavior: "instant" });
    if (activeTabIndex !== null) {
      if (activeTabIndex > completedSteps && activeTabIndex <= 8) {
        dispatch(saveUserProfile({
          onboarding_tab: activeTabIndex,
        }));
      }
    }
  }, [activeTabIndex]);

  const onTabChange = (tab) => {
    const tabIndex = tabs.findIndex((tabName) => tabName === tab);
    dispatch(setOnboardingTab(tabIndex));
  };

  window.nativeBack = () => {
    if (activeTabIndex > 0) {
      dispatch(setOnboardingTab(activeTabIndex - 1));
    }
  };

  return (
    <>
      <HeaderOnboarding
        completedSteps={completedSteps + protocolStage + 1}
        allSteps={tabs.length}
        audioGuide={ONBOARDING_AUDIO_GUIDES[activeTabIndex]}
      />
      <Container>
        <div className="onBoarding__tabs-container">
          <CustomTabs
            tabs={tabs}
            defaultTabKey="Apps"
            onTabChange={onTabChange}
            activeTab={tabs[activeTabIndex]}
            completedSteps={completedSteps + protocolStage}
            activeScroll
          >
            {tabs[activeTabIndex] === "Apps" && (
              <AppsPage tabIndex={activeTabIndex} />
            )}
            {tabs[activeTabIndex] === "Techniques" && (
              <TechniquesPage tabIndex={activeTabIndex} />
            )}
            {tabs[activeTabIndex] === "Training" && (
              <TrainingPage tabIndex={activeTabIndex} />
            )}
            {tabs[activeTabIndex] === "Brainwaves" && (
              <BrainwavesPage tabIndex={activeTabIndex} />
            )}
            {tabs[activeTabIndex] === "Goals" && (
              <GoalsPage tabIndex={activeTabIndex} />
            )}
            {tabs[activeTabIndex] === "Signal" && (
              <SignalCheckPage tabIndex={activeTabIndex} isCompleted={isCompleted} />
            )}
            {tabs[activeTabIndex] === "Baseline" && (
              <BaselinePage tabIndex={activeTabIndex} isCompleted={isCompleted} />
            )}
            {tabs[activeTabIndex] === "Diagnostic" && (
              <DiagnosticPage tabIndex={activeTabIndex} isCompleted={isCompleted} />
            )}
            {activeTabIndex >= tabs.indexOf("Protocol") && (
              <ProtocolPage tabIndex={activeTabIndex} />
            )}
          </CustomTabs>
        </div>
      </Container>
    </>
  );
}

export default OnboardingSteps;
