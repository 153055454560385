import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTriangleExclamation, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

import "./InfoBox.scss";

export function InfoBox({
  type = "info", children, customClass = "", noIcon,
}) {
  const iconTypes = {
    info: faInfoCircle,
    warning: faTriangleExclamation,
    danger: faCircleExclamation,
  };

  return (
    <div className={`info-box is-${type} ${customClass}`}>
      {!noIcon && <FontAwesomeIcon icon={iconTypes[type]} />}
      <div className="info-box__content">
        {children}
      </div>
    </div>
  );
}

export default InfoBox;
