import { Container } from "react-bootstrap";

import { SubHeader } from "components";

import "./Communications.scss";

function CommunicationsPage() {
  return (
    <Container>
      <div className="communicationsPage">
        <SubHeader
          title="Contact"
          text="Be part of our community."
        />
        <p>
          If you have any problems or questions please
          reach out by mail or visit our forum.
        </p>
        <div className="communicationsPage__links">
          <a
            href="mailto:support@godeeper.com"
          >
            support@godeeper.com
          </a>
          <a
            href="https://alpha.godeeper.com/community/"
            target="_blank"
            rel="noreferrer"
          >
            GoDeeper Forum
          </a>
        </div>
      </div>
    </Container>
  );
}

export default CommunicationsPage;
