import { ReactComponent as SuccessIcon } from "assets/icons_signal_success.svg";
import "./CompletedStep.scss";

export function CompletedStep({ text }) {
  return (
    <div className="completedStep">
      <div className="completedStep__icon">
        <SuccessIcon />
      </div>
      <p>{text}</p>
    </div>
  );
}

export default CompletedStep;
