import { Card } from "components";

import "./SessionInfo.scss";

export function SessionInfo({ data, rulesList }) {
  const activeRules = data?.calibration.weights.map((value) => value !== 0);

  return data && rulesList && (
    <Card
      customClass="brainwaveAnalyzer__session-info"
      collapseHeader={<h3>Session Details</h3>}
      collapsed={false}
    >
      <div className="brainwaveAnalyzer__session-info-wrapper">
        <table className="brainwaveAnalyzer__session-info-table">
          <thead>
            <tr>
              <td aria-label="rule" />
              <td aria-label="weight" />
              <td colSpan={2}>Baseline</td>
              <td colSpan={2}>Z-Scores</td>
            </tr>
            <tr>
              <td aria-label="rule" />
              <td>Weight</td>
              <td>Mean</td>
              <td>SD</td>
              <td>Calibration</td>
              <td>Session</td>
            </tr>
          </thead>
          <tbody>
            {activeRules && activeRules.map((active, i) => active && (
            <tr key={rulesList[i].rule}>
              <td>
                {rulesList[i].descriptions?.name || rulesList[i].title}
              </td>
              <td>{data.calibration.weights[i]}</td>
              <td>{data.calibration.mean.rules[i]?.toFixed(2)}</td>
              <td>{data.calibration.sd.rules[i]?.toFixed(2)}</td>
              <td>{data.zscore.calibration.rules[i]?.toFixed(2)}</td>
              <td>{data.zscore.session.rules[i]?.toFixed(2)}</td>
            </tr>
            ))}
            <tr>
              <td>Average</td>
              <td aria-label="weight" />
              <td>{data.calibration.mean.avg?.toFixed(2)}</td>
              <td>{data.calibration.sd.avg?.toFixed(2)}</td>
              <td>{data.zscore.calibration.avg?.toFixed(2)}</td>
              <td>{data.zscore.session.avg?.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export default SessionInfo;
