/* eslint-disable camelcase */
import { Container } from "react-bootstrap";
import { useQueryClient, useQuery, useMutation } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { SubHeader, Card, Spinner } from "components";
import {
  getCoachesPersmissions, updateCoachesPersmissions,
} from "api/services/settings";

import "./CoachPermissions.scss";

function CoachPermissionsPage() {
  const queryClient = useQueryClient();
  const coachesQuery = useQuery({
    queryKey: ["coaches-permissions"],
    queryFn: () => getCoachesPersmissions(),
  });

  const coachesUpdate = useMutation({
    mutationFn: (coaches) => updateCoachesPersmissions(coaches),
    onSuccess: (update) => { queryClient.setQueryData(["coaches-permissions"], update); },
  });

  const updateCoach = (value, updatedCoach) => {
    const updated = _.cloneDeep(coachesQuery.data);
    updated.find(({ coach }) => coach === updatedCoach).is_authorized = value;
    coachesUpdate.mutate(updated);
  };

  return (
    <Container>
      <div className="coachPermissionPage">
        <SubHeader
          title="Coach Permissions"
          text="Manage permission to see your data."
        />
      </div>
      <div className="coachPermissionPage__authorized-coaches-section">
        {coachesQuery.isPending ? (
          <Spinner />
        ) : !coachesQuery.isError && (
          coachesQuery.data.map(({ coach, is_authorized, full_name }) => (
            <div
              key={coach}
              className={`coachPermissionPage__list-item ${
                is_authorized ? "is--auth" : ""
              }`}
            >
              <div className="coachPermissionPage__item-email">
                {full_name}
              </div>
              <button
                type="button"
                className={`coachPermissionPage__item-btn 
                    ${!is_authorized ? "is--approve" : "is--remove"}`}
                onClick={() => updateCoach(!is_authorized, coach)}
              >
                <FontAwesomeIcon icon={!is_authorized ? faCheck : faTimes} />
              </button>
            </div>
          ))
        )}
      </div>
      <div className="coachPermissionPage__card-section">
        <Card customClass="card-text">
          <p>
            Approve the email address of the coach you would like to authorize
            to see your data.
          </p>
          <p>
            You can deauthorized at any time to block a coach from seeing any
            of your past, present, or future data.
          </p>
          <p>
            Coaches see your name, email, EEG data, Progress charts and
            notes. They do not see your physical address or credit card
            information.
          </p>
        </Card>
      </div>
    </Container>
  );
}

export default CoachPermissionsPage;
