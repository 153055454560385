import { useEffect } from "react";
import { useSelector } from "react-redux";
import { museController } from "utils/native-controllers";

export default function useMuse(demountStopRef) {
  const { activeStream, btDevice } = useSelector(
    ({ sessionReducer }) => sessionReducer,
  );

  useEffect(() => {
    museController(activeStream && activeStream !== "status" && btDevice.status === 5 ? "start" : "stop");
  }, [activeStream, btDevice]);

  useEffect(() => () => {
    if (demountStopRef?.current ?? true) museController("stop");
  }, []);
}
