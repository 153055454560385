import { useState, useRef, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { GenericButton } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Scrollbar from "react-perfect-scrollbar-z";
import "react-perfect-scrollbar-z/build/styles.css";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getCoachTabList, getCoachTabData, createCoachTab, deleteCoachTab, modifyCoachTab, manageCoachTabs,
} from "api/services/coach";
import TabsEdit from "./TabsEdit";

import "./CoachTabs.scss";

function CoachTabs({
  tableName,
  tabsGroup,
  activeFilter,
  columnVisibility,
  temporaryPin,
  getPinnedColumn,
  onSave,
  onTabChange,
  isTableReady,
  isTabSwitched,
}) {
  const refScroll = useRef(null);
  const queryClient = useQueryClient();

  const storage = JSON.parse(sessionStorage.getItem(tableName));

  const [activeTab, setActiveTab] = useState(storage?.tab || "standard");
  const [showEdit, setShowEdit] = useState(false);
  const [menuState, setMenuState] = useState({ open: false, position: 0 });
  const [isPresetChanged, setIsPresetChanged] = useState(false);

  const { data: tabList } = useQuery({
    queryKey: ["coach-tabs", tabsGroup],
    queryFn: () => getCoachTabList(tabsGroup),
  });

  const { isSuccess, data: tabData } = useQuery({
    queryKey: ["coach-tab-filter", activeTab, tabsGroup],
    queryFn: () => activeTab !== "standard" && getCoachTabData(activeTab),
    enabled: isTableReady,
  });

  useEffect(() => {
    if (isSuccess) {
      onTabChange(
        activeTab === "standard" ? JSON.parse(sessionStorage.getItem(tableName)) : tabData.configuration,
        activeTab,
      );
    }
  }, [isSuccess, tabData]);

  const manageTabs = useMutation({
    mutationFn: (tabs) => manageCoachTabs(tabsGroup, { details: tabs }),
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["coach-tabs", tabsGroup],
        () => response.data,
      );
      setShowEdit(false);
      setMenuState({ ...menuState, open: false });
      setActiveTab("standard");
    },
  });

  const createNewTab = useMutation({
    mutationFn: (payload) => createCoachTab(
      payload || {
        title: "New tab",
        group: tabsGroup,
        is_visible: true,
        color: "",
      },
    ),
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["coach-tabs", tabsGroup],
        (stale) => [...stale, response],
      );
      setMenuState({ ...menuState, open: false });
      setActiveTab(response.id);
      setTimeout(() => {
        refScroll.current.element.scrollTo(refScroll.current.contentWidth, 0);
      }, 100);
    },
  });

  const deleteTab = useMutation({
    mutationFn: (id) => deleteCoachTab(id),
    onSuccess: (_data, tabId) => {
      queryClient.setQueryData(
        ["coach-tabs", tabsGroup],
        () => tabList.filter((item) => item.id !== tabId),
      );
      setMenuState({ ...menuState, open: false });
      setActiveTab("standard");
    },
  });

  const modifyTab = useMutation({
    mutationFn: ({ id, payload }) => modifyCoachTab(id, payload),
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["coach-tabs", tabsGroup],
        () => tabList.map((item) => (item.id === response.id ? response : item)),
      );
      setMenuState({ ...menuState, open: false });
    },
  });

  const savePreset = () => {
    queryClient.setQueryData(
      ["coach-tabs", tabsGroup],
      () => tabList.map((item) => (item.id === activeTab
        ? { ...item, configuration: activeFilter } : item)),
    );
    onSave(tabData);
    setIsPresetChanged(false);
  };

  const menuOptions = [
    {
      key: 1,
      name: "Duplicate",
      onClick: () => createNewTab.mutate({ ...tabData, title: `${tabData.title} (copy)` }),
    },
    {
      key: 2,
      name: "Hide",
      onClick: (id) => {
        modifyTab.mutate({ id, payload: { ...tabData, is_visible: false } });
        setActiveTab("standard");
      },
    },
    {
      key: 3,
      name: "Delete",
      onClick: (id) => deleteTab.mutate(id),
    },
  ];

  useEffect(() => {
    if (isTabSwitched) return;
    if (activeTab === "standard") {
      sessionStorage.setItem(tableName, JSON.stringify(
        {
          filters: activeFilter.filters,
          sortBy: activeFilter.sortBy,
          hiddenColumns: columnVisibility
              && Object.keys(columnVisibility).filter((key) => !columnVisibility[key]),
          pinnedColumn: temporaryPin,
          pagination: activeFilter.pagination,
          tab: activeTab,
        },
      ));
    } else {
      setIsPresetChanged(true);
    }
  }, [activeFilter, columnVisibility, temporaryPin]);

  useEffect(() => setIsPresetChanged(false), [activeTab]);

  return tabsGroup && (
    <div className="coachTabs">
      <Scrollbar
        className="coachTabs__group"
        refScroll={refScroll}
        effectData={tabList}
        style={{ pointerEvents: `${menuState.open ? "none" : "auto"}` }}
        always
      >
        <div
          role="presentation"
          className={`coachTabs__group-tab ${activeTab === "standard" ? "active" : ""}`}
          onClick={() => setActiveTab("standard")}
        >
          <div className="coachTabs__group-tab-title">
            Standard
          </div>
        </div>
        {tabList && tabList.map((tab) => tab.is_visible && (
          <div
            key={tab.id}
            role="presentation"
            className={`coachTabs__group-tab ${activeTab === tab.id ? "active" : ""}`}
            style={{ borderColor: tab.color || "transparent" }}
            onClick={() => setActiveTab(tab.id)}
          >
            <div className="coachTabs__group-tab-title">
              {tab.title}
            </div>
            <FontAwesomeIcon
              icon={faAngleDown}
              className="coachTabs__group-tab-menuIcon"
              onClick={(e) => setMenuState({
                open: !menuState.open,
                position:
                  e.currentTarget.parentElement.offsetLeft - refScroll.current.scrollbarXLeft,
              })}
            />
          </div>
        ))}
        <div className="coachTabs__plus-btn">
          <FontAwesomeIcon
            icon={faPlus}
            onClick={() => createNewTab.mutate()}
          />
        </div>
      </Scrollbar>
      <div className="coachTabs__actions">
        <GenericButton
          text=""
          className="coachTabs__actions-edit-btn"
          iconElement={<FontAwesomeIcon icon={faPen} />}
          onButtonClick={() => setShowEdit(!showEdit)}
        />
        <div className="coachTabs__actions-save">
          <GenericButton
            text="Save"
            className={`coachTabs__actions-save-btn ${activeTab === "standard" || isPresetChanged ? "button--success" : ""}`}
            onButtonClick={() => (activeTab === "standard"
              ? createNewTab.mutate({
                title: "New tab",
                group: tabsGroup,
                is_visible: true,
                configuration: {
                  filters: activeFilter.filters,
                  sortBy: activeFilter.sortBy,
                  hiddenColumns: columnVisibility
                    && Object.keys(columnVisibility).filter((key) => !columnVisibility[key]),
                  pinnedColumn: getPinnedColumn(),
                },
              })
              : savePreset())}
          />
          <p className={`coachTabs__actions-save-info text-muted ${isPresetChanged ? "warning" : ""}`}>
            {activeTab === "standard" ? "Temporary Preset" : ""}
            {(activeTab !== "standard" && isPresetChanged) ? "Unsaved Preset" : ""}
          </p>
        </div>
      </div>
      <TabsEdit
        tabList={tabList}
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        onSave={(tabs) => manageTabs.mutate(tabs)}
      />
      <Dropdown
        className="tabMenu"
        style={{ left: menuState.position }}
        autoClose="outside"
        show={menuState.open}
      >
        {menuState.open && (
          <div
            role="presentation"
            className="dropdown-backdrop"
            onClick={() => setMenuState({ ...menuState, open: false })}
          />
        )}
        <Dropdown.Menu className="defaultMenu menu">
          {menuOptions.map((option) => (
            <Dropdown.Item
              key={option.key}
              className="defaultMenu item"
              onClick={() => option.onClick(activeTab)}
            >
              {option.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default CoachTabs;
