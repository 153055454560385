import _ from "lodash";
import * as d3 from "d3";

export default class ChartsBuildModal {
  constructor(props) {
    this.element = d3.select(props.element);
    this.width = props.width || 0;
    this.height = props.height || 0;
    this.classes = {
      svgContainer: "chart-container__svg",
      ..._.get(props, "classes", {}),
    };
  }

  static hideElement(element) {
    element.style("visibility", "hidden");
  }

  static showElement(element) {
    element.style("visibility", "visible");
  }

  static createSvgElement(svgElement, {
    groupClass,
    pathClass,
    circleClass,
    textClass,
    textTitle,
  }) {
    const element = svgElement.append("g").attr("class", groupClass);
    element
      .append("path")
      .attr("class", pathClass);
    element
      .append("circle")
      .attr("class", circleClass);
    element
      .append("text")
      .attr("class", textClass)
      .text(textTitle);
    return element;
  }

  getYScaleLinear(data) {
    return d3.scaleLinear().domain(data).range([0, this.height]);
  }

  getXScaleLinear(data) {
    return d3.scaleLinear().domain(data).range([0, this.width]);
  }

  getXScaleBand(data, range = [0, this.width]) {
    return d3.scaleBand().domain(data).range(range).padding(0.3);
  }

  createSvgRef(callback) {
    if (!this.element.select(`.${this.classes.svgContainer}`).size()) {
      const svgElement = d3.create("svg").attr("class", this.classes.svgContainer);
      svgElement.append("g").attr("class", "x-axis-chart");
      svgElement.append("g").attr("class", "y-axis-chart");
      svgElement.append("text").attr("class", "value");
      svgElement.append("defs");
      svgElement
        .selectAll("defs")
        .append("linearGradient")
        .attr("gradientTransform", "rotate(90)")
        .attr("id", "mainGradient");
      svgElement
        .selectAll("defs")
        .select("linearGradient")
        .append("stop")
        .attr("class", "stop-left")
        .attr("offset", "0%");
      svgElement
        .selectAll("defs")
        .select("linearGradient")
        .append("stop")
        .attr("class", "stop-right")
        .attr("offset", "100%");
      svgElement
        .append("g")
        .attr("class", "grid");
      svgElement
        .append("g")
        .attr("class", "grid-2");
      svgElement
        .append("text")
        .attr("class", "grid-title");
      svgElement
        .append("path")
        .attr("class", "line")
        .attr("id", "svgline");
      if (_.isFunction(callback)) {
        callback(svgElement);
      }
      this.element.append(() => svgElement.node());
      return svgElement;
    }
    return this.element.select(`.${this.classes.svgContainer}`);
  }
}
