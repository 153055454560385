import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function ButtonWithIcon({
  text, size = "md", onClick, onBtnClick, icon, customClass,
}) {
  return (
    <div
      className={`select select--${size} select--active ${customClass}`}
      onClick={onBtnClick}
      role="presentation"
    >
      <div className="select-text">{text}</div>
      <div
        className="select-check--default"
        role="presentation"
        onClick={onClick}
      >
        {icon && (<FontAwesomeIcon icon={icon} />)}
      </div>
    </div>
  );
}

export default ButtonWithIcon;
