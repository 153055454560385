import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt as faTrash } from "@fortawesome/fontawesome-free-regular";
import { InputText } from "components";
import { CirclePicker } from "react-color";

import "./DragList.scss";

function Element({
  element, index, list, setList,
}) {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [editTitle, setEditTitle] = useState(false);

  const defaultColor = "#f2f5f9";
  const colors = [defaultColor, "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548"];

  const updateTab = (type, value) => {
    setList(list.map((item) => {
      if (item.id === element.id) return { ...item, [type]: value };
      return item;
    }));
  };

  return (
    <>
      {(showColorPicker || editTitle) && (
      <div
        className="backdrop"
        onClick={() => setShowColorPicker(false)}
        role="presentation"
      />
      )}
      <Draggable draggableId={element.id} index={index}>
        {(provided) => (
          <div
            className={`dragList__element 
              ${(showColorPicker || editTitle) ? "focus" : ""}
              ${!list[index].is_visible ? "hidden" : ""}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {editTitle
              ? (
                <InputText
                  defaultValue={list[index].title}
                  onBlurValue={() => {
                    if (!list[index].title) updateTab("title", `View ${element.id}`);
                    setEditTitle(false);
                  }}
                  onChangeValue={(e) => updateTab("title", e.target.value)}
                  autoFocus
                />
              )
              : list[index].title}
            <div className="dragList__element-actions">
              <div className="color" style={{ zIndex: showColorPicker ? 1 : 0 }}>
                <div
                  role="presentation"
                  style={{ background: list[index].color }}
                  className="color-current"
                  onClick={() => setShowColorPicker(!showColorPicker)}
                />
                {showColorPicker && (
                <CirclePicker
                  color={list[index]?.color || ""}
                  colors={colors}
                  width="180px"
                  circleSize={22}
                  circleSpacing={6}
                  onChangeComplete={(e) => updateTab("color", e.hex === defaultColor ? "" : e.hex)}
                />
                )}
              </div>
              <FontAwesomeIcon
                icon={faEdit}
                onClick={() => setEditTitle(!editTitle)}
              />
              <FontAwesomeIcon
                icon={list[index].isVisible ? faEye : faEyeSlash}
                onClick={() => updateTab("is_visible", !element.is_visible)}
              />
              <FontAwesomeIcon
                icon={faTrash}
                className="delete"
                onClick={() => setList(list.filter((item) => item.id !== element.id))}
              />
            </div>
          </div>
        )}
      </Draggable>
    </>
  );
}

const Elements = React.memo(({ list, setList }) => list.map((element, index) => (
  <Element element={element} index={index} key={element.id} list={list} setList={setList} />
)));

function DragList({ tabs, setTabs }) {
  const tabList = tabs.map((tab) => ({
    ...tab,
    id: tab.id.toString(),
  }));
  const [list, setList] = useState(tabList);

  const reorder = (listData, startIndex, endIndex) => {
    const result = Array.from(listData);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const elements = reorder(
      list,
      result.source.index,
      result.destination.index,
    );
    setList(elements);
  };

  useEffect(() => setTabs(
    list.map((item) => ({
      ...item,
      id: +item.id,
    })),
  ), [list]);

  return (
    <div className="dragList">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Elements list={list} setList={setList} />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default DragList;
