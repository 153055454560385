import {
  useMemo, useEffect, useState, useCallback, useRef,
} from "react";
import { Container } from "react-bootstrap";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";

import { getClient, getClientSessionDetails } from "api/services/coach";
import { getBrainwaveReport } from "api/services/reporting";
import {
  PercentageZones, SessionScores, Spinner, SessionProgressScores,
  ProtocolSelectContent, CoachNav, Breadcrumbs, Loader, EmptyData, GenericButton,
} from "components";

import { getRulesContent, getDepthData } from "features/BrainwaveInterpretation/helpers/helper";
import { DepthBarChart } from "features/BrainwaveInterpretation/DepthBarChart/DepthBarChart";
import InfoRules from "features/BrainwaveInterpretation/BrainwaveInfo/InfoRules";
import { SessionChart, SessionBadges, JournalForm } from "features";
import useProtocol from "hooks/useProtocol";
import { useMediaQuery } from "react-responsive";
import { formatDuration } from "utils/time-format";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockRotateLeft, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import appIcon from "assets/icon.png";

import "./SessionSummary.scss";

function SessionSummaryPage() {
  const navigate = useNavigate();
  const { clientId, sessionId } = useParams();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const [activeChartType, setActiveChartType] = useState("timeline");
  const scrollSync = useRef({ scrollX: 0, wrappers: [] });

  const setScrollSync = useCallback((i) => ({
    setWrapper: (wrapperRef) => { scrollSync.current.wrappers[i] = wrapperRef; },
    setScrollX: (x) => { scrollSync.current.scrollX = x; },
  }), []);

  const client = useQuery({
    queryKey: ["client", clientId],
    queryFn: () => getClient(clientId),
  });
  const { isPending, isError, data: summary } = useQuery({
    queryKey: ["session-summary", sessionId],
    queryFn: () => getClientSessionDetails(sessionId),
  });
  const brainwaveReport = useQuery({
    queryKey: ["session-brainwaves", sessionId],
    queryFn: () => getBrainwaveReport(sessionId),
    staleTime: Infinity,
  });

  useEffect(() => {
    if (client.isError || isError) navigate("*", { replace: true });
  }, [client.isError, isError]);

  useEffect(() => {
    if (brainwaveReport.isSuccess) {
      if (!brainwaveReport.data.data?.details || brainwaveReport.data.data?.rules.length === 0) {
        toast.error("Brainwave data could not be generated.");
      }
    }
  }, [brainwaveReport.isSuccess]);

  const sessionProtocol = useProtocol(
    summary?.sessionData?.protocol.categoryID,
    summary?.sessionData?.protocol.protocolID,
    summary?.protocolDetails,
  );

  const sessionLevel = useMemo(() => {
    const sessionLevelId = summary?.sessionData.protocol.levelID;
    return sessionLevelId?.split("/")[1];
  }, [summary]);

  const sessionRule = useMemo(() => {
    const ruleNo = summary?.sessionData.protocol.rule
      || summary?.protocolDetails?.protocolIds?.rule;
    if (ruleNo && sessionProtocol) {
      return sessionProtocol.protocolRules.find(
        (rule) => rule?.rule === ruleNo,
      );
    }
    return null;
  }, [summary, sessionProtocol]);

  const rulesContent = useMemo(() => {
    const rules = sessionProtocol?.protocolRules;
    const scores = brainwaveReport.data?.data?.general.zscore.session.rules;
    if (rules && scores) {
      return getRulesContent(scores, rules, sessionLevel, sessionRule);
    }
    return null;
  }, [brainwaveReport.data, sessionProtocol?.protocolRules, sessionRule]);

  const depthData = useMemo(
    () => getDepthData(summary?.sessionData.fullData),
    [summary?.sessionData.fullData],
  );

  return (
    <div className="coach-panel">
      <CoachNav />
      <div className="sessionSummaryPage">
        {!isPending && sessionProtocol ? (
          <>
            <Container>
              <div className="page-title">
                <h1>Session Summary</h1>
                <Breadcrumbs
                  active="Session Summary"
                  urls={[
                    ["/coach/clients", "Clients"],
                    [`/coach/clients/${clientId}/progress`, "Progress"],
                  ]}
                />
              </div>
              <div className="sessionSummaryPage__header">
                <ProtocolSelectContent
                  protocol={sessionProtocol}
                  level={sessionLevel}
                  rule={sessionRule?.descriptions?.name}
                />
                <div className="sessionSummaryPage__header-client">
                  <p className="text-muted">Client Name</p>
                  <Link
                    className="h2"
                    to={`/coach/clients/${clientId}/progress`}
                  >
                    {client.data?.first_name}
                    {" "}
                    {client.data?.last_name}
                  </Link>
                  <div className="sessionSummaryPage__header-date">
                    {`${format(new Date(summary.sessionData.date), "MMMM do, yyyy")}`}
                    &nbsp;-&nbsp;
                    {`${format(new Date(summary.sessionData.date), "hh:mma")}`}
                    &nbsp;-&nbsp;
                    <strong>{formatDuration(summary.sessionData.length, true)}</strong>
                  </div>
                </div>
              </div>
            </Container>
            <div className="sessionSummaryPage__wrapper">
              <Container>
                <div className="sessionSummaryPage__wrapper-content">
                  <SessionProgressScores report={summary.report} />
                  <div className="sessionSummaryPage__chart">
                    <div className="brainwaveGraphs__filters-row">
                      <h2>Depth Score Graph</h2>
                      <div className="button-switch">
                        <GenericButton
                          className={activeChartType === "timeline" ? "btn--active" : ""}
                          text="Timeline"
                          onButtonClick={() => setActiveChartType("timeline")}
                        />
                        <GenericButton
                          className={activeChartType === "mean" ? "btn--active" : ""}
                          text="Average"
                          onButtonClick={() => setActiveChartType("mean")}
                        />
                      </div>
                    </div>
                    {activeChartType === "timeline" && (
                      <SessionChart
                        sessionData={summary.sessionData}
                        depthAvg={summary.report.depthAvg}
                        initialScale={!isMobile ? 1 : 0}
                        scrollSync={scrollSync}
                        setScrollSync={setScrollSync(0)}
                        showMarks
                      />
                    )}
                    {activeChartType === "mean" && (
                    <>
                      <DepthBarChart chartData={depthData.barData} />
                      <div className="brainwaveGraphs__depth-footer">
                        <div className="brainwaveGraphs__depth-avg">
                          <span>AVG</span>
                          {" "}
                          <strong>{depthData.avg ? depthData.avg.toFixed(0) : "-"}</strong>
                        </div>
                      </div>
                    </>
                    )}
                  </div>
                </div>
                <div className="sessionSummaryPage__wrapper-footer">
                  <h2>Scores</h2>
                  <div className="sessionSummaryPage__scores">
                    <SessionScores
                      points={summary.report.points}
                      rewards={summary.report.rewards}
                      reminders={summary.report.reminders}
                      length={summary.sessionData.length}
                    />
                    <Link
                      className="sessionSummaryPage__btn-brainwave"
                      to={`/coach/clients/${clientId}/brainwave-analyzer/${sessionId}`}
                    >
                      <div className="button-icon-wrapper">
                        <img
                          src={appIcon}
                          alt="GoDeeper"
                        />
                      </div>
                      <span>Explore This Session in the Brainwave Analyzer</span>
                      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                    </Link>
                  </div>
                </div>
              </Container>
            </div>
            <Container>
              <div className="sessionSummaryPage__reports">
                <div className="sessionSummaryPage__reports-metrics">
                  {brainwaveReport.isPending ? (
                    <div className="calibrationSession__loading">
                      <div className="loader-wrapper">
                        <Loader />
                      </div>
                      <h3>Preparing metrics data</h3>
                      <p className="text-muted">Please wait for a few seconds...</p>
                    </div>
                  ) : !rulesContent && (
                  <EmptyData
                    icon={faClockRotateLeft}
                    title="Report generation failed"
                    text="Try again later."
                  />
                  )}
                  {brainwaveReport.isSuccess && rulesContent && (
                  <InfoRules
                    protocolDetails={sessionProtocol}
                    rulesContent={rulesContent}
                  />
                  )}
                </div>
              </div>
              <div className="sessionSummaryPage__section">
                <div className="card">
                  <PercentageZones time={summary.report.zoneCounter} />
                </div>
                <div className="card">
                  <div className="sessionSummaryPage__journal">
                    <h2>Journal Entry</h2>
                    <JournalForm journalEntry={summary.journalEntry} isEditable={false} />
                  </div>
                </div>
              </div>
              <div className="sessionSummaryPage__badges">
                <SessionBadges
                  fullData={summary.sessionData.fullData}
                  length={summary.sessionData.length}
                  thresholds={summary.sessionData.thresholds}
                  report={summary.report}
                  isCertified
                />
              </div>

            </Container>
          </>
        )
          : <Spinner />}
      </div>
    </div>
  );
}

export default SessionSummaryPage;
