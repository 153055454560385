import { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Container, Modal } from "react-bootstrap";
import { CoachNav, GenericButton, CustomTabs } from "components";
import { InfoBox } from "components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faList } from "@fortawesome/free-solid-svg-icons";
import { CoachTable } from "features";
import {
  getRecalculationList, getRecalculationResults, createRecalculation, getTestBatchesExists,
} from "api/services/coach";

import BatchDetailsModal from "features/BatchDetailsModal/BatchDetailsModal";

import "pages/Coach/Dashboard/Dashboard.scss";

function Recalculations() {
  const queryClient = useQueryClient();

  const [batchId, setBatchId] = useState(null);
  const [activeFilter, setActiveFilter] = useState({});
  const [activeTable, setActiveTable] = useState("Sessions");
  const [totalRecords, setTotalRecords] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [showModal, setShowModal] = useState({ creator: false, details: false });

  const { data: isBatchesProcessing } = useQuery({
    queryKey: ["batches-existance"],
    queryFn: () => getTestBatchesExists(),
    refetchOnMount: "always",
  });

  const toggleModal = (type, id) => {
    const newState = !type ? { details: false } : { [type]: !showModal[type] };
    if (type === "creator" && !newState.creator) setAgreement(false);
    if (id) setBatchId(id);
    setShowModal((prevState) => ({ ...prevState, ...newState }));
  };

  const getSessions = async (filter) => {
    setActiveFilter(filter);
    return getRecalculationList(filter);
  };

  const sessionsList = {
    title: "All Sessions",
    tableName: "sessions-list",
    tableType: 8,
    getdata: (filter) => getSessions(filter),
    callbackTotal: setTotalRecords,
    stickyColumn: {
      title: "Summary",
      id: "summary",
      data: "sessionId",
      element: {
        icon: <FontAwesomeIcon icon={faChartLine} />,
        path: (clientId, sessionId) => `/coach/clients/${clientId}/session-summary/${sessionId}`,
      },
      disabled: { key: "session_type", value: "Test Session" },
    },
  };

  const resultsTable = {
    title: "Recalculations",
    tableName: "recalculations",
    tableType: 9,
    getdata: (filter) => getRecalculationResults(filter),
    stickyColumn: {
      title: "Details",
      id: "details",
      data: "id",
      element: {
        icon: <FontAwesomeIcon icon={faList} />,
        action: (id) => toggleModal("details", id),
      },
    },
  };

  const processingStatus = useMutation({
    mutationFn: () => getTestBatchesExists(),
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["batches-existance"],
        () => response,
      );
    },
  });

  const startRecalculation = async () => {
    await createRecalculation(activeFilter);
    setTimeout(() => {
      processingStatus.mutate();
      toggleModal("creator");
    }, 1000);
  };

  return (
    <div className="coach-panel">
      <CoachNav />
      <div className="dashboard">
        <Container className="dashboard__header">
          <h1>Dashboard</h1>
          <div className="dashboard__tabs">
            <CustomTabs
              tabs={["Sessions", "Recalculations"]}
              defaultTabKey={activeTable}
              onTabChange={(tab) => setActiveTable(tab)}
              activeTab={activeTable}
            />
          </div>
        </Container>
        {activeTable === "Sessions" && (
          <Container>
            <div className="dashboard__actions">
              <GenericButton
                className="dashboard__actions-button text-sm"
                text={!isBatchesProcessing ? "Recalculate" : "Recalculation in progress..."}
                disabled={isBatchesProcessing}
                onButtonClick={() => toggleModal("creator")}
              />
            </div>
          </Container>
        )}
        {activeTable === "Recalculations" && (
          <>
            <CoachTable {...resultsTable} />
            <BatchDetailsModal
              showModal={showModal.details}
              setShowModal={toggleModal}
              batchId={batchId}
              batchNote={false}
            />
          </>
        )}
        {activeTable === "Sessions" && (
          <>
            <CoachTable {...sessionsList} />
            <Modal
              animation
              show={showModal.creator}
              dialogClassName="dialog"
              className="coach-modal"
              onHide={() => toggleModal("creator")}
            >
              <Modal.Header closeButton>
                <Modal.Title>Start Recalculation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="text-center">
                  <h2>
                    Number of sessions:
                    {" "}
                    {totalRecords}
                  </h2>
                  <p className="text-muted">Based on active filters</p>
                  <br />
                  <p className="text-danger">
                    You are going to recalculate existed sessions.
                    <br />
                    This process is irreversible!
                  </p>
                  <p>
                    Your scheduled Recalculations will be visible on Recalculations tab.
                    <br />
                    Processing time depends on chosen number of sessions.
                  </p>
                  <p>We will inform you by e-mail when the recalculations are completed.</p>
                </div>
                {totalRecords > 10 && (
                <InfoBox type="danger" customClass="justify-content-center mt-4">
                  <p>
                    <strong>You chose over 10 sessions for tests!</strong>
                    <br />
                    Processing time will be much longer.
                  </p>
                  <div className="alertBox__checkbox">
                    <input
                      type="checkbox"
                      checked={agreement}
                      onChange={(e) => setAgreement(e.target.checked)}
                    />
                    <span>Understand and proceed</span>
                  </div>
                </InfoBox>
                )}
              </Modal.Body>
              <Modal.Footer>
                <GenericButton
                  text="Cancel"
                  onButtonClick={() => toggleModal("creator")}
                />
                <GenericButton
                  text="Recalculate"
                  className="button--success"
                  onButtonClick={startRecalculation}
                  disabled={totalRecords > 10 && !agreement}
                />
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
    </div>
  );
}

export default Recalculations;
