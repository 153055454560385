import mqtt from "mqtt";
import { toast } from "react-toastify";
import { clientApi } from "api/axios";
import * as urls from "api/urls";
import {
  isSignalStream, isDataStream, isDistractionStream,
} from "utils/stream-type-checker";
import errorHandler from "utils/error-handler";
import { createReport } from "api/services/reporting";

export const SET_APP_ACTIVE = "SET_APP_ACTIVE";
export const SET_BT_DEVICE = "SET_BT_DEVICE";

export const SET_SESSION_LENGTH = "SET_SESSION_LENGTH";

export const SET_STREAM_ERROR = "SET_STREAM_ERROR";
export const SET_ACTIVE_STREAM = "SET_ACTIVE_STREAM";

export const GET_SIGNAL_CHECK = "GET_SIGNAL_CHECK";
export const GET_SIGNAL_CHECK_ERROR = "GET_SIGNAL_CHECK_ERROR";
export const GET_SIGNAL_CHECK_SUCCESS = "GET_SIGNAL_CHECK_SUCCESS";

export const SET_SIGNAL_CHECK_COMPLETED = "SET_SIGNAL_CHECK_COMPLETED";

export const GET_LIVE_SESSION_DATA = "GET_LIVE_SESSION_DATA";
export const GET_LIVE_SESSION_DATA_ERROR = "GET_LIVE_SESSION_DATA_ERROR";
export const GET_LIVE_SESSION_DATA_SUCCESS = "GET_LIVE_SESSION_DATA_SUCCESS";

export const GET_SIGNAL_STREAM_SUCCESS = "GET_SIGNAL_STREAM_SUCCESS";
export const GET_SESSION_STREAM_SUCCESS = "GET_SESSION_STREAM_SUCCESS";
export const GET_BT_STREAM_SUCCESS = "GET_BT_STREAM_SUCCESS";
export const GET_BATTERY_STREAM_SUCCESS = "GET_BATTERY_STREAM_SUCCESS";
export const GET_DISTRACTION_STREAM_SUCCESS = "GET_DISTRACTION_STREAM_SUCCESS";

export const STOP_STREAM_DATA = "STOP_STREAM_DATA";
export const STOP_STREAM_DATA_ERROR = "STOP_STREAM_DATA_ERROR";
export const STOP_STREAM_DATA_SUCCESS = "STOP_STREAM_DATA_SUCCESS";

export const CANCEL_STREAM_DATA = "CANCEL_STREAM_DATA";
export const CANCEL_STREAM_DATA_ERROR = "CANCEL_STREAM_DATA_ERROR";
export const CANCEL_STREAM_DATA_SUCCESS = "CANCEL_STREAM_DATA_SUCCESS";

export const SET_TIMMER = "SET_TIMMER";

export const PAUSE_SESSION = "PAUSE_SESSION";
export const PAUSE_SESSION_ERROR = "PAUSE_SESSION_ERROR";
export const PAUSE_SESSION_SUCCESS = "PAUSE_SESSION_SUCCESS";

export const CLEAR_LIVE_SESSION_DATA = "CLEAR_LIVE_SESSION_DATA";
export const CLEAR_CALIBRATION_DATA = "CLEAR_CALIBRATION_DATA";

export const GET_CALLIBRATION_DATA = "GET_CALLIBRATION_DATA";
export const GET_CALLIBRATION_DATA_ERROR = "GET_CALLIBRATION_DATA_ERROR";
export const GET_CALLIBRATION_DATA_SUCCESS = "GET_CALLIBRATION_DATA_SUCCESS";

export const DELETE_BASELINE = "DELETE_BASELINE";
export const DELETE_BASELINE_ERROR = "DELETE_BASELINE_ERROR";
export const DELETE_BASELINE_SUCCESS = "DELETE_BASELINE_SUCCESS";

export const setSessionLength = (payload) => (dispatch) => {
  dispatch({
    type: SET_SESSION_LENGTH,
    payload,
  });
};

export const setTimmer = (payload) => ({
  type: SET_TIMMER,
  payload,
});

export const setAppActive = (payload) => ({
  type: SET_APP_ACTIVE,
  payload,
});

export const setDevice = (payload) => ({
  type: SET_BT_DEVICE,
  payload,
});

export const setStreamError = (payload) => (dispatch) => {
  dispatch({
    type: SET_STREAM_ERROR,
    payload,
  });
};

export const setActiveStream = (payload) => (dispatch) => {
  dispatch({
    type: SET_ACTIVE_STREAM,
    payload,
  });
};

export const getSignalStreamSuccess = (payload) => ({
  type: GET_SIGNAL_STREAM_SUCCESS,
  payload,
});

export const getBatteryStreamSuccess = (payload) => ({
  type: GET_BATTERY_STREAM_SUCCESS,
  payload,
});

export const getBtStreamSuccess = (payload) => ({
  type: GET_BT_STREAM_SUCCESS,
  payload,
});

export const getDistractionStreamSuccess = (payload) => ({
  type: GET_DISTRACTION_STREAM_SUCCESS,
  payload,
});

export const getSessionStreamSuccess = (payload) => ({
  type: GET_SESSION_STREAM_SUCCESS,
  payload,
});

export const stoppingStreamData = () => ({
  type: STOP_STREAM_DATA,
});

export const stopStreamDataSuccess = (payload) => ({
  type: STOP_STREAM_DATA_SUCCESS,
  payload,
});

export const stopStreamDataSuccessError = (err) => ({
  type: STOP_STREAM_DATA_ERROR,
  payload: err,
});

export const stopStreamData = (userId) => async (dispatch) => {
  try {
    const { data } = await clientApi.get(urls.stopStreamDataUrl(userId));
    dispatch(stopStreamDataSuccess(data));
  } catch (err) {
    errorHandler(err, dispatch, stopStreamDataSuccessError);
  }
};

export const cancellingStreamData = () => ({
  type: CANCEL_STREAM_DATA,
});

export const cancelStreamDataSuccess = (payload) => ({
  type: CANCEL_STREAM_DATA_SUCCESS,
  payload,
});

export const cancelStreamDataSuccessError = (err) => ({
  type: CANCEL_STREAM_DATA_ERROR,
  payload: err,
});

export const cancelStreamData = (userId) => async (dispatch) => {
  try {
    const { data } = await clientApi.get(urls.cancelStreamDataUrl(userId));
    dispatch(cancelStreamDataSuccess(data));
  } catch (err) {
    errorHandler(err, dispatch, cancelStreamDataSuccessError);
  }
};

export const gettingSignalCheck = () => ({
  type: GET_SIGNAL_CHECK,
});

export const getSignalCheckSuccess = (payload) => ({
  type: GET_SIGNAL_CHECK_SUCCESS,
  payload,
});

export const getSignalCheckError = (err) => ({
  type: GET_SIGNAL_CHECK_ERROR,
  payload: err,
});

export const getSignalCheck = (userId) => async (dispatch) => {
  dispatch(gettingSignalCheck());
  try {
    const { data } = await clientApi.get(urls.signalCheckUrl(userId));
    dispatch(getSignalCheckSuccess(data));
  } catch (err) {
    errorHandler(err, dispatch, getSignalCheckError);
  }
};

export const setSignalCheckCompleted = (payload) => ({
  type: SET_SIGNAL_CHECK_COMPLETED,
  payload,
});

export const gettingLiveSessionData = () => ({
  type: GET_LIVE_SESSION_DATA,
});

export const getLiveSessionDataSuccess = (payload) => ({
  type: GET_LIVE_SESSION_DATA_SUCCESS,
  payload,
});

export const getLiveSessionDataError = (err) => ({
  type: GET_LIVE_SESSION_DATA_ERROR,
  payload: err,
});

export const getLiveSessionData = (userId, protocolId) => async (dispatch) => {
  dispatch(gettingLiveSessionData());
  try {
    const { data } = await clientApi.get(urls.sessionLiveDataUrl(userId, protocolId));
    dispatch(getLiveSessionDataSuccess({ data }));
  } catch (err) {
    errorHandler(err, dispatch, getLiveSessionDataError);
  }
};

export const gettingCallibrationData = () => ({
  type: GET_CALLIBRATION_DATA,
});

export const getCallibrationDataSuccess = (payload) => ({
  type: GET_CALLIBRATION_DATA_SUCCESS,
  payload,
});

export const getCallibrationDataError = (err) => ({
  type: GET_CALLIBRATION_DATA_ERROR,
  payload: err,
});

export const getCallibrationData = (userId) => async (dispatch) => {
  dispatch(gettingCallibrationData());
  try {
    const { data } = await clientApi.get(urls.baselineCalibrationUrl(userId));
    dispatch(getCallibrationDataSuccess(data));
  } catch (err) {
    errorHandler(err, dispatch, getCallibrationDataError);
  }
};

export const deletingBaseline = () => ({
  type: DELETE_BASELINE,
});

export const deleteBaselineSuccess = (payload) => ({
  type: DELETE_BASELINE_SUCCESS,
  payload,
});

export const deleteBaselineError = (err) => ({
  type: DELETE_BASELINE_ERROR,
  payload: err,
});

export const deleteBaseline = (userId) => async (dispatch) => {
  dispatch(deletingBaseline());
  try {
    const { data } = await clientApi.delete(urls.deleteBaselineUrl(userId));
    dispatch(deleteBaselineSuccess(data));
  } catch (err) {
    errorHandler(err, dispatch, deleteBaselineError);
  }
};

export const clearLiveSessionData = () => ({
  type: CLEAR_LIVE_SESSION_DATA,
});

export const pauseSessionStart = (payload) => ({
  type: PAUSE_SESSION,
  payload,
});

export const pauseSessionSuccess = (payload) => ({
  type: PAUSE_SESSION_SUCCESS,
  payload,
});

export const pauseSessionError = (err) => ({
  type: PAUSE_SESSION_ERROR,
  payload: err,
});

export const pauseSession = (
  options,
  { sessionData, protocol, sessionError },
) => async (dispatch) => {
  dispatch(pauseSessionStart({ sessionData, protocol, sessionError }));
  try {
    const { fullData, thresholds } = sessionData;
    const report = fullData.length > 0
      ? await createReport({ fullData, thresholds }) : null;
    dispatch(pauseSessionSuccess({
      sessionData, protocol, sessionError, report,
    }));
    if (options?.navigate) options.navigate(options?.nextUrl);
  } catch (err) {
    errorHandler(err, dispatch, pauseSessionError);
  }
};

export const clearCalibrationData = () => ({
  type: CLEAR_CALIBRATION_DATA,
});

const stream = {
  client: null,
  isConnected: false,
  isReconnecting: false,
};
export const getDataStream = (connect, userId, streamType, startStream) => (dispatch) => {
  if (connect === false) {
    if (stream.isConnected) {
      stream.client.end();
      stream.isConnected = false;
    }
    return;
  }

  const subscribe = (client) => {
    if (stream.isConnected && !stream.isReconnecting) {
      setTimeout(() => {
        client.subscribe(urls.signalStreamUrl(userId), (err) => {
          console.log("MQTT signalStreamUrl subscription Error:", err);
        });
        if (streamType === "live-session" || streamType === "data") {
          client.subscribe(urls.dataStreamUrl(userId), (err) => {
            console.log("MQTT sessionStreamUrl subscription Error:", err);
          });
        }
        if (streamType === "live-session") {
          client.subscribe(urls.distractionStreamUrl(userId), (err) => {
            console.log("MQTT distractionStreamUrl subscription Error:", err);
          });
        }
        startStream?.();
      }, 1000);
    }
  };

  const handleMessage = (streamData, topic) => {
    if (streamData?.msg?.includes("Data transmission timeout")) toast.info(streamData.msg);
    switch (topic) {
      case "signal-quality":
        if (isSignalStream(streamData)) {
          dispatch(getSignalStreamSuccess(streamData));
        }
        break;
      case "distraction":
        if (isDistractionStream(streamData)) {
          dispatch(getDistractionStreamSuccess(streamData));
        }
        break;
      case "data":
        if (isDataStream(streamData)) {
          dispatch(getSessionStreamSuccess(streamData));
        } else {
          dispatch(setStreamError(streamData));
        }
        break;
      default:
    }
  };

  if (connect === true && !stream.isConnected) {
    // TODO: rejectUnauthorized vulnerability
    stream.client = mqtt.connect(process.env.REACT_APP_CLIENT_MQTT_WS, {
      rejectUnauthorized: false,
      clientId: userId,
    });
    const { client } = stream;

    client.on("connect", (success) => {
      if (success) {
        console.log("MQTT connected");
        stream.isConnected = true;
        dispatch(setActiveStream(streamType));
        subscribe(client);
      }
      stream.isReconnecting = false;
    });
    client.on("reconnect", () => {
      console.log("MQTT reconnecting");
      stream.isReconnecting = true;
    });
    client.on("close", () => {
      console.log("MQTT close");
      stream.isConnected = false;
      dispatch(setActiveStream(null));
    });
    client.on("error", (error) => {
      console.log("MQTT error:", error);
    });
    client.on("message", (topicUrl, message) => {
      const decondedMessage = new TextDecoder("utf-8").decode(message);
      const streamData = JSON.parse(decondedMessage);
      const topic = topicUrl.split("/").pop();
      console.log("MQTT MSG", topic, decondedMessage);
      handleMessage(streamData, topic);
    });
  } else {
    subscribe(stream.client);
  }
};

const muse = {
  stream: {
    active: false,
    interval: null,
  },
  battery: {
    level: null,
    interval: null,
  },
};
export const sendDataStream = (userId, payload) => (dispatch) => {
  if (!muse.stream.active) {
    muse.stream.active = true;
    dispatch(getBtStreamSuccess(true));
  } else {
    clearInterval(muse.stream.interval);
    muse.stream.interval = setTimeout(() => {
      muse.stream.active = false;
      muse.stream.interval = null;
      dispatch(getBtStreamSuccess(false));
    }, 2000);
  }
  if (payload.battery && (!muse.battery.level || !muse.battery.interval)) {
    muse.battery.level = payload.battery;
    console.log("send data", muse.battery.level);
    muse.battery.interval = setTimeout(() => { muse.battery.interval = null; }, 10000);
    dispatch(getBatteryStreamSuccess(muse.battery.level));
  }
  if (stream.isConnected) {
    stream.client.publish(urls.dataPublishUrl(userId), JSON.stringify(payload), { qos: 0 });
  }
};
