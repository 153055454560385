import "./InfoSection.scss";

export function InfoSection({
  icon, type = "info", shine = false, children, customClass = "",
}) {
  return (
    <div className={`info-section is-${type} ${shine ? "do-shine" : ""} ${customClass}`}>
      <div className="info-section__wrapper">
        <div className="info-section__icon">
          {icon}
        </div>
        <div className="info-section__content">
          {children}
        </div>
      </div>
    </div>
  );
}

export default InfoSection;
