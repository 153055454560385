import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQuery, keepPreviousData } from "@tanstack/react-query";
import _ from "lodash";

import {
  SelectTile, Spinner, GenericButton, EmptyData,
} from "components";
import { getPastSessionsList } from "api/services/reporting";
import { changePastSessionReportsOrder } from "store/actions/reporting";
import { formatFilters } from "utils/session-filters";

import { ORDER_TYPES } from "constants/profile_progress_sessions";
import { ReactComponent as Filter } from "assets/icons_filter.svg";
import { ReactComponent as Sort } from "assets/icons_sort.svg";

import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

import ProgressListItem from "./ProgressListItem";
import ProgressListPagination from "./ProgressListPagination";
import ProgressListFilters from "./ProgressListFilters";

import "./ProgressList.scss";

function ProgressList({ pastProtocols }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    pastSessionReportsOrder,
    pastSessionReportsFilters,
    pastSessionReportsCurrentPage,
  } = useSelector(({ reportingReducer }) => reportingReducer);

  const [showFilters, setShowFilters] = useState(false);

  const filters = formatFilters(pastSessionReportsFilters);

  const {
    isPending, isSuccess, isPlaceholderData, data: sessionReports,
  } = useQuery({
    queryKey: ["past-sessions",
      pastSessionReportsOrder,
      pastSessionReportsFilters,
      pastSessionReportsCurrentPage,
    ],
    queryFn: () => getPastSessionsList({
      pageNumber: pastSessionReportsCurrentPage,
      dateRange: filters.dateRange,
      protocol: filters.protocol,
      order: pastSessionReportsOrder,
    }),
    placeholderData: keepPreviousData,
    staleTime: Infinity,
  });

  const {
    pastSessionReports,
    pastSessionReportsTotalPages,
    pastSessionReportsTotalNumber,
  } = { ...sessionReports };

  const changeOrder = () => {
    const index = _.indexOf(ORDER_TYPES, pastSessionReportsOrder);
    const order = (index + 1) === ORDER_TYPES.length ? ORDER_TYPES[0] : ORDER_TYPES[index + 1];
    dispatch(changePastSessionReportsOrder(order));
  };

  return (
    <div className="progress-sessions">
      <div className="progress-sessions__header">
        <h3 className="progress-sessions__header-title">Sessions</h3>
        <div className="progress-sessions__header-buttons">
          <GenericButton
            className={`progress-sessions__header-buttons__button btn-sm 
            ${pastSessionReportsFilters?.protocol?.length || pastSessionReportsFilters?.dateRange ? "active" : ""}`}
            text="Filter"
            iconSide="right"
            iconElement={<Filter />}
            onButtonClick={() => setShowFilters(true)}
          />
          <GenericButton
            className={`progress-sessions__header-buttons__button btn-sm ${pastSessionReportsOrder > 0 ? "active--up" : "active--down"}`}
            text="Sort"
            iconSide="right"
            iconElement={<Sort />}
            onButtonClick={changeOrder}
          />
          <ProgressListFilters
            pastProtocols={pastProtocols}
            show={showFilters}
            onHide={() => setShowFilters(false)}
          />
        </div>
      </div>
      <div className="progress-sessions__body">
        {isPlaceholderData && (
        <div className="progress-sessions__overlay">
          <Spinner />
        </div>
        )}
        {isPending && (
        <div className="progress-list__loader">
          <div className="icon-wrapper">
            <Spinner />
          </div>
        </div>
        )}
        {(isSuccess && pastSessionReports?.length > 0) ? (
          <>
            {pastSessionReports && pastSessionReports.map((session) => (
              <SelectTile
                key={String(session.sessionId)}
                customClass="progress-list__protocol-select"
                withButton
                onSelect={() => {
                  navigate(`/app/progress/session-summary/${session.sessionId}`, {
                    state: { from: "progress" },
                  });
                }}
              >
                <ProgressListItem session={session} />
              </SelectTile>
            ))}
            <ProgressListPagination
              totalPages={pastSessionReportsTotalPages}
              totalNumber={pastSessionReportsTotalNumber}
            />
          </>
        ) : !isPending && (
          <EmptyData
            icon={faFilterCircleXmark}
            title="No sessions found"
            text="Try different set of filters."
          />
        )}
      </div>
    </div>
  );
}

export default ProgressList;
