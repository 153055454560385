import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { BottomBarSticky, GenericButton, ProtocolSelectContent } from "components";

import useSkipSignalCheck from "hooks/useSkipSignalCheck";
import useProtocol from "hooks/useProtocol";
import useIsVisible from "hooks/useIsVisible";
import { DiagnosticResults } from "features";
import ProtocolList from "pages/App/Common/ProtocolList/ProtocolList";
import ProtocolDetails from "pages/App/Common/ProtocolDetails/ProtocolDetails";
import ProtocolLevel from "pages/App/Common/ProtocolLevel/ProtocolLevel";
import SignalCheck from "pages/App/Common/SignalCheck/SignalCheck";
import ProtocolCalibration from "pages/App/Common/ProtocolCalibration/ProtocolCalibration";
import { saveUserProfile } from "store/actions/profile";
import { setOnboardingTab } from "store/actions/onBoarding";

import { ONBOARDING_TABS } from "constants/onBoarding_tabs";

import "./Protocol.scss";

function ProtocolPage({ tabIndex }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const tabs = ONBOARDING_TABS;
  const protocolTabIndex = tabs.indexOf("Protocol");

  const protocolDetails = useProtocol(searchParams.get("categoryID"), searchParams.get("protocolID"));

  const stage = tabs[tabIndex];
  const skipSignalCheck = useSkipSignalCheck(stage);
  const [calibrationStage, setCalibrationStage] = useState(skipSignalCheck ? "calibration" : "signal");

  const actions = useRef();
  const isActionsVisible = useIsVisible(actions, -100, true);

  const onStageChange = (step) => {
    dispatch(setOnboardingTab(tabIndex + step));
  };

  const onProtocolSelect = (categoryID, protocolID) => {
    setSearchParams({ categoryID, protocolID });
    onStageChange(1);
  };

  const onComplete = () => {
    const options = { navigate, nextUrl: "/app/onboarding/completed" };
    dispatch(saveUserProfile({
      onboarding_complete: true,
      saved_session_length: { hours: 0, minutes: 20 },
    }, options));
  };

  const onRetakeDiagnostic = () => {
    dispatch(saveUserProfile({
      diagnostic: null,
      onboarding_tab: protocolTabIndex - 1,
    }, { nextTab: protocolTabIndex - 1 }));
  };

  if (stage === "Protocol") {
    return (
      <>
        <DiagnosticResults onRetake={onRetakeDiagnostic} collapsed />
        <ProtocolList onSelect={onProtocolSelect} withTraining={false} />
      </>
    );
  }

  if (stage === "Confirm") {
    return (
      <>
        <ProtocolSelectContent protocol={protocolDetails} size="lg" centered />
        <div className="protocolActions" ref={actions}>
          <GenericButton
            className="button--dark mt-3"
            text="Yes, This Is My Technique"
            onButtonClick={() => onStageChange(1)}
          />
          <div
            className="text-decoration-underline font-700"
            role="presentation"
            onClick={() => onStageChange(-1)}
          >
            No, Let Me Choose Another
          </div>
        </div>
        <ProtocolDetails protocol={protocolDetails} />
        <BottomBarSticky customClass={`${isActionsVisible ? "is-hidden" : ""}`}>
          <GenericButton
            className="button--dark"
            text="Yes, This Is My Technique"
            onButtonClick={() => onStageChange(1)}
          />
        </BottomBarSticky>
      </>
    );
  }

  if (stage === "Expertise" && protocolDetails) {
    return <ProtocolLevel protocol={protocolDetails} onSelect={() => onStageChange(1)} />;
  }

  if (stage === "Calibration") {
    return calibrationStage === "signal"
      ? <SignalCheck onComplete={() => setCalibrationStage("calibration")} />
      : (
        <ProtocolCalibration
          onComplete={onComplete}
          onCancel={() => onStageChange(-1)}
          onError={() => setCalibrationStage("signal")}
        />
      );
  }

  return null;
}

export default ProtocolPage;
