import metrics from "assets/unlocked_metrics.png";
import graph from "assets/unlocked_graph.png";
import scores from "assets/unlocked_scores.png";
import soundscape from "assets/unlocked_soundscape.png";
import threshold from "assets/unlocked_threshold.png";

const UNLOCKABLE_CONTENT = [
  {
    title: "Progress Screens Track Depth over Days/Weeks/Months",
    caption: "Depth Progress screens show how you are progressing by week, month and year.",
    img: scores,
  },
  {
    title: "Reward Threshold Slider",
    caption: "Set the Reward Threshold Slider to maximize your learning rate. You won’t learn as fast if you are frustrated from not enough reward or bored from too much.",
    img: threshold,
  },
  {
    title: "Session Summary Stats",
    caption: "AVG Depth, MAX Depth, Time above Training Threshold, and Longest Streak.",
    img: metrics,
  },
  {
    title: "Session Depth Graph",
    caption: "Session Depth Graph shows how much time you are spending above and below your training threshold.",
    img: graph,
  },
  {
    title: "Custom Soundscapes",
    caption: "Customize your sound environment to be the most rewarding for you.",
    img: soundscape,
  },
];

export default UNLOCKABLE_CONTENT;
