import { useState, useRef, useEffect } from "react";
import { Tab, Nav } from "react-bootstrap";

import "./Tabs.scss";

export function CustomTabs({
  tabs, defaultTabKey, children, onTabChange, activeTab, completedSteps, activeScroll,
}) {
  const navWrapper = useRef();
  const activeNavItem = useRef();
  const mounted = useRef(false);
  const [navFade, setNavFade] = useState({ scroll: 0, start: false, end: false });

  useEffect(() => {
    if (activeScroll && mounted.current) {
      setTimeout(() => {
        activeNavItem.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "center" });
      }, 1);
    }
  }, [activeTab]);

  useEffect(() => {
    const onScroll = () => {
      setNavFade((state) => ({ ...state, scroll: navWrapper.current.scrollLeft }));
    };
    navWrapper.current?.addEventListener("scroll", onScroll);
    mounted.current = true;
    return () => {
      navWrapper.current?.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    const scrollEnd = navWrapper.current.scrollWidth - navWrapper.current.clientWidth;
    const scrollOffset = 10;
    setNavFade((state) => ({
      ...state,
      start: navFade.scroll > scrollOffset,
      end: navFade.scroll < scrollEnd - scrollOffset,
    }
    ));
  }, [navFade.scroll]);

  return (
    <Tab.Container
      className="tabs"
      defaultActiveKey={defaultTabKey}
      id="generic-tabs"
      transition={false}
      activeKey={activeTab}
      onSelect={(tabName, e) => onTabChange(tabName, e)}
      unmountOnExit
    >
      <div className={`nav-tabs__wrapper ${navFade.start ? "fade-start" : ""} ${navFade.end ? "fade-end" : ""}`}>
        <Nav ref={navWrapper} as="ul" className="tabs nav-tabs">
          {tabs.map((tabName, i) => (
            <Nav.Item as="li" key={tabName}>
              <Nav.Link
                ref={tabName === activeTab ? activeNavItem : null}
                eventKey={tabName}
                className={i > completedSteps ? "disabled" : ""}
              >
                {tabName}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
      <Tab.Content>
        {tabs.map((tabName) => (
          <Tab.Pane
            eventKey={tabName}
            title={tabName}
            key={tabName}
          >
            {children}
          </Tab.Pane>
        ))}
      </Tab.Content>
    </Tab.Container>
  );
}

export default CustomTabs;
