import { Container } from "react-bootstrap";
import _ from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import useProtocol from "hooks/useProtocol";
import {
  SubNav, GenericButton, BottomBarSticky, ProtocolSelectContent,
} from "components";
import ProtocolDetails from "pages/App/Common/ProtocolDetails/ProtocolDetails";

function ProtocolDetailsPage() {
  const navigate = useNavigate();

  const { protocolID, categoryID } = useParams();
  const protocol = useProtocol(categoryID, protocolID);

  return (
    <Container>
      <SubNav title={_.unescape(protocol?.protocolName)} audioGuide="coach-protocol-explanation">
        <ProtocolSelectContent protocol={protocol} size="lg" centered />
      </SubNav>
      <ProtocolDetails protocol={protocol} />
      <BottomBarSticky>
        <GenericButton
          className="button--dark"
          text="Calibrate"
          onButtonClick={() => navigate(`/app/meditation/protocol-level/${categoryID}/${protocolID}`)}
        />
      </BottomBarSticky>
    </Container>
  );
}

export default ProtocolDetailsPage;
