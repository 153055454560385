import { userApi } from "api/axios";
import * as urls from "api/urls";
import errorHandler from "utils/error-handler";
import { toast } from "react-toastify";

export const getClient = async (clientId) => {
  try {
    const { data } = await userApi.get(urls.getClientUrl(clientId));
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const getClientProtocols = async (clientId) => {
  try {
    const { data } = await userApi.get(urls.getClientProtocolsUrl(clientId));
    return data;
  } catch (err) {
    errorHandler(err);
    return false;
  }
};

export const getClients = async (payload) => {
  try {
    const { data } = await userApi.post(urls.getClientsUrl(), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getClientsSessions = async (payload) => {
  try {
    const { data } = await userApi.post(urls.getClientsSessionsUrl(), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    return false;
  }
};

export const getClientSessions = async (id, payload) => {
  try {
    const { data } = await userApi.post(urls.getClientSessionsUrl(id), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    return false;
  }
};

export const getClientSessionDetails = async (sessionId) => {
  try {
    const { data } = await userApi.get(urls.getClientSessionDetailsUrl(sessionId));
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const getCoachTable = async (id) => {
  try {
    const { data } = await userApi.get(urls.getCoachTableUrl(id));
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getOnboardingChoices = async () => {
  try {
    const { data } = await userApi.get(urls.onboardingChoicesUrl());
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getClientsCharts = async (clientsId, range) => {
  try {
    const { data } = await userApi.get(urls.getClientsChartsUrl(), {
      params: {
        dates: range || "-",
        users: clientsId,
      },
    });
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getSessionsReport = async (payload) => {
  try {
    const { data } = await userApi.post(urls.getSessionsReportUrl(), payload);
    return data.url;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

// TABLE TABS
export const getCoachTabList = async (group) => {
  try {
    const { data } = await userApi.get(urls.getCoachTabListUrl(group || ""));
    return data.results;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const createCoachTab = async (payload) => {
  try {
    const { data } = await userApi.post(urls.createCoachTabUrl(), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getCoachTabData = async (tabId) => {
  try {
    const { data } = await userApi.get(urls.coachTabUrl(tabId));
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const modifyCoachTab = async (tabId, payload) => {
  try {
    const { data } = await userApi.put(urls.coachTabUrl(tabId), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const deleteCoachTab = async (tabId) => {
  try {
    const { data } = await userApi.delete(urls.coachTabUrl(tabId));
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const manageCoachTabs = async (group, payload) => {
  try {
    const { data } = await userApi.post(urls.manageCoachTabsUrl(group), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

// RECALCULATIONS
export const getRecalculationList = async (payload) => {
  try {
    const { data } = await userApi.post(urls.getRecalculationListUrl(), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const getRecalculationResults = async (payload) => {
  try {
    const { data } = await userApi.post(urls.getRecalculationResultsUrl(), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const createRecalculation = async (payload) => {
  try {
    const { data } = await userApi.post(urls.createRecalculationUrl(), payload);
    toast.success("Recalculation successfully scheduled.");
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

// TEST BATCHES
export const createTestBatch = async (payload) => {
  try {
    await userApi.post(urls.createTestBatchUrl(), payload);
    toast.success("Batch tests successfully scheduled.");
    return true;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

export const getTestBatches = async (payload) => {
  try {
    const { data } = await userApi.post(urls.getTestBatchesUrl(), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const getTestBatchesExists = async () => {
  try {
    const { data } = await userApi.get(urls.getTestBatchesExistsUrl());
    return data.batch_exists;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const getTestBatchDetails = async (id) => {
  try {
    const { data } = await userApi.get(urls.getTestBatchDetailsUrl(id));
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const resumeTestBatch = async (id) => {
  try {
    const { data } = await userApi.post(urls.resumeTestBatchUrl(id));
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const cancelTestBatch = async (id) => {
  try {
    const { data } = await userApi.post(urls.cancelTestBatchUrl(id));
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const getTestSessions = async (payload) => {
  try {
    const { data } = await userApi.post(urls.getTestSessionsUrl(), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const getTestReport = async (payload) => {
  try {
    const { data } = await userApi.post(urls.getTestReportUrl(), payload);
    return data.url;
  } catch (err) {
    errorHandler(err);
    return err;
  }
};

// RESEARCH LIBRARY
export const getResearchLibrary = async (payload) => {
  try {
    const { data } = await userApi.post(urls.getResearchLibraryUrl(), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const getResearchData = async (id) => {
  try {
    const { data } = await userApi.get(urls.getResearchDataUrl(id));
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const getResearchComments = async (id) => {
  try {
    const { data } = await userApi.get(urls.researchCommentsUrl(id));
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const addResearchComment = async (id, payload) => {
  try {
    const { data } = await userApi.post(urls.researchCommentsUrl(id), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const deleteResearchComment = async (id) => {
  try {
    const { data } = await userApi.delete(urls.deteleResearchCommentUrl(id));
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

// DIAGNOSTIC SESSIONS
export const getDiagnosticSessions = async (payload) => {
  try {
    const { data } = await userApi.post(urls.getDiagnosticSessionsUrl(), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};

export const getDiagnosticSessionsReport = async (payload) => {
  try {
    const { data } = await userApi.post(urls.getDiagnosticSessionsReportUrl(), payload);
    return data.url;
  } catch (err) {
    errorHandler(err);
    throw err;
  }
};
