import { Card } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as StarIcon } from "assets/icons_promo_star.svg";

import CERTIFICATION_REQ from "constants/certification";

function CalibrationInfo({ protocolCertification }) {
  const { user } = protocolCertification;

  const renderDot = (value, index) => {
    const content = {
      success: { icon: <FontAwesomeIcon icon={faCheck} />, class: "success" },
      empty: { icon: null, class: "empty" },
    };
    if (index === 9) {
      content.success = { icon: <StarIcon />, class: "success", style: { border: "none" } };
      content.empty = { icon: <StarIcon />, class: "empty", style: { border: "none" } };
    }
    return (
      <div key={index.toString()} className={`dot is-${content[value].class}`} style={content[value].style}>
        {content[value].icon}
      </div>
    );
  };

  return user && (
    <Card customClass="pb-3">
      <p className="font-700 mb-1">
        Sessions completed:
      </p>
      <div className="sessionComplete__counter-wrapper my-3">
        <div className="sessionComplete__counter-dots">
          {[...Array(CERTIFICATION_REQ.contributionsNeeded)]
            .map((_value, index) => renderDot((index + 1 <= user.contributions ? "success" : "empty"), index))}
        </div>
        <p className="text-sm text-dark ms-2">
          {user.contributions}
          /
          {CERTIFICATION_REQ.contributionsNeeded}
        </p>
      </div>
      <div className="sessionComplete__counter-info is-fail">
        When you finish
        {" "}
        {CERTIFICATION_REQ.contributionsNeeded}
        {" "}
        sessions with this protocol you get to try another.
      </div>
    </Card>
  );
}

export default CalibrationInfo;
