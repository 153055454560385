import { formatDuration } from "utils/time-format";
import "./PercentageZones.scss";

export function PercentageZones({ time }) {
  const totalTime = Object.values(time).reduce((a, b) => a + b, 0);
  const percentage = {
    reminders: (time.reminders / totalTime) * 100,
    rewards: (time.rewards / totalTime) * 100,
    neutral: (time.neutral / totalTime) * 100,
  };
  const calcHeight = (value) => {
    const maxHeight = 260;
    const minHeight = 60;
    const height = (maxHeight - minHeight) * (Math.min(100, value + (value % 100)) / 100);
    return ((minHeight + height) / maxHeight) * 100;
  };

  return (
    <div className="PercentageZones">
      <h2 className="mb-3">% Zones</h2>
      <div className="PercentageZones__container">
        <div className="PercentageZones__thermometer">
          <div className="PercentageZones__subtitle">
            <span className="PercentageZones__subtitle-inner">Time in Each Zone</span>
          </div>
          <div
            className="PercentageZones__thermometer--zone-warning"
            style={{ height: `${calcHeight(percentage.reminders)}%` }}
          >
            <div className="PercentageZones__percentageValues">
              <p className="percentage">
                {Math.round(percentage.reminders) || 0}
                %
              </p>
              <p className="time">{formatDuration(time.reminders * 1000)}</p>
            </div>
          </div>
          <div
            className="PercentageZones__thermometer--zone-neutral"
            style={{ height: `${calcHeight(percentage.neutral)}%` }}
          >
            <div className="PercentageZones__percentageValues">
              <p className="percentage">
                {Math.round(percentage.neutral) || 0}
                %
              </p>
              <p className="time">{formatDuration(time.neutral * 1000)}</p>
            </div>
          </div>
          <div
            className="PercentageZones__thermometer--zone-reward"
            style={{ height: `${calcHeight(percentage.rewards)}%` }}
          >
            <div className="PercentageZones__percentageValues">
              <p className="percentage">
                {Math.round(percentage.rewards) || 0}
                %
              </p>
              <p className="time">{formatDuration(time.rewards * 1000)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PercentageZones;
