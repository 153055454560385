import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useQuery } from "@tanstack/react-query";

import { Loader, Spinner } from "components";
import CalibrationSession from "features/CalibrationSession/CalibrationSession";
import useProtocolCalibration from "hooks/useProtocolCalibration";

import { getLiveSessionData } from "store/actions/session";
import { saveUserProfile } from "store/actions/profile";
import { getSuggestedProtocols } from "api/services/protocols";

function Diagnostic({ onComplete, onCancel, onError }) {
  const dispatch = useDispatch();
  const loggedInUserId = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.pk,
  );

  const { allProtocols } = useSelector(
    ({ protocolsReducer }) => protocolsReducer,
  );

  const protocolCalibration = useMemo(() => {
    const modernProtocols = allProtocols?.find(({ protocolCategory }) => protocolCategory === "Modern Meditations");
    const { protocolID, protocolName } = modernProtocols.protocols[0];
    const level = modernProtocols.protocols[0].levels.find((lvl) => lvl.level === 3);
    const protocolData = {
      protocol: { categoryID: modernProtocols.categoryID, protocolID, protocolName },
      level,
    };
    return protocolData;
  }, []);

  const [sessionStatus, setSessionStatus] = useState("loading");

  const protocolReady = useProtocolCalibration(loggedInUserId, protocolCalibration);

  const {
    isSuccess, isError, data: diagnostic, refetch,
  } = useQuery({
    queryKey: ["protocols-diagnostic"],
    queryFn: () => getSuggestedProtocols(loggedInUserId),
    enabled: false,
    gcTime: 0,
  });

  useEffect(() => {
    if (isSuccess) {
      if (diagnostic?.comparison) {
        const saveProfile = async () => {
          const protocols = diagnostic.comparison.reverse().map((protocol) => ({
            ...protocol,
            sessionDepth: Math.round(-protocol.sessionDepth[0]),
            threshold: -protocol.threshold,
          }));
          try {
            await dispatch(saveUserProfile({
              diagnostic: {
                date: new Date().toISOString(),
                sessionID: diagnostic.sessionID,
                baselineFile: diagnostic.baselineFile,
                protocols,
              },
            }));
            onComplete?.();
          } catch {
            onCancel?.();
          }
        };
        saveProfile();
      } else {
        onCancel?.();
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      onCancel?.();
    }
  }, [isError]);

  useEffect(() => {
    if (protocolReady === true) {
      setSessionStatus("ready");
    }
    if (protocolReady === false) {
      toast.error("Diagnostic preparation failed.");
      onCancel?.();
    }
  }, [protocolReady]);

  const startStream = () => {
    const protocolId = protocolCalibration?.level.levelID;
    dispatch(getLiveSessionData(loggedInUserId, protocolId));
  };

  const sessionCompleted = () => {
    refetch();
  };

  return (
    <CalibrationSession
      onComplete={sessionCompleted}
      onCancel={onCancel}
      onError={onError}
      sessionStatus={[sessionStatus, setSessionStatus]}
      startStream={startStream}
      initialTime={300}
    >
      <h2>Meditation Diagnostic</h2>
      {["ready", "started"].includes(sessionStatus) && (
        <div className="calibrationSession__text">
          <p>
            We are going to run a
            diagnostic to see which feedback protocol would be best for your
            normal style of mediation. If you do many styles, just do the one that
            you do most often.
          </p>
          <p>
            We will play the sound of crickets
            and ring a bell when five minutes are up.
          </p>
          <p>
            Press the
            {" "}
            <strong>Start</strong>
            {" "}
            button, close your eyes, and meditate in whatever
            style is most comfortable to you.
          </p>
        </div>
      )}
      {["loading", "completed"].includes(sessionStatus) && (
        <div className="calibrationSession__loading">
          <div className="loader-wrapper">
            {sessionStatus === "loading" ? <Spinner /> : <Loader />}
          </div>
          <h3>
            {sessionStatus === "loading" ? (
              <>Preparing Protocols for Analysis</>
            ) : (
              <>
                Analyzing your Average Depth
                <br />
                for each of the
                <br />
                Modern Protocols
              </>
            )}
          </h3>
          <p className="text-muted">Please wait about half a minute… </p>
        </div>
      )}
    </CalibrationSession>
  );
}

export default Diagnostic;
