import { useEffect, useRef, useState } from "react";

export default function useInterval(callback, delay) {
  const savedCallback = useRef();
  const [currentInt, setCurrentInt] = useState(null);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const currentInterval = setInterval(() => {
      savedCallback.current();
    }, delay);
    setCurrentInt(currentInterval);
    return () => clearInterval(currentInterval);
  }, [delay]);

  return currentInt;
}
