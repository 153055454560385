import { useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { GenericButton, InfoBox } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { formatDuration } from "utils/time-format";

import TapEdit from "./TapEdit";

import "./TapsModal.scss";

export function TapsModal({ sessionId, taps }) {
  const [showModal, setShowModal] = useState(false);
  const [activeDialog, setActiveDialog] = useState(null);
  const [tapToEdit, setTapToEdit] = useState(null);

  const renderTapIcon = (type) => (
    <div className="tapsList__item-icon">
      <div className={`tapIcon type-${type}`}>
        {[...Array(type)].map((_type, i) => <span key={i.toString()} />)}
      </div>
    </div>
  );

  const onShowDialog = (type, tap) => {
    setActiveDialog(type);
    setTapToEdit(tap);
  };

  return (
    <div className="tapsModal">
      <GenericButton
        text="Edit Markers"
        className="button--white btn-xs"
        iconElement={<FontAwesomeIcon icon={faPen} />}
        onButtonClick={() => setShowModal(true)}
      />
      <Modal
        animation
        dialogClassName={`dialog dialog-bg ${activeDialog ? "tapsModal--is-hidden" : ""}`}
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <h2>Edit Markers</h2>
        </Modal.Header>
        <Modal.Body>
          <Card className="tapsModal__list text-sm">
            <table>
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Type</th>
                  <th>Note</th>
                  <th aria-label="edit" />
                </tr>
              </thead>
              <tbody>
                {taps.map((tap) => (
                  <tr key={tap.timestamp}>
                    <td>{formatDuration(tap.timestamp * 1000)}</td>
                    <td>{renderTapIcon(tap.type)}</td>
                    <td>
                      <div className="tapsModal__list-note">
                        {tap.notes || (
                          <div className="tapsModal__list-note-add">
                            <FontAwesomeIcon icon={faPlus} onClick={() => onShowDialog("note", tap)} />
                            <span>Add Note</span>
                          </div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div
                        role="presentation"
                        onClick={() => onShowDialog("marker", tap)}
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
          <InfoBox customClass="mt-4" noIcon>
            <div className="tapsModal__info">
              {renderTapIcon(3)}
              <div>
                <strong>Special Moments</strong>
                <p className="font-sm">Triple taps can be used to leave your own notes</p>
              </div>
            </div>
          </InfoBox>
        </Modal.Body>
        <Modal.Footer>
          <GenericButton
            text="Close"
            className="button--dark"
            onButtonClick={() => setShowModal(false)}
          />
        </Modal.Footer>
      </Modal>
      <TapEdit
        tapToEdit={tapToEdit}
        sessionId={sessionId}
        show={!!activeDialog}
        setShow={setActiveDialog}
        type={activeDialog}
      />
    </div>
  );
}

export default TapsModal;
