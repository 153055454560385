import * as urls from "api/urls";
import { userApi } from "api/axios";
import errorHandler from "utils/error-handler";

export const GET_ON_BOARDING_CHOICES = "GET_ON_BOARDING_CHOICES";
export const GET_ON_BOARDING_CHOICES_ERROR = "GET_ON_BOARDING_CHOICES_ERROR";
export const GET_ON_BOARDING_CHOICES_SUCCESS = "GET_ON_BOARDING_CHOICES_SUCCESS";

export const SET_ON_BOARDING_ACTIVE_TAB = "SET_ON_BOARDING_ACTIVE_TAB";

export const gettingOnBoardingChoices = () => ({
  type: GET_ON_BOARDING_CHOICES,
});

export const getOnBoardingChoicesSuccess = (payload) => ({
  type: GET_ON_BOARDING_CHOICES_SUCCESS,
  payload,
});

export const getOnBoardingChoicesError = (err) => ({
  type: GET_ON_BOARDING_CHOICES_ERROR,
  payload: err,
});

export const getOnBoardingChoices = () => async (dispatch) => {
  dispatch(gettingOnBoardingChoices());
  try {
    const res = await userApi.get(urls.onboardingChoicesUrl());
    dispatch(getOnBoardingChoicesSuccess(res.data));
  } catch (err) {
    errorHandler(err, dispatch, getOnBoardingChoicesError);
  }
};

export const setOnboardingTab = (payload) => ({
  type: SET_ON_BOARDING_ACTIVE_TAB,
  payload,
});
