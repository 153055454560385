import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { GenericButton } from "components";
import { JournalForm } from "features";
import _ from "lodash";

import { updateSessionJournal } from "api/services/reporting";

import "./SessionJournal.scss";

export function SessionJournal({
  journalEntry, sessionId, isCertified, isTap,
}) {
  const queryClient = useQueryClient();

  const [journalData, setJournalData] = useState(null);
  const [isEdited, setIsEdited] = useState(false);

  const journalUpdate = useMutation({
    mutationFn: (payload) => updateSessionJournal(sessionId, payload),
    onSuccess: (_data, payload) => {
      queryClient.setQueryData(
        ["session-summary", sessionId],
        (state) => ({ ...state, journalEntry: { ...payload.journalEntry } }),
      );
      toast.success("Journal has been updated.");
      setIsEdited(false);
    },
  });

  useEffect(() => {
    if (journalData?.journalEntry) {
      setIsEdited(!_.isEqual(journalEntry, journalData?.journalEntry));
    }
  }, [journalData]);

  return (
    <div className="SessionJournal card">
      <div className="SessionJournal__header">
        <h2>Journal Entry</h2>
      </div>
      <JournalForm
        journalEntry={journalEntry}
        setJournalData={setJournalData}
        isCertified={isCertified}
        isEdited={isEdited}
        isTap={isTap}
        isEditable
      />
      {isEdited && (
        <div className="SessionJournal__footer">
          <GenericButton
            className="btn-sm"
            text="Cancel"
            onButtonClick={() => setIsEdited(false)}
          />
          <GenericButton
            className="button--success btn-sm"
            text="Save"
            onButtonClick={() => journalUpdate.mutate(journalData)}
          />
        </div>
      )}
    </div>
  );
}

export default SessionJournal;
