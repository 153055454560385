import { format } from "date-fns";
import _ from "lodash";

export const formatFilters = (pageFilters) => {
  const isDateRange = _.get(pageFilters, "dateRange", null);
  const isProtocol = _.get(pageFilters, "protocol", null);
  const formatType = "yyyy-MM-dd";
  const payload = {
    dateRange: isDateRange ? [
      pageFilters.dateRange.startDate ? format(pageFilters.dateRange.startDate, formatType) : null,
      pageFilters.dateRange.endDate ? format(pageFilters.dateRange.endDate, formatType) : null,
    ] : [],
    protocol: isProtocol ? pageFilters.protocol : [],
  };
  return payload;
};

export default formatFilters;
