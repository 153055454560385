import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

import Container from "react-bootstrap/Container";
import { Form } from "react-bootstrap";
import {
  SubHeader,
  InputText,
  GenericButton,
  ErrorValidation,
} from "components";
import useFormData from "hooks/useFormData";
import { resetPassword } from "api/services/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { RESET_PASS_FORM } from "constants/forms_data";

import "./ResetPassword.scss";

function ResetPassword() {
  const navigate = useNavigate();

  const {
    formData, formErrors, setFormValue, formValidate,
  } = useFormData(RESET_PASS_FORM);

  const [isSuccess, setIsSucces] = useState(false);

  const reset = useMutation({
    mutationFn: () => resetPassword(formData),
    onSuccess: async (data) => {
      formValidate(data, () => setIsSucces(true));
    },
  });

  const onReset = (e) => {
    e.preventDefault();
    if (!reset.isPending) {
      reset.mutate();
    }
  };

  window.nativeBack = () => {
    navigate("/app/login");
  };

  return (
    <Container className="login__container">
      <SubHeader
        title="Reset Password"
        text="Forgot password? We will reset it for you."
      />
      {!isSuccess ? (
        <>
          <Form
            onSubmit={(e) => onReset(e)}
            autoComplete="on"
            noValidate
          >
            {RESET_PASS_FORM.map((input) => (
              <div key={input.key}>
                <InputText
                  inputType={input.inputType}
                  placeholder={input.placeholder}
                  autocomplete={input.autocomplete}
                  defaultValue={formData[input.key]}
                  onChangeValue={(e) => setFormValue(e.target.value, input.key)}
                  isValid={!formErrors[input.key]}
                  inputMode={input.key}
                />
                <ErrorValidation errors={formErrors[input.key]} />
              </div>
            ))}
            <div className="login__button-container">
              <GenericButton
                type="submit"
                text="Reset"
                className="button--success"
              />
            </div>
          </Form>
          <div className="login__registration-container">
            <div>
              If you remember your password,
              <GenericButton
                text="Sign In"
                onButtonClick={() => navigate("/app/login")}
                buttonType="text"
                className="button-link"
              />
            </div>
          </div>
        </>
      ) : (
        <div className="password-reset__success">
          <div className="password-reset__success-icon">
            <FontAwesomeIcon icon={faEnvelopeOpenText} />
          </div>
          <div className="password-reset__success-content">
            <p>
              If email
              {" "}
              <span className="text-primary">
                {formData.email}
              </span>
              {" "}
              exists in GoDeeper database, we will
              {" "}
              <strong>reset password</strong>
              {" "}
              for this account
              and send new one to this address.
            </p>
            <p>
              Check your inbox and after login you will be able
              to change your password in settings section.
            </p>
          </div>
          <GenericButton
            text="Back to Login"
            onButtonClick={() => navigate("/app/login")}
            buttonType="text"
          />
        </div>
      )}

    </Container>
  );
}

export default ResetPassword;
