import { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { toast } from "react-toastify";

import {
  GenericButton, Card, BottomBarSticky,
} from "components";
import { saveUserProfile } from "store/actions/profile";

import "./Brainwaves.scss";
import { BRAINWAVE_FORM } from "constants/forms_data";
import BrainwavesSelect from "./components/BrainwavesSection";

function BrainwavesPage({
  tabIndex, saveBtnText,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onBoardingChoices = useSelector(
    ({ onBoardingReducer }) => onBoardingReducer.onBoardingChoices,
  );
  const userProfile = useSelector(
    ({ profileReducer }) => profileReducer.userProfile,
  );
  const isCompleted = useMemo(
    () => (tabIndex !== undefined ? (tabIndex < userProfile?.onboarding_tab) : true),
    [tabIndex, userProfile],
  );

  const [formData, setFormData] = useState({
    handedness: userProfile.handedness || "",
    sex: userProfile.sex || "",
    eyes: userProfile.eyes || "",
    region: userProfile.region || "",
    birthday: userProfile.birthday ? new Date(userProfile.birthday) : "",
  });
  const [isValid, setIsValid] = useState(false);

  const setFormValue = (value, key) => {
    setFormData({ ...formData, [key]: value });
  };

  useEffect(() => {
    setIsValid(!Object.values(formData).includes(""));
  }, [formData]);

  const onSave = () => {
    if (!isValid) {
      toast.error("Please answer to all questions.");
    } else {
      const options = tabIndex >= 0
        ? { nextTab: tabIndex + 1 }
        : { navigate, nextUrl: "/app/settings" };
      dispatch(saveUserProfile({
        ...formData,
        birthday: formData.birthday ? format(formData.birthday, "yyyy-MM-dd") : "",
      }, options));
    }
  };

  return (
    <div className="brainwavesPage">
      <h2>
        These items affect how we interpret your brainwaves:
      </h2>
      {BRAINWAVE_FORM.map((section) => (
        <BrainwavesSelect
          key={section.key}
          title={section.placeholder}
          name={section.key}
          size={section.size}
          choices={onBoardingChoices}
          formData={formData}
          setFormValue={setFormValue}
        />
      ))}
      <div className="brainwavesPage__card-section">
        <Card customClass="card-text mt-5">
          <h3>
            Why these questions are important?
          </h3>
          <p>
            Brainwaves vary by handedness, gender, and age, and whether your
            eyes are open. We filter out the frequency of the electricity where you are located.
          </p>
        </Card>
      </div>
      <BottomBarSticky>
        <GenericButton
          className={`button--success ${isValid ? "" : "button--muted"}`}
          text={saveBtnText || isCompleted ? "Save" : "Next"}
          onButtonClick={onSave}
        />
      </BottomBarSticky>
    </div>
  );
}

export default BrainwavesPage;
