export const getStatusBegin = (currentPage) => Math.max(1, (currentPage - 1) * 10 + 1);

export const getStatusBeginEnd = (currentPage, totalPages, totalNumber, perPage) => (
  Number(currentPage) === totalPages
    ? totalNumber
    : currentPage * perPage
);

export const getPaginationStatus = (currentPage, totalPages, totalNumber, perPage) => (
  `${getStatusBegin(currentPage)} - ${getStatusBeginEnd(currentPage, totalPages, totalNumber, perPage)} of ${totalNumber}`
);
