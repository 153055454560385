import { ReactComponent as InfoIcon } from "assets/icons_signal_info.svg";
import SIGNAL_INFO from "constants/signal_check";

import "./SignalInfo.scss";

function SignalInfo({
  completed, info,
}) {
  return (
    <div className="signalInfo">
      <div className="signalInfo__legend">
        <div className="signalInfo__legend-row">
          <span className="signalInfo__legend-label bg-s3" />
          {" "}
          Strong
        </div>
        <div className="signalInfo__legend-row">
          <span className="signalInfo__legend-label bg-s2" />
          {" "}
          Weak
        </div>
        <div className="signalInfo__legend-row">
          <span className="signalInfo__legend-label bg-s1" />
          {" "}
          Noisy
        </div>
        <div className="signalInfo__legend-row">
          <span className="signalInfo__legend-label bg-s0" />
          {" "}
          None
        </div>
      </div>
      <div
        className={`signalInfo__status bg-s${info} ${
          completed ? "is-completed" : ""
        }`}
      >
        <InfoIcon />
        {SIGNAL_INFO[info]}
      </div>
    </div>
  );
}

export default SignalInfo;
