import { useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ResponsiveBar } from "@nivo/bar";
import { line } from "d3-shape";
import { computeXYScalesForSeries } from "@nivo/scales";
import { useTooltip, TableTooltip } from "@nivo/tooltip";
import { Axes, Grid } from "@nivo/axes";
import { format } from "date-fns";

import { CHART_TYPES, CHART_TYPES_TAPS_CHART } from "constants/profile_progress_charts";
import ChartLoader from "features/ProgressGraphs/ChartLoader";
import ChartType from "features/ProgressGraphs/ChartType";
import usePinch from "hooks/usePinch";

import { changeGraphStatus } from "store/actions/reporting";

const getScale = (data, innerWidth, innerHeight) => computeXYScalesForSeries(
  [
    {
      id: "deep",
      data: data.map((serie, index) => ({
        x: index,
        y: serie.deepAmount,
      })),
    },
    {
      id: "distracted",
      data: data.map((serie, index) => ({
        x: index,
        y: serie.distractedAmount,
      })),
    },
  ],
  { type: "linear" },
  { type: "linear" },
  innerWidth,
  innerHeight,
);

const Tips = (data) => function CustomLayer(layerProps) {
  const { bars, innerHeight } = layerProps;
  const tooltip = useTooltip();

  const showTip = (e, serie) => tooltip.showTooltipFromEvent(
    <TableTooltip
      rows={[
        ["Deep", <div className="custom-tooltip__color" style={{ background: "#3B76F6" }} />, `${serie.deepAmount} (${serie.deep}%)`],
        ["Distracted", <div className="custom-tooltip__color" style={{ background: "#FF8975" }} />, `${serie.distractedAmount} (${serie.distracted}%)`],
      ]}
    />,
    e,
  );

  return (
    <>
      {data.map((serie, index) => (
        <rect
          key={serie.date}
          x={bars[index].x}
          y={0}
          height={innerHeight}
          width={bars[index].width}
          fill="transparent"
          onMouseLeave={tooltip.hideTooltip}
          onClick={(e) => showTip(e, serie)}
        />
      ))}
    </>
  );
};

const Lines = (data) => function CustomLayer(layerProps) {
  const {
    bars, xScale, innerWidth, innerHeight,
  } = layerProps;
  const scale = getScale(data, innerWidth, innerHeight);

  const lineGenerator = line()
    .x((serie, index) => {
      const { width, x } = bars[index];
      return x + width / 2;
    })
    .y((serie) => scale.yScale(serie[serie.line]));

  return (
    <>
      <Axes
        yScale={scale.yScale}
        xScale={xScale}
        width={innerWidth}
        height={innerHeight}
        left={{
          ticksPosition: "left",
          legend: "Amount of Taps",
          legendPosition: "middle",
          legendOffset: -50,
        }}
      />
      <Grid
        yScale={scale.yScale}
        xScale={false}
        width={innerWidth}
        height={innerHeight}
      />
      {[
        { key: "deepAmount", color: "#3B76F6" },
        { key: "distractedAmount", color: "#FF8975" },
      ].map(({ key, color }) => (
        <g key={key}>
          <path
            d={lineGenerator(data.map((serie) => ({ ...serie, line: key })))}
            fill="none"
            stroke={color}
            strokeWidth={2}
            style={{ pointerEvents: "none" }}
          />
          {data.map((serie, index) => (
            <circle
              key={serie.date}
              cx={bars[index].x + bars[index].width / 2}
              cy={scale.yScale(serie[key])}
              r={3}
              fill={color}
              stroke={color}
              style={{ pointerEvents: "none" }}
            />
          ))}
        </g>
      ))}
    </>
  );
};

function DepthChart({ data }) {
  const dispatch = useDispatch();
  const { graphStatus } = useSelector(({ reportingReducer }) => reportingReducer);
  const { range, period, types } = graphStatus;
  const { isPending, isPlaceholderData, data: chartData } = data;
  const isBars = types.taps.includes(CHART_TYPES.TAPS_PERCENT);

  const pinchRef = useRef(null);
  usePinch({
    element: pinchRef,
    min: 8,
    max: 20,
    current: range,
    onChange: (newRange) => dispatch(changeGraphStatus({ ...graphStatus, range: newRange })),
    rangeMode: true,
  });

  const filteredData = useMemo(() => {
    if (!isPending) return chartData?.taps.slice(-range);
    return null;
  }, [chartData, range]);

  const formatDate = (value) => {
    const date = value.split("/");
    const newDate = new Date(`${date[2]}-${date[1]}-${date[0]}`);
    return format((newDate), period === "Month" ? "MMM yy" : "d MMM");
  };

  return (
    <ChartLoader
      loading={isPending || isPlaceholderData}
      isData={isPending || filteredData?.length > 0}
      pinchInfo
    >
      <div className="chart-container chart-container--depth" ref={pinchRef}>
        {!!filteredData?.length && (
        <ResponsiveBar
          data={filteredData}
          theme={{
            background: "transparent",
            text: {
              fontSize: 11,
              fontFamily: "Quicksand",
              fill: "#171d66",
            },
            labels: {
              text: {
                fontSize: 10,
              },
            },
          }}
          keys={["deep", "distracted"]}
          indexBy="date"
          colors={["#c6d8ff", "#ffd8d1"]}
          margin={{
            top: 15, right: 40, bottom: 50, left: 60,
          }}
          minValue={0}
          maxValue={100}
          padding={0.3}
          borderRadius={4}
          label={false}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -90,
            format: (value) => formatDate(value),
          }}
          axisLeft={isBars && {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            format: (value) => `${value}%`,
            legend: "Percentage of Taps",
            legendPosition: "middle",
            legendOffset: -50,
          }}
          layers={["axes", isBars ? ["grid", "bars"] : Lines(filteredData), Tips(filteredData), "markers", "legends"].flat()}
          animate={false}
          tooltip={false}
          isInteractive
        />
        )}
        <ChartType
          chartTypeData={CHART_TYPES_TAPS_CHART}
          chartType="taps"
          isSingleSelect
        />
      </div>
    </ChartLoader>
  );
}

export default DepthChart;
