import { RangeInput } from "components";
import { HelpModal } from "features";

import "./RewardSlider.scss";
import { useState } from "react";

function RewardSlider({ difficulty, setDifficulty, calibration }) {
  const [showCalibration, setShowCalibration] = useState(false);

  return (
    <div className="liveSessionPage__difficulty-wrapper">
      <div className="liveSessionPage__difficulty-title">
        <h4>
          Reward Threshold
          <HelpModal
            toggleClass="link font-600"
            location="reward-slider"
            helpIcon
          />
        </h4>
        <div className="liveSessionPage__difficulty-labels">
          <div>Easier</div>
          <div>Harder</div>
        </div>
      </div>
      <div
        role="presentation"
        className="liveSessionPage__difficulty-settings"
      >
        <div className="liveSessionPage__difficulty-calibration-wrapper">
          <div
            className={`liveSessionPage__difficulty-calibration 
              ${showCalibration ? "is-active" : ""} 
              ${difficulty === -calibration ? "is-set" : ""}
            `}
            style={{ left: `${-calibration / 10}%` }}
          >
            calibration
            {" "}
            <strong>{-calibration}</strong>
          </div>
        </div>

        <RangeInput
          max={1000}
          min={0}
          step={50}
          uiStep={100}
          customValues={[-calibration]}
          value={difficulty}
          onChange={(value) => setDifficulty(value)}
          onTouchStart={() => setShowCalibration(true)}
          onTouchEnd={() => setShowCalibration(false)}
        />
      </div>
    </div>
  );
}

export default RewardSlider;
