import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import { getProtocols } from "store/actions/protocols";
import { FRQ_COLOR } from "constants/brainwave_analyzer";
import { PROTOCOLS_TRAIN } from "constants/onBoarding_protocols";

export default function useProtocol(categoryId, protocolId, protocolDetails) {
  const dispatch = useDispatch();

  const { allProtocols, gettingAllProtocols } = useSelector(
    ({ protocolsReducer }) => protocolsReducer,
  );

  const [protocolData, setProtocolData] = useState(null);

  const getProtocol = (category) => {
    if (!category) return null;
    let protocol;
    if ("bands" in category) {
      Object.entries(category.bands).some(
        ([band, locations]) => Object.entries(locations).some(
          ([location, directions]) => Object.entries(directions).some(
            ([direction, id]) => {
              if (id.startsWith(protocolId)) {
                const weights = Array(4).fill(direction.toLowerCase() === "up" ? 1 : -1);
                const name = _.startCase(`${location} ${band} ${direction}`);
                protocol = {
                  protocolName: `Training - ${name}`,
                  color: FRQ_COLOR[band],
                  icon: "training",
                  description: PROTOCOLS_TRAIN[band].description,
                  protocolRules: [
                    {
                      rule: 1,
                      title: name,
                      frequencyBandName: [band],
                      direction,
                      weights,
                      location: [location],
                      measure: "Power",
                      descriptions: {
                        name,
                      },
                    },
                  ],
                };
                return true;
              }
              return false;
            },
          ),
        ),
      );
    } else {
      protocol = category.protocols.find(
        ({ protocolID }) => protocolID === protocolId,
      );
    }
    return protocol;
  };

  useEffect(() => {
    if (protocolDetails?.protocolIds) {
      setProtocolData(protocolDetails);
    } else if (categoryId && protocolId) {
      if (allProtocols) {
        const protocolCategory = allProtocols?.find(
          (category) => category.categoryID === categoryId,
        );
        const protocol = getProtocol(protocolCategory);
        if (protocol) {
          setProtocolData({
            ...protocol,
            categoryName: protocolCategory.protocolCategory,
            categoryID: categoryId,
          });
        }
      } else if (!gettingAllProtocols) dispatch(getProtocols());
    }
  }, [categoryId, protocolId, protocolDetails, allProtocols]);

  return protocolData;
}
