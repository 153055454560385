import { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import "./InputText.scss";

export function InputText({
  label,
  inputType,
  autocomplete,
  placeholder,
  onChangeValue,
  onBlurValue,
  onFocusValue,
  defaultValue,
  inputMode,
  isValid = true,
  autoFocus = false,
  icon,
  onIconClick,
  withButton,
  onEnterPress,
  maxlength,
}) {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const isTypePassword = inputType === "password";
  const input = useRef();

  const onFocus = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onFocusValue?.(e);
    document.body.scrollBy(0, 0);
    // setTimeout(() => {
    //   input.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    // }, 300);
  };
  return (
    <div className="input-container">
      <Form.Group>
        {label && <Form.Label>{label}</Form.Label>}
        <Form.Control
          className={`input ${isTypePassword ? "input--password" : "input--text"}`}
          type={!isPasswordShown ? inputType : "text"}
          placeholder={placeholder}
          maxLength={maxlength}
          inputMode={inputMode || "text"}
          value={defaultValue}
          onChange={onChangeValue}
          onBlur={onBlurValue}
          onFocus={(e) => onFocus(e)}
          isValid={isValid}
          autoComplete={autocomplete || "off"}
          autoFocus={autoFocus}
          ref={input}
          onKeyDown={(e) => e.key === "Enter" && onEnterPress?.()}
        />
      </Form.Group>
      {withButton && (
        <div
          onClick={onIconClick}
          className="select--active cursor-pointer"
          style={{ position: "absolute", right: 10, top: 9 }}
          role="presentation"
        >
          <div className="select-check--default">
            <FontAwesomeIcon icon={icon} />
          </div>
        </div>
      )}
      {isTypePassword && (
        <div
          role="presentation"
          className="icon-wrapper cursor-pointer"
          onClick={() => setIsPasswordShown(!isPasswordShown)}
        >
          <FontAwesomeIcon
            icon={isPasswordShown ? faEyeSlash : faEye}
            className="input--password-svg-icon"
          />
        </div>
      )}
    </div>
  );
}

export default InputText;
