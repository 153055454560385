export const SESSION_SCORES_STATS = {
  PER_MIN: "Per Min",
  TOTAL: "Total",
};

export const JOURNAL_QUESTIONS = {
  HOW_DEEP_DID_YOU_GO: "How did you do?",
  GRAPH_EXPERIENCE: "How well did the graph track your experience?",
  NOTES: "Notes",
};

export const FEEL_GRADE = {
  SLEEP: 1,
  UNHAPPY: 2,
  NEUTRAL: 3,
  HAPPY: 4,
  VERY_HAPPY: 5,
};

export const JOURNAL_ANSWERS_HOW_DEEP_DID_YOU_GO = [
  { number: "1", question: "I Struggled To Relax" },
  {
    number: "2",
    question: "A Little Floggy. Lots of thoughts about my life",
  },
  { number: "3", question: "Calm, relaxed" },
  {
    number: "4",
    question:
      "Lightness, more concentration, may be swaying, clearer images",
  },
  {
    number: "5",
    question:
      "Effortless concentration. Vivid awareness of breath and heartbeat. May feel body filled with air",
  },
  { number: "6", question: "Lucid, detached, deep, intense" },
  { number: "7", question: "New level of awareness" },
];

export const JOURNAL_ANSWERS_GRAPH_EXPERIENCE = [
  { number: "0", question: "N/A: I wasn't noticing" },
  { number: "1", question: "Not at all" },
  { number: "2", question: "Some Misses" },
  { number: "3", question: "Seemed on Target" },
  { number: "4", question: "Well done" },
  { number: "5", question: "Amazing" },
];

export default {
  SESSION_SCORES_STATS,
};
