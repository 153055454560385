import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { SubNav, GuideWrapper } from "components";

import useGuide from "hooks/useGuide";
import { setProtocolGuide } from "store/actions/protocols";
import { saveUserProfile } from "store/actions/profile";
import ProtocolList from "pages/App/Common/ProtocolList/ProtocolList";
import { DiagnosticResults } from "features";

function ProtocolListPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { protocols, protocolGuide } = useSelector(
    ({ protocolsReducer }) => protocolsReducer,
  );

  const { guide, nextStep } = useGuide(1);

  useEffect(() => {
    if (protocols?.length < 1 && protocolGuide) {
      nextStep();
      dispatch(setProtocolGuide(false));
    }
  }, []);

  const onSelect = (categoryID, protocolID) => {
    navigate(`/app/meditation/protocol-details/${categoryID}/${protocolID}`);
  };

  const onRetakeDiagnostic = async () => {
    const options = { navigate, nextUrl: "/app/meditation/protocol-diagnostic" };
    dispatch(saveUserProfile({
      diagnostic: null,
    }, options));
  };

  return (
    <Container>
      <SubNav
        customClass="mb-0"
        title="All Protocols"
        setGuide={nextStep}
        audioGuide="coach-diagnostic-and-protocol-selection"
        onBack={() => navigate("/app/meditation/protocol-calibrated")}
        staticTitle
      />
      <DiagnosticResults
        onRetake={onRetakeDiagnostic}
        collapsed
      />
      <GuideWrapper
        guide={guide}
        step={1}
        content="protocols"
        popoverPositionY="20px"
        pointerPlacement="bottom"
        onClose={nextStep}
      >
        <ProtocolList onSelect={onSelect} />
      </GuideWrapper>
    </Container>
  );
}

export default ProtocolListPage;
