import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { GenericButton } from "components/ui";

import logo from "assets/coach_logo.png";

import "./CoachNav.scss";

export function CoachNav() {
  const navigate = useNavigate();
  const location = useLocation();

  const userLevel = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.subscription_level_id,
  );

  const navElements = [
    {
      permission: 3,
      text: "Clients",
      path: "/coach/clients",
    },
    {
      permission: 3,
      text: "Sessions",
      path: "/coach/sessions",
    },
    {
      permission: 3,
      text: "Diagnostics",
      path: "/coach/diagnostics",
    },
    {
      permission: 5,
      text: "Recalculations",
      path: "/coach/recalculations",
    },
    // {
    // permission: 3,
    // text: "Research Library",
    // className: `button--text ${location.pathname === "/coach/research-library" ? "active" : ""}`,
    // path: "/coach/research-library",
    // },
    {
      permission: 5,
      text: "Tests",
      path: "/coach/tests",
    },
    {
      permission: 5,
      text: "Protocols",
      path: "/coach/protocols-list",
    },
    {
      text: "Logout",
      className: "button button--danger logout",
      onClick: () => navigate("/coach/logout"),
    },
  ];

  return (
    <nav className="coach-nav">
      <Container>
        <div className="coach-nav__content">
          <div className="coach-nav__content-logo">
            <img src={logo} height="40" alt="GoDeeper" />
          </div>
          <div className="coach-nav__content-elements">
            {navElements.map(
              (element) => (!element.permission || element.permission <= userLevel) && (
                <GenericButton
                  key={element.text}
                  text={element.text}
                  className={`button--text ${location.pathname === element.path ? "active" : ""}`}
                  href={element.path}
                  onButtonClick={element.onClick}
                />
              ),
            )}
          </div>
        </div>
      </Container>
    </nav>
  );
}

export default CoachNav;
