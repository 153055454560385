import { useState, useEffect } from "react";

export default function useScrollIsTop() {
  const [scroll, setScroll] = useState(document.body.scrollTop < 15);

  useEffect(() => {
    const onScroll = () => {
      setScroll(document.body.scrollTop < 15);
    };

    document.body.addEventListener("scroll", onScroll);

    return () => {
      document.body.removeEventListener("scroll", onScroll);
    };
  }, [scroll]);

  return scroll;
}
