import { Container } from "react-bootstrap";

import { SubHeader } from "components";

import Expertise from "pages/App/Common/Expertise/Expertise";

function ExpertisePage() {
  return (
    <Container>
      <div className="settingsPage">
        <SubHeader
          title="Experience"
          text="Change information about your meditation practice."
        />
        <Expertise />
      </div>
    </Container>
  );
}

export default ExpertisePage;
