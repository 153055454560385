import { Spinner as RBSpinner } from "react-bootstrap";

import "./Spinner.scss";

export function Spinner({
  fixed = false,
  className = "",
}) {
  return (
    <div className={`spinner ${className} ${fixed ? "spinner--fixed" : ""}`}>
      <RBSpinner animation="border" role="status" />
    </div>
  );
}

export default Spinner;
