import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Modal, Accordion } from "react-bootstrap";
import { GenericButton, InputText } from "components";
import { InfoBox } from "components/ui";
import Scrollbar from "react-perfect-scrollbar-z";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { getProtocols } from "store/actions/protocols";
import { createTestBatch, getTestBatchesExists } from "api/services/coach";

import { LEVEL_CARDS_COLORS } from "constants/onBoarding_level";

import "features/CoachTable/CoachModal.scss";
import "./BatchCreator.scss";

function BatchCreator({
  activeFilter,
  totalRecords,
  showModal,
  setShowModal,
}) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { allProtocols } = useSelector(
    ({ protocolsReducer }) => protocolsReducer,
  );

  const defaultCreationState = {
    note: "",
    agreement: false,
    type: null,
    level: [],
    eb: "",
  };

  const [creationState, setCreationState] = useState(defaultCreationState);
  const [isScheduleValid, setIsScheduleValid] = useState(false);

  const accordionScroll = useRef(null);

  const processingStatus = useMutation({
    mutationFn: () => getTestBatchesExists(),
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["batches-existance"],
        () => response,
      );
    },
  });

  const onLevelSelect = (selection) => {
    const selected = creationState.level.some((item) => item.levelId === selection.levelId)
      ? creationState.level.filter((item) => item.levelId !== selection.levelId)
      : [...creationState.level, selection];
    setCreationState({ ...creationState, level: selected });
  };

  const startTests = async () => {
    await createTestBatch({
      ...activeFilter,
      note: creationState.note,
      target: {
        type: creationState.type,
        scope: creationState[creationState.type] || null,
      },
    });
    setTimeout(() => {
      processingStatus.mutate();
      setCreationState(defaultCreationState);
      setShowModal(false);
    }, 1000);
  };

  useEffect(() => {
    const isScopeValid = () => {
      switch (creationState.type) {
        case "family": return true;
        case "level": return creationState.level.length > 0;
        case "eb": return !!creationState.eb.trim();
        default: return false;
      }
    };
    setIsScheduleValid(() => (
      !(totalRecords > 10 && !creationState.agreement) && isScopeValid()
    ));
  }, [creationState]);

  useEffect(() => {
    dispatch(getProtocols());
  }, []);

  return (
    <Modal
      animation
      show={showModal}
      dialogClassName="dialog"
      className="coach-modal batchCreator wide"
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Schedule Test Sessions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="text-center">
            <h2>
              Number of sessions:
              {" "}
              {totalRecords}
            </h2>
            <p className="text-muted">Based on active filters</p>
            <br />
            <p>
              Your scheduled Test Sessions will be visible on Test Page.
              <br />
              Processing time depends on chosen number of sessions.
            </p>
            <p>We will inform you by e-mail when the tests are completed.</p>
          </div>
          <div className="input__wrapper">
            <InputText
              label="Batch Note (optional)"
              defaultValue={creationState.note}
              onChangeValue={(e) => setCreationState({ ...creationState, note: e.target.value })}
            />
          </div>
          {totalRecords > 10 && (
          <InfoBox type="danger" customClass="justify-content-center">
            <p>
              <strong>You chose over 10 sessions for tests!</strong>
              <br />
              Processing time will be much longer.
            </p>
            <div className="alertBox__checkbox">
              <input
                type="checkbox"
                checked={creationState.agreement}
                onChange={(e) => setCreationState(
                  { ...creationState, agreement: e.target.checked },
                )}
              />
              <span>Understand and proceed</span>
            </div>
          </InfoBox>
          )}
        </div>

        {allProtocols && (
          <div>
            <h2>Choose target protocols</h2>
            <Accordion
              defaultActiveKey={creationState.type}
              onSelect={(type) => setCreationState(
                { ...creationState, type },
              )}
            >
              <Accordion.Item eventKey="family">
                <Accordion.Header>By Family</Accordion.Header>
                <Accordion.Body>
                  <p>
                    Traditional session data will be replicated into
                    Modern session data based on the orginal session family.
                  </p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="level">
                <Accordion.Header>By Style</Accordion.Header>
                <Accordion.Body>
                  <div
                    role="presentation"
                    className="protocolSelection__clear"
                    onClick={() => setCreationState({ ...creationState, level: [] })}
                  >
                    Clear All
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </div>
                  <Scrollbar className="protocolSelection__wrapper" refScroll={accordionScroll} always height="325px">
                    {allProtocols.map((category) => category.protocolCategory !== "Training Protocols" && (
                    <div key={category.categoryID} className="protocolSelection__group">
                      <h3 className="mb-3">{category.protocolCategory}</h3>
                      {category.protocols.map((protocol) => (
                        <div className="protocolSelection__item" key={protocol.protocolID}>
                          <div className="select select--xs">
                            <div className="select-text">{protocol.protocolName}</div>
                            <div className="protocolSelection__item-levels">
                              {protocol.levels.map((item) => (
                                <div
                                  role="presentation"
                                  key={item.level}
                                  className={`protocolSelection__item-level ${creationState.level.some((el) => el.levelId === item.levelID) ? "selected" : ""}`}
                                  style={{ background: `${LEVEL_CARDS_COLORS[item.level - 1]}` }}
                                  onClick={() => onLevelSelect(
                                    { protocolName: protocol.protocolName, levelId: item.levelID },
                                  )}
                                >
                                  {`Lvl. ${item.level}`}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    ))}
                  </Scrollbar>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="eb">
                <Accordion.Header>E.B.</Accordion.Header>
                <Accordion.Body>
                  <InputText
                    label="Custom identifier (required)"
                    defaultValue={creationState.eb}
                    onChangeValue={(e) => setCreationState(
                      { ...creationState, eb: e.target.value },
                    )}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        )}

      </Modal.Body>
      <Modal.Footer>
        <GenericButton
          text="Cancel"
          onButtonClick={() => setShowModal(false)}
        />
        <GenericButton
          text="Start Tests"
          className="button--success"
          disabled={!isScheduleValid}
          onButtonClick={startTests}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default BatchCreator;
