import { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { getBrainwaveReport } from "api/services/reporting";

import { EmptyData, Spinner } from "components";

import InfoRules from "features/BrainwaveInterpretation/BrainwaveInfo/InfoRules";
import { getRulesContent } from "features/BrainwaveInterpretation/helpers/helper";

import { BRAINWAVE_STATUS } from "constants/brainwave_analyzer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import appIcon from "assets/icon.png";

import "./SessionMetrics.scss";

function SessionMetrics({
  summary, sessionProtocol, sessionLevel, sessionRule,
}) {
  const { sessionId } = summary.sessionData;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const userLevel = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.subscription_level_id,
  );

  const {
    isPending, isError, isSuccess, isRefetching, data: brainwaveReport, refetch,
  } = useQuery({
    queryKey: ["session-brainwaves", sessionId],
    queryFn: () => getBrainwaveReport(sessionId),
    gcTime: Infinity,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (isSuccess && !isRefetching && !brainwaveReport.data) {
      queryClient.invalidateQueries({
        queryKey: ["session-brainwaves", sessionId],
        exact: true,
        refetchType: "none",
      });
      if ([1, 3].includes(brainwaveReport.status)) setTimeout(() => refetch(), 10000);
    }
  }, [isSuccess, isRefetching]);

  const rulesContent = useMemo(() => {
    const rules = sessionProtocol?.protocolRules;
    const scores = brainwaveReport?.data?.general?.zscore.session.rules;
    if (rules && scores) {
      return getRulesContent(scores, rules, sessionLevel, sessionRule);
    }
    return null;
  }, [brainwaveReport, sessionProtocol?.protocolRules, sessionRule]);

  if (isPending) {
    return (
      <EmptyData>
        <Spinner />
      </EmptyData>
    );
  }

  if (isError) {
    return (
      <EmptyData
        icon={faCircleExclamation}
        title="Downloading analysis failed"
        text="Check your connection and try again..."
      />
    );
  }

  if (brainwaveReport.status !== 2) {
    const status = brainwaveReport.status - 1;
    return (
      <EmptyData
        icon={BRAINWAVE_STATUS[status].icon}
        title={BRAINWAVE_STATUS[status].title}
        text={BRAINWAVE_STATUS[status].text}
        isLoading={status === 0}
      />
    );
  }

  if (rulesContent) {
    return (
      <>
        <h3>Metrics Report Card</h3>
        <p className="text-sm">
          Each of these metrics are associated with depth for
          this meditation style and level of expertise.
        </p>
        <InfoRules
          protocolDetails={sessionProtocol}
          rulesContent={rulesContent}
        />
        {userLevel >= 2 && (
        <div
          role="presentation"
          className="sessionSummaryPage__btn-brainwave"
          onClick={() => navigate(`/app/progress/brainwave-analyzer/${sessionId}`)}
        >
          <div className="button-icon-wrapper">
            <img
              src={appIcon}
              alt="GoDeeper"
            />
          </div>
          <span>
            Explore This Session in the
            {" "}
            <br />
            Brainwave Analyzer
          </span>
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </div>
        )}
      </>
    );
  }

  return null;
}

export default SessionMetrics;
