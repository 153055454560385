import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Container, Form } from "react-bootstrap";

import {
  GenericButton, InputText, ErrorValidation, Card,
} from "components";
import { SocialButtons } from "features";
import useFormData from "hooks/useFormData";
import { loginUser } from "store/actions/authentication";
import { LOGIN_FORM } from "constants/forms_data";
import cardImg from "assets/goDeeper_logo.png";

import "./CoachLogin.scss";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    formData, formErrors, setFormValue, formValidate,
  } = useFormData(LOGIN_FORM);

  const onLogin = async (e) => {
    e.preventDefault();
    const response = await dispatch(loginUser(formData));
    formValidate(response, () => navigate("/coach"));
  };

  return (
    <Container className="coachLogin">
      <div className="coachLogin__wrapper">
        <Card imgUrl={cardImg}>
          <h2>Coach Panel</h2>
          <Form onSubmit={(e) => onLogin(e)} autoComplete="on">
            {LOGIN_FORM.map((input) => (
              <div key={input.key}>
                <InputText
                  inputType={input.inputType}
                  placeholder={input.placeholder}
                  autocomplete={input.autocomplete}
                  defaultValue={formData[input.key]}
                  onChangeValue={(e) => setFormValue(e.target.value, input.key)}
                  isValid={!formErrors[input.key]}
                  inputMode={input.key}
                />
                <ErrorValidation errors={formErrors[input.key]} />
              </div>
            ))}
            <div className="coachLogin__buttonWrapper">
              <GenericButton
                text="Sign In with Email"
                type="submit"
                className="button-login--plain"
              />
              <div className="coachLogin__separator">
                <span> OR </span>
              </div>
            </div>
          </Form>
          <SocialButtons />
        </Card>
      </div>
    </Container>
  );
}

export default Login;
