import {
  useState, useMemo, useCallback, useEffect, useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";

import {
  GenericButton, InfoBox, Spinner,
  ZoomSlider,
} from "components";
import { SessionChart } from "features/SessionChart/SessionChart";
import useProtocolCertification from "hooks/useProtocolCertification";
import { getBrainwaveReport } from "api/services/reporting";
import { isCoachPanel } from "utils/app-url-origin";
import { isAllowed } from "utils/user-permissions";
import { addCalibrationRule, setProtocolToCalibrate } from "store/actions/protocols";

import { GRAPH_ZOOM } from "constants/session_graph";
import {
  FRQ_KEYS, FRQ_FILTER_DATA, FRQ_COLOR,
} from "constants/brainwave_analyzer";

import TapsInfo from "./TapsInfo/TapsInfo";
import ChartsWrapper from "./BrainwaveCharts/ChartsWrapper";
import BrainwaveInfo from "./BrainwaveInfo/BrainwaveInfo";
import InfoRules from "./BrainwaveInfo/InfoRules";
import { SessionInfo } from "./SessionInfo/SessionInfo";
import { DepthBarChart } from "./DepthBarChart/DepthBarChart";
import { TimeRange } from "./TimeRange/TimeRange";
import { TapsModal } from "./TapsModal/TapsModal";
import { BrainwaveFilters } from "./BrainwaveFilters/BrainwaveFilters";
import { getDepthData, getFilterData, processData } from "./helpers/helper";

import "./BrainwaveInterpretation.scss";

export function BrainwaveInterpretation({
  sessionData, sessionId, sessionProtocol, sessionRule, sessionLevel,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userId = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.pk,
  );
  const protocols = useSelector(
    ({ protocolsReducer }) => protocolsReducer.protocols,
  );
  const userLevel = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.subscription_level_id,
  );
  const isCoach = isAllowed(userLevel, "COACH");

  const { protocol: protocolIds, fullData } = sessionData || null;

  const [zoom, setZoom] = useState(2);
  const [hiddenRules, setHiddenRules] = useState([]);
  const [quotes, setQuotes] = useState(false);
  const [filters, setFilters] = useState({
    options: "all",
    metrics: "protocol",
    graphsType: "mean",
    frqs: FRQ_FILTER_DATA,
  });
  const [timeline, setTimeline] = useState({ start: null, end: null });
  const scrollSync = useRef({ scrollX: 0, wrappers: [], zoom });

  const setScrollSync = useCallback((i) => ({
    setWrapper: (wrapperRef) => { scrollSync.current.wrappers[i] = wrapperRef; },
    setScrollX: (x) => { scrollSync.current.scrollX = x; },
    setZoom: (zoomLevel) => { scrollSync.current.zoom = zoomLevel; },
  }), []);

  const protocolCertification = useProtocolCertification(
    protocolIds.levelID,
    protocolIds.family,
    protocolIds.rule,
    true,
  );

  const isCertified = protocolCertification.global?.certified;
  const isDepthAvailable = isCertified || (isCoach && isCoachPanel);
  const { isPending, isSuccess, data: brainwaveReport } = useQuery({
    queryKey: ["session-brainwaves", sessionId],
    queryFn: () => getBrainwaveReport(sessionId),
    staleTime: Infinity,
  });

  useEffect(() => {
    if (isSuccess) {
      if (!brainwaveReport.data?.details || brainwaveReport.data?.rules.length === 0) {
        toast.error("Brainwave data could not be generated.");
        navigate(-1);
      }
    }
  }, [isSuccess]);

  const filterData = useMemo(
    () => getFilterData(
      fullData,
      { tapsData: sessionData.taps, zoneData: sessionData.zoneData },
      filters.options,
      { start: timeline.start, end: timeline.end },
    ),
    [brainwaveReport, filters.options, timeline],
  );

  const depthData = useMemo(() => getDepthData(fullData, filterData), [fullData, filterData]);

  const processedData = useMemo(() => processData(
    brainwaveReport?.data,
    sessionProtocol,
    sessionLevel,
    sessionRule,
    filterData,
  ), [brainwaveReport, sessionProtocol, sessionLevel, sessionRule, filterData]);

  const toggleFrq = (frq) => {
    const frqs = { ...filters.frqs, [frq]: !filters.frqs[frq] };
    if (Object.values(frqs).includes(true)) setFilters((state) => ({ ...state, frqs }));
  };

  const calibration = useMemo(() => protocols?.find(
    (protocol) => protocol.thresholds.protocolIds.protocolID === protocolIds.protocolID
    && protocol.level === Number(sessionLevel),
  ), [protocols, protocolIds, sessionLevel]);

  const calibrateRule = useCallback(
    async (rule) => {
      if (calibration) {
        // eslint-disable-next-line no-underscore-dangle
        const success = await dispatch(addCalibrationRule(userId, calibration._id, rule));
        if (success) toast.success("Rule added to existing protocol calibration.");
      } else {
        dispatch(setProtocolToCalibrate({
          protocol: sessionProtocol,
          level: { level: Number(sessionLevel), levelID: protocolIds.levelID },
          rule,
        }));
        navigate("/app/meditation/protocol-calibration");
      }
    },
    [protocols, protocolIds, sessionLevel],
  );

  const handleSetZoom = (zoomLevel) => {
    scrollSync.current.zoom = zoomLevel;
    setZoom(zoomLevel);
  };

  return !isPending && processedData ? (
    <>
      <div className="brainwaveGraphs__navHeader">
        {/* {baseline?.date && (
        <p className="text-muted text-sm text-center">
          Deviation Compared to
          {" "}
          {format(new Date(baseline.date), "MMM do, yyyy")}
          {" "}
          Baseline
        </p>
        )} */}
      </div>
      <div className="brainwaveGraphs">
        <Card className="graph-card">
          {isDepthAvailable && (
            <>
              <div className="d-flex justify-content-between mb-3">
                <h3 className="mb-0">Depth Score</h3>
              </div>
              {filters.graphsType === "timeline" && (
              <div className="brainwaveGraphs__depth-timeline">
                <SessionChart
                  sessionData={sessionData}
                  filterData={filterData}
                  depthAvg={depthData.avg ? -depthData.avg : null}
                  timeline={timeline}
                  analyze={filters.options}
                  zoomState={[zoom, setZoom]}
                  scrollSync={scrollSync}
                  setScrollSync={setScrollSync(1)}
                  isCertified={isDepthAvailable}
                />
              </div>
              )}
              {filters.graphsType === "mean" && (
              <div className="brainwaveGraphs__depth-mean">
                <DepthBarChart chartData={depthData.barData} />
              </div>
              )}
            </>
          )}
          <div className="brainwaveGraphs__depth-footer">
            <TimeRange
              timeline={timeline}
              setTimeline={setTimeline}
              sessionId={sessionData.sessionId}
            />
            {isDepthAvailable && filters.graphsType === "mean" && (
            <div className="brainwaveGraphs__depth-avg">
              <span>AVG</span>
              {" "}
              <strong>{depthData.avg ? depthData.avg.toFixed(0) : "-"}</strong>
            </div>
            )}
            {filters.graphsType === "timeline" && (
            <ZoomSlider
              zoom={zoom}
              setZoom={handleSetZoom}
              onChangeEnd={(value) => setZoom(value)}
              max={GRAPH_ZOOM.length - 1}
            />
            )}

          </div>
          <hr />
          <div className="brainwaveGraphs__header">
            <h3>{filters.metrics === "protocol" ? "Protocol Rules" : "Brain State"}</h3>
            <p className="text-sm text-muted">
              {filters.metrics === "protocol" ? "Performance Z-Score" : "SD from Baseline"}
            </p>
          </div>
          {filters.metrics === "brain" && (
            <div className="brainwaveGraphs__filters-brain">
              <div className="button-group">
                {FRQ_KEYS.map((frq) => (
                  <GenericButton
                    key={frq}
                    text={frq}
                    className={`${!filters.frqs[frq] ? "button--inactive" : ""}`}
                    style={filters.frqs[frq] ? { background: FRQ_COLOR[frq] } : null}
                    onButtonClick={() => toggleFrq(frq)}
                  />
                ))}
              </div>
            </div>
          )}
          <div className="brainwaveGraphs__wrapper">
            <ChartsWrapper
              chartsData={processedData}
              tapsData={sessionData.taps}
              filterData={filterData}
              hiddenFrq={filters.frqs}
              activeState={filters.metrics}
              activeChartType={filters.graphsType}
              zoom={zoom}
              hiddenRules={hiddenRules}
              scrollSync={scrollSync}
              setScrollSync={setScrollSync}
            />
          </div>
          {filters.graphsType === "timeline" && (
          <div className="graph-card__footer">
            {sessionData.taps.length === 0
              ? <TapsInfo />
              : <TapsModal sessionId={sessionData.sessionId} taps={sessionData.taps} />}
          </div>
          )}
        </Card>
      </div>
      <BrainwaveFilters
        dataLength={sessionData.fullData.length}
        filters={filters}
        setFilters={setFilters}
        timeline={timeline}
        setTimeline={setTimeline}
        scrollSync={scrollSync}
        setScrollSync={setScrollSync}
        isCertified={isCertified}
        isCoach={isCoach}
        isCoachPanel={isCoachPanel}
      />
      {filters.metrics === "protocol" && (
      <section className={`brainwaveAnalyzer__analyze ${quotes ? "w-quotes" : ""}`}>
        <h3>Detailed Metrics Report Card</h3>
        <p className="text-sm">
          Click on the arrow to the right of each rule for suggestions for how to get deeper on this
          rule and for the option to create a protocol to train on just one rule.
        </p>
        <InfoRules
          protocolDetails={sessionProtocol}
          rulesContent={processedData?.protocol.rulesContent}
          calibration={calibration}
          calibrateRule={calibrateRule}
          hiddenRules={hiddenRules}
          setHiddenRules={setHiddenRules}
          isCollapsed
        />
        <InfoBox customClass="mt-3">
          <p>
            Click on the metric name in the card to turn it off in the graphs.
          </p>
        </InfoBox>
      </section>
      )}
      {isCoachPanel && (
      <SessionInfo
        data={brainwaveReport?.data?.general}
        rulesList={sessionProtocol.protocolRules}
      />
      )}
      <section className={`brainwaveAnalyzer__info ${quotes ? "w-quotes" : ""}`}>
        <BrainwaveInfo
          activeState={filters.metrics}
          rulesContent={processedData?.protocol.rulesContent}
          brainData={processedData?.brain.brainData}
          setQuotes={setQuotes}
          frqsFilters={filters.frqs}
          rulesFilters={hiddenRules}
        />
      </section>
    </>
  ) : (
    <div className="brainwaveAnalyzer__loading">
      <Spinner />
      <h3>Generating Brainwave Data</h3>
      <p className="text-muted mb-0">Please wait for a few seconds...</p>
    </div>
  );
}

export default BrainwaveInterpretation;
