export const CALIBRATED_PROTOCOLS_START = "CALIBRATED_PROTOCOLS_START";
export const CALIBRATED_PROTOCOLS_SUCCESS = "CALIBRATED_PROTOCOLS_SUCCESS";
export const CALIBRATED_PROTOCOLS_ERROR = "CALIBRATED_PROTOCOLS_ERROR";

export const GET_PROTOCOLS = "GET_PROTOCOLS";
export const GET_PROTOCOLS_ERROR = "GET_PROTOCOLS_ERROR";
export const GET_PROTOCOLS_SUCCESS = "GET_PROTOCOLS_SUCCESS";

export const SAVE_CALIBRATED_PROTOCOL_ERROR = "SAVE_CALIBRATED_PROTOCOL_ERROR";
export const SAVE_CALIBRATED_PROTOCOL_SUCCESS = "SAVE_CALIBRATED_PROTOCOL_SUCCESS";

export const DELETE_CALIBRATED_PROTOCOL = "DELETE_CALIBRATED_PROTOCOL";
export const DELETE_CALIBRATED_PROTOCOL_ERROR = "DELETE_CALIBRATED_PROTOCOL_ERROR";
export const DELETE_CALIBRATED_PROTOCOL_SUCCESS = "DELETE_CALIBRATED_PROTOCOL_SUCCESS";

export const ADD_CALIBRATION_RULE = "ADD_CALIBRATION_RULE";
export const DELETE_CALIBRATION_RULE = "DELETE_CALIBRATION_RULE";

export const RESET_PROTOCOL_SYNC = "RESET_PROTOCOL_SYNC";

export const SET_PROTOCOL_TO_CALIBRATE = "SET_PROTOCOL_TO_CALIBRATE";

export const SET_PROTOCOL_GUIDE = "SET_PROTOCOL_GUIDE";

export const SET_PROTOCOLS_LIST_COLLAPSE = "SET_PROTOCOLS_LIST_COLLAPSE";
