import { useState, useMemo } from "react";
import { format } from "date-fns";

import CERTIFICATION_REQ from "constants/certification";
import UNLOCKABLE_CONTENT from "constants/unlockable_content";

import { Card } from "components";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as StarIcon } from "assets/icons_promo_dot.svg";

import ReferModal from "./ReferModal";

import "./ContributionCard.scss";

function ContributionCard({ isValid, protocolCertification }) {
  const [showModal, setShowModal] = useState(false);

  const counter = useMemo(() => (
    isValid
      ? protocolCertification.user.counter + 1
      : protocolCertification.user.counter
  ), []);

  const contributionData = useMemo(() => {
    if (!protocolCertification.user) return null;
    const {
      resets, completeDate,
    } = protocolCertification.user;
    const dots = [...Array(CERTIFICATION_REQ.contributionsNeeded)]
      .map((_value, index) => (index + 1 <= counter ? "success" : "empty"));
    if (!isValid && counter < 10) dots[counter] = "fail";
    const status = (() => {
      if (counter === 10) return "completed";
      if (!isValid) return "fail";
      if (resets.counter > 0 && resets.afterReset && counter === 1) return "reset";
      return "success";
    })();
    const date = completeDate ? format(new Date(completeDate), "MMM do") : "";
    const text = {
      fail: "Session didn’t count because there weren’t enough taps or it was not long enough.",
      reset: "The counter was reset because you didn’t reach 10 sessions last month.",
      success: `Get here by ${date} to get a free month!`,
      completed: "You’ve unlocked 1 free month of GoDeeper!",
    };
    return {
      dots,
      status,
      text: {
        tooltip: text[status],
        contribution: isValid ? "Thank You for Contributing!" : "Try To Contribute Again!",
      },
    };
  }, [protocolCertification]);

  const renderDot = (value, index) => {
    const arrowIndex = (() => {
      if (contributionData.status === "reset") return 0;
      if (isValid || contributionData.status === "completed") return 9;
      return counter;
    })();
    const content = {
      success: { icon: <FontAwesomeIcon icon={faCheck} />, class: "success" },
      empty: { icon: null, class: "empty" },
      fail: { icon: <FontAwesomeIcon icon={faTimes} />, class: "fail" },
    };
    if (index === 9 && value !== "fail") {
      content.empty.icon = <StarIcon />;
      content.success.icon = <StarIcon />;
    }
    return (
      <div key={index.toString()} className={`dot is-${content[value].class}`}>
        {content[value].icon}
        {index === arrowIndex && (<span className={`arrow is-${contributionData.status}`} />)}
      </div>
    );
  };

  return (
    <div className="sessionComplete__contribution card">
      <div className="sessionComplete__counter">
        <p className="text-xs font-700">
          {protocolCertification.user.counterDate
            ? `Sessions Completed Since ${format(new Date(protocolCertification.user.counterDate), "MMM do")}:`
            : "Start Completing Eligible Sessions:"}
        </p>
        <div className="sessionComplete__counter-wrapper">
          <div className="sessionComplete__counter-dots">
            {contributionData.dots.map((value, index) => renderDot(value, index))}
          </div>
          <p className="text-sm m-0">
            {counter}
            /
            {CERTIFICATION_REQ.contributionsNeeded}
          </p>
        </div>
        <div className={`sessionComplete__counter-info is-${contributionData.status}`}>
          {contributionData.text.tooltip}
        </div>
      </div>
      <h3>{contributionData.status === "completed" ? "Refer a Fellow Meditator" : contributionData.text.contribution}</h3>
      {contributionData.status === "completed" ? (
        <p className="text-sm">
          We need more practitioners of this technique before we can
          certify this protocol to enable depth scores and audio feedback.
        </p>
      ) : (
        <p className="text-sm">
          We need a minimum of
          {" "}
          <strong>{CERTIFICATION_REQ.sessionsNeeded}</strong>
          {" "}
          sessions from
          {" "}
          <strong>{CERTIFICATION_REQ.uniqueUsers}</strong>
          {" "}
          unique contributors to certify this protocol for
          accurate depth scores and audio feedback.
        </p>
      )}
      <div
        onClick={() => setShowModal(true)}
        role="presentation"
        className="text-sm font-700 text-decoration-underline help-toggle"
      >
        <FontAwesomeIcon icon={faQuestionCircle} />
        What Happens When a Protocol Gets Certified?
      </div>
      <ReferModal />
      <Modal
        animation
        dialogClassName="dialog dialog-bg"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <h1 className="text-center">What Happens When a Protocol Gets Certified?</h1>
          <p className="text-center text-sm my-4">
            When a protocol is certified it means we have found the right combination of
            metrics to reliably measure depth for a particular style and level of
            meditation practice. This makes it possible to offer super-accurate audio
            feedback to help people go deeper, faster. We call it “GPS for meditation”.
          </p>
          {UNLOCKABLE_CONTENT.map((element) => (
            <Card
              key={element.title}
              customClass="pb-0 mb-4 text-center"
            >
              <h2>{element.title}</h2>
              <p className="text-sm mt-4">{element.caption}</p>
              <img src={element.img} alt={element.title} />
            </Card>
          ))}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ContributionCard;
