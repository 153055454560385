import { useSelector } from "react-redux";
import { MeditationStyleForm } from "features";

import "./Techniques.scss";

function TechniquesPage({
  tabIndex, saveBtnText,
}) {
  const techniqueOptions = useSelector(
    ({ onBoardingReducer }) => onBoardingReducer.onBoardingChoices?.general_practices,
  );
  const userSavedTechniqueOptions = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.meditation_styles,
  );

  return (
    <div className="meditationSection">
      <h2>
        Which Techniques have you tried?
      </h2>
      <p className="text-muted">
        Choose all that apply
      </p>
      <MeditationStyleForm
        stepIndex={tabIndex}
        saveBtnText={saveBtnText}
        stepOptions={techniqueOptions}
        stepSavedOptions={userSavedTechniqueOptions}
        stepType={1}
        inputPlaceholder="Add another technique"
      />
    </div>
  );
}

export default TechniquesPage;
