const CHART_COLORS = [
  "#97e3d5",
  "#cddc39",
  "#fdb462",
  "#e78ac3",
  "#66c2a5",
  "#8c75b4",
  "#f5d74e",
  "#00a6da",
  "#FF8975",
];

export default CHART_COLORS;
