import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { GenericButton, BottomBarSticky } from "components";

import logo from "assets/logo_stacked.png";
import { ReactComponent as Sign } from "assets/founder_sign.svg";

import AudioGuide from "features/AudioGuide/AudioGuide";

import "./Introduction.scss";

function IntroductionPage() {
  const navigate = useNavigate();

  const completedSteps = useSelector(
    ({ profileReducer }) => profileReducer.userProfile?.onboarding_tab,
  );

  return (
    <div className="introPage">
      <img src={logo} alt="GoDeeper_logo" className="introPage__logo" />
      <Container className="introPage__container">
        <div className="introPage__trial-description mb-5">
          <h1 className="h2 text-center mb-4">Welcome & Thank You</h1>
          <h2 className="text-md font-600 mb-0">
            GoDeeper is IMBR’s* platform to help people
            go deeper faster in meditation.
          </h2>
          <AudioGuide
            track="coach-welcome"
            menuPosition="up"
            playerOnly
            detached
          />
          <p>
            In this early phase, it empowers people like you to contribute
            your brainwaves to help us compile feedback protocols for every
            meditation style and level of expertise.
          </p>
          <p>
            Get your first month free and maintain free access for the next six months
            by contributing 10 sessions per month.
          </p>
        </div>
        <div className="introPage__quote">
          <p className="font-600 text-sm mb-1">
            &ldquo;IMBR is a public benefit corporation where
            all income goes back into more research.&ldquo;
          </p>
          <p className="text-xs">Mark McDonough - IMBR Founder</p>
          <Sign />
        </div>
        <BottomBarSticky>
          <GenericButton
            text={completedSteps === 0 ? "Start" : "Continue Set-up"}
            className="button--white"
            onButtonClick={() => navigate("/app/onboarding/steps")}
          />
        </BottomBarSticky>
      </Container>
    </div>
  );
}

export default IntroductionPage;
