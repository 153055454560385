import { Pagination } from "components";
import { getPaginationStatus } from "features/CoachTable/helpers/paginationStatus";

import "./CoachTablePagination.scss";

function CoachTablePagination({
  totalPages,
  totalNumber,
  pagination,
  setPagination,
}) {
  const paginagtionStatus = getPaginationStatus(
    pagination.page,
    totalPages,
    totalNumber,
    pagination.perPage,
  );
  const onPaginationChange = (type, value) => {
    setPagination(
      type === "perPage"
        ? { perPage: value, page: 1 }
        : { ...pagination, page: value },
    );
  };

  return (
    <div className="coach-pagination-container">
      <div className="coach-pagination-container__group">
        <div className="coach-pagination-container__status">
          <p>{paginagtionStatus}</p>
        </div>
        <div>
          Display
          <select
            className="coach-pagination-container__select"
            value={pagination.perPage}
            onChange={(e) => onPaginationChange("perPage", e.target.value)}
          >
            <option>10</option>
            <option>25</option>
            <option>50</option>
          </select>
          records
        </div>
      </div>
      <Pagination
        pageCount={totalPages || 1}
        forcePage={pagination.page}
        onPageChange={(e) => onPaginationChange("page", e.selected + 1)}
      />
    </div>
  );
}

export default CoachTablePagination;
