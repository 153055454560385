import ReactSlider from "react-slider";
import { formatDuration } from "utils/time-format";
import { GRAPH_TIMESCALE } from "constants/session_graph";

import "./BrainwaveFiltersTimerangeSlider.scss";

export function BrainwaveFiltersTimerangeSlider({
  dataLength, timeline, setTimeline, scrollSync,
}) {
  const getRange = (timeRange) => timeRange.map((time) => Math.round((100 * time) / dataLength));
  const getTime = (timePart) => Math.round((dataLength * timePart) / 100);

  const onSelect = (range) => {
    scrollSync.current.wrappers.forEach((element) => {
      if (element) {
        element.scrollTo({
          left: GRAPH_TIMESCALE[scrollSync.current.zoom].scale * getTime(range[0]) - 20,
          behavior: "smooth",
        });
      }
    });
    setTimeline(getTime(range[0]) === 0 && getTime(range[1]) === dataLength
      ? { start: null, end: null }
      : { start: getTime(range[0]), end: getTime(range[1]) });
  };

  return (
    <ReactSlider
      className="brainwaveGraphs__slider"
      trackClassName={`${timeline.start !== null && timeline.end !== null ? "active" : ""} brainwaveGraphs__slider-track`}
      defaultValue={[0, 100]}
      value={getRange([timeline.start ?? 0, timeline.end ?? dataLength])}
      renderThumb={(props, state) => (
        <div
          {...props}
          key={props.key}
          id={props.key}
          className="brainwaveGraphs__slider-thumb"
        >
          <span className="brainwaveGraphs__slider-time">
            {formatDuration(getTime(state.valueNow) * 1000, false)}
          </span>
        </div>
      )}
      onAfterChange={(range) => onSelect(range)}
      minDistance={1}
      snapDragDisabled
      pearling
    />
  );
}

export default BrainwaveFiltersTimerangeSlider;
