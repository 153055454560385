import { useMemo } from "react";
import BADGES from "constants/badges";
import SessionBadge from "./SessionBadge";
import halfZonesPercentages from "./helpers/halfZonesPercentages";

import "./SessionBadges.scss";

export function SessionBadges({
  fullData, length, thresholds, report, withSummary = false, isCertified,
}) {
  const scores = useMemo(() => {
    const minutes = length / 60000;
    const getScoresPerMinute = (value, time) => Number(value / time).toFixed(2);
    const getRandomQuote = (quotes) => Math.floor(Math.random() * quotes.length);
    return {
      summary: report.zonePercentage,
      points: Number(getScoresPerMinute(report.points, minutes)),
      trophies: Number(getScoresPerMinute(report.rewards, minutes)),
      warnings: Number(getScoresPerMinute(report.reminders, minutes)),
      zones: report.zonePercentage,
      halfZones: halfZonesPercentages(fullData, thresholds, report.zoneCounter),
      quote: getRandomQuote(BADGES.quote.content),
    };
  }, [fullData, length, thresholds, report]);

  const {
    summary,
    points,
    trophies,
    warnings,
    zones,
    halfZones,
    quote,
  } = scores;

  const getBadges = (scoreTypes) => {
    const badgesData = [];
    Object.keys(BADGES).forEach((key) => {
      Object.keys(scoreTypes).forEach((type) => {
        if (BADGES[key].name === type) {
          const badge = BADGES[key].content.find(({ metric }) => metric(scores[type]));
          if (badge) {
            badgesData.push({
              score: scoreTypes[type], title: BADGES[key].name, icon: BADGES[key].icon, ...badge,
            });
          }
        }
      });
    });
    return badgesData;
  };

  const recommendations = getBadges({ zones, halfZones });
  const badges = getBadges({ points, trophies, warnings });
  const randomQuote = { icon: BADGES.quote.icon, ...BADGES.quote.content[quote] };
  const summaryText = getBadges({ summary })[0];

  const getBadgeComponent = (type) => (
    <SessionBadge
      key={type.text}
      title={type.title}
      color={type.color}
      score={type.score}
      icon={type.icon}
      text={type.text}
      author={type.author}
    />
  );

  return (
    <div className="SessionBadges">
      {isCertified && recommendations.length > 0 && (
      <>
        <h2 className="mb-3">Recommendations</h2>
        <div className="SessionBadges__recommendations">
          {recommendations.map((rec) => (
            getBadgeComponent(rec)
          ))}
        </div>
      </>
      )}
      {isCertified && (
        <>
          <h2 className="mb-3">Badges</h2>
          <div className="SessionBadges__badges">
            {withSummary && getBadgeComponent(summaryText)}
            {badges.map((badge) => (
              getBadgeComponent(badge)
            ))}
          </div>
        </>
      )}
      {getBadgeComponent(randomQuote)}
    </div>
  );
}

export default SessionBadges;
