import { useEffect } from "react";
import { Spinner as RBSpinner } from "react-bootstrap";

import "./LoadingOverlay.scss";

export function LoadingOverlay({
  loadingText,
  darkMode,
  spinner = true,
  onClick,
  isTop = false,
}) {
  useEffect(() => {
    document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, []);

  return (
    <div className={`loading-overlay ${darkMode ? "loading-overlay--dark" : ""} ${isTop ? "loading-overlay--top" : ""}`} onClick={onClick} role="presentation">
      <div className="loading-overlay__spinner">
        {spinner && (
          <RBSpinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </RBSpinner>
        )}
      </div>
      {loadingText && (
        <p>{loadingText}</p>
      )}
    </div>
  );
}

export default LoadingOverlay;
