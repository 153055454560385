import { ReactComponent as FilterIcon } from "assets/icons_filter.svg";
import { useState, useRef, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEyeSlash, faSortUp, faSortDown, faThumbtack,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Close } from "assets/icons_close.svg";
import {
  InputText, InputMinMax, DateRange, GenericButton,
} from "components";
import Dropdown from "react-bootstrap/Dropdown";
import Scrollbar from "react-perfect-scrollbar-z";
import { format } from "date-fns";

import "./FilterMenu.scss";

function FilterMenu({
  header,
  selectChoices,
  activeFilter,
  sendFilters,
  togglePinning,
  getPinnedColumn,
}) {
  const { column, index } = header;
  const isLast = index === header.headerGroup.headers.length - 1;
  const refScroll = useRef(null);

  const activeFilterValue = useMemo(
    () => activeFilter.filters?.find((item) => item.id === column.columnDef.name)?.value,
    [activeFilter],
  );

  const formattedValue = (value) => {
    const getDate = (date) => (date ? new Date(date) : 0);
    switch (column.columnDef.filter_type) {
      case "keyword":
        return value || "";
      case "select":
        return value ? value.split(",").map((item) => +item) : [];
      case "range":
        return {
          min: value?.split("_")[0] || null,
          max: value?.split("_")[1] || null,
        };
      case "time_range":
        return {
          min: value?.split("_")[0] || null,
          max: value?.split("_")[1] || null,
        };
      case "date":
        return {
          startDate: getDate(value?.split("_")[0]),
          endDate: getDate(value?.split("_")[1]),
          key: "selection",
        };
      default:
        return null;
    }
  };

  const [tempSettings, setTempSettings] = useState({});
  const [showCalendar, setShowCalendar] = useState(false);
  const [dates, setDates] = useState(null);

  const normalizedValue = formattedValue(tempSettings.changed
    ? tempSettings.value
    : activeFilterValue);

  const defaultDate = normalizedValue?.startDate && normalizedValue?.endDate
    ? (tempSettings.value || activeFilterValue).replace("_", " - ")
    : "Choose dates";

  const updateFilterValue = (value) => {
    const updatedValue = (newValue) => {
      setTempSettings({ ...tempSettings, value: newValue, changed: true });
    };
    switch (column.columnDef.filter_type) {
      case "keyword":
        return updatedValue(value);
      case "select":
        return updatedValue(value.length > 0 ? value.toString() : null);
      case "range":
        return (!!value.min || !!value.max)
          ? updatedValue(`${(value.min) || ""}_${value.max || ""}`)
          : updatedValue(null);
      case "time_range":
        return (!!value.min || !!value.max)
          ? updatedValue(`${(value.min) || ""}_${value.max || ""}`)
          : updatedValue(null);
      case "date":
        return updatedValue(value?.startDate && value?.endDate
          ? `${format(new Date(value.startDate), "yyyy-MM-dd")}_${format(new Date(value.endDate), "yyyy-MM-dd")}`
          : null);
      default:
        return null;
    }
  };

  const onMenuOpen = () => {
    if (column.columnDef.filter_type === "date") {
      setShowCalendar(false);
      setDates(normalizedValue);
    }
    setTempSettings({
      value: activeFilterValue,
      sortDirection: (activeFilter.sortBy?.id === column.columnDef.name)
          && activeFilter.sortBy.direction,
    });
  };

  const onSort = (direction) => {
    setTempSettings({
      ...tempSettings,
      sortDirection: tempSettings.sortDirection === direction ? null : direction,
      changed: true,
    });
  };

  const onClear = () => setTempSettings({ value: null, direction: null, changed: true });

  const onApplyFilter = () => {
    sendFilters({
      id: column.columnDef.name,
      name: column.columnDef.title,
      value: tempSettings.value,
      sortDirection: tempSettings.sortDirection,
    });
  };

  const basicActionButtons = [
    {
      key: 1,
      text: "Sort",
      style: column.columnDef.filter_type === "select" ? { opacity: "0.5", pointerEvents: "none" } : {},
      className: `button ${(tempSettings.sortDirection === 1) ? "active" : ""}`,
      onClick: () => onSort(1),
      icon: <FontAwesomeIcon icon={faSortUp} className="svg-icon sort-up" />,
    },
    {
      key: 2,
      text: "Sort",
      style: column.columnDef.filter_type === "select" ? { opacity: "0.5", pointerEvents: "none" } : {},
      className: `button ${(tempSettings.sortDirection === -1) ? "active" : ""}`,
      onClick: () => onSort(-1),
      icon: <FontAwesomeIcon icon={faSortDown} className="svg-icon sort-down" />,
    },
    {
      key: 3,
      text: "Hide",
      className: "button",
      icon: <FontAwesomeIcon icon={faEyeSlash} className="svg-icon" />,
      onClick: () => {
        column.toggleVisibility(false);
        if (getPinnedColumn() === column.id) togglePinning(column.id);
      },
    },
  ];

  return (
    <Dropdown
      className="filterMenu__button"
      onToggle={(state) => state && onMenuOpen()}
    >
      <Dropdown.Toggle>
        <FilterIcon className={(
          activeFilter.sortBy?.id === column.columnDef.name
          || activeFilter.filters?.some((item) => item.id === column.columnDef.name))
          ? "active" : ""}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className={`filterMenu menu ${isLast && "last"}`}>
        <div className="filterMenu__btns">
          {basicActionButtons.map((button) => (
            <button
              key={button.key}
              type="button"
              style={button.style}
              className={button.className}
              onClick={() => button.onClick()}
            >
              {button.text}
              {button.icon}
            </button>
          ))}
          <Dropdown.Item
            className={`button pin ${getPinnedColumn() === column.id ? "pinned" : ""}`}
            onClick={() => setTimeout(() => { togglePinning(column.columnDef); }, 200)}
          >
            <FontAwesomeIcon icon={faThumbtack} className="svg-icon pin" />
          </Dropdown.Item>
        </div>

        {column.columnDef.filter_type === "keyword" && (
          <div className="filterMenu__type">
            <div className="filterMenu__type-name">Filter by keyword</div>
            <div className="filterMenu__type-keyword">
              <InputText
                defaultValue={normalizedValue}
                onChangeValue={(e) => updateFilterValue(e.target.value)}
                onEnterPress={onApplyFilter}
                autoFocus
              />
            </div>
          </div>
        )}
        {(column.columnDef.filter_type === "select" && selectChoices && selectChoices[column.id]) && (
          <div className="filterMenu__type">
            <div className="filterMenu__type-group">
              <div className="filterMenu__type-name">Filter by value</div>
              <button
                type="button"
                className="filterMenu button-text"
                onClick={selectChoices[column.columnDef.name]?.length === normalizedValue?.length
                  ? () => updateFilterValue([])
                  : () => updateFilterValue(selectChoices[column.columnDef.name]
                    .map((i) => i.value))}
              >
                { selectChoices[column.id].length === normalizedValue.length
                  ? "Deselect all" : "Select all" }
              </button>
            </div>
            <Scrollbar className="filterMenu__type-select" refScroll={refScroll} always maxHeight="225px">
              <ul>
                {selectChoices[column.id].map((item) => (
                  <li key={item.value}>
                    <GenericButton
                      text={item.key}
                      className={`${normalizedValue.includes(item.value) ? "active" : ""}`}
                      onButtonClick={() => (!normalizedValue.includes(item.value)
                        ? updateFilterValue([...normalizedValue, item.value])
                        : updateFilterValue(normalizedValue.filter((i) => i !== item.value)))}
                    />
                  </li>
                ))}
              </ul>
            </Scrollbar>
          </div>
        )}
        {(column.columnDef.filter_type === "range" || column.columnDef.filter_type === "time_range") && (
          <div className="filterMenu__type">
            <div className="filterMenu__type-name">Filter by range</div>
            <div className="filterMenu__type-range">
              <InputMinMax
                id="min"
                label="Min"
                type={column.columnDef.filter_type === "time_range" && "time"}
                defaultValue={normalizedValue.min || (column.columnDef.filter_type === "time_range" && "00:00:00") || ""}
                min={0}
                max={Number(normalizedValue?.max) || ""}
                placeholder="-"
                onBlurValue={(value) => updateFilterValue({ ...normalizedValue, min: value })}
                onChangeValue={() => {}}
              />
              <InputMinMax
                id="max"
                label="Max"
                type={column.columnDef.filter_type === "time_range" && "time"}
                defaultValue={normalizedValue.max || (column.columnDef.filter_type === "time_range" && "00:00:00") || ""}
                min={Number(normalizedValue?.min) || 0}
                placeholder="-"
                onBlurValue={(value) => updateFilterValue({ ...normalizedValue, max: value })}
                onChangeValue={() => {}}
              />
            </div>
          </div>
        )}
        {column.columnDef.filter_type === "date" && (
          <div className="filterMenu__type">
            <div className="filterMenu__type-name">Filter by date</div>
            <div className="filterMenu__type-date">
              <div
                className="daterange-form__input"
                aria-hidden="true"
                onClick={() => setShowCalendar(true)}
              >
                {defaultDate}
                <div
                  role="presentation"
                  className="daterange-form__input-close"
                  onClick={(e) => {
                    e.stopPropagation();
                    updateFilterValue(null);
                  }}
                >
                  <Close />
                </div>
              </div>

              <div className="calendar" style={{ visibility: `${!showCalendar ? "hidden" : ""}` }}>
                <DateRange
                  ranges={[dates]}
                  onChange={(item) => setDates(item.selection)}
                  onClose={() => {
                    updateFilterValue(dates);
                    setShowCalendar(false);
                  }}
                />
              </div>

            </div>
          </div>
        )}

        <div className="filterMenu__btns footer">
          <GenericButton
            text="Clear"
            onButtonClick={() => onClear()}
          />
          <Dropdown.Item
            className={`${tempSettings.changed ? "apply" : ""}`}
            onClick={() => onApplyFilter()}
          >
            Apply
          </Dropdown.Item>
        </div>

      </Dropdown.Menu>
    </Dropdown>
  );
}

export default FilterMenu;
