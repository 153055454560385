import { clientApi, userApi } from "api/axios";
import * as urls from "api/urls";
import errorHandler from "utils/error-handler";

export const getSuggestedProtocols = async (userId) => {
  try {
    const { data } = await clientApi.get(urls.getSuggestedProtocolsUrl(userId));
    return data;
  } catch (err) {
    errorHandler(err);
    return false;
  }
};

export const saveCalibration = async (payload) => {
  try {
    const { data } = await userApi.post(urls.saveCalibrationDataUrl(), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    return false;
  }
};

export const getProtocolCertificationList = async () => {
  try {
    const { data } = await userApi.get(urls.getProtocolCertificationListUrl());
    return data;
  } catch (err) {
    errorHandler(err);
    return false;
  }
};

export const getTotalProtocolCertifications = async () => {
  try {
    const { data } = await userApi.get(urls.getTotalProtocolCertificationsUrl());
    return data;
  } catch (err) {
    errorHandler(err);
    return false;
  }
};

export const getProtocolCertification = async (levelID) => {
  try {
    const { data } = await userApi.get(urls.getProtocolCertificationUrl(levelID));
    return data;
  } catch (err) {
    errorHandler(err);
    return false;
  }
};

export const getProtocolCertificationUser = async (levelID) => {
  try {
    const { data } = await userApi.get(urls.getProtocolCertificationUserUrl(levelID));
    return data;
  } catch (err) {
    errorHandler(err);
    return false;
  }
};

export const getProtocolUniqueContributors = async (levelID) => {
  try {
    const { data } = await userApi.get(urls.getProtocolUniqueContributorsUrl(levelID));
    return data;
  } catch (err) {
    errorHandler(err);
    return false;
  }
};

export const saveReferral = async (payload) => {
  try {
    const { data } = await userApi.post(urls.saveReferralUrl(), payload);
    return data;
  } catch (err) {
    return err;
  }
};

export const certifyProtocol = async (levelID, payload) => {
  try {
    const { data } = await userApi.post(urls.certifyProtocolUrl(levelID), payload);
    return data;
  } catch (err) {
    errorHandler(err);
    return false;
  }
};

export const updateProtocolCertificationQuery = async (levelID, family, rule, queryClient) => {
  if (family === "training" || rule || !queryClient) return;
  const certificationData = await getProtocolCertification(levelID);
  if (certificationData) {
    queryClient.setQueryData(["protocol-certification", levelID], certificationData);
  }
};
