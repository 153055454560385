import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Container, Form } from "react-bootstrap";

import {
  HeaderLogo, GenericButton, InputText, ErrorValidation, SectionSeparator,
} from "components";
import { SocialButtons } from "features";
import useFormData from "hooks/useFormData";
import { loginUser } from "store/actions/authentication";
import { LOGIN_FORM } from "constants/forms_data";

import "./Login.scss";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    formData, formErrors, setFormValue, formValidate,
  } = useFormData(LOGIN_FORM);

  const onLogin = async (e) => {
    e.preventDefault();
    const response = await dispatch(loginUser(formData));
    formValidate(response, () => navigate("/app"));
  };

  return (
    <>
      <HeaderLogo />
      <Container className="login__container">
        <div className="login__login-title">Sign In</div>
        <Form onSubmit={(e) => onLogin(e)} autoComplete="on">
          {LOGIN_FORM.map((input) => (
            <div key={input.key}>
              <InputText
                inputType={input.inputType}
                placeholder={input.placeholder}
                autocomplete={input.autocomplete}
                defaultValue={formData[input.key]}
                onChangeValue={(e) => setFormValue(e.target.value, input.key)}
                isValid={!formErrors[input.key]}
                inputMode={input.key}
              />
              <ErrorValidation errors={formErrors[input.key]} />
            </div>
          ))}
          <div className="login__button-container">
            <GenericButton
              text="Sign In with Email"
              type="submit"
              className="button--success"
            />
            <div className="text-center text-sm mt-3">
              Forgot password?
              <GenericButton
                text="Reset"
                onButtonClick={() => navigate("/app/reset-password")}
                buttonType="text"
                className="button-link"
              />
            </div>
            <SectionSeparator text="OR" />
          </div>
        </Form>
        <SocialButtons />
        <div className="login__registration-container">
          <div>
            If you do not have an account,
            <GenericButton
              text="Sign Up"
              onButtonClick={() => navigate("/app/registration")}
              buttonType="text"
              className="button-link"
            />
          </div>
        </div>
      </Container>
    </>
  );
}

export default Login;
