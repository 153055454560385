import {
  useState, useRef, useMemo, useEffect,
} from "react";
import { ResponsiveLine } from "@nivo/line";
import { useDraggable } from "react-use-draggable-scroll";

import { formatDuration, generateNumberArray } from "utils/time-format";
import useClickOutside from "hooks/useClickOutside";
import {
  faHandsClapping, faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GRAPH_TIMESCALE } from "constants/session_graph";
import { TapNote } from "components";

import "./LineChart.scss";
import _ from "lodash";

export function LineChart({
  chartData, tapsData, filterData, dashedLines = false, zoom, hiddenRules, hiddenFrq,
  scrollSync, setScrollSync, overlay,
}) {
  const [activeNote, setActiveNote] = useState(null);

  const chartBodyRef = useRef();
  const scrollRef = useRef();
  const { events } = useDraggable(scrollRef);

  useClickOutside(
    chartBodyRef,
    () => !!activeNote && setActiveNote(null),
  );

  useEffect(() => {
    scrollRef.current.scrollLeft = scrollSync.current.scrollX;
  }, []);

  const filteredData = useMemo(() => {
    if (chartData) {
      if (hiddenRules) return chartData.filter((rule) => !hiddenRules?.includes(rule.ruleId));
      if (hiddenFrq) return chartData.filter((serie) => hiddenFrq[serie.frq]);
      return chartData;
    }
    return null;
  }, [chartData, hiddenRules, hiddenFrq]);

  const setTickValues = () => {
    const length = chartData[0]?.data.length || 0;
    const values = generateNumberArray(0, length, 1, false);
    return values.filter((value) => value % GRAPH_TIMESCALE[zoom].steps === 0);
  };

  const getTooltip = (data) => (
    <div className="custom-tooltip series">
      {data.slice.points.map((serie) => (
        <div className="custom-tooltip__serie" key={serie.serieId}>
          <div className="custom-tooltip__color" style={{ background: serie.serieColor }} />
          <span>{serie.serieId}</span>
          <strong>{serie.data.yFormatted}</strong>
        </div>
      ))}
    </div>
  );

  const onScroll = _.debounce((e) => {
    if (scrollSync.current.scrollX === e.target.scrollLeft) return;
    setScrollSync.setScrollX(e.target.scrollLeft);
    scrollSync.current.wrappers.forEach((el) => {
      if (el && e.target !== el) el.scrollTo({ left: e.target.scrollLeft, behavior: "smooth" });
    });
  }, 300);
  const renderTap = (index) => {
    const tap = tapsData.find(({ timestamp }) => timestamp === index);
    if (tap) {
      return (
        <div className={`marker-tap tapIcon type-${tap.type}`}>
          {[...Array(tap.type)].map((_type, i) => <span key={i.toString()} />)}
        </div>
      );
    }
    return null;
  };

  const setClassName = (i) => {
    if (!filterData?.[i]) return "";
    return !filterData[i + 1] ? "last-active" : "active";
  };

  const onNoteShow = (index) => {
    if (tapsData.length === 0) return;
    setActiveNote(tapsData.find(({ timestamp, notes }) => timestamp === index && notes) || null);
  };

  return filteredData && (
  <div className={`brainwaveGraphs__line-chart-wrapper ${dashedLines ? "w-dashed" : ""}`} ref={chartBodyRef}>
    <div className="brainwaveGraphs__line-chart-scaleY">
      {overlay && (
      <div className="metrics-overlay">
        <div className="metrics-overlay__label-good">
          <FontAwesomeIcon icon={faHandsClapping} />
          Great
        </div>
        <div className="metrics-overlay__label-bad">
          <FontAwesomeIcon icon={faThumbsDown} />
          Poor
        </div>
      </div>
      )}
      <ResponsiveLine
        className="test"
        data={[...filteredData].reverse()}
        theme={{
          background: "transparent",
          text: {
            fontSize: 11,
            fontFamily: "Quicksand",
            fill: "#171d66",
          },
        }}
        margin={{
          top: 15, right: 10, bottom: 50, left: 30,
        }}
        enableGridX={false}
        enablePoints={false}
        xScale={{
          type: "point",
        }}
        yScale={{
          type: "linear",
          min: -3,
          max: 3,
          stacked: false,
          reverse: false,
        }}
        axisBottom={null}
        axisLeft={{
          tickSize: 6,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: 6,
        }}
        pointLabelYOffset={-12}
        layers={[
          "axes",
        ]}
      />
    </div>
    <div
      className="brainwaveGraphs__line-chart-scroll"
      onScroll={onScroll}
      ref={(ref) => {
        scrollRef.current = ref;
        setScrollSync.setWrapper(ref);
      }}
      {...events}
    >
      <div
        className="brainwaveGraphs__line-chart-body"
        style={{
          width: filteredData[0] ? (GRAPH_TIMESCALE[zoom].scale * (filteredData[0].data.length - 1)) : "auto",
        }}
      >
        <div className="brainwaveGraphs__line-chart-step">
          {filteredData[0]?.data.map((_val, index) => index < filteredData[0].data.length - 1 && (
          <div
            key={index.toString()}
            style={{ width: GRAPH_TIMESCALE[zoom].scale }}
            className={setClassName(index)}
          >
            {renderTap(index)}
          </div>
          ))}
        </div>
        <ResponsiveLine
          data={filteredData}
          theme={{
            background: "transparent",
            text: {
              fontSize: 11,
              fontFamily: "Quicksand",
              fill: "#171d66",
            },
            tooltip: {
              container: {
                background: "#ffffff",
                color: "#171d66",
                fontSize: 11,
              },
              tableCell: {
                padding: "2px 5px",
              },
            },
          }}
          colors={filteredData.map((data) => data.color)}
          margin={{
            top: 15, right: 0, bottom: 50, left: 0,
          }}
          enableGridX={false}
          curve="monotoneX"
          xScale={{
            type: "point",
          }}
          yScale={{
            type: "linear",
            min: -3,
            max: 3,
            stacked: false,
            reverse: false,
          }}
          axisBottom={{
            tickValues: setTickValues(),
            tickSize: 5,
            tickPadding: 5,
            format: (value) => formatDuration(value * 1000),
          }}
          gridYValues={6}
          axisLeft={null}
          pointLabelYOffset={-12}
          enableSlices="x"
          sliceTooltip={(data, event) => getTooltip(data, event)}
          layers={[
            "grid",
            "lines",
            "axes",
            "slices",
            "crosshair",
          ]}
          onClick={(value) => onNoteShow(value.points[0].data.x)}
        />
      </div>
    </div>
    {activeNote && <TapNote activeNote={activeNote} setActiveNote={setActiveNote} />}
  </div>
  );
}

export default LineChart;
