import { Card, InfoBox } from "components/ui";
import { Container } from "react-bootstrap";

import { ALL_LEVELS, LEVEL_CARDS_COLORS } from "constants/onBoarding_level";

import "./PracticeSummary.scss";

export function PracticeSummary({
  appPractice, regularHours, totalHours, suggestedLevel,
}) {
  const protocolLevel = suggestedLevel
    && ALL_LEVELS.find((level) => level.key === suggestedLevel).value;

  return (
    <div className="practiceSummary">
      {suggestedLevel && (
        <Container>
          <h3>Practice Calculator</h3>
          <p>
            Based on your practice hours, we suggest that you consider a
            <strong style={{ color: LEVEL_CARDS_COLORS[protocolLevel - 1] }}>
              {` Lvl ${protocolLevel}: ${suggestedLevel} `}
            </strong>
            protocol.
          </p>
        </Container>
      )}
      <Card>
        <div className="practiceSummary__wrapper">
          {appPractice !== 0 && (
          <div className="practiceSummary__details">
            <div className="practiceSummary__entry">
              <div className="practiceSummary__text">
                Regular practice hours:
              </div>
              <div className="practiceSummary__value">
                {regularHours.toFixed(2)}
              </div>
            </div>
            <div className="practiceSummary__entry">
              <div className="practiceSummary__text">
                GoDeeper app hours:
              </div>
              <div className="practiceSummary__value">
                {appPractice.toFixed(2)}
              </div>
            </div>
          </div>
          )}
          <div className="practiceSummary__entry--summary">
            <div className="practiceSummary__text">
              Est. total lifetime practice
            </div>
            <div className="practiceSummary__value">
              {totalHours.toFixed(2)}
            </div>
          </div>
        </div>
      </Card>
      {suggestedLevel && (
        <InfoBox customClass="mb-4">
          <p>
            You can calculate and set your Regular practice hours in Experience
            page inside Settings section.
          </p>
        </InfoBox>
      )}
    </div>
  );
}

export default PracticeSummary;
