/* eslint-disable no-param-reassign */
import { createUseGesture, pinchAction } from "@use-gesture/react";

export default function usePinch({
  element, min = 0, max, step = 2, current, onChange, rangeMode = false, onChangeEnd,
}) {
  const useGesture = createUseGesture([pinchAction]);
  if (element.current) element.current.style.touchAction = "pan-y";

  useGesture(
    {
      onPinch: ({ distance, delta: [direction], memo }) => {
        if (memo && Math.floor(distance[1]) % step === 0 && Math.floor(distance[1]) !== memo[0]) {
          const zoom = direction * (rangeMode ? -1 : 1);
          if (zoom > 0) {
            if ((current + 1) <= max) onChange(current + 1);
            else onChange(max);
          } else if (zoom < 0) {
            if ((current - 1) >= min) onChange(current - 1);
            else onChange(min);
          }
        }

        memo = [Math.floor(distance[1])];
        return memo;
      },
      onPinchEnd: (state) => onChangeEnd?.(state),
    },
    { target: element },
  );
}
