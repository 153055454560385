import { Container } from "react-bootstrap";
import { SubNav } from "components";
import { useNavigate, useParams } from "react-router-dom";
import useProtocol from "hooks/useProtocol";
import ProtocolLevel from "pages/App/Common/ProtocolLevel/ProtocolLevel";

function ProtocolLevelPage() {
  const navigate = useNavigate();
  const { protocolID, categoryID } = useParams();
  const protocol = useProtocol(categoryID, protocolID);

  const onSelect = () => {
    navigate("/app/meditation/protocol-calibration");
  };

  return (
    <Container>
      <SubNav title="Protocol Experience" audioGuide="coach-protocol-expertise" staticTitle />
      {protocol && (
        <ProtocolLevel
          protocol={protocol}
          onSelect={onSelect}
          showSuggest
        />
      )}
    </Container>
  );
}

export default ProtocolLevelPage;
