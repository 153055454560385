import { useSelector } from "react-redux";
import { MeditationStyleForm } from "features";

import "./Apps.scss";

function AppsPage({
  tabIndex, saveBtnText,
}) {
  const appsOptions = useSelector(
    ({ onBoardingReducer }) => onBoardingReducer.onBoardingChoices?.guided_meditations,
  );
  const userSavedAppsOptions = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.meditation_styles,
  );

  return (
    <div className="appsPage">
      <h2>
        Which Guided Meditation Apps have you used?
      </h2>
      <p className="text-muted">Choose all that apply</p>
      <MeditationStyleForm
        stepIndex={tabIndex}
        saveBtnText={saveBtnText}
        stepOptions={appsOptions}
        stepSavedOptions={userSavedAppsOptions}
        stepType={3}
        inputPlaceholder="Add another app"
      />
    </div>
  );
}

export default AppsPage;
