import "./SessionBadge.scss";

function SessionBadge(badgeData) {
  const {
    color,
    title,
    score,
    icon,
    text,
    author,
  } = badgeData;

  return (
    <div className={`SessionBadge__item card ${color || "default"}`}>
      {icon && (
        <div className="SessionBadge__item-svg">{icon}</div>
      )}
      <div className="SessionBadge__item-info">
        {score >= 0 && <p className="SessionBadge__item-score">{`${score} ${title}/min`}</p>}
        <p className="SessionBadge__item-text">{text}</p>
        {author ? <span className="SessionBadge__item-author">{author}</span> : ""}
      </div>
    </div>
  );
}

export default SessionBadge;
