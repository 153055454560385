import { Breadcrumb } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import "./Breadcrumbs.scss";

export function Breadcrumbs({
  active,
  urls,
}) {
  const navigate = useNavigate();
  return (
    <Breadcrumb>
      { urls.length > 0 && urls.map((url) => (
        <Breadcrumb.Item key={url} onClick={() => navigate(url[0])}>{url[1]}</Breadcrumb.Item>
      ))}
      <Breadcrumb.Item active>{active}</Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default Breadcrumbs;
