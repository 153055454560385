import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

import { logoutUser } from "store/actions/authentication";

function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    const userAuth = localStorage.getItem("userAuth");
    const refreshToken = JSON.parse(userAuth).refresh_token;
    dispatch(logoutUser(navigate, refreshToken, queryClient));
  }, []);
  return null;
}

export default Logout;
