import { useMemo } from "react";
import { format } from "date-fns";
import { ReactComponent as SunIcon } from "assets/ic_protocols/ic_sun.svg";
import { PROTOCOLS_ICONS } from "constants/onBoarding_protocols";
import { formatDuration } from "utils/time-format";
import useProtocol from "hooks/useProtocol";

import "./ProgressListItem.scss";

function ProgressListItem({ session }) {
  const protocolDetails = useProtocol(
    session?.protocol.categoryID,
    session?.protocol.protocolID,
    session?.protocol.icon ? session.protocol : null,
  );
  const sessionRule = useMemo(() => {
    if (session?.protocol.ruleName) return session.protocol.ruleName;
    const ruleNo = session?.protocol.rule;
    if (ruleNo && protocolDetails) {
      return protocolDetails.protocolRules.find(
        (rule) => rule.rule === ruleNo,
      ).descriptions?.name;
    }
    return null;
  }, [session, protocolDetails]);
  const duration = formatDuration(session?.length, true);
  const time = `${format(new Date(session.date), "hh:mma")} - ${duration}`;
  const date = `${format(new Date(session.date), "MMM do, yyyy")}`;
  const protocolIcon = () => PROTOCOLS_ICONS.find(({ name }) => name === protocolDetails?.icon)?.getIcon() || <SunIcon className="protocol_icon" />;
  const protocolColor = protocolDetails?.color ? protocolDetails.color : "#171d66";

  const scores = [
    {
      key: "avg_depth",
      name: "Avg. Depth",
      value: Math.round(session?.depthAvg),
    },
    {
      key: "threshold_time",
      name: "Time Above Threshold",
      value: `${Math.round(session?.rewardsPercentage)}%`,
    },
    {
      key: "reward_streak",
      name: "Top Reward Streak",
      value: formatDuration((session?.longestRewardsStreak || 0) * 1000, false, true),
    },
    {
      key: "max_depth",
      name: "Max Depth",
      value: Math.round(Math.abs(session?.depthMax)),
    },
  ];

  const taps = useMemo(() => {
    const tapsData = [
      {
        type: 1,
        count: 0,
        name: "From \nDepth",
      },
      {
        type: 2,
        count: 0,
        name: "From Distraction",
      },
    ];
    return tapsData.map((data) => {
      const count = session.taps.find(({ type }) => data.type === type)?.count || 0;
      return {
        ...data,
        count,
      };
    });
  }, [session.taps]);

  return (
    <div className="listItem">
      <div className="listItem__header">
        <div className="listItem__header-icon" style={{ fill: protocolColor }}>
          {protocolIcon()}
        </div>
        <div className="listItem__header-body">
          <div className="listItem__protocol">
            <div className="listItem__protocol-title">
              <span style={{ color: protocolColor }}>{protocolDetails?.protocolName}</span>
              &nbsp;
              <span style={{ background: protocolColor }} className="listItem__protocol-lvl">
                Lv.
                {" "}
                {session?.protocol.levelID.split("/")[1]}
              </span>
            </div>
          </div>
          {sessionRule && (
            <span style={{ color: protocolColor }} className="listItem__protocol-rule">{sessionRule}</span>
          )}
          <div className="listItem__info">
            <span className="listItem__date">
              {date}
              {" "}
              {time}
            </span>
          </div>
        </div>
      </div>
      <div className="listItem__body">
        {session?.protocol.certified ? scores.map((score) => (
          <div className="listItem__body-col" key={score.key}>
            <div className={`listItem__body-col__score ${score.key}`}>
              {score.value}
            </div>
            <p className="listItem__body-col__text">{score.name}</p>
          </div>
        )) : taps.map((tap) => (tap.type !== 3 || tap.count > 0) && (
          <div className="listItem__body-col" key={tap.name}>
            <div className={`listItem__body-col__score tap-${tap.type}`}>
              {tap.count}
            </div>
            <p className="listItem__body-col__text">{tap.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProgressListItem;
