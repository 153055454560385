const audioController = (action, file, volumeLevel = 1) => {
  window?.ReactNativeWebView?.postMessage(JSON.stringify({ handler: "sounds", data: { action, file, volumeLevel } }));
};
const museController = (action) => {
  window?.ReactNativeWebView?.postMessage(JSON.stringify({ handler: "muse", data: { action } }));
};
const btMenuController = (action) => {
  window?.ReactNativeWebView?.postMessage(JSON.stringify({ handler: "bluetooth", data: { action } }));
};
const socialLoginController = (backend) => {
  window?.ReactNativeWebView?.postMessage(JSON.stringify({ handler: "social", data: { backend } }));
};
const authController = (user) => {
  window?.ReactNativeWebView?.postMessage(JSON.stringify({ handler: "auth", data: { user } }));
};
const appViewController = (viewUrl) => {
  window?.ReactNativeWebView?.postMessage(JSON.stringify({ handler: "appView", data: { viewUrl } }));
};

export {
  audioController, museController, btMenuController, socialLoginController,
  appViewController, authController,
};
