import { ReactComponent as FeelIconSleep } from "assets/icons_feel_sleep.svg";
import { ReactComponent as FeelIconUnhappy } from "assets/icons_feel_unhappy.svg";
import { ReactComponent as FeelIconNeutral } from "assets/icons_feel_neutral.svg";
import { ReactComponent as FeelIconHappy } from "assets/icons_feel_happy.svg";
import { ReactComponent as FeelIconVeryHappy } from "assets/icons_feel_very_happy.svg";

import { FEEL_GRADE } from "constants/profile_progress_session";

import "./FeelSelector.scss";

export function FeelSelector({
  value, onChange, readonly,
}) {
  const feelData = [
    { value: FEEL_GRADE.SLEEP, icon: <FeelIconSleep /> },
    { value: FEEL_GRADE.UNHAPPY, icon: <FeelIconUnhappy /> },
    { value: FEEL_GRADE.NEUTRAL, icon: <FeelIconNeutral /> },
    { value: FEEL_GRADE.HAPPY, icon: <FeelIconHappy /> },
    { value: FEEL_GRADE.VERY_HAPPY, icon: <FeelIconVeryHappy /> },
  ];

  const onClick = (newValue) => {
    if (!readonly) onChange(newValue);
  };

  return (
    <div className="FeelSelector__wrapper">
      {feelData.map((feel) => (
        <div key={feel.value.toString()} className="FeelSelector">
          <div
            onClick={() => onClick(feel.value)}
            role="presentation"
            className={`FeelSelector__item ${String(value) === String(feel.value) ? "active" : ""}`}
          >
            {feel.icon}
          </div>
        </div>
      ))}
    </div>
  );
}

export default FeelSelector;
