export const PROFILE_PROGRESS_SESSIONS_PAGES_PER_PAGE = 5;

export const ORDER_TYPES = [-1, 1];

export const PROGRESS_SESSIONS_FILTER_PROTOCOL = [];
export const PROGRESS_SESSIONS_FILTER_DATE_RANGE = {
  startDate: null,
  endDate: null,
};

export const PROGRESS_SESSION_SETTINGS = Object.freeze({
  BEGIN_PAGE: 1,
  LIMIT: 7,
  ORDER: ORDER_TYPES[0],
  PAGINATION_MAX_NUMBERS: 4,
  FILTERS: null,
});

export const DEFAULT_STREAK_COUNTER = Object.freeze({
  currentStreak: 0,
  longestStreak: 0,
  totalSessions: 0,
  lastSessionDate: "",
});
