import { useState } from "react";
import { Modal } from "react-bootstrap";
import { GenericButton } from "components";
import DragList from "./DragList";

import "features/CoachTable/CoachModal.scss";

function TabsModal({
  tabList,
  showEdit,
  setShowEdit,
  onSave,
}) {
  const [tabs, setTabs] = useState(tabList);
  return (
    <Modal
      animation
      show={showEdit}
      dialogClassName="dialog"
      className="coach-modal"
      onHide={() => setShowEdit(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit Views</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DragList tabs={tabList} setTabs={setTabs} />
      </Modal.Body>
      <Modal.Footer>
        <GenericButton
          text="Cancel"
          onButtonClick={() => setShowEdit(false)}
        />
        <GenericButton
          text="Save"
          className="button--success"
          onButtonClick={() => onSave(tabs)}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default TabsModal;
