import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import {
  HeaderMenu, GenericButton, BluetoothDevice, ButtonLink,
} from "components";
import useProtocolCertification from "hooks/useProtocolCertification";
import { setSessionLength } from "store/actions/session";
import { btMenuController } from "utils/native-controllers";
import { ReactComponent as AudioIcon } from "assets/icons_sound.svg";
import AudioGuide from "features/AudioGuide/AudioGuide";

import MeditationMenuItems from "./Components/MeditationMenuItems";
import CertificationInfo from "./Components/CertificationInfo";

import "./MeditationTraining.scss";

function MeditationTrainingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hiddenElement, setHiddenElement] = useState(false);

  const userSavedProtocol = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.selected_protocol,
  );
  const userSavedLength = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.saved_session_length,
  );
  const { btDevice } = useSelector(({ sessionReducer }) => sessionReducer);
  const { protocols } = useSelector(({ protocolsReducer }) => protocolsReducer);

  const selectedProtocol = protocols?.find(
    (protocol) => protocol.thresholds?.protocolIds?.levelID === userSavedProtocol.levelID,
  );

  const protocolCertification = useProtocolCertification(
    userSavedProtocol?.levelID,
    userSavedProtocol?.family,
    userSavedProtocol?.rule,
    true,
  );

  useEffect(() => {
    if (userSavedProtocol.levelID && !selectedProtocol) {
      toast.error("Previously selected calibration was removed. Please choose another one to Start Session.");
    }
  }, [protocols]);

  const startSession = () => {
    if (btDevice.status === 5) {
      dispatch(setSessionLength(userSavedLength));
      navigate("/app/meditation/signal");
    } else {
      btMenuController("toggle");
      toast.error("Muse device not connected.");
    }
  };

  window.nativeBack = () => false;

  return (
    <>
      <HeaderMenu />
      <Container>
        <div className="meditationMenuPage">
          <CertificationInfo protocolCertification={protocolCertification} />
          <div className="header">
            <h2 style={{ opacity: hiddenElement ? 0 : 1 }}>Start a Meditation Session</h2>
            <AudioGuide track="coach-meditation-setup" setHiddenElement={setHiddenElement} detached />
          </div>
          <MeditationMenuItems
            selectedProtocol={selectedProtocol}
            selectedRule={userSavedProtocol.rule}
            userSavedLength={userSavedLength}
            protocolCertification={protocolCertification}
          />
          {protocolCertification.global?.certified && (
          <div className="meditationMenuPage__sound-settings-container">
            <ButtonLink to="/app/meditation/sound">
              Sound Settings
              <AudioIcon />
            </ButtonLink>
          </div>
          )}
          <BluetoothDevice />
          <div className="meditationMenuPage__start-session-container">
            <GenericButton
              disabled={!(userSavedLength.hours || userSavedLength.minutes) || !selectedProtocol}
              onButtonClick={startSession}
              className="button--start button--dark"
              text="Start"
              iconElement={<FontAwesomeIcon icon={faPlay} />}
            />
          </div>
        </div>
      </Container>
    </>
  );
}

export default MeditationTrainingPage;
