import { useState } from "react";
import { ReactComponent as Diamond } from "assets/icons_diamond.svg";
import { ReactComponent as Trophy } from "assets/icons_trophy.svg";
import { ReactComponent as Warning } from "assets/icons_warning.svg";
import { CustomTabs } from "components/navs/Tabs/Tabs";
import { SESSION_SCORES_STATS } from "constants/profile_progress_session";

import "./SessionScores.scss";

export function SessionScores({
  points, rewards, reminders, length,
}) {
  const [type, setType] = useState(SESSION_SCORES_STATS.PER_MIN);

  const getCurrentScoresData = (value, title, minutes) => {
    if (SESSION_SCORES_STATS.PER_MIN === type) {
      return {
        value: value ? Number(value / minutes).toFixed(1) : 0,
        title: `${title} / min`,
      };
    }
    return { title, value };
  };

  const minutes = length / 60000;
  const scores = [
    { icon: <Diamond />, ...getCurrentScoresData(points, "points", minutes) },
    { icon: <Trophy />, ...getCurrentScoresData(rewards, "trophies", minutes) },
    { icon: <Warning />, ...getCurrentScoresData(reminders, "warnings", minutes) },
  ];

  return (
    <div className="SessionScores">
      <div className="SessionScores__tabs">
        <CustomTabs
          tabs={[
            SESSION_SCORES_STATS.PER_MIN,
            SESSION_SCORES_STATS.TOTAL,
          ]}
          defaultTabKey={type}
          onTabChange={(name) => setType(name)}
          activeTab={type}
        />
      </div>
      <div className="SessionScores__items">
        {scores.map((score) => (
          <div key={score.title} className="SessionScores__item">
            <div className="SessionScores__item-svg">
              {score.icon}
            </div>
            <div className="SessionScores__item-info">
              <h3 className="SessionScores__item-info__value">{score.value || 0}</h3>
              <p className="SessionScores__item-info__title">{score.title}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SessionScores;
