import {
  useState, useEffect, useRef, useMemo,
} from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { audioController } from "utils/native-controllers";
import AUDIO_FILES from "constants/profile_audio";

import { getCurrentZone, getZoneData, getZoneValue } from "pages/App/Meditation/LiveSession/helpers/helper";

export default function useLiveChart(remainingTime, thresholds, isCertified) {
  const userProfile = useSelector(
    ({ profileReducer }) => profileReducer.userProfile,
  );
  const loggedInUserId = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.pk,
  );
  const {
    liveSessionData, sequenceData, distractionData, liveSessionId, remainingSessionTime,
    pausedSessionData,
  } = useSelector(({ sessionReducer }) => sessionReducer);

  // AUDIO DATA
  const audioVolumes = useMemo(() => ({
    blue: userProfile.blue_background_volume,
    green: userProfile.green_background_volume,
    red: userProfile.red_background_volume,
    movement: userProfile.movement_volume,
    reward: userProfile.reward_volume,
    reminder: userProfile.wondering_reminder_volume,
    tap: userProfile.tap_volume,
  }), [userProfile]);

  const audioStreaks = useMemo(() => ({
    reward: userProfile.sounds_after_seconds_deep,
    reminder: userProfile.sounds_after_seconds_wondering,
  }), [userProfile]);

  const audioFiles = useMemo(() => {
    const getSoundName = (key) => AUDIO_FILES[key].find(
      ({ value }) => value === userProfile[key],
    ).file;
    return {
      movement: getSoundName("movement_sound"),
      reward: getSoundName("reward_sound"),
      reminder: getSoundName("wondering_reminder_sound"),
      background: getSoundName("background_sound"),
      tap: getSoundName("tap_sound"),
    };
  }, [userProfile]);

  const tapAudioFiles = useMemo(() => AUDIO_FILES.tap_sound.map(({ file }) => file), []);

  const [chartSessionData, setChartSessionData] = useState({
    userId: loggedInUserId,
    meditationStreamId: liveSessionId,
    fullData: pausedSessionData?.sessionData.fullData || [],
    thresholds,
    protocol: userProfile.selected_protocol,
    taps: pausedSessionData?.sessionData.taps || [],
    timeData: pausedSessionData?.sessionData.timeData || [],
    zoneData: pausedSessionData?.sessionData.zoneData || [],
    distractionData: pausedSessionData?.sessionData.distractionData || [],
    secondsForAReward: audioStreaks.reward,
    secondsForAReminder: audioStreaks.reminder,
  });
  const zoneStreak = useRef({ count: 0, lastZone: "" });
  const tapping = useRef({ status: false, timer: null, taps: 0 });
  const isActive = useRef(false);

  useEffect(() => {
    isActive.current = true;
    return () => {
      audioController("stop", audioFiles.background);
      audioController("stop", audioFiles.reward);
      audioController("stop", audioFiles.reminder);
      audioController("stop", audioFiles.movement);
      tapping.current = {
        status: false, timer: null, timestamp: 0, taps: 0,
      };
    };
  }, []);

  useEffect(() => {
    if (!liveSessionData.length || remainingTime === remainingSessionTime) return;

    const score = _.clamp([...liveSessionData].pop(), -1000, 0);
    const {
      fullData, timeData, taps, zoneData,
    } = chartSessionData;

    if (!tapping.current.status && tapping.current.taps > 0 && fullData) {
      const tapType = tapping.current.taps > 3 ? 3 : tapping.current.taps;
      taps.push({ type: tapType, timestamp: tapping.current.timestamp });
      tapping.current.taps = 0;
    }

    const zoneName = getCurrentZone(score, thresholds);
    if (isCertified && !zoneStreak.current.lastZone && zoneName) {
      audioController("loop", audioFiles.background, audioVolumes[zoneName] / 10);
    }
    if (zoneName === zoneStreak.current.lastZone) {
      zoneStreak.current.count += 1;
    } else {
      zoneStreak.current.count = 1;
      if (isCertified) {
        audioController("volume", audioFiles.background, audioVolumes[zoneName] / 10);
        audioController("stop", audioFiles.reward);
        audioController("stop", audioFiles.reminder);
      }
    }
    if (isCertified && zoneName === "blue" && zoneStreak.current.count === audioStreaks.reward) {
      audioController("loop", audioFiles.reward, audioVolumes.reward / 10);
    }
    if (isCertified && zoneName === "red" && zoneStreak.current.count === audioStreaks.reminder) {
      audioController("loop", audioFiles.reminder, audioVolumes.reminder / 10);
    }
    const zoneValue = getZoneValue(zoneName, zoneStreak.current.count, audioStreaks);
    zoneStreak.current.lastZone = zoneName;
    setChartSessionData((state) => ({
      ...state,
      fullData: [...fullData, score],
      timeData: [...timeData, sequenceData],
      zoneData: [...zoneData, zoneValue],
    }));
  }, [remainingTime]);

  useEffect(() => {
    if (distractionData && distractionData.sequence === sequenceData && isActive.current) {
      setChartSessionData((state) => ({
        ...state,
        distractionData: [...state.distractionData, distractionData],
      }));
    }
  }, [distractionData]);

  useEffect(() => {
    const zoneData = getZoneData(chartSessionData.fullData, thresholds, audioStreaks);
    setChartSessionData((state) => ({
      ...state,
      zoneData,
      thresholds,
    }));
  }, [thresholds]);

  const onTap = (timestamp) => {
    if (Number.isNaN(timestamp)) return;
    clearTimeout(tapping.current.timer);
    tapping.current.taps += 1;
    const tapType = tapping.current.taps > 3 ? 3 : tapping.current.taps;
    if (audioFiles.tap) audioController("play", tapAudioFiles[tapType], audioVolumes.tap / 10);
    if (userProfile.tap_haptic) audioController("haptic", "impactMedium");
    if (!tapping.current.status) {
      tapping.current.status = true;
      tapping.current.timestamp = timestamp;
    }
    tapping.current.timer = setTimeout(() => {
      tapping.current.status = false;
    }, 1000);
  };

  return {
    onTap,
    chartSessionData,
  };
}
