import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  SelectTile, ProtocolSelect, SelectPicker, InfoSection,
} from "components";
import { setProtocolGuide } from "store/actions/protocols";

import { HelpModal } from "features";
import { saveUserProfile } from "store/actions/profile";
import { generateNumberArray } from "utils/time-format";

import { faPen } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Star } from "assets/icons_promo_star.svg";

import "./MeditationMenuItems.scss";

function MeditationMenuItems({
  selectedProtocol, selectedRule, userSavedLength, protocolCertification,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mapLengthOptions = useMemo(() => ([
    generateNumberArray(0, 3, 1, false),
    generateNumberArray(0, 60, 1, false),
  ]), []);

  const onLengthSelect = (time) => {
    const sessionHour = time[0];
    const sessionMinutes = time[1];
    const formattedLength = {
      hours: sessionHour || 0,
      minutes: sessionMinutes || 0,
    };
    dispatch(saveUserProfile({ saved_session_length: formattedLength }));
  };

  const navigateToPage = (value) => {
    navigate(value);
    if (value === "/app/meditation/protocol") dispatch(setProtocolGuide(true));
  };

  return (
    <>
      <div className="meditationMenuPage__select-wrapper">
        <div className="meditationMenuPage__select-wrapper-protocol">
          <h4>Protocol</h4>
          <HelpModal toggleClass="link font-600 text-sm" location="protocols" helpIcon>
            Learn about Protocols
          </HelpModal>
        </div>
        {selectedProtocol
          ? (
            <ProtocolSelect
              protocolID={selectedProtocol?.thresholds.protocolIds.protocolID}
              categoryID={selectedProtocol?.thresholds.protocolIds.categoryID}
              selectedRule={selectedRule}
              level={selectedProtocol?.level}
              onSelect={() => navigateToPage("/app/meditation/protocol-calibrated")}
            />
          )
          : (
            <SelectTile
              onSelect={() => navigateToPage("/app/meditation/protocol-calibrated")}
              customClass="selectTile--sm"
              withButton
            >
              Choose
            </SelectTile>
          )}
      </div>
      <div className="meditationMenuPage__select-wrapper">
        <h4 className="font-400 mb-2">Session Length</h4>
        <SelectPicker
          options={mapLengthOptions}
          initialValue={[userSavedLength.hours, userSavedLength.minutes]}
          onSelect={onLengthSelect}
          labels={["hours", "minutes"]}
          disabled={(value) => !(value[0] || value[1])}
          icon
          infoSection={!protocolCertification.global?.certified
            && protocolCertification.requirements && (
            <InfoSection icon={<Star />}>
              <p className="text-muted">
                <strong>Sessions</strong>
                {" "}
                need to be
                {" "}
                {protocolCertification.requirements.minLength}
                + minutes and include lots of accurate taps to count towards
                {" "}
                <strong className="text-primary">an additional free month</strong>
                .
              </p>
            </InfoSection>
          )}
        >
          <SelectTile
            customClass="selectTile--sm"
            customIconName={faPen}
            withButton
          >
            {userSavedLength && (userSavedLength.hours > 0 || userSavedLength.minutes > 0)
              ? `${userSavedLength.hours} h ${userSavedLength.minutes} min`
              : "Choose"}
          </SelectTile>
        </SelectPicker>
      </div>
    </>
  );
}

export default MeditationMenuItems;
