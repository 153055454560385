import { useState, useRef, useEffect } from "react";
import _ from "lodash";

import { useDraggable } from "react-use-draggable-scroll";
import { TapNote } from "components";
import useClickOutside from "hooks/useClickOutside";

import LayersChartModel from "./LayersChartModel";

import LayersChartWrapper from "./LayersChartWrapper";
import LayersChartBody from "./LayersChartBody";

import "./LayersChart.scss";

export function LayersChart({
  data,
  filterData,
  thresholds,
  zone,
  graphBottom,
  graphTop,
  rewardMarkers,
  reminderMarkers,
  scale,
  taps,
  avgScore,
  timeline,
  marksVisible,
  isColored,
  showCurrentScore,
  analyze,
  scrollSync,
  setScrollSync,
  isCertified,
}) {
  const [activeNote, setActiveNote] = useState(null);
  const chartBodyRef = useRef();
  const wrapperRef = useRef();
  const scrollRef = useRef();
  const { events } = useDraggable(scrollRef);

  useClickOutside(
    chartBodyRef,
    () => !!activeNote && setActiveNote(null),
  );

  useEffect(() => {
    if (scrollRef && scrollSync) scrollRef.current.scrollLeft = scrollSync.current.scrollX;
  }, []);

  useEffect(() => {
    if (wrapperRef.current) {
      new LayersChartModel({
        element: wrapperRef.current,
        data,
        scale,
        graphBottom,
        graphTop,
      }).draw();
    }
    if (showCurrentScore) {
      scrollRef.current.scroll({
        top: 0,
        left: scrollRef.current.scrollWidth,
        behavior: /(iPad|iPhone|iPod)/g.test(navigator.userAgent) ? "auto" : "smooth",
      });
    }
  }, [
    data,
    scale,
    graphBottom,
    graphTop,
    wrapperRef.current,
  ]);

  const onScroll = _.debounce((e) => {
    e.preventDefault();
    if (scrollSync.current.scrollX === e.target.scrollLeft) return;
    setScrollSync.setScrollX(e.target.scrollLeft);
    scrollSync.current.wrappers.forEach((el) => {
      if (el && e.target !== el) el.scrollTo({ left: e.target.scrollLeft, behavior: "smooth" });
    });
  }, 300);

  return (
    <div
      className={
      `LayersChart 
      ${scale > 6 ? "is-zoom" : ""} 
      ${isColored ? "is-colored" : ""} 
      ${showCurrentScore ? "is-live" : ""}`
      }
      ref={chartBodyRef}
    >
      <div className="LayersChart__overlay">
        <div className="LayersChart__overlay-left" />
        <div className="LayersChart__overlay-right" />
        <div className="LayersChart__overlay-bottom" />
      </div>
      {isCertified && (
        <LayersChartWrapper
          data={data}
          zone={zone}
          analyze={analyze}
          thresholds={thresholds}
          avgScore={avgScore}
          graphTop={graphTop}
          graphBottom={graphBottom}
          showCurrentScore={showCurrentScore}
        />
      )}
      <div
        className="LayersChart__scroll"
        onScroll={scrollSync ? onScroll : null}
        ref={(ref) => {
          scrollRef.current = ref;
          setScrollSync?.setWrapper(ref);
        }}
        {...events}
      >
        <div className="LayersChart__body" style={{ width: scale * (showCurrentScore ? data.length : data.length - 1) }}>
          {isCertified && (
          <div className="LayersChart__chart-container" ref={wrapperRef} />
          )}
          <LayersChartBody
            data={data}
            filterData={filterData}
            rewardMarkers={rewardMarkers}
            reminderMarkers={reminderMarkers}
            taps={taps}
            graphBottom={graphBottom}
            graphTop={graphTop}
            scale={scale}
            analyze={analyze}
            timeline={timeline}
            marksVisible={marksVisible}
            isCertified={isCertified}
            activeNote={activeNote}
            setActiveNote={setActiveNote}
          />
        </div>
      </div>
      {activeNote?.notes && <TapNote activeNote={activeNote} setActiveNote={setActiveNote} />}
    </div>
  );
}

export default LayersChart;
