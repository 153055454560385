import { LEVEL_CARDS_COLORS } from "constants/onBoarding_level";
import "./ExpertiseCard.scss";

export function ExpertiseCard({
  title,
  value,
  description,
  activeItem,
  suggestedItem,
  onItemSelect,
  disabled,
}) {
  return (
    <div
      className={`expertiseCard__select-card-wrapper ${
        activeItem ? "expertiseCard__select-card-wrapper--active" : ""
      } ${
        suggestedItem ? "expertiseCard__select-card-wrapper--suggested" : ""
      } ${
        disabled ? "expertiseCard__select-card-wrapper--disabled" : ""
      }`}
      style={{
        backgroundColor: activeItem ? LEVEL_CARDS_COLORS[value - 1] : "white",
        borderColor: suggestedItem ? LEVEL_CARDS_COLORS[value - 1] : "transparent",
      }}
    >
      <div
        role="presentation"
        onClick={onItemSelect}
        className="expertiseCard__select-card"
      >
        <div
          style={{
            backgroundColor: activeItem || disabled ? "white" : LEVEL_CARDS_COLORS[value - 1],
            color: activeItem || disabled ? LEVEL_CARDS_COLORS[value - 1] : "white",
          }}
          className="expertiseCard__select-card-level"
        >
          Lv.
          {" "}
          {value}
        </div>
        <div
          style={{ color: activeItem || disabled ? "inherit" : LEVEL_CARDS_COLORS[value - 1] }}
          className="expertiseCard__select-card-title"
        >
          {title}
        </div>
        <div className="expertiseCard__select-card-description">
          <p className="mb-1">{!disabled ? description : "Not Available"}</p>
          {disabled && <span className="text-sm font-400">Not appropriate for this style of meditation</span>}
        </div>
      </div>
    </div>
  );
}

export default ExpertiseCard;
