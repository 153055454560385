import * as settingsActions from "store/actions/session";

const initialState = {
  appActive: true,
  btDevice: { name: null, status: 0 },
  btDeviceBattery: null,
  btDeviceStream: false,
  activeStream: null,
  sessionLength: 0,
  streamError: null,
  stopStreamError: null,
  signalData: [],
  signalDataError: null,
  signalCheck: [],
  signalCheckSeq: null,
  signalStreamError: null,
  signalCheckCompleted: null,
  distractionData: null,
  gettingLiveSessionData: false,
  liveSessionData: [],
  getLiveSessionDataError: null,
  sequenceData: null,
  liveSessionId: null,
  remainingSessionTime: null,
  pausedSessionData: null,
  pausedSessionError: false,
  calibrationData: [],
  calibrationDataError: null,
};

const sessionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case settingsActions.SET_APP_ACTIVE:
      return {
        ...state,
        appActive: action.payload,
      };
    case settingsActions.SET_BT_DEVICE: {
      return {
        ...state,
        btDevice: action.payload,
        btDeviceBattery: null,
        btDeviceStream: false,
      };
    }
    case settingsActions.GET_BATTERY_STREAM_SUCCESS:
      return {
        ...state,
        btDeviceBattery: action.payload,
      };
    case settingsActions.GET_BT_STREAM_SUCCESS:
      return {
        ...state,
        btDeviceStream: action.payload,
      };
    case settingsActions.SET_SESSION_LENGTH:
      return {
        ...state,
        sessionLength: { ...action.payload },
      };
    case settingsActions.SET_STREAM_ERROR:
      return {
        ...state,
        streamError: action.payload,
      };
    case settingsActions.SET_ACTIVE_STREAM:
      return {
        ...state,
        activeStream: action.payload,
      };
    case settingsActions.SET_SIGNAL_CHECK_COMPLETED:
      return {
        ...state,
        signalCheckCompleted: action.payload,
      };
    case settingsActions.GET_SIGNAL_CHECK_SUCCESS:
      return {
        ...state,
        signalData: { ...action.payload },
      };
    case settingsActions.GET_SIGNAL_CHECK_ERROR:
      return {
        ...state,
        signalStreamError: { ...action.payload },
      };

    case settingsActions.GET_LIVE_SESSION_DATA:
      return {
        ...state,
      };
    case settingsActions.GET_LIVE_SESSION_DATA_SUCCESS:
      return {
        ...state,
        gettingLiveSessionData: true,
      };
    case settingsActions.GET_LIVE_SESSION_DATA_ERROR:
      return {
        ...state,
        gettingLiveSessionData: false,
        getLiveSessionDataError: { ...action.payload },
      };

    case settingsActions.GET_SIGNAL_STREAM_SUCCESS:
      return {
        ...state,
        signalCheck: action.payload.signalCheck,
        signalCheckSeq: action.payload.sequenceNumber,
      };
    case settingsActions.GET_DISTRACTION_STREAM_SUCCESS:
      return {
        ...state,
        distractionData: {
          sequence: action.payload.sequenceNumber,
          values: action.payload.distraction.slice(0, 4),
        },
      };
    case settingsActions.GET_SESSION_STREAM_SUCCESS:
      return {
        ...state,
        liveSessionData: [
          ...state.liveSessionData,
          action.payload.values[0][0],
        ],
        sequenceData: action.payload.sequenceNumber,
        liveSessionId: action.payload.sessionId,
        streamError: action.payload?.rc ? action.payload : null,
      };

    case settingsActions.STOP_STREAM_DATA_SUCCESS:
      return {
        ...state,
        signalCheck: initialState.signalCheck,
        signalCheckSeq: initialState.sequenceNumber,
        streamError: initialState.streamError,
      };
    case settingsActions.STOP_STREAM_DATA_ERROR:
      return {
        ...state,
        stopStreamError: { ...action.payload },
        signalCheck: initialState.signalCheck,
        signalCheckSeq: initialState.sequenceNumber,
        streamError: initialState.streamError,
      };

    case settingsActions.CANCEL_STREAM_DATA_SUCCESS:
      return {
        ...state,
        signalCheck: initialState.signalCheck,
        signalCheckSeq: initialState.sequenceNumber,
        streamError: initialState.streamError,
      };
    case settingsActions.CANCEL_STREAM_DATA_ERROR:
      return {
        ...state,
        stopStreamError: { ...action.payload },
        signalCheck: initialState.signalCheck,
        signalCheckSeq: initialState.sequenceNumber,
        streamError: initialState.streamError,
      };

    case settingsActions.SET_TIMMER:
      return {
        ...state,
        remainingSessionTime: action.payload,
      };

    case settingsActions.PAUSE_SESSION:
      return {
        ...state,
        pausedSessionData: { ...action.payload },
      };
    case settingsActions.PAUSE_SESSION_SUCCESS:
      return {
        ...state,
        pausedSessionData: { ...action.payload },
        distractionData: null,
      };
    case settingsActions.PAUSE_SESSION_ERROR:
      return {
        ...state,
        pausedSessionError: { ...action.payload },
      };

    case settingsActions.CLEAR_LIVE_SESSION_DATA:
      return {
        ...state,
        liveSessionData: initialState.liveSessionData,
        sequenceData: initialState.sequenceData,
        liveSessionId: initialState.liveSessionId,
        gettingLiveSessionData: initialState.gettingLiveSessionData,
        pausedSessionData: initialState.pausedSessionData,
        remainingSessionTime: initialState.remainingSessionTime,
        signalCheck: initialState.signalCheck,
        signalCheckSeq: initialState.sequenceNumber,
        streamError: initialState.streamError,
        distractionData: initialState.distractionData,
      };
    case settingsActions.CLEAR_CALIBRATION_DATA:
      return {
        ...state,
        liveSessionData: initialState.liveSessionData,
        sequenceData: initialState.sequenceData,
        liveSessionId: initialState.liveSessionId,
        calibrationData: initialState.calibrationData,
      };

    case settingsActions.GET_CALLIBRATION_DATA_SUCCESS:
      return {
        ...state,
        calibrationData: [...state.calibrationData, { ...action.payload }],
      };
    case settingsActions.GET_CALLIBRATION_DATA_ERROR:
      return {
        ...state,
        calibrationDataError: { ...action.payload },
      };

    default:
      return state;
  }
};

export default sessionReducer;
