import { Container } from "react-bootstrap";

import { SubHeader } from "components";
import { HelpModal } from "features";
import AudioFeedback from "pages/App/Common/AudioFeedback/AudioFeedback";

import "./SessionSound.scss";

function SessionSoundPage() {
  return (
    <Container>
      <div className="sessionSoundPage">
        <SubHeader
          title="Audio Feedback"
          helpModal={<HelpModal location="audio-settings" helpIcon />}
          text="Creating an enjoyable sound environment is critical to fast learning.
            The more reward you feel, the faster you learn."
          audioGuide="coach-sound-settings"
        />
        <AudioFeedback nextUrl="/app/meditation" />
      </div>
    </Container>
  );
}

export default SessionSoundPage;
