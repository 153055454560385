import { Container } from "react-bootstrap";

import Goals from "pages/App/Common/Goals/Goals";
import { SubHeader } from "components";

function GoalsPage() {
  return (
    <Container>
      <div className="SettingsPage">
        <SubHeader
          title="Meditation Goals"
          text="Change information about your meditation goals and notifications."
          audioGuide="coach-goals"
        />
        <Goals saveBtnText="Save" />
      </div>
    </Container>
  );
}

export default GoalsPage;
