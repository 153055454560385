import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import authenticationReducer from "./reducers/authentication";
import onBoardingReducer from "./reducers/onBoarding";
import profileReducer from "./reducers/profile";
import reportingReducer from "./reducers/reporting";
import sessionReducer from "./reducers/session";
import protocolsReducer from "./reducers/protocols";

const rootReducer = () => combineReducers({
  authenticationReducer,
  onBoardingReducer,
  profileReducer,
  reportingReducer,
  sessionReducer,
  protocolsReducer,
});

const middleWares = [thunk];

const store = configureStore(
  {
    reducer: rootReducer(),
    middleware: () => middleWares,
  },
);

export default store;
