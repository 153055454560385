import { useMemo } from "react";
import { ResponsiveBar } from "@nivo/bar";

import {
  faHandsClapping, faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./MetricsChart.scss";

export function RuleBarChart({
  chartData, keys, hiddenRules,
}) {
  const filteredData = useMemo(() => {
    if (chartData) {
      return chartData.filter((rule) => !hiddenRules.includes(rule.ruleId)).slice().reverse();
    }
    return null;
  }, [chartData, hiddenRules]);

  const getTooltip = (data) => (
    <div className="custom-tooltip">
      <div className="custom-tooltip__color" style={{ background: data.color }} />
      <span>
        {data.data.title}
      </span>
      <strong>
        {data.value.toFixed(2)}
      </strong>
    </div>
  );

  const getTitle = (ruleNo) => {
    const ruleData = filteredData.find(({ rule }) => rule === ruleNo);
    return ruleData && (
      <div className="rule-title">
        <span style={{ color: ruleData.color }}>{ruleData.title}</span>
      </div>
    );
  };

  return filteredData && (
    <div className="bar-chart-wrapper">
      <div className="metrics-overlay">
        <div className="metrics-overlay__label-good">
          <FontAwesomeIcon icon={faHandsClapping} />
          Great
        </div>
        <div className="metrics-overlay__label-bad">
          <FontAwesomeIcon icon={faThumbsDown} />
          Poor
        </div>
      </div>
      <ResponsiveBar
        theme={{
          background: "transparent",
          text: {
            fontSize: 11,
            fontFamily: "Quicksand",
            fill: "#171d66",
          },
          tooltip: {
            container: {
              background: "#ffffff",
              color: "#171d66",
              fontSize: 11,
            },
          },
        }}
        layout="horizontal"
        enableGridX
        gridXValues={7}
        enableGridY={false}
        data={filteredData}
        keys={keys}
        indexBy="rule"
        margin={{
          top: 15, right: 10, bottom: 50, left: 110,
        }}
        padding={0.5}
        innerPadding={5}
        borderRadius={4}
        borderWidth={5}
        borderColor={{
          from: "color",
          modifiers: [
            ["opacity", "0"],
          ],
        }}
        groupMode="grouped"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        minValue={-3}
        maxValue={3}
        colorBy="indexValue"
        colors={filteredData.map((data) => data?.color)}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: 6,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          renderTick: ({ value, x, y }) => (
            <foreignObject x={x} y={y - 12} width={100} height={30}>
              {getTitle(value)}
            </foreignObject>
          ),
        }}
        label={null}
        labelTextColor="white"
        labelSkipHeight={14}
        enableLabel
        tooltip={(data) => getTooltip(data)}
        role="application"
      />
    </div>

  );
}

export default RuleBarChart;
