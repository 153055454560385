import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ResponsivePie } from "@nivo/pie";

import {
  faMountain, faChartSimple, faStopwatch, faBrain,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Diamond } from "assets/icons_diamond.svg";
import { ReactComponent as Trophy } from "assets/icons_trophy.svg";
import { ReactComponent as Warning } from "assets/icons_warning.svg";

import "./ClientInfo.scss";

function ClientInfo({ data }) {
  const zoneData = [
    {
      id: "blue",
      value: data.average_blue,
      color: "#00a6da",
    },
    {
      id: "green",
      value: data.average_green,
      color: "#00c6ab",
    },
    {
      id: "red",
      value: data.average_red,
      color: "#FF8975",
    },
  ];

  const getGoal = (goal) => {
    const [h, m, s] = goal.split(":");
    return (+h + (m / 60) + (s / 360)).toFixed(2);
  };

  return (
    <div className="progressPage__header">
      <div className="progressPage__header-item">
        <h3>
          <FontAwesomeIcon icon={faChartSimple} />
          {" "}
          Sessions Average
        </h3>
        <div className="progressPage__header-item-content">
          <div>
            <h4>%Zone</h4>
            <div className="progressPage__header-pie">
              <ResponsivePie
                data={zoneData}
                margin={{
                  top: 5, left: 5, right: 5, bottom: 5,
                }}
                innerRadius={0.4}
                padAngle={2}
                enableArcLinkLabels={false}
                enableArcLabels={false}
                colors={zoneData.map((zone) => zone.color)}
                valueFormat={(value) => `${value}%`}
                activeOuterRadiusOffset={5}
              />
            </div>
          </div>
          <div className="progressPage__header-data">
            <h4>Stats</h4>
            <div className="progressPage__header-data-row">
              <div className="icon-wrapper"><Diamond /></div>
              Points:
              {" "}
              <strong>
                {data.average_points?.toFixed(0)}
              </strong>
              {" "}
              /min
            </div>
            <div className="progressPage__header-data-row">
              <div className="icon-wrapper"><Trophy /></div>
              Rewards:
              {" "}
              <strong>
                {data.average_rewards?.toFixed(0)}
              </strong>
              {" "}
              /min
            </div>
            <div className="progressPage__header-data-row">
              <div className="icon-wrapper"><Warning /></div>
              Reminders:
              {" "}
              <strong>
                {data.average_reminders?.toFixed(0)}
              </strong>
              {" "}
              /min
            </div>
            <div className="progressPage__header-data-row">
              <div className="icon-wrapper"><FontAwesomeIcon icon={faStopwatch} /></div>
              Length:
              {" "}
              <strong>
                {data.average_length}
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div className="progressPage__header-item">
        <h3>
          <FontAwesomeIcon icon={faMountain} />
          {" "}
          Experience
        </h3>
        <div className="progressPage__header-item-content">
          <div>
            <h4>Practice</h4>
            <div className="progressPage__header-data-row">
              GoDeeper:
              {" "}
              <strong>
                {data.experience_app_hours || 0}
                {" "}
                h
              </strong>
            </div>
            <div className="progressPage__header-data-row">
              Regular:
              {" "}
              <strong>
                {data.experience_practice || 0}
                {" "}
                h
              </strong>
            </div>
            <div className="progressPage__header-data-row">
              Weekly Goal:
              {" "}
              <strong>
                {data.goal_time ? getGoal(data.goal_time) : 0}
                {" "}
                h
              </strong>
            </div>
          </div>
          <div>
            <h4>Sessions</h4>
            <div className="progressPage__header-item-row">
              First Session:
              {" "}
              <strong>
                {data.first_session_date}
              </strong>
            </div>
            <div className="progressPage__header-item-row">
              Last Session:
              {" "}
              <strong>
                {data.last_session_date}
              </strong>
            </div>
            <div className="progressPage__header-item-row">
              All-time:
              {" "}
              <strong>
                {data.sessions_lifetime}
              </strong>
            </div>
            <div className="progressPage__header-item-row">
              This month:
              {" "}
              <strong>
                {data.sessions_this_month}
              </strong>
            </div>
            <div className="progressPage__header-item-row">
              Last month:
              {" "}
              <strong>
                {data.sessions_last_month}
              </strong>
            </div>
          </div>
        </div>
      </div>
      <div className="progressPage__header-item">
        <h3>
          <FontAwesomeIcon icon={faBrain} />
          {" "}
          Meditation
        </h3>
        <div className="progressPage__header-item-content">
          <div>
            <h4>Techniques</h4>
            <div className="progressPage__header-item-row">
              {data.general_practices || "None"}
            </div>
            <h4>Training</h4>
            <div className="progressPage__header-item-row">
              {data.lineage_practices || "None"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientInfo;
