import "./SectionSeparator.scss";

export function SectionSeparator({ text, customClass = "" }) {
  return (
    <div className={`section-separator ${customClass}`}>
      <span className="section-separator-text">
        {" "}
        {text}
        {" "}
      </span>
    </div>
  );
}

export default SectionSeparator;
