import { useState } from "react";
import _ from "lodash";
import useProtocol from "hooks/useProtocol";
import { PROTOCOLS_ICONS } from "constants/onBoarding_protocols";
import { ReactComponent as ArrowIcon } from "assets/icons_arrow_dark.svg";
import { ReactComponent as SunIcon } from "assets/ic_protocols/ic_sun.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt as faTrash } from "@fortawesome/fontawesome-free-regular";
import {
  faArrowRight, faChevronDown, faChevronUp, faXmark,
} from "@fortawesome/free-solid-svg-icons";

import "./ProtocolSelect.scss";

export function ProtocolSelect({
  protocol,
  selectedRule,
  protocolID,
  categoryID,
  level,
  onSelect,
  onDelete,
  showDelete,
  customClass = "",
}) {
  const protocolDetails = useProtocol(
    protocol ? protocol.thresholds.protocolIds.categoryID : categoryID,
    protocol ? protocol.thresholds.protocolIds.protocolID : protocolID,
  );

  const [collapse, setCollapse] = useState(false);

  const protocolColor = { background: protocolDetails?.color || "#171d66" };
  const protocolIcon = PROTOCOLS_ICONS.find(({ name }) => name === protocolDetails?.icon)?.getIcon() || <SunIcon className="protocol_icon" />;
  const getRuleName = (ruleNo) => {
    const rule = protocolDetails.protocolRules?.find((r) => r.rule === ruleNo);
    return `${rule.frequencyBandName.join("/")} ${rule.location.join("/")} ${rule.measure} ${rule.direction}`;
  };

  return protocolDetails && (
    <>
      <div
        className={`protocolTile ${customClass}`}
        style={protocolColor}
        role="presentation"
        onClick={showDelete
          ? () => onDelete?.(protocol)
          : () => onSelect?.(protocol)}
      >
        <div className="protocolTile__title">
          {protocolIcon}
          <div className="protocolTile__title-text">
            {_.unescape(protocolDetails.protocolName)}
            {selectedRule && (
            <span className="protocolTile__title-text-rule">{getRuleName(selectedRule)}</span>
            )}
          </div>
          {(protocol?.level || level) && (
            <div className="protocolTile__title-lvl">
              Lv.
              {" "}
              {protocol?.level || level}
            </div>
          )}
        </div>
        {showDelete ? (
          <div className="protocolTile__select-button--delete">
            <FontAwesomeIcon icon={faTrash} />
          </div>
        ) : (
          <div className="protocolTile__select-button--right">
            <ArrowIcon />
          </div>
        )}
      </div>
      {protocol && protocol.rules.length > 0 && (
      <>
        <div
          role="presentation"
          className="protocolTile__rules-item"
          style={protocolColor}
          onClick={() => setCollapse(!collapse)}
        >
          <span className="protocolTile__rules-item-text">
            <strong>{showDelete ? "Remove" : "Select"}</strong>
            {" "}
            single rule calibration
          </span>
          <span className="protocolTile__rules-collapse">
            <FontAwesomeIcon icon={collapse ? faChevronUp : faChevronDown} />
          </span>
        </div>
          {collapse && (
          <ul className={`protocolTile__rules-list ${showDelete ? "is-delete" : ""}`}>
            {protocol.rules.map((rule) => (
              <li
                key={rule}
                className="protocolTile__rules-item"
                style={protocolColor}
                role="presentation"
                onClick={showDelete
                  ? () => onDelete(protocol, Number(rule.split(".")[1]))
                  : () => onSelect?.(protocol, Number(rule.split(".")[1]))}
              >
                <span className="protocolTile__rules-item-text">{getRuleName(Number(rule.split(".")[1]))}</span>
                <div className="protocolTile__rules-icon">
                  <FontAwesomeIcon icon={showDelete ? faXmark : faArrowRight} />
                </div>
              </li>
            ))}
          </ul>
          )}
      </>
      )}
    </>
  );
}

export default ProtocolSelect;
