import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";

import { setActiveStream } from "store/actions/session";

import { SubNav } from "components";
import SignalCheck from "pages/App/Common/SignalCheck/SignalCheck";
import Calibration from "pages/App/Common/BaselineCalibration/BaselineCalibration";

function BaselineRecord() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [stage, setStage] = useState("signal");

  useEffect(() => {
    if (stage === "calibration") dispatch(setActiveStream("status"));
    return () => dispatch(setActiveStream(null));
  }, [stage]);

  const onComplete = () => {
    navigate("/app/meditation");
  };
  const onCancel = () => {
    navigate(-1);
  };

  return (
    <Container>
      {stage === "signal" && (
        <>
          <SubNav title="Baseline Recording" onBack={onCancel} staticTitle noSensors />
          <SignalCheck onComplete={() => setStage("calibration")} onCancel={onCancel} />
        </>
      )}
      {stage === "calibration" && (
        <>
          <SubNav title="Baseline Recording" onBack={onCancel} audioGuide="coach-baseline" staticTitle />
          <Calibration
            onComplete={onComplete}
            onCancel={onCancel}
            onError={() => setStage("signal")}
          />
        </>
      )}
    </Container>
  );
}

export default BaselineRecord;
