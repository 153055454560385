import { useEffect, useRef } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import { Dropdown } from "react-bootstrap";
import CsvExportButton from "features/CoachTable/components/CsvExportButton";

const columnHelper = createColumnHelper();

function IndeterminateCheckbox({
  indeterminate,
  ...rest
}) {
  const ref = useRef(!null);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      {...rest}
    />
  );
}

function getColumns(tableModel, groupName) {
  const filteredColumns = tableModel.fields.filter((item) => item.field.group === groupName);
  return filteredColumns.map((column) => (
    columnHelper.accessor(column.field.data, {
      header: () => column.field.title,
      id: column.field.data,
      name: column.field.name,
      title: column.field.title,
      group: column.field.group,
      isVisible: column.is_visible,
      filter_type: column.field?.filter_type,
    })
  ));
}

export default function getGroups(tableModel, groupNames, stickyColumn) {
  const stickyGroup = [
    columnHelper.group({
      id: "sticky",
      columns: [
        {
          id: "select",
          header: ({ table }) => (
            <div className="rowsMenu">
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
              />
              <Dropdown className="defaultMenu">
                <Dropdown.Toggle className="defaultMenu toggle" />
                <Dropdown.Menu className="defaultMenu menu">
                  <Dropdown.Item as="button">
                    <CsvExportButton tableData={table} className="defaultMenu dropdown-item" />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          ),
          cell: ({ row }) => (
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          ),
        },
        columnHelper.accessor(stickyColumn?.data || "", {
          id: stickyColumn?.id || " ",
          header: () => stickyColumn?.title || " ",
        }),
      ],
    }),
  ];

  const pinnedGroup = [
    columnHelper.group({
      id: "pinned",
      columns: [],
    }),
  ];

  const groups = groupNames.map((groupName) => ({
    header: groupName,
    columns: getColumns(tableModel, groupName),
  }));
  return [...stickyGroup, ...pinnedGroup, ...groups];
}
