import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getAppOriginUrl } from "utils/app-url-origin";

export function AuthProvider() {
  const { isUserLoggedIn } = useSelector(({ authenticationReducer }) => authenticationReducer);

  if (isUserLoggedIn) return <Navigate to={getAppOriginUrl()} />;
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Outlet />
    </GoogleOAuthProvider>
  );
}

export default AuthProvider;
