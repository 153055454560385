import { DatePickerForm } from "components/forms/DatePicker/DatePickerForm";

import "./DateRangeFromTo.scss";

export function DateRangeFromTo({
  onChange,
  ranges,
}) {
  const onDateRangeChange = (date, rangePoint) => {
    const newDate = date ? new Date(date) : null;
    onChange({ ...ranges, [rangePoint]: newDate });
  };

  return (
    <div className="daterange-form">
      <DatePickerForm
        selectedDate={ranges?.startDate}
        onChange={(date) => onDateRangeChange(date, "startDate")}
        placeholder="From"
        maxDate={ranges?.endDate || new Date()}
        withClear
      />
      -
      <DatePickerForm
        selectedDate={ranges?.endDate}
        onChange={(date) => onDateRangeChange(date, "endDate")}
        placeholder="To"
        minDate={ranges?.startDate}
        maxDate={new Date()}
        withClear
      />
    </div>
  );
}

export default DateRangeFromTo;
