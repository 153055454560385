import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { getHelpListFilters } from "api/services/settings";

import {
  Spinner, SubHeader, SelectTile, CustomTabs, Card,
} from "components";

import "./Help.scss";

function HelpListPage() {
  const navigate = useNavigate();

  const helpListQuery = useQuery({
    queryKey: ["help-list"],
    queryFn: () => getHelpListFilters(),
  });

  const [activeTab, setActiveTab] = useState("All");
  const [listData, setListData] = useState([]);

  useEffect(() => {
    if (helpListQuery.isSuccess && helpListQuery.data?.list) {
      if (activeTab === "All") {
        const filtered = helpListQuery.data.list.filter(({ groups }) => groups !== "Coach");
        setListData(filtered);
      } else {
        const filtered = helpListQuery.data.list.filter(({ groups }) => groups === activeTab);
        setListData(filtered);
      }
    }
  }, [helpListQuery.isSuccess, activeTab]);

  return (
    <Container>
      <div className="settingsPage help-page">
        <SubHeader
          title="Help"
          text="Learn more about GoDeeper."
        />
        {helpListQuery.isPending ? (
          <Spinner />
        ) : !helpListQuery.isError && (
        <>
          <div className="help-page__filters">
            <CustomTabs
              tabs={["All", ...helpListQuery.data.categories.filter((category) => category !== "Coach")]}
              defaultTabKey={activeTab}
              onTabChange={(tab) => setActiveTab(tab)}
              activeTab={activeTab}
              activeScroll
            />
          </div>
          <Card customClass="help-page__card--list">
            { listData.map((help) => (
              <div key={help.id} className="help-page__item">
                <SelectTile
                  withButton
                  customClass="selectTile--sm"
                  onSelect={() => navigate(`${help.id}`)}
                >
                  {help.name}
                </SelectTile>
              </div>
            ))}
          </Card>
        </>
        )}
      </div>
    </Container>
  );
}

export default HelpListPage;
