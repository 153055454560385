import { PROGRESS_SESSION_SETTINGS } from "constants/profile_progress_sessions";

export const getStatusBegin = (currentPage) => Math.max(
  1,
  (currentPage - 1) * PROGRESS_SESSION_SETTINGS.LIMIT + 1,
);

export const getStatusBeginEnd = (currentPage, totalPages, totalNumber) => (
  Number(currentPage) === totalPages
    ? totalNumber
    : currentPage * PROGRESS_SESSION_SETTINGS.LIMIT
);

export const getPaginationStatus = (currentPage, totalPages, totalNumber) => (
  `${getStatusBegin(currentPage)} - ${getStatusBeginEnd(currentPage, totalPages, totalNumber)} of ${totalNumber}`
);
