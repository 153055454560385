import { EmptyData, Spinner } from "components";

import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as PinchIcon } from "assets/icons_pinch.svg";

import "./ChartLoader.scss";

function ChartLoader({
  loading, isData, children, pinchInfo,
}) {
  return (
    <>
      {pinchInfo && (
      <div
        className="chart-loader__pinch"
        style={{ visibility: isData ? "visible" : "hidden" }}
      >
        <PinchIcon />
        <span>Pinch to zoom graph</span>
      </div>
      )}
      <div className="chart-loader">
        {loading && (
        <>
          <div className="chart-loader__overlay" />
          <Spinner className="chart-loader__spinner" />
        </>
        )}
        { isData ? children : (
          <EmptyData
            icon={faFilterCircleXmark}
            title="No sessions found"
            text="Try different period."
          />
        )}
      </div>
    </>
  );
}

export default ChartLoader;
