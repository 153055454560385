import {
  useState, useEffect, useMemo, memo,
} from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import _ from "lodash";

import { EmptyData } from "components";
import {
  FRQ_KEYS, FRQ_COLOR, FILTERS,
} from "constants/brainwave_analyzer";
import { getAnalyzerDeeperContent, getRules } from "api/services/reporting";
import { isAllowed } from "utils/user-permissions";
import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { InfoFilters } from "./InfoFilters";
import { InfoCategory } from "./InfoCategory";

import "./BrainwaveInfo.scss";

const BrainwaveInfo = memo(({
  activeState, rulesContent, brainData, setQuotes, frqsFilters, rulesFilters,
}) => {
  const userLevel = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.subscription_level_id,
  );
  const isCoach = isAllowed(userLevel, "COACH");
  const isMaster = isAllowed(userLevel, "MASTER");

  const { data: deeperContent } = useQuery({
    queryKey: ["analyzer-deeper-content"],
    queryFn: () => getAnalyzerDeeperContent({
      anatomy: ["front-back", "left-right"],
      teachings: FRQ_KEYS,
      category: FRQ_KEYS,
    }),
    staleTime: Infinity,
  });
  const { data: rulesDefinition } = useQuery({
    queryKey: ["analyzer-rules"],
    queryFn: () => getRules(rulesContent.map(
      (rule) => rule.descriptions?.definition || null,
    ).filter((id) => !!id)),
  });

  const [filters, setFilters] = useState(FILTERS);

  useEffect(() => {
    setQuotes(filters.quotes);
  }, [filters.quotes]);

  const protocolCategories = useMemo(() => {
    if (deeperContent?.category && rulesContent) {
      const filteredCategory = Object.entries(deeperContent.category).filter(
        ([category]) => rulesContent.some(
          (rule) => rule?.frequencyBandName === category,
        ),
      );
      return filteredCategory.map(([category, content]) => {
        const categoryRules = rulesContent.filter(
          (rule) => rule?.frequencyBandName === category,
        );
        return {
          ...content,
          frq: category,
          rules: categoryRules.map((rule) => {
            const definition = rule.descriptions?.definition && rulesDefinition?.definitions
              ? rulesDefinition?.definitions[rule.descriptions?.definition]
              : null;
            return { ...rule, definition };
          }).filter((rule) => !rulesFilters.includes(rule.ruleId)),
        };
      }).filter((category) => category.rules.length > 0);
    }
    return null;
  }, [rulesContent, deeperContent, rulesFilters]);

  const brainCategories = useMemo(() => {
    if (deeperContent?.category && brainData) {
      const filterData = _.cloneDeep(brainData);
      Object.keys(filterData).forEach((frq) => {
        Object.entries(filterData[frq]).forEach(([location, value]) => {
          const state = filters.sd_value < Math.abs(Number(value));
          filterData[frq][location] = state ? Number(value) : false;
        });
      });
      return Object.entries(deeperContent.category).map(([category, content]) => {
        if (frqsFilters[category]
          && Object.values(filterData[category]).some((value) => value !== false)) {
          return {
            ...content,
            frq: category,
            brain: filterData[category],
          };
        }
        return null;
      }).filter((category) => category);
    }
    return null;
  }, [deeperContent, brainData, filters, frqsFilters]);

  const safeHtml = (html) => {
    if (!html) return null;
    const quotes = html.replaceAll(" [(", "<span class=\"interpretation-quote\"> (").replaceAll(")]", ")</span>");
    return parse(DOMPurify.sanitize(quotes));
  };

  const categoryContent = activeState === "brain" ? brainCategories : protocolCategories;

  return (
    <>
      <h2>
        Full Report
        <span>
          {activeState === "brain" ? "Brain State Metrics" : "Protocol Metrics"}
        </span>
      </h2>
      <div className="brainwaveReport">
        <InfoFilters
          activeState={activeState}
          filters={filters}
          setFilters={setFilters}
        />
        <div className="brainwaveReport__info-wrapper">
          {categoryContent?.length ? categoryContent.map((content) => (
            <InfoCategory
              key={content.title}
              activeState={activeState}
              filters={filters}
              content={content}
              isCoach={isCoach}
            />
          )) : (
            <EmptyData
              icon={faFilterCircleXmark}
              title="Empty"
              text="No data for selected filters."
            />
          )}
        </div>
        {filters.deeper_anatomy && (
        <div className="brainwaveReport__anatomy">
          <h2>Deeper Brain Anatomy</h2>
          {deeperContent?.anatomy
          && Object.entries(deeperContent.anatomy).map(([key, content]) => (
            <div key={key} className="deeper-wrapper">
              <h3>{content.title}</h3>
              <div className="deeper-content">
                {safeHtml(content.content)}
                {filters.coach && (
                <>
                  {isCoach && safeHtml(content.coach_content)}
                  {isMaster >= 4 && safeHtml(content.master_coach_content)}
                </>
                )}
              </div>
            </div>
          ))}
        </div>
        )}
        {filters.deeper_frq && (
        <div className="brainwaveReport__teachings">
          <h2>Deeper Brainwave Teachings</h2>
          {deeperContent?.teachings
          && Object.entries(deeperContent.teachings).map(([key, content]) => frqsFilters[key] && (
            <div key={key} className="deeper-wrapper">
              <h3 style={{ color: FRQ_COLOR[key] }}>
                {content.title}
              </h3>
              <div className="deeper-content">
                {safeHtml(content.intro)}
                {safeHtml(content.extra)}
                {filters.coach && (
                <>
                  {isCoach >= 3 && safeHtml(content.coach_content)}
                  {isMaster >= 4 && safeHtml(content.master_coach_content)}
                </>
                )}
              </div>
            </div>
          ))}
        </div>
        )}
      </div>
    </>
  );
});

export default BrainwaveInfo;
