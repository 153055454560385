import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";

import { generateNumberArray } from "utils/time-format";
import {
  GenericButton,
  SelectPicker,
  ToggleSwitch,
  ToggleButton,
  BottomBarSticky,
  InfoBox,
} from "components";
import daysOfWeek from "constants/days_of_week";
import { saveUserProfile } from "store/actions/profile";

import "./Goals.scss";

function GoalsPage({ saveBtnText, tabIndex }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userSavedPracticeRemindersDays = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.practice_reminders_days,
  ) || [];
  const userSavedNotifications = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.practice_reminders_enabled,
  );
  const userRemindersTime = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.practice_reminders_time,
  );
  const userGoalTime = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.future_goal_time,
  );
  const activeGoalTime = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.goal_time,
  );

  const mapReminderOptions = useMemo(() => ([
    ["12", ...generateNumberArray(1, 12, 1)],
    generateNumberArray(0, 60, 5),
    ["AM", "PM"],
  ]), []);

  const mapGoalsOptions = useMemo(() => ([
    generateNumberArray(0, 13, 1, false),
    generateNumberArray(0, 60, 5, false),
  ]), []);

  const [isValid, setIsValid] = useState({ goal: false, reminders: true });
  const [goal, setGoal] = useState(userGoalTime?.split(":").slice(0, 2));
  const [selectedDays, setSelectedDays] = useState([...userSavedPracticeRemindersDays]);
  const [areNotificationsEnabled, setAreNotificationsEnabled] = useState(userSavedNotifications);
  const [reminderTime, setReminderTime] = useState(() => {
    const time = userRemindersTime?.split(":").slice(0, 2);
    return time && [
      time[0] % 12 ? (time[0] % 12).toString().padStart(2, "0") : "12",
      time[1],
      time[0] >= 12 ? "PM" : "AM",
    ];
  });

  useEffect(() => {
    setIsValid({
      ...isValid,
      reminders: areNotificationsEnabled
        ? !!(reminderTime && selectedDays.length)
        : true,
    });
  }, [areNotificationsEnabled, reminderTime, selectedDays]);

  useEffect(() => {
    setIsValid({ ...isValid, goal: goal?.some((time) => time >= 0) });
  }, [goal]);

  const onSelectDay = (index) => {
    setSelectedDays(selectedDays.includes(index)
      ? selectedDays.filter((day) => day !== index) : [...selectedDays, index]);
  };
  const save = () => {
    const format24Time = (time) => {
      const [hours, mins, modifier] = time;
      const formatHours = ((+hours % 12) + (modifier === "PM" ? 12 : 0)).toString().padStart(2, "0");
      return `${formatHours}:${mins}:00`;
    };
    if (!isValid.reminders || !isValid.goal) {
      toast.error(!isValid.reminders
        ? "Please pick time and at least one day for reminder."
        : "Please choose your goal time.");
    } else {
      const options = tabIndex >= 0
        ? { nextTab: tabIndex + 1 }
        : { navigate, nextUrl: "/app/settings" };
      dispatch(saveUserProfile({
        practice_reminders_days: selectedDays,
        practice_reminders_enabled: areNotificationsEnabled,
        practice_reminders_time: reminderTime ? format24Time(reminderTime) : null,
        goal_time: activeGoalTime || `${goal?.join(":")}:00`,
        future_goal_time: `${goal?.join(":")}:00`,
      }, options));
    }
  };

  const getTime = (time) => {
    const [hours, minutes] = time?.split(":") || [];
    return `${Number(hours)}h ${Number(minutes)}min`;
  };

  const getNextPeriodStart = () => {
    const today = new Date();
    const date = today.setDate(today.getDate() + ((7 - today.getDay()) % 7 || 7));
    return format(date, "MMM d, yyyy");
  };

  const format12hTime = (time) => {
    const [hours, mins, modifier] = time;
    return `${hours}:${mins} ${modifier}`;
  };

  return (
    <div className="goalsPage">
      <div className="goals">
        <h3 className="my-3">
          What is your goal for time spent meditating each week?
        </h3>
        {activeGoalTime && (
        <InfoBox customClass="mb-3">
          <p>
            Your goal will be set on weekly basis. You can pick upcoming
            goal value starting from
            {" "}
            {getNextPeriodStart()}
            .
          </p>
          <p>
            Goal for current week:
            {" "}
            <strong>{getTime(activeGoalTime)}</strong>
          </p>
        </InfoBox>
        )}
        <div className="goals__form">
          <SelectPicker
            options={mapGoalsOptions}
            initialValue={goal ? goal.map((time) => Number(time)) : [0, 0]}
            onSelect={(time) => setGoal(time?.slice(0, 2))}
            buttonText={goal ? `${Number(goal[0])} h ${Number(goal[1])} mins` : "Pick goal"}
            labels={["hours", "minutes"]}
            icon
          />
        </div>
        <div className="goals__notifications">
          <p className="text-muted">Do you want a reminder notification?</p>
          <div className="goals__notifications-switch">
            <p className="h3">Enable notifications</p>
            <ToggleSwitch
              isActive={areNotificationsEnabled}
              onClick={() => setAreNotificationsEnabled(!areNotificationsEnabled)}
            />
          </div>
        </div>
      </div>
      {areNotificationsEnabled && (
      <div className="goals__notifications-wrapper">
        <div className="goals__notifications-days">
          {daysOfWeek.map((item, index) => (
            <ToggleButton
              key={item}
              onClick={() => onSelectDay(index)}
              isActive={selectedDays.includes(index)}
            >
              {item}
            </ToggleButton>
          ))}
        </div>
        <SelectPicker
          options={mapReminderOptions}
          initialValue={reminderTime}
          onSelect={(time) => setReminderTime(time?.slice(0, 3))}
          buttonText={reminderTime ? format12hTime(reminderTime) : "Pick Time"}
          formClass="form-time"
          icon
        />
      </div>
      )}
      <BottomBarSticky>
        <GenericButton
          className={`button--success ${(isValid.goal && isValid.reminders) ? "" : "button--muted"}`}
          text={saveBtnText || userGoalTime ? "Save" : "Next"}
          onButtonClick={save}
        />
      </BottomBarSticky>
    </div>
  );
}

export default GoalsPage;
