import { Container } from "react-bootstrap";

import Apps from "pages/App/Common/Apps/Apps";
import { SubHeader } from "components";

function AppsPage() {
  return (
    <Container>
      <div className="settingsPage">
        <SubHeader
          title="Apps"
          text="Change information about your apps background."
          audioGuide="coach-apps"
        />
        <Apps saveBtnText="Save" />
      </div>
    </Container>
  );
}

export default AppsPage;
