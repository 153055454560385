// CLIENT (VILLISTUS)
const CLIENT_ENV_API_URL = process.env.REACT_APP_CLIENT_API_URL;
export const CLIENT_API = `${CLIENT_ENV_API_URL}`;
const MQTT_PREFIX = process.env.REACT_APP_CLIENT_MQTT_PREFIX || "C1";
// AUTH
export const generateClientAuthTokenUrl = (userId) => `/generate_auth_token/${userId}`;
// SETTINGS
export const clearClientAccountUrl = (userId) => `/backend_factory_reset/${userId}`;
// PROTOCOLS
export const getProtocolsUrl = () => "/get_protocol_library_with_rules";
export const getProtocolRulesUrl = (protocolId) => `/get_protocol_rules_by_protocol_id/${protocolId}`;
export const getSuggestedProtocolsUrl = (userId) => `/protocol_comparison/${userId}`;
export const deleteProtocolUrl = (id) => `/delete_protocol_threshold/${id}`;
export const deleteProtocolByIdUrl = (userId, levelId) => `/delete_protocol_thresholds_by_protocol_id/${userId}/${levelId}`;
export const calibratedProtocols = (userId) => `/get_calibrated_protocols/${userId}`;
export const saveCalibratedProtocolUrl = (userId, levelId) => `/calibrate_protocol/${userId}/${levelId}`;
export const updateCalibratedProtocolUrl = (userId, levelId) => `/update_calibration_thresholds/${userId}/${levelId}`;
export const addCalibrationRuleUrl = (userId, calibrationId, rule) => `/add_single_rule_calibration/${userId}/${calibrationId}/${rule}`;
export const deleteCalibrationRuleUrl = (calibrationId, rule) => `/delete_single_rule_calibration/${calibrationId}/${rule}`;
// SESSION
export const stopStreamDataUrl = (userId) => `/stop_stream/${userId}`;
export const cancelStreamDataUrl = (userId) => `/cancel_stream/${userId}`;
export const baselineCalibrationUrl = (userId) => `/start_baseline_stream/${userId}`;
export const signalCheckUrl = (userId) => `/start_muse_signal_check/${userId}`;
export const sessionLiveDataUrl = (userId, protocolId) => `/start_meditation_stream/${userId}/${protocolId}`;
export const protocolSessionDataUrl = (userId, protocolId) => `/calibrate_protocol_off_last_session/${userId}/${protocolId}`;
export const deleteBaselineUrl = (userId) => `/delete_latest_baseline_data/${userId}`;
// STREAM

export const dataPublishUrl = (userId) => `/mindstreams/muse/muse-${userId}/data`;
export const dataStreamUrl = (userId) => `/mindstreams/${MQTT_PREFIX}-${userId}/data`;
export const signalStreamUrl = (userId) => `/mindstreams/${MQTT_PREFIX}-${userId}/signal-quality`;
export const batteryStreamUrl = (userId) => `/mindstreams/${MQTT_PREFIX}-${userId}/battery`;
export const distractionStreamUrl = (userId) => `/mindstreams/${MQTT_PREFIX}-${userId}/distraction`;
export const calibrationStreamUrl = (userId) => `/mindstreams/${MQTT_PREFIX}-${userId}/calibration`;
// CLIENT-END

// USER (MILO)
const ENV_USER_API_URL = process.env.REACT_APP_API_URL;
const API_CONTEXT = "/api";
const V = "/v1";
export const USER_API = `${ENV_USER_API_URL}${API_CONTEXT}${V}`;
// AUTH
export const refreshTokenUrl = () => "/auth/token/refresh/";
export const convertTokenUrl = (social) => `/auth/${social}/`;
export const registrationUserUrl = () => "/auth/registration/";
export const loginUserUrl = () => "/auth/login/";
export const logoutUserUrl = () => "/auth/logout/";
export const updateUserUrl = () => "/auth/user/";
export const resetPasswordUrl = () => "/auth/reset-password/";
// PROFILE
export const profileUrl = () => "/accounts/profile/";
export const onboardingChoicesUrl = () => "accounts/profile/onboarding-choices/";
export const saveCalibrationDataUrl = () => "/accounts/calibration/";
// SETTINGS
export const coachesPersmissionsUrl = () => "/accounts/profile/coaches/authorize/";
export const bulkUpdateCoachesPersmissionsUrl = () => "/accounts/profile/coaches/authorize/bulk_update/";
export const resetOnboardingUrl = () => "/accounts/profile/onboarding-reset/";
export const changeUserPasswordUrl = () => "/auth/password/change/";
export const clearUserAccountUrl = () => "/accounts/profile/reset-to-default/";
export const deleteUserAccountUrl = () => "/accounts/delete/";
// HELP
export const getHelpFromLocationUrl = (id) => `/help/location/${id}`;
export const getHelpDetailsUrl = (id) => `/help/${id}/`;
export const getHelpListUrl = () => "/help/";
export const getHelpListFiltersUrl = () => "/help/filter_list/";
// REPORTING
export const sessionsListUrl = () => "/session/list/";
export const getGoalUrl = (userId) => `/graph/lifetime/${userId}/`;
export const getTimeProgressUrl = (userId, period, last = 7) => `/graph/time/${period}/${last}/${userId}/`;
export const getProtocolProgressUrl = (userId, period, protocol, last = 7) => `/graph/stats2/${protocol}/${period}/${last}/${userId}/`;
export const getProtocolMetricsUrl = (userId, period, protocol, last = 7) => `/graph/metrics/${protocol}/${period}/${last}/${userId}/`;
// SESSION
export const getBrainwaveReportUrl = (id) => `/coach/clients-sessions/brainwave-activity/${id}`;
export const importSessionUrl = (id) => `/session/external/import/${id}/`;
export const createSessionUrl = () => "/session/create/";
export const createReportUrl = () => "/session/report/";
export const updateSessionNoteUrl = () => "/session/note/";
export const deleteSessionNoteUrl = (id, timestamp) => `/session/note/delete/${id}/${timestamp}/`;
export const updateSessionTapUrl = () => "/session/taps/";
export const deleteSessionTapUrl = (id, timestamp) => `/session/taps/delete/${id}/${timestamp}/`;
export const getSessionDetailsUrl = (id) => `/session/detail/${id}/`;
export const deleteSessionUrl = (id) => `/session/external/delete/${id}/`;
export const updateSessionJournalUrl = (id) => `/session/update/journal/${id}/`;
export const updateSessionSubmitUrl = (id) => `/session/submit/${id}/`;
export const getAnalyzerDeeperContentUrl = () => "/brainwave-analyzer/deeper/";
export const getRulesUrl = () => "/brainwave-analyzer/rules/";
// PROTOCOLS
export const allHistoricProtocolsUrl = () => "/accounts/my/protocols/";
export const getProtocolCertificationListUrl = () => "/protocols/list/";
export const getProtocolCertificationUrl = (id) => `/protocols/detail/${id}/`;
export const getProtocolCertificationUserUrl = (id) => `/protocols/user-detail/${id}/`;
export const getTotalProtocolCertificationsUrl = () => "/protocols/info/";
export const getProtocolUniqueContributorsUrl = (levelID) => `/protocols/unique-users/${levelID}/`;
export const certifyProtocolUrl = (levelID) => `/protocols/certify/${levelID}/`;
export const saveReferralUrl = () => "/accounts/add-referral/";
// COACH
export const getCoachTableUrl = (id) => `/coach/table-definitions/${id}/`;
export const getClientUrl = (clientId) => `/coach/clients/${clientId}/`;
export const getClientsUrl = () => "/coach/clients/";
export const getClientsSessionsUrl = () => "/coach/clients-sessions/";
export const getClientSessionsUrl = (clientId) => `/coach/clients-sessions/${clientId}/profile/`;
export const getClientProtocolsUrl = (clientId) => `/accounts/${clientId}/protocols/`;
export const getClientsChartsUrl = () => "/coach/clients/charts/";
export const getClientSessionDetailsUrl = (sessionId) => `/coach/clients-sessions/details/${sessionId}/`;
export const getTestBatchesUrl = () => "/coach/batches/";
export const getTestBatchesExistsUrl = () => "/coach/batches/processing-batch-exists/";
export const getTestBatchDetailsUrl = (id) => `/coach/batches/${id}/`;
export const createTestBatchUrl = () => "/coach/batches/create/";
export const resumeTestBatchUrl = (id) => `/coach/batches/resume/${id}/`;
export const cancelTestBatchUrl = (id) => `/coach/batches/stop/${id}/`;
export const getTestSessionsUrl = () => "/coach/test-sessions/";
export const getTestReportUrl = () => "/coach/export/test-sessions/report/";
export const getCoachTabListUrl = (group) => `/coach/table-tabs/?group=${group}`;
export const coachTabUrl = (tabId) => `/coach/table-tabs/${tabId}/`;
export const createCoachTabUrl = () => "/coach/table-tabs/";
export const manageCoachTabsUrl = (group) => `/coach/tabs/manage/${group}/`;
export const getSessionsReportUrl = () => "/coach/export/report/";
export const getResearchLibraryUrl = () => "/coach/research-library/list/";
export const getResearchDataUrl = (id) => `/coach/research-library/${id}/`;
export const researchCommentsUrl = (id) => `/coach/research-library/${id}/comments/`;
export const deteleResearchCommentUrl = (id) => `/coach/research-library/comments/${id}/`;
export const getDiagnosticSessionsUrl = () => "/coach/diagnostics/";
export const getDiagnosticSessionsReportUrl = () => "/coach/export/diagnostic-report/";
export const getRecalculationListUrl = () => "/coach/recalculate-sessions/";
export const getRecalculationResultsUrl = () => "/coach/recalculations/";
export const createRecalculationUrl = () => "/coach/recalculate-sessions/create/";
// USER-END
