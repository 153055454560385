import { useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ResponsiveLine } from "@nivo/line";
import { format } from "date-fns";

import { changeGraphStatus } from "store/actions/reporting";
import usePinch from "hooks/usePinch";

import { CHART_COLORS, CHART_TYPES_STATS_CHART } from "constants/profile_progress_charts";
import ChartType from "features/ProgressGraphs/ChartType";
import ChartLoader from "features/ProgressGraphs/ChartLoader";
import { useMediaQuery } from "react-responsive";

function StatsChart({ data }) {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const dispatch = useDispatch();
  const { graphStatus } = useSelector(({ reportingReducer }) => reportingReducer);
  const { range, period, types } = graphStatus;
  const { isPending, isPlaceholderData, data: chartData } = data;

  const pinchRef = useRef(null);
  usePinch({
    element: pinchRef,
    min: 8,
    max: 20,
    current: range,
    onChange: (newRange) => dispatch(changeGraphStatus({ ...graphStatus, range: newRange })),
    rangeMode: true,
  });

  const filteredData = useMemo(() => {
    if (!isPending) {
      return chartData?.stats.filter((serie) => types.stats.includes(serie.id))
        ?.map((serie) => ({
          ...serie,
          data: serie.data.slice(-range),
        }));
    }
    return null;
  }, [chartData, range, types.stats]);

  const setColors = () => filteredData.map((serie) => (CHART_COLORS[serie.id]));

  const formatDate = (value) => {
    const date = value.split("/");
    const newDate = new Date(`${date[2]}-${date[1]}-${date[0]}`);
    return format((newDate), period === "Month" ? "MMM yy" : "d MMM");
  };

  return (
    <ChartLoader
      loading={isPending || isPlaceholderData}
      isData={isPending || filteredData?.[0].data.length > 0}
      pinchInfo
    >
      <>
        <div className="chart-container chart-container--statschart" ref={pinchRef}>
          {!!filteredData?.length && (
          <ResponsiveLine
            data={filteredData}
            theme={{
              background: "transparent",
              text: {
                fontSize: 11,
                fontFamily: "Quicksand",
                fill: "#171d66",
              },
            }}
            colors={setColors()}
            margin={{
              top: 15, right: 40, bottom: 50, left: 60,
            }}
            enableGridX={false}
            curve="monotoneX"
            xScale={{
              type: "point",
            }}
            yScale={{
              type: "linear",
              min: 0,
              max: filteredData[0].data.some(({ y }) => y !== null) ? "auto" : 0,
              stacked: false,
              reverse: false,
            }}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -90,
              format: (value) => formatDate(value),
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Stats per Minute",
              legendPosition: "middle",
              legendOffset: -50,
            }}
            pointLabelYOffset={-12}
            animate={false}
            isInteractive={!isMobile}
            enableSlices="x"
            enableCrosshair
          />
          )}
        </div>
        <ChartType
          chartTypeData={CHART_TYPES_STATS_CHART}
          chartType="stats"
        />
      </>
    </ChartLoader>
  );
}

export default StatsChart;
