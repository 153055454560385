import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getClientsCharts } from "api/services/coach";

import { CustomTabs, DateRange } from "components";
import { ReactComponent as Close } from "assets/icons_close.svg";
import { Container, Dropdown } from "react-bootstrap";
import { format } from "date-fns";
import normalizedTime from "utils/time-normalizer";

import { Chart } from "./Chart";

import "./ClientsChart.scss";

export function ClientsChart({ clients }) {
  const chartModel = [
    {
      type: "ambitious",
      name: "Most Ambitious",
      title: "Who has the most Ambitious Weekly Meditation Goal?",
      legend: "Goal in Minutes",
      units: " min",
    },
    {
      type: "goal",
      name: "% Goal",
      title: "Who Needs Help Reaching their Weekly Meditation Goal?",
      legend: "Percent of Goal",
      units: "%",
    },
    {
      type: "practice",
      name: "Practice Time",
      title: "Who Spends the Most and Least Time Practicing?",
      legend: "Minutes meditating",
      units: " min",
    },
    {
      type: "blue",
      name: "Most Blue",
      title: "Who Spends the Most Time in the Blue Zone?",
      legend: "Percent of Sessions in each Zone",
      units: "%",
    },
    {
      type: "red",
      name: "Most Red",
      title: "Who Spends the Most Time in the Red Zone?",
      legend: "Percent of Sessions in each Zone",
      units: "%",
    },
    {
      type: "longer_shorter",
      name: "Longer/Shorter",
      title: "Who Should Consider Longer or Shorter Meditations?",
      legend: "GoDeeper Points (2nd half-1st half)",
      units: "",
    },
  ];

  const [activeTab, setActiveTab] = useState(chartModel[0]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null, key: "selection" });

  const getDate = (date) => (date ? format(date, "yyyy-MM-dd") : "");
  const customDate = dateRange.startDate && dateRange.endDate
    ? `${getDate(dateRange.startDate)} - ${getDate(dateRange.endDate)}`
    : "Choose dates";

  const timeRange = [
    { name: "Last 7 days", value: "last - 7" },
    { name: "Last 30 days", value: "last - 30" },
    { name: "All Sessions", value: "-" },
    { name: "Custom", value: (dateRange.startDate && dateRange.endDate) && customDate },
  ];
  const [activeTimeRange, setActiveTimeRange] = useState(timeRange[0]);

  const getChartData = async (users, range) => {
    try {
      const response = await getClientsCharts(users, range);
      const getValue = (value) => (typeof value === "number"
        ? value
        : normalizedTime(value).inMinutes);
      const normalizedData = {};
      Object.entries(response).forEach(([key]) => {
        if (key === "red" || key === "blue") {
          normalizedData[key] = response[key].map(
            (item) => ({
              name: `${item.user} (${item.id})`,
              blue: item.blue,
              green: item.green,
              red: item.red,
            }),
          ).sort((a, b) => (key === "red" ? b.red - a.red : b.blue - a.blue));
        } else {
          normalizedData[key] = response[key]?.map(
            (item) => ({
              name: `${item.user} (${item.id})`,
              value: item.value ? getValue(item.value) : 0,
              helper: item.data,
            }),
          ).sort((a, b) => b.value - a.value);
        }
      });
      return normalizedData;
    } catch { return null; }
  };

  const { data: chartData } = useQuery({
    queryKey: ["clients-data", clients, activeTimeRange],
    queryFn: () => getChartData(clients.join(), activeTimeRange.value),
    enabled: !!clients,
  });

  const onTabChange = (tab) => setActiveTab(chartModel.find((item) => item.name === tab));
  const onTimeRangeChange = (tab) => {
    setActiveTimeRange(timeRange.find((item) => item.name === tab));
    if (tab === "Custom") setShowCalendar(false);
  };

  return (
    <div className="clientsChart__wrapper">
      <Container>
        <div className="clientsChart__tabs">
          <CustomTabs
            tabs={chartModel.map((tab) => tab.name)}
            defaultTabKey={activeTab.name}
            onTabChange={onTabChange}
            activeTab={activeTab.name}
          />
          <div className="clientsChart__tabs-timerange">
            <CustomTabs
              tabs={timeRange.map((tab) => tab.name)}
              defaultTabKey={activeTimeRange.name}
              onTabChange={onTimeRangeChange}
              activeTab={activeTimeRange.name}
            />
            {activeTimeRange.name === "Custom"
              && (
              <div className="daterange">
                <div
                  className="daterange-form__input"
                  aria-hidden="true"
                  onClick={() => setShowCalendar(true)}
                >
                  {customDate}
                  <div
                    role="presentation"
                    className="daterange-form__input-close"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDateRange({ ...dateRange, startDate: null, endDate: null });
                      setActiveTimeRange({ ...activeTimeRange, value: "-" });
                    }}
                  >
                    <Close />
                  </div>
                </div>
                {showCalendar
                  && (
                  <Dropdown className="daterange-form__calendar" autoClose="outside" show={showCalendar}>
                    <div
                      role="presentation"
                      className="dropdown-backdrop"
                      onClick={() => setShowCalendar(false)}
                    />
                    <Dropdown.Menu>
                      <DateRange
                        ranges={[dateRange]}
                        onChange={(item) => setDateRange(item.selection)}
                        onClose={() => {
                          setShowCalendar(false);
                          if (dateRange.startDate && dateRange.endDate) {
                            setActiveTimeRange({ ...activeTimeRange, value: customDate });
                          }
                        }}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                  )}
              </div>
              )}
          </div>
        </div>

        <h2 className="clientsChart__title">{activeTab.title}</h2>
        <Chart
          chartData={chartData ? chartData[activeTab.type] : []}
          keys={activeTab.type === "red" || activeTab.type === "blue" ? ["blue", "green", "red"] : ["value"]}
          groupMode={(activeTab.type === "red" || activeTab.type === "blue") && "stacked"}
          legend={activeTab.legend}
          format={activeTab.units}
        />
      </Container>
    </div>
  );
}

export default ClientsChart;
