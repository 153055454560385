import { ReactComponent as Sun } from "assets/ic_protocols/ic_sun.svg";
import { ReactComponent as QuietMind } from "assets/ic_protocols/ic_quiet_mind.svg";
import { ReactComponent as EffortlessAbsorption } from "assets/ic_protocols/ic_effortless_absorption.svg";
import { ReactComponent as Focus } from "assets/ic_protocols/ic_focus.svg";
import { ReactComponent as OpenHeart } from "assets/ic_protocols/ic_open_heart.svg";
import { ReactComponent as AwakenedMind } from "assets/ic_protocols/ic_awakened_mind.svg";
import { ReactComponent as CalmRelaxed } from "assets/ic_protocols/ic_calm_relaxed.svg";
import { ReactComponent as Creativity } from "assets/ic_protocols/ic_creativity.svg";
import { ReactComponent as StressReduction } from "assets/ic_protocols/ic_stress_reduction.svg";
import { ReactComponent as OpenMonitoring } from "assets/ic_protocols/ic_open_monitoring.svg";
import { ReactComponent as NonDual } from "assets/ic_protocols/ic_non_dual.svg";
import { ReactComponent as Buddha } from "assets/ic_protocols/ic_buddha.svg";
import { ReactComponent as Enso } from "assets/ic_protocols/ic_enso.svg";
import { ReactComponent as Ohm } from "assets/ic_protocols/ic_ohm.svg";
import { ReactComponent as Vajra } from "assets/ic_protocols/ic_vajra.svg";
import { ReactComponent as Train } from "assets/ic_protocols/ic_train.svg";
import { ReactComponent as Modern } from "assets/ic_protocols/ic_modern.svg";

import { ReactComponent as Alpha } from "assets/ic_protocols/ic_alpha.svg";
import { ReactComponent as Beta } from "assets/ic_protocols/ic_beta.svg";
import { ReactComponent as Gamma } from "assets/ic_protocols/ic_gamma.svg";
import { ReactComponent as Delta } from "assets/ic_protocols/ic_delta.svg";
import { ReactComponent as Theta } from "assets/ic_protocols/ic_theta.svg";

const PROTOCOLS_ICONS = [
  {
    name: "sun",
    getIcon: () => <Sun className="protocol_icon" />,
  },
  {
    name: "quiet_mind",
    getIcon: () => <QuietMind className="protocol_icon" />,
  },
  {
    name: "effortless_absorption",
    getIcon: () => <EffortlessAbsorption className="protocol_icon" />,
  },
  {
    name: "focus",
    getIcon: () => <Focus className="protocol_icon" />,
  },
  {
    name: "focused_awareness",
    getIcon: () => <Focus className="protocol_icon" />,
  },
  {
    name: "open_heart",
    getIcon: () => <OpenHeart className="protocol_icon" />,
  },
  {
    name: "awakened_mind",
    getIcon: () => <AwakenedMind className="protocol_icon" />,
  },
  {
    name: "calm_relaxed",
    getIcon: () => <CalmRelaxed className="protocol_icon" />,
  },
  {
    name: "creativity",
    getIcon: () => <Creativity className="protocol_icon" />,
  },
  {
    name: "stress_reduction",
    getIcon: () => <StressReduction className="protocol_icon" />,
  },
  {
    name: "open_monitoring",
    getIcon: () => <OpenMonitoring className="protocol_icon" />,
  },
  {
    name: "non_dual",
    getIcon: () => <NonDual className="protocol_icon" />,
  },
  {
    name: "buddha",
    getIcon: () => <Buddha className="protocol_icon" />,
  },
  {
    name: "enso",
    getIcon: () => <Enso className="protocol_icon" />,
  },
  {
    name: "ohm",
    getIcon: () => <Ohm className="protocol_icon" />,
  },
  {
    name: "vajra",
    getIcon: () => <Vajra className="protocol_icon" />,
  },
  {
    name: "training",
    getIcon: () => <Train className="protocol_icon" />,
  },
  {
    name: "alpha",
    getIcon: () => <Alpha className="protocol_icon" />,
  },
  {
    name: "beta",
    getIcon: () => <Beta className="protocol_icon" />,
  },
  {
    name: "gamma",
    getIcon: () => <Gamma className="protocol_icon" />,
  },
  {
    name: "delta",
    getIcon: () => <Delta className="protocol_icon" />,
  },
  {
    name: "theta",
    getIcon: () => <Theta className="protocol_icon" />,
  },
];

const PROTOCOLS_TRAIN = {
  delta: {
    title: "Delta 2-4 Hz",
    description: "Deep dreamless sleep, pleasure, access to the unconscious, ESP",
  },
  theta: {
    title: "Theta 4-8 Hz",
    description: "Access to subconscious material, creativity, dreams, repressed memories",
  },
  alpha: {
    title: "Alpha 8-12 Hz",
    description: "Relaxed attention, visualization, bridge to subconscious theta thoughts",
  },
  beta: {
    title: "Beta 12-25 Hz",
    description: "Rational thought, concentration, alertness, stress and anxiety with higher Beta waves",
  },
  gamma: {
    title: "Gamma 25-50 Hz",
    description: " Flow states or racing mind, learning, flashes of insight",
  },
};

const PROTOCOLS_CATEGORY_ICONS = [
  {
    name: "Modern Meditations",
    getIcon: () => <Modern className="protocol_icon" />,
  },
  {
    name: "Buddhist Meditations",
    getIcon: () => <Buddha className="protocol_icon" />,
  },
  {
    name: "Vajrayana Meditations",
    getIcon: () => <Vajra className="protocol_icon" />,
  },
  {
    name: "Yogic Meditations",
    getIcon: () => <Ohm className="protocol_icon" />,
  },
  {
    name: "Training Protocols",
    getIcon: () => <Train className="protocol_icon" />,
  },
];
const PROTOCOLS_SECTION_ORDER = [
  "Modern Meditations",
  "Buddhist Meditations",
  "Vajrayana Meditations",
  "Yogic Meditations",
  "Training Protocols",
];
const PROTOCOLS_SECTION_COLORS = ["#e9f5ff", "#fffaef", "#faf2e8", "#fffaef", "#ffffff"];
const PROTOCOLS_SECTION_TEXT = [
  "Choose based on your Goal State.",
  "Choose based on your Practice Lineage.",
  "Choose based on your Practice Lineage.",
  "Choose based on your Practice Lineage.",
  "Choose frequency band to train.",
];

export {
  PROTOCOLS_ICONS, PROTOCOLS_SECTION_COLORS, PROTOCOLS_SECTION_TEXT,
  PROTOCOLS_TRAIN, PROTOCOLS_SECTION_ORDER, PROTOCOLS_CATEGORY_ICONS,
};
