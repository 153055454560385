/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import {
  useState, useMemo, useRef, useEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSliders, faPlay, faPause, faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as IconClose } from "assets/icons_close.svg";
import { ReactComponent as IconGuide } from "assets/icons_audio_guide.svg";
import { ReactComponent as IconRewind } from "assets/icons_rewind.svg";
import { ReactComponent as IconForward } from "assets/icons_forward.svg";
import { Dropdown } from "react-bootstrap";

import { saveUserProfile } from "store/actions/profile";
import { HelpModal } from "features/HelpModal/HelpModal";
import ProgressTrack from "./ProgressTrack";

import "./AudioGuide.scss";

function AudioGuide({
  track, setHiddenElement, detached, playerOnly = false, className, menuPosition,
}) {
  const dispatch = useDispatch();

  const checkedGuides = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.audioGuides,
  );

  const [isPanelOpen, setIsPanelOpen] = useState(playerOnly);
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeSpeed, setActiveSpeed] = useState(1);
  const [progress, setProgress] = useState(0);
  const [showTextModal, setShowTextModal] = useState(false);

  const audio = useRef(null);
  const speedOptions = [0.5, 0.75, 1, 1.25, 1.5, 2];

  const audioControls = useMemo(() => {
    const progressUpdate = () => {
      setProgress(audio.current.duration
        ? ((audio.current.currentTime / audio.current.duration) * 100).toFixed()
        : 0);
    };
    const progressEnded = () => {
      setIsPlaying(false);
      setProgress(0);
      if (!playerOnly) setIsPanelOpen(false);
    };

    return {
      play: () => setIsPlaying(true),
      pause: () => setIsPlaying(false),
      timeupdate: progressUpdate,
      ended: progressEnded,
    };
  }, []);

  useEffect(() => {
    if (isPlaying) audio.current.pause();
    if (track) {
      audio.current = new Audio(require(`assets/audio_guides/${track}.mp3`));
      audio.current.playbackRate = activeSpeed;
      Object.entries(audioControls).forEach(([event, action]) => {
        audio.current.addEventListener(event, action);
      });
      if (!checkedGuides.includes(track)) {
        try {
          audio.current.play();
        } catch (error) {
          console.log("Can't autoplay audio on web");
        }
        if (!playerOnly) setIsPanelOpen(true);
        dispatch(saveUserProfile({
          audioGuides: [...checkedGuides, track],
        }));
      }
      if (isPanelOpen && !playerOnly && audio.current.paused) setIsPanelOpen(false);
    }
    setProgress(0);
    return () => {
      audio.current?.pause();
    };
  }, [track]);

  useEffect(() => {
    if (track) audio.current.playbackRate = activeSpeed;
  }, [activeSpeed]);

  useEffect(() => {
    setHiddenElement?.(isPanelOpen);
    if (isPanelOpen && !isPlaying && !playerOnly) audio.current.play();
  }, [isPanelOpen]);

  const togglePlay = () => {
    if (!isPlaying) audio.current.play();
    if (isPlaying) audio.current.pause();
  };

  const fastSeek = (seek) => {
    if (isPlaying) audio.current.currentTime += seek;
  };

  const renderPlayingButton = () => (isPlaying
    ? (
      <div className="playingButton">
        <span className="playingButton__bar" />
        <span className="playingButton__bar" />
        <span className="playingButton__bar" />
      </div>
    )
    : <IconGuide />
  );

  if (!track) return null;

  return (
    <div className={`audioGuide ${detached ? "detached" : ""} ${className || ""}`}>
      {!playerOnly && (
        <button
          type="button"
          className={`audioGuide-button ${isPlaying && !isPanelOpen ? "isPlaying" : ""}`}
          style={{ boxShadow: isPanelOpen && "none" }}
          onClick={() => setIsPanelOpen(!isPanelOpen)}
          aria-label="Close Controls"
        >
          {isPanelOpen
            ? <IconClose />
            : renderPlayingButton()}
        </button>
      )}
      <div className={`audioGuide__panel ${isPanelOpen ? "isPanelOpen" : ""} ${playerOnly ? "playerOnly" : ""}`}>
        <button
          type="button"
          className="audioGuide__icon-btn"
          onClick={() => setShowTextModal(!showTextModal)}
          style={{ display: "none" }}
          aria-label="Show Guide Text"
        >
          <FontAwesomeIcon
            className="audioGuide__panel-info"
            icon={faCircleInfo}
          />
        </button>

        <HelpModal
          toggleClass="audioGuide__panel-info"
          backdropClass="backdrop-compact"
          location={track}
          infoIcon
          modalIcon={<IconGuide />}
          compact
        />

        <button
          type="button"
          className="audioGuide__icon-btn"
          onClick={() => fastSeek(-10)}
          aria-label="Guide Audio Rewind"
        >
          <IconRewind
            className={`audioGuide__panel-seek ${!isPlaying ? "disabled" : ""}`}
          />
        </button>

        <div className="audioGuide__panel-progress">
          <button
            type="button"
            className="audioGuide__icon-btn"
            onClick={() => togglePlay()}
            aria-label="Guide Audio Toggle Play"
          >
            <ProgressTrack progress={progress} />
            <FontAwesomeIcon
              className="audioGuide__panel-progress-icon"
              icon={isPlaying ? faPause : faPlay}
            />
          </button>
        </div>

        <button
          type="button"
          className="audioGuide__icon-btn"
          onClick={() => fastSeek(10)}
          aria-label="Guide Audio Forward"
        >
          <IconForward
            className={`audioGuide__panel-seek ${!isPlaying ? "disabled" : ""}`}
          />
        </button>

        <Dropdown
          onSelect={(value) => setActiveSpeed(+value)}
          className="audioGuide__panel-settings slim"
          drop={menuPosition}
        >
          <Dropdown.Toggle className="audioGuide__panel-settings">
            <FontAwesomeIcon icon={faSliders} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Header>Speed</Dropdown.Header>
            {speedOptions.map((option) => (
              <Dropdown.Item
                key={option}
                eventKey={option}
                active={option === activeSpeed}
              >
                {option === 1 ? "Normal" : `${option}x`}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

export default AudioGuide;
