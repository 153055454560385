import { TapsList } from "components";
import { ReactComponent as TapIcon } from "assets/icons_tap.svg";

import "./TapGuide.scss";

function TapGuide({ onClick }) {
  return (
    <div
      role="presentation"
      className="tapGuide__info"
      onClick={() => onClick()}
    >
      <div className="tapGuide__info-head">
        <div className="tapGuide__info-icon">
          <TapIcon />
        </div>
        <div className="tapGuide__info-description">
          <h3>Tap for Advanced Tracking</h3>
          <p>
            Nothing your mind states can help you get familiar with what depth
            feels like. Use tapping to not only deepen your practice, but leave
            markers that you can review in your graphs.
          </p>
        </div>
      </div>
      <div className="tapGuide__list">
        <TapsList darkMode />
      </div>
    </div>
  );
}

export default TapGuide;
