import { useState, useEffect } from "react";

export default function useIsVisible(
  element,
  marginTop,
  initialVisibility = false,
) {
  const [isVisible, setIsVisible] = useState(initialVisibility);

  useEffect(() => {
    const { current } = element;
    const checkVisibility = (entries) => {
      const [entry] = entries;
      if (entry.intersectionRatio > 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    const rootMargin = `${marginTop}px 0px 0px 0px`;
    const observer = new IntersectionObserver(checkVisibility, { rootMargin, threshold: [0, 1] });
    if (current) observer.observe(current);
    return () => {
      if (current) observer.disconnect();
    };
  }, []);

  return isVisible;
}
