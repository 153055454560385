import { useState } from "react";
import { toast } from "react-toastify";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt as faTrash } from "@fortawesome/fontawesome-free-regular";

import { ButtonWithIcon, InputText } from "components";
import "./AddCustomOptions.scss";

/**
 * TYPES:
 * techniques 1
 * trainings 2
 * apps 3
 */
export function AddCustomOptions({
  items, setItems, selectedType, inputPlaceholder,
}) {
  const [inputValue, setInputValue] = useState("");

  const addCustom = () => {
    if (inputValue === "") toast.error("Please fill input field.");
    const itemIndex = items.findIndex(
      (item) => (item.name === inputValue && item.type === selectedType),
    );
    if (itemIndex !== -1) {
      if (items[itemIndex]?.delete) {
        const filteredArray = [...items];
        filteredArray[itemIndex] = { ...filteredArray[itemIndex], delete: false };
        setItems(filteredArray);
      } else {
        toast.error("We already have item with this name");
      }
    } else if (inputValue.trim("")) {
      setItems([...items, { name: inputValue, type: selectedType, delete: false }]);
    }
    setInputValue("");
  };

  const removeCustom = (name) => {
    const filteredArray = [...items];
    const deleteIndex = items.findIndex((item) => (
      item.name === name && item.type === selectedType));
    filteredArray[deleteIndex] = { ...filteredArray[deleteIndex], delete: true };
    setItems(filteredArray);
  };

  const submitForm = (e) => {
    e.preventDefault();
    addCustom();
  };

  return (
    <div className="add-custom__wrapper">
      <div className="add-custom__header">
        <span>other</span>
      </div>
      <div className="add-custom__list">
        {items && items.map(({ name, delete: isDeleted, type }) => (
          (!isDeleted && type === selectedType) && (
          <div key={name} className="meditationSection__select-wrapper">
            <ButtonWithIcon
              text={name}
              id={name}
              icon={faTrash}
              onClick={() => removeCustom(name)}
            />
          </div>
          )
        ))}
      </div>
      <form onSubmit={(e) => submitForm(e)}>
        <InputText
          placeholder={inputPlaceholder || "Add New"}
          maxLength={64}
          withButton
          icon={faPlus}
          defaultValue={inputValue}
          onChangeValue={(e) => setInputValue(e.target.value)}
          onIconClick={addCustom}
        />
      </form>
    </div>
  );
}

export default AddCustomOptions;
