import { useRef } from "react";
import useIsVisible from "hooks/useIsVisible";
import "./BottomBarSticky.scss";

export function BottomBarSticky({
  children, customClass = "",
}) {
  const sticky = useRef();
  const isSticky = useIsVisible(sticky, 0, true);

  return (
    <div className={`bottomBar__wrapper ${customClass}`}>
      <div ref={sticky} className={`bottomBar__sticky ${isSticky ? "is-bottom" : ""}`} />
      <div className="bottomBar__content">
        {children}
      </div>
    </div>
  );
}

export default BottomBarSticky;
