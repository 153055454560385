import { useNavigate } from "react-router-dom";
import { useState, useMemo } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";

import {
  HeaderMenu, GenericButton, Card, Dialog, SubNav,
} from "components";
import {
  ACCOUNT_MENU_ITEMS,
  DATA_MENU_ITEMS,
  PROFILE_MENU_ITEMS,
  ABOUT_ITEMS,
} from "constants/settings_menu_items";

import "./MainMenu.scss";

function SettingsMenuPage() {
  const navigate = useNavigate();

  const user = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user,
  );
  const onboardingComplete = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.onboarding_complete,
  );
  const socialAccount = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.socialAccount,
  );

  const [showLogoutDialog, setShowLogoutDialog] = useState(false);

  const filteredAccountItems = useMemo(
    () => (socialAccount
      ? ACCOUNT_MENU_ITEMS.filter(({ url }) => url !== "/app/settings/changepassword")
      : ACCOUNT_MENU_ITEMS),
    [socialAccount],
  );

  window.nativeBack = () => false;

  const renderHead = () => (
    <div className="header">
      <h2 className="text-capitalize">
        Hello
        {" "}
        {user.first_name}
        {" "}
        {user.last_name}
      </h2>
    </div>
  );

  return (
    <>
      {onboardingComplete && <HeaderMenu />}
      <Container>
        <div className="settingsMenuPage">
          {onboardingComplete ? (
            renderHead()
          ) : (
            <SubNav title="Settings">
              {renderHead()}
            </SubNav>
          )}
          <div className="settingsMenuPage__user-section">
            <h2 className="font-500 text-primary mb-3">Account</h2>
            <Card customClass="settingsMenuPage__list-wrapper">
              {filteredAccountItems.map(({ name, url, icon }) => (
                <GenericButton
                  key={name}
                  text={name}
                  className="settingsMenuPage__list-btn"
                  iconElement={icon}
                  onButtonClick={() => navigate(url)}
                />
              ))}
            </Card>
            <Card customClass="settingsMenuPage__list-wrapper mt-4">
              {DATA_MENU_ITEMS.map(({ name, url, icon }) => (
                <GenericButton
                  key={name}
                  text={name}
                  className="settingsMenuPage__list-btn"
                  iconElement={icon}
                  onButtonClick={
                    name === "Log Out"
                      ? () => setShowLogoutDialog(true)
                      : () => navigate(url)
                  }
                />
              ))}
            </Card>
          </div>
          {onboardingComplete && (
          <div className="settingsMenuPage__user-profile-section">
            <h2 className="font-500 text-primary mb-3">Profile</h2>
            <Card customClass="settingsMenuPage__list-wrapper">
              {PROFILE_MENU_ITEMS.map(({ name, url, icon }) => (
                <GenericButton
                  key={name}
                  text={name}
                  className="settingsMenuPage__list-btn"
                  iconElement={icon}
                  onButtonClick={() => navigate(url)}
                />
              ))}
            </Card>
          </div>
          )}
          <div className="settingsMenuPage__user-profile-section">
            <h2 className="font-500 text-primary mb-3">Information</h2>
            <Card customClass="settingsMenuPage__list-wrapper">
              {ABOUT_ITEMS.map(({ name, url, icon }) => (
                <GenericButton
                  key={name}
                  text={name}
                  className="settingsMenuPage__list-btn"
                  iconElement={icon}
                  onButtonClick={() => navigate(url)}
                />
              ))}
            </Card>
          </div>
        </div>
        <Dialog
          show={showLogoutDialog}
          title="Logout"
          onSuccess={() => navigate("/app/logout")}
          onCancel={() => setShowLogoutDialog(false)}
        >
          <p>
            Are you sure you want to log out?
          </p>
        </Dialog>
      </Container>
    </>
  );
}

export default SettingsMenuPage;
