import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";

import {
  GenericButton,
  InputText,
  SubHeader,
  BottomBarSticky,
  ErrorValidation,
} from "components";
import useFormData from "hooks/useFormData";
import { changeUserPassword } from "store/actions/authentication";
import { CHANGE_PASS_FORM } from "constants/forms_data";

function ResetPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    formData, formErrors, setFormValue, formValidate,
  } = useFormData(CHANGE_PASS_FORM);

  const onSave = async () => {
    const response = await dispatch(changeUserPassword(formData));
    formValidate(response, () => {
      toast.success("New password has been saved.");
      navigate("/app/settings");
    });
    document.body.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <Container>
      <div className="settingsPage">
        <SubHeader
          title="Change Password"
          text="Reset your account password."
        />
        <div className="changePassword__form">
          <Form>
            {CHANGE_PASS_FORM.map((input) => (
              <div key={input.key}>
                <InputText
                  inputType={input.inputType}
                  placeholder={input.placeholder}
                  autocomplete={input.autocomplete}
                  defaultValue={formData[input.key]}
                  onChangeValue={(e) => setFormValue(e.target.value, input.key)}
                  isValid={!formErrors[input.key]}
                  inputMode={input.key}
                />
                <ErrorValidation errors={formErrors[input.key]} />
              </div>
            ))}
          </Form>
        </div>
        <BottomBarSticky>
          <GenericButton className="button--success" text="Save" onButtonClick={onSave} />
        </BottomBarSticky>
      </div>
    </Container>
  );
}

export default ResetPasswordPage;
