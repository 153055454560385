import { GRAPH_TIMESCALE } from "constants/session_graph";

const markersInRange = (index, markers, step) => {
  const sectionMarkers = markers.slice(index, index + step);
  return sectionMarkers.reduce((count, currentValue) => (
    currentValue ? count + 1 : count
  ), 0);
};

const stackedMarkers = (markers, step) => markers.map((_, index) => {
  if ((index % step === 0) && (index + step < markers.length || index < step)) {
    const isLast = index + (step * 2) >= markers.length;
    const sectionCount = markersInRange(index, markers, isLast ? step * 2 : step);
    return sectionCount <= 0
      ? false
      : { number: sectionCount };
  }
  return false;
});

export default function getMarkers(zoneData, zoom) {
  const { steps, scale } = GRAPH_TIMESCALE[zoom];
  const isZoomOut = scale <= 29;
  const rewardMarkers = zoneData.map((value) => value === 2);
  const reminderMarkers = zoneData.map((value) => value === -2);

  return {
    reminder: isZoomOut
      ? stackedMarkers(reminderMarkers, steps)
      : reminderMarkers,
    reward: isZoomOut
      ? stackedMarkers(rewardMarkers, steps)
      : rewardMarkers,
  };
}
