import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { GenericButton } from "components";

import "./Denied.scss";

function Denied() {
  const navigate = useNavigate();

  return (
    <div className="dashboard">
      <Container>
        <h1>Access Denied</h1>
        <h3>Your account do not have permission to view this page</h3>
        <GenericButton
          text="Logout"
          className="button button--danger"
          onButtonClick={() => navigate("/coach/logout")}
        />
      </Container>
    </div>
  );
}

export default Denied;
