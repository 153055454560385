import "./SignalSequences.scss";

function SignalSequences({ sequences }) {
  const sensorsLocation = ["LE", "LF", "RF", "RE"];

  return (
    <div className="signalCheck__content">
      <div className="signalCheck__sequences-wrapper">
        {sequences && sequences.map(
          (sequence, timestap) => sequence && (
          <div
            key={timestap.toString()}
            className="signalCheck__sequences"
          >
            <div className="signalCheck__timestamp">{timestap}</div>
            <div className="signalCheck__sensors">
              {sequence.map((sensor, i) => (
                <div
                  key={i.toString()}
                  className={`signalCheck__sensor bg-s${sensor}`}
                >
                  {sequences.length === (timestap + 1) && sensorsLocation[i]}
                </div>
              ))}
            </div>
          </div>
          ),
        )}
      </div>
      <div className="signalCheck__footer">
        <div className="signalCheck__sequences">
          <div className="signalCheck__timestamp" />
          <div className="signalCheck__sensors">
            <div />
            <div>
              <span className="signalCheck__footer-label">
                Left Front
              </span>
            </div>
            <div>
              <span className="signalCheck__footer-label">
                Right Front
              </span>
            </div>
            <div />
          </div>
        </div>
        <div className="signalCheck__sequences">
          <div className="signalCheck__timestamp" />
          <div className="signalCheck__sensors">
            <div>
              <span className="signalCheck__footer-label">Left Ear</span>
            </div>
            <div className="signalCheck__footer-title">Sensors</div>
            <div>
              <span className="signalCheck__footer-label">
                Right Ear
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignalSequences;
