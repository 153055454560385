import * as d3 from "d3";
import ChartsBuildModal from "./ChartsBuildModal";

export default class LayersChartModel extends ChartsBuildModal {
  constructor(props) {
    super(props);
    this.scale = props.scale;
    this.data = props.data;
    this.graphTop = props.graphTop;
    this.graphBottom = props.graphBottom;
    this.height = this.element.node().scrollHeight;
  }

  draw() {
    const svg = this.createSvgRef();
    const width = this.data.length ? (this.data.length - 1) * this.scale : 0;
    svg.transition().duration(500).attr("width", `${width}`);

    const xScale = d3.scaleLinear()
      .domain([0, this.data.length])
      .range([0, this.data.length * this.scale]);

    const yScale = d3.scaleLinear()
      .domain([
        this.graphTop,
        this.graphBottom,
      ])
      .range([this.height, 0]);

    const myLine = d3.line()
      .x((_, index) => xScale(index))
      .y(yScale)
      .curve(d3.curveMonotoneX);

    svg
      .selectAll(".line")
      .data([this.data])
      .join("path")
      .attr("class", "line")
      .transition()
      .duration(500)
      .attr("d", myLine)
      .attr("fill", "none");
  }
}
