import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye, faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import "./ToggleVisible.scss";

export function ToggleVisible({ text, isActive, onToggle }) {
  return (
    <div
      role="presentation"
      className={`btn__toggle-visible ${isActive ? "is-active" : ""}`}
      onClick={onToggle}
    >
      <FontAwesomeIcon icon={isActive ? faEye : faEyeSlash} />
      {text}
      <div className="btn__toggle-visible-switch"><span /></div>
    </div>
  );
}

export default ToggleVisible;
