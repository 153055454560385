import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import {
  SubNav, Card, GenericButton, BottomBarSticky, InfoBox,
} from "components";
import cardImg from "assets/onboarding_completed.jpg";

import "./CompletedPage.scss";

function OnboardingCompleted() {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate("/app/meditation");
  };

  window.nativeBack = () => {
    handleNext();
  };

  return (
    <Container className="onboarding-completed">
      <SubNav
        audioGuide="coach-post-calibration"
        tile="Onboarding Completed"
        noBack
      />
      <h1 className="text-center mt-3">
        You&apos;re ready
        <br />
        to GoDeeper!
      </h1>
      <Card imgUrl={cardImg}>
        <p className="font-700">
          {/* TODO: certification check */}
          {/* We have tuned your thresholds to optimize your learning rate. */}
          Thank you for completing the onboarding process so you can contribute
          your brainwaves to meditation science and also learn about what is going
          on in your brain during your meditations.
        </p>
        <p className="font-700">
          Our goal is to get you to deeper meditations faster.
        </p>
        <p>
          Not racing to a finish line, but arriving sooner at the peace & bliss you seek.
        </p>
      </Card>
      <div className="onboarding-completed__info">
        <InfoBox>
          <p>
            You can always come back to change your answers or record a new baseline under
            {" "}
            <strong>Settings</strong>
            .
          </p>
        </InfoBox>
      </div>
      <BottomBarSticky>
        <GenericButton
          text="GoDeeper"
          className="button--dark"
          onButtonClick={handleNext}
        />
      </BottomBarSticky>
    </Container>
  );
}

export default OnboardingCompleted;
