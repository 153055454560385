import { ResponsiveBar } from "@nivo/bar";
import CHART_COLORS from "constants/coach_charts";

import "./Chart.scss";

export function Chart({
  chartData, keys, legend, format, groupMode,
}) {
  const getTooltip = (data) => (
    <div className="custom-tooltip">
      <div className="custom-tooltip__color" style={{ background: data.color }} />
      <span>{data.data.name}</span>
      <strong>
        {data.value + format}
      </strong>
      {data.data.helper && (
        <span>{data.data.helper}</span>
      )}
    </div>
  );
  return (
    <div className="chart">
      <ResponsiveBar
        theme={{
          background: "transparent",
          text: {
            fontSize: 11,
            fontFamily: "Quicksand",
            fill: "#171d66",
          },
          tooltip: {
            container: {
              background: "#ffffff",
              color: "#171d66",
              fontSize: 11,
            },
          },
        }}
        layout="vertical"
        data={chartData}
        keys={keys}
        indexBy="name"
        margin={{
          top: 15, right: 10, bottom: 50, left: 85,
        }}
        padding={0.3}
        borderRadius={!groupMode ? 4 : 0}
        groupMode={groupMode || "grouped"}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colorBy={!groupMode ? "indexValue" : "id"}
        colors={!groupMode ? CHART_COLORS.map((color) => color) : ["#00a6da", "#00c6ab", "#FF8975"]}
        axisBottom={chartData.length <= 10 && {
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend,
          legendPosition: "middle",
          legendOffset: -70,
          format: (data) => (data ? `${data + format}` : null),
        }}
        label={groupMode && ((label) => `${label.value + format}`)}
        labelTextColor="white"
        labelSkipHeight={14}
        enableLabel={chartData.length <= 10}
        tooltip={(data) => getTooltip(data)}
        role="application"
      />
    </div>
  );
}

export default Chart;
