import { Dropdown as RbDropdown } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

import "./Dropdown.scss";

export function Dropdown({
  items, activeItem, selectedItem, additionalText, align, icon,
}) {
  const findAcitveItemTitle = () => items
    && items.find((({ value }) => value === +activeItem))?.key;
  return (
    <RbDropdown className={`dropdown ${icon ? "w-icon" : ""}`} onSelect={(value) => selectedItem(value)}>
      <div className="dropdown__backdrop" />
      <RbDropdown.Toggle
        className="dropdown__btn"
        id="dropdown-basic"
      >
        <span>
          {findAcitveItemTitle() === 0
            ? `${findAcitveItemTitle()} ${additionalText ?? ""}`
            : `${findAcitveItemTitle()} ${additionalText ?? ""}` || "Choose"}
        </span>
        {icon && (
          <div className="selectPicker__icon">
            <FontAwesomeIcon icon={faPen} />
          </div>
        )}
      </RbDropdown.Toggle>

      <RbDropdown.Menu className="scroll-wrapper" align={align}>
        {items
          && items.map(({ value, key }) => (
            <RbDropdown.Item key={value} eventKey={value}>
              {key}
            </RbDropdown.Item>
          ))}
      </RbDropdown.Menu>
    </RbDropdown>
  );
}

export default Dropdown;
