import "./ToggleSwitch.scss";

export function ToggleSwitch({ isActive, onClick }) {
  return (
    <div role="presentation" {...{ onClick }} className={`button__switch ${isActive ? "button__switch--active" : ""}`}>
      <span />
    </div>
  );
}

export default ToggleSwitch;
