import { useMemo } from "react";
import { ButtonSwitch, GenericButton } from "components";
import _ from "lodash";

import { GRAPH_TIMESCALE } from "constants/session_graph";

export function BrainwaveFiltersTimerangeSelect({
  dataLength, timeline, setTimeline, scrollSync,
}) {
  const rangeSelections = useMemo(() => {
    const timeThird = Math.round(dataLength / 3);
    return [
      {
        key: "Full Session",
        range: { start: null, end: null },
      },
      {
        key: "Start",
        range: { start: 0, end: timeThird },
      },
      {
        key: "Middle",
        range: { start: timeThird, end: timeThird * 2 },
      },
      {
        key: "End",
        range: { start: timeThird * 2, end: dataLength },
      },
    ];
  }, [dataLength]);

  const onSelect = (timerange) => {
    scrollSync.current.wrappers.forEach((element) => {
      if (element) {
        element.scrollTo({
          left: GRAPH_TIMESCALE[scrollSync.current.zoom].scale * timerange.start - 20,
          behavior: "smooth",
        });
      }
    });
    setTimeline(timerange);
  };

  return (
    <ButtonSwitch pillsLayout>
      {rangeSelections.map((selection) => (
        <GenericButton
          key={selection.key}
          className={`btn-sm text-nowrap ${_.isEqual(timeline, selection.range) ? "btn--active" : ""}`}
          text={selection.key}
          onButtonClick={() => onSelect(selection.range)}
        />
      ))}
    </ButtonSwitch>
  );
}

export default BrainwaveFiltersTimerangeSelect;
