import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Button.scss";

export function GenericButton({
  className = "",
  style = null,
  type = "button",
  text,
  iconElement,
  iconSide = "left",
  onButtonClick,
  disabled = false,
  href,
  id,
}) {
  const renderContent = () => {
    const getKey = (value) => `${iconElement}_${text}_${value}`;
    const elements = [
      iconElement && <div key={getKey("icon")} className="button-icon-wrapper">{iconElement}</div>,
      <span key={getKey("text")} className="button-text">{text}</span>,
    ];
    if (iconSide === "right") elements.reverse();
    return elements;
  };

  return !href ? (
    <Button
      onClick={onButtonClick}
      variant="link"
      type={type}
      className={`button ${className}`}
      style={style}
      id={id}
      disabled={disabled}
    >
      {renderContent()}
    </Button>
  ) : (
    <Link
      to={href}
      className={`button ${className} ${disabled ? "disabled" : ""}`}
      style={style}
      id={id}
    >
      {renderContent()}
    </Link>
  );
}

export default GenericButton;
