import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { HeaderSensors } from "components/navs/HeaderSensors/HeaderSensors";
import useIsVisible from "hooks/useIsVisible";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as ArrowIcon } from "assets/icons_arrow.svg";

import AudioGuide from "features/AudioGuide/AudioGuide";

import "./SubNav.scss";

export function SubNav({
  title, onBack, noBack, setGuide, audioGuide, staticTitle, children, noSensors, size = "md", backText = "Back",
}) {
  const navigate = useNavigate();

  const [isSensorsBar, setIsSensorsBar] = useState(null);
  const [hiddenElement, setHiddenElement] = useState(false);

  const pageTitle = useRef(null);
  const isVisible = useIsVisible(pageTitle, -70, true);

  const goBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  window.nativeBack = () => {
    if (!noBack) goBack();
  };

  return (
    <div className={`subnav__container size-${size}`} style={{ minHeight: isSensorsBar && 110 }}>
      <nav className={`subnav ${!isVisible || staticTitle ? "show-title" : ""}`}>
        <div className="subnav__content-wrapper" style={{ height: isSensorsBar && 110 }}>
          <div className="subnav__content">
            {!noBack && (
            <button
              type="button"
              className="subnav__back"
              onClick={goBack}
            >
              <div className="icon-wrapper">
                <ArrowIcon />
              </div>
              <span>
                {backText}
              </span>
            </button>
            )}
            <div className={`subnav__title ${hiddenElement ? "hidden" : ""}`}>
              { title }
            </div>
            {setGuide && (
            <button type="button" className="guide-button" onClick={null} aria-label="Open Guide" style={{ right: audioGuide ? 65 : "inset" }}>
              <FontAwesomeIcon icon={faInfo} onClick={setGuide} />
            </button>
            )}
          </div>
          {!noSensors && <HeaderSensors setIsSensorsBar={setIsSensorsBar} />}
        </div>
        {audioGuide && <AudioGuide track={audioGuide} setHiddenElement={setHiddenElement} />}
      </nav>
      {children && (
      <div ref={pageTitle} className="page-title">
        { children }
      </div>
      )}
    </div>
  );
}

export default SubNav;
