import {
  useState, useRef, useMemo, useEffect,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Select, GenericButton, BottomBarSticky,
} from "components";
import { saveUserProfile } from "store/actions/profile";
import { AddCustomOptions } from "./AddCustomOptions/AddCustomOptions";

export function MeditationStyleForm({
  stepIndex, saveBtnText, stepOptions, stepSavedOptions, stepType, inputPlaceholder,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userSavedCustomOptions = useSelector(
    ({ profileReducer }) => profileReducer.userProfile.meditation_styles_other,
  );
  const completedSteps = useSelector(
    ({ profileReducer }) => profileReducer.userProfile?.onboarding_tab,
  );
  const isCompleted = useMemo(
    () => (stepIndex >= 0 ? (stepIndex < completedSteps) : true),
    [stepIndex, completedSteps],
  );

  const init = useRef(true);
  const [isValid, setIsValid] = useState(false);
  const [isNone, setIsNone] = useState(false);
  const [selectedItems, setSelectedItems] = useState([...stepSavedOptions]);
  const [customItems, setCustomItems] = useState([...userSavedCustomOptions]);

  useEffect(() => {
    const selected = (stepOptions.map(({ id }) => selectedItems.includes(id))).includes(true);
    const other = customItems.map((item) => item.type === stepType && !item.delete).includes(true);
    if (init.current) {
      init.current = false;
      if (isCompleted) {
        setIsNone(!selected && !other);
        setIsValid(true);
      }
    } else {
      setIsValid(selected || other || isNone);
    }
  }, [selectedItems, customItems, isNone]);

  const selectedItem = (id, isSelected) => {
    if (id && isSelected) {
      setSelectedItems([...selectedItems, id]);
      setIsNone(false);
    } else {
      const selectedIds = selectedItems.filter((ids) => ids !== id);
      setSelectedItems(selectedIds);
    }
  };

  const deselectItems = (deselect) => {
    setIsNone(!isNone);
    if (deselect) {
      const options = stepOptions.map(({ id }) => id);
      setSelectedItems(selectedItems.filter((item) => !options.includes(item)));
    }
  };

  const save = () => {
    if (isValid) {
      const options = stepIndex >= 0
        ? { nextTab: stepIndex + 1 }
        : { navigate, nextUrl: "/app/settings" };
      dispatch(saveUserProfile({
        meditation_styles: selectedItems,
        meditation_styles_other: customItems,
      }, options));
    } else {
      toast.error("Please select at least one option.");
    }
  };

  return (
    <div className="OnBoarding__step-form">
      <div className="appsPage__selectes-section">
        {stepOptions && stepOptions.map(({ name, id }) => (
          <div key={name} className="appsPage__select-wrapper">
            <Select
              text={name}
              id={id}
              defaultActive={selectedItems.includes(id)}
              selectedItem={(isSelectActive) => selectedItem(id, isSelectActive)}
            />
          </div>
        ))}
      </div>
      <div className="appsPage__select-wrapper">
        <Select
          text="None"
          defaultActive={isNone}
          selectedItem={() => deselectItems(!isNone)}
          disabled={customItems.map(
            (item) => item.type === stepType && !item.delete,
          ).includes(true)}
        />
      </div>
      <AddCustomOptions
        items={customItems}
        setItems={(items) => {
          setIsNone(false);
          setCustomItems(items);
        }}
        selectedType={stepType}
        inputPlaceholder={inputPlaceholder}
      />
      <BottomBarSticky>
        <GenericButton
          className={`button--success ${isValid ? "" : "button--muted"}`}
          text={saveBtnText || isCompleted ? "Save" : "Next"}
          onButtonClick={save}
        />
      </BottomBarSticky>
    </div>
  );
}

export default MeditationStyleForm;
