import { useSelector } from "react-redux";
import { useQuery, keepPreviousData } from "@tanstack/react-query";

import {
  getGoal, getProtocolMetrics, getProtocolProgress, getTimeProgress,
} from "api/services/reporting";

import { EmptyData } from "components";
import ZoneChart from "features/ProgressGraphs/ZoneChart/ZoneChart";
import TimeChart from "features/ProgressGraphs/TimeChart/TimeChart";
import StatsChart from "features/ProgressGraphs/StatsChart/StatsChart";
import DepthChart from "features/ProgressGraphs/DepthChart/DepthChart";
import LifetimeChart from "features/ProgressGraphs/LifetimeChart/LifetimeChart";
import MetricsChart from "features/ProgressGraphs/MetricsChart/MetricsChart";
import ChartStatus from "features/ProgressGraphs/ChartStatus";
import ChartProtocol from "features/ProgressGraphs/ChartProtocol";
import InfoRules from "features/BrainwaveInterpretation/BrainwaveInfo/InfoRules";

import useProtocol from "hooks/useProtocol";
import { useMediaQuery } from "react-responsive";
import useMetrics from "pages/App/Progress/Progress/ProgressCharts/hooks/useMetrics";

import { COACH_PROGRESS_GRAPH_TABS_KEYS } from "constants/profile_progress_charts";

import { faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

function ProgressChartController({
  clientId, pastProtocols, weeklyGoal, activeTab,
}) {
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  const dataLength = isMobile ? 20 : 30;

  const { period } = useSelector(({ reportingReducer }) => reportingReducer.graphStatus);

  const graphProtocol = useSelector(
    ({ reportingReducer }) => reportingReducer.graphProtocol,
  );

  const protocolDetails = useProtocol(graphProtocol?.categoryID, graphProtocol?.protocolID);

  const timeData = useQuery({
    queryKey: ["time-progress", clientId, period],
    queryFn: () => getTimeProgress(clientId, period.toLowerCase(), dataLength),
    placeholderData: keepPreviousData,
    staleTime: Infinity,
    refetchOnMount: "always",
  });
  const statsData = useQuery({
    queryKey: ["stats-progress", clientId, period, graphProtocol?.levelID],
    queryFn:
      () => getProtocolProgress(clientId, period.toLowerCase(), dataLength, graphProtocol?.levelID),
    placeholderData: keepPreviousData,
    staleTime: Infinity,
    refetchOnMount: "always",
    enabled: !!graphProtocol,
  });
  const metricsQuery = useQuery({
    queryKey: ["metrics-progress", clientId, period, graphProtocol?.levelID],
    queryFn:
      () => getProtocolMetrics(clientId, period.toLowerCase(), dataLength, graphProtocol?.levelID),
    placeholderData: keepPreviousData,
    staleTime: Infinity,
    refetchOnMount: "always",
    enabled: !!graphProtocol,
  });
  const lifetimeData = useQuery({
    queryKey: ["lifetime-progress", clientId],
    queryFn: () => getGoal(clientId),
  });

  const metricsData = useMetrics(
    metricsQuery,
    graphProtocol?.level,
  );

  const renderChart = () => {
    switch (activeTab) {
      case COACH_PROGRESS_GRAPH_TABS_KEYS.DEPTH:
        return <DepthChart data={statsData} />;
      case COACH_PROGRESS_GRAPH_TABS_KEYS.METRICS:
        return <MetricsChart data={metricsData} />;
      case COACH_PROGRESS_GRAPH_TABS_KEYS.ZONE:
        return <ZoneChart data={statsData} />;
      case COACH_PROGRESS_GRAPH_TABS_KEYS.STATS:
        return <StatsChart data={statsData} />;
      case COACH_PROGRESS_GRAPH_TABS_KEYS.TIME:
        return <TimeChart data={timeData} />;
      case COACH_PROGRESS_GRAPH_TABS_KEYS.LIFETIME:
        return <LifetimeChart data={lifetimeData} goalData={weeklyGoal} />;
      default:
        return <div className="progress-session-chart__wrapper-empty">Empty</div>;
    }
  };

  return (
    <>
      <div className="progress-session-chart">
        <div className="progress-session-chart__wrapper-container">
          {renderChart()}
        </div>
        <div className="progress-session-chart__footer">
          {activeTab !== COACH_PROGRESS_GRAPH_TABS_KEYS.LIFETIME && (
            <ChartStatus dataMin={14} dataLength={dataLength} />
          )}
          {activeTab !== COACH_PROGRESS_GRAPH_TABS_KEYS.LIFETIME
        && activeTab !== COACH_PROGRESS_GRAPH_TABS_KEYS.TIME && (
          <ChartProtocol data={pastProtocols} protocolDetails={protocolDetails} />
          )}
        </div>
      </div>
      {activeTab === COACH_PROGRESS_GRAPH_TABS_KEYS.METRICS && (
      <div className="progress-session-metrics">
        {metricsData.isData ? (
          <>
            <div className="progressPage__chart-header mt-5">
              <h2>Metrics Report Card</h2>
              <hr />
            </div>
            <p className="text-sm">
              Each of these metrics are associated with depth for
              this meditation style and level of expertise.
            </p>
            <InfoRules
              rulesContent={metricsData.rulesContent}
              protocolDetails={protocolDetails}
            />
          </>
        ) : (
          <EmptyData
            icon={faFilterCircleXmark}
            title="No sessions found"
            text="Try different period or range."
          />
        )}
      </div>
      )}
    </>

  );
}

export default ProgressChartController;
