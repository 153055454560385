import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SensorsBar, BluetoothBattery, BluetoothDevice } from "components/eeg";
import { ReactComponent as IconBt } from "assets/icons_bt.svg";
import { btMenuController } from "utils/native-controllers";

import "./HeaderSensors.scss";

export function HeaderSensors({ setIsSensorsBar }) {
  const {
    activeStream, signalCheck, btDeviceStream,
  } = useSelector(({ sessionReducer }) => sessionReducer);

  const [activeBar, setActiveBar] = useState(null);

  useEffect(() => {
    setActiveBar(() => {
      if (activeStream && activeStream !== "signal-quality") {
        if (activeStream === "status") return "status";
        if (signalCheck.length > 0) return "sensors";
        return "status";
      }
      return null;
    });
  }, [activeStream, signalCheck]);

  useEffect(() => {
    if (setIsSensorsBar) setIsSensorsBar(activeBar);
  }, [activeBar]);

  const onClick = () => {
    btMenuController("toggle");
  };

  return activeBar
    && (
    <div className="headerSensors">
      {activeBar === "sensors" && (
        <div
          role="presentation"
          className={`headerSensors__wrapper ${btDeviceStream ? "streaming" : ""}`}
          onClick={onClick}
        >
          <IconBt />
          <SensorsBar signalCheck={signalCheck} />
          <BluetoothBattery />
        </div>
      )}
      {activeBar === "status" && <BluetoothDevice showBattery />}
    </div>
    );
}

export default HeaderSensors;
