/* eslint-disable camelcase */
import {
  GenericButton, RangeInput, BottomBarSticky, InfoBox, ToggleSwitch, Select, SelectPicker,
} from "components";
import { ReactComponent as SoundIcon } from "assets/icons_sound.svg";

import useAudioFeedback from "./useAudioFeedback";

import "./AudioFeedback.scss";

function AudioFeedback({ nextUrl }) {
  const {
    isCertified,
    audioFeedbackOptions,
    soundScapeLabels,
    selectedOption,
    selectedTimeOption,
    selectedSoundVolume,
    activeSound,
    stopSound,
    onOptionsSelect,
    onTimeOptionsSelect,
    onRangeOptionChange,
    onSave,
    isVolumeSection,
  } = useAudioFeedback(nextUrl);

  const isTapSection = (field) => field === "tap_sound";

  return (
    <div className="audioFeedback">
      {!isCertified && (
      <InfoBox type="warning" customClass="mb-3">
        <p>
          None of these sound settings will operate during a session unless you
          are running a protocol that has been certified.
        </p>
        <p>
          Generally it takes at least 30 people and 200 sessions with tapping feedback before a
          protocol can be certified as reliable.
        </p>
        <p>
          Please send experienced friends to IMBR.ORG to sign up to donate
          their brain waves to meditation science and get your favorite protocol certified faster.
        </p>
      </InfoBox>
      )}
      <InfoBox>
        <p>
          Hold slider thumb pressed for selected sound to continue playing.
        </p>
      </InfoBox>
      {audioFeedbackOptions && audioFeedbackOptions.map(({
        name, seconds_label, seconds_options, description, options, field, seconds_field,
        volume_fields,
      }) => (
        <div key={field} className="audioFeedback__section">
          <div className="h2">
            {name}
          </div>
          { seconds_options && (
          <div className="audioFeedback__time-selector-wrapper">
            <p className="text-sm text-muted mb-0">
              {seconds_label}
            </p>
            <div className="audioFeedback__section-time-selector">
              <SelectPicker
                options={[seconds_options]}
                initialValue={[selectedTimeOption[seconds_field]]}
                onSelect={(value) => onTimeOptionsSelect(value, seconds_field)}
                buttonText={`${selectedTimeOption[seconds_field]} sec`}
                labels={["seconds"]}
              />
            </div>
          </div>
          )}
          <p className={!seconds_options ? "text-muted text-sm" : ""}>{description}</p>
          { isTapSection(field) && (
            <div className="audioFeedback__taps">
              <div className="audioFeedback__section-switch">
                <p className="text-sm text-muted mb-0">
                  Play haptic feedback for taps.
                </p>
                <ToggleSwitch
                  isActive={selectedOption.tap_haptic}
                  onClick={() => onOptionsSelect("tap_haptic", null, !selectedOption.tap_haptic)}
                />
              </div>
              <div className="audioFeedback__section-switch">
                <p className="text-sm text-muted mb-0">
                  Play audio sound for taps.
                </p>
                <ToggleSwitch
                  isActive={selectedOption.tap_sound}
                  onClick={() => onOptionsSelect("tap_sound", null, selectedOption.tap_sound ? 0 : 1)}
                />
              </div>
            </div>
          )}
          { (!isTapSection(field) || selectedOption.tap_sound > 0) && (
          <div className="audioFeedback__section-options-description">
            { !seconds_options && (
            <p>{ isTapSection(field) ? "Select volume for sounds:" : "Choose your sound:"}</p>
            )}
            <div className="audioFeedback__section-options">
              {options.map(({ key, value }) => value !== 0 && (
                <div
                  role="presentation"
                  key={key}
                  className={`audioFeedback__section-option-wrapper ${key === activeSound ? "is-sound" : ""}`}
                >
                  <div className="select-icon">
                    <SoundIcon />
                  </div>
                  <Select
                    text={key}
                    defaultActive={selectedOption[field] === value}
                    selectedItem={() => onOptionsSelect(field, key, value)}
                    size="sm"
                    customClass={isTapSection(field) ? "tap-audio" : ""}
                    isRadio
                  />
                </div>
              ))}
            </div>
            {isVolumeSection(field, options) && (
            <div className="audioFeedback__section-sound-range">
              { name === "Soundscape" && (
              <h3 className="mb-4">
                Set the soundscape volume for each zone
              </h3>
              )}
              {volume_fields && volume_fields.map((volumeField) => (
                <div key={volumeField}>
                  <p className="text-sm">{soundScapeLabels[volumeField]}</p>
                  <RangeInput
                    min={0}
                    max={10}
                    step={1}
                    value={selectedSoundVolume[volumeField]}
                    onChange={(val) => onRangeOptionChange(val, volumeField, field, options)}
                    onTouchEnd={() => stopSound()}
                    field={volumeField}
                  />
                </div>
              ))}
            </div>
            )}

          </div>
          )}
        </div>
      ))}
      <BottomBarSticky>
        <GenericButton className="button--success" text="Save" onButtonClick={onSave} />
      </BottomBarSticky>
    </div>
  );
}

export default AudioFeedback;
