import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { HeaderSensors } from "components";
import { HelpModal } from "features";
import AudioGuide from "features/AudioGuide/AudioGuide";
import { formatDuration } from "utils/time-format";
import { ReactComponent as IconBack } from "assets/icons_arrow.svg";
import AudioFeedback from "pages/App/Common/AudioFeedback/AudioFeedback";
import { setActiveStream } from "store/actions/session";

import "./LiveSessionSound.scss";

function LiveSessionSoundPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    sessionLength,
    remainingSessionTime,
  } = useSelector(({ sessionReducer }) => sessionReducer);
  const initSessionLength = useMemo(
    () => (sessionLength.hours * 60 * 60) + (sessionLength.minutes * 60),
    [sessionLength],
  );

  const onResumeSession = () => {
    navigate(remainingSessionTime ? "/app/meditation/pause-report" : "/app/meditation/live-session");
  };

  useEffect(() => {
    dispatch(setActiveStream("status"));
  }, []);

  return (
    <>
      <div className="liveSessionPage__header-wrapper">
        <div className="liveSessionPage__header">
          <div className={`liveSessionPage__timer ${remainingSessionTime !== null && remainingSessionTime <= 0 ? "extended" : ""}`}>
            <div className="liveSessionPage__header-text">
              <span>+</span>
              {formatDuration(Math.abs(remainingSessionTime ?? initSessionLength) * 1000)}
            </div>
          </div>
          <HeaderSensors />
          <button type="button" className="back-button" onClick={onResumeSession}>
            <IconBack />
            <span>Back</span>
          </button>
          <AudioGuide track="coach-sound-settings" />
        </div>
      </div>
      <Container>
        <div className="liveSessionSoundPage">
          <div className="header">
            <h1 className="page-title">
              Audio Feedback
              <HelpModal location="audio-settings" helpIcon />
            </h1>
            <p>
              Creating an enjoyable sound environment is critical to fast learning.
              The more reward you feel, the faster you learn.
            </p>
          </div>
          <AudioFeedback nextUrl="/app/meditation/pause-report" />
        </div>
      </Container>
    </>
  );
}

export default LiveSessionSoundPage;
