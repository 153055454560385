import { Container } from "react-bootstrap";

import Training from "pages/App/Common/Training/Training";
import { SubHeader } from "components";

function TrainingPage() {
  return (
    <Container>
      <div className="SettingsPage">
        <SubHeader
          title="Training"
          text="Change information about your training programs."
          audioGuide="coach-trainings"
        />
        <Training saveBtnText="Save" />
      </div>
    </Container>
  );
}

export default TrainingPage;
