import ReactPaginate from "react-paginate";

import arrow from "assets/icons_arrow.svg";

import "./Pagination.scss";

export function Pagination({
  onPageChange,
  paginagtionStatus,
  pageCount,
  initialPage,
  forcePage,
}) {
  return (
    <div className="pagination-container">
      <ReactPaginate
        className="pagination"
        nextLabel={<img src={arrow} alt="next" />}
        previousLabel={<img src={arrow} alt="previous" />}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        activeClassName="active"
        disabledClassName="disabled"
        onPageChange={onPageChange}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        initialPage={initialPage && Math.max(0, Number(initialPage) - 1)}
        forcePage={forcePage && forcePage - 1}
        renderOnZeroPageCount={null}
      />
      {paginagtionStatus && (
        <div className="pagination-container__status">
          {paginagtionStatus}
        </div>
      )}
    </div>
  );
}

export default Pagination;
