import { Modal } from "react-bootstrap";
import { GenericButton } from "components/ui/Button/Button";
import { Spinner } from "components/ui/Spinner/Spinner";

export function Dialog({
  onCancel,
  onSuccess,
  successText,
  cancelText,
  title,
  className,
  show,
  loading,
  children,
}) {
  const onHide = () => {
    if (!loading) onCancel?.();
  };
  return (
    <Modal
      animation
      dialogClassName={`dialog-xs ${className || ""} ${loading ? "is-loading" : ""}`}
      show={show}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { children }
      </Modal.Body>
      <Modal.Footer>
        <GenericButton
          onButtonClick={onCancel}
          text={cancelText || "Cancel"}
          className="button--cancel"
          disabled={loading}
        />
        {onSuccess && (
        <GenericButton
          onButtonClick={onSuccess}
          text={successText || "Yes"}
          className="button--success"
          disabled={loading}
        />
        )}
      </Modal.Footer>
      {loading && <Spinner fixed />}
    </Modal>
  );
}

export default Dialog;
