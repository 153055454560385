import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ProtocolSelectContent } from "components/eeg/ProtocolSelectContent/ProtocolSelectContent";
import { ProtocolDetails } from "components/eeg/ProtocolDetails/ProtocolDetails";
import _ from "lodash";

export function ProtocolSelectInfo({
  protocol, level, rule, showDescription, date, centered, size, customClass = "", infoIcon = true, isActive = true,
}) {
  const isTraining = protocol?.icon === "training";
  const [showProtocolDetails, setShowProtocolDetails] = useState(false);
  return protocol && (
    <>
      <div
        role="presentation"
        className={`protocolSelectInfo__wrapper ${customClass}`}
        onClick={() => !isTraining && isActive && setShowProtocolDetails(true)}
      >
        <ProtocolSelectContent
          protocol={protocol}
          level={level}
          rule={rule}
          date={date}
          showDescription={showDescription}
          centered={centered}
          size={size}
          infoIcon={!isTraining && infoIcon}
          infoDisabled={!isActive}
        />
      </div>
      {!isTraining && (
      <Modal
        animation
        dialogClassName="dialog"
        show={showProtocolDetails}
        onHide={() => setShowProtocolDetails(false)}
      >
        <Modal.Header closeButton>
          <h2 className="protocolDetails__protocol-title m-0" style={{ color: protocol.color }}>
            {_.unescape(protocol.protocolName)}
          </h2>
        </Modal.Header>
        <Modal.Body>
          <ProtocolDetails protocol={protocol} />
        </Modal.Body>
      </Modal>
      )}
    </>
  );
}

export default ProtocolSelectInfo;
