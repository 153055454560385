const AUDIO_FILES = {
  end_gong: "operation_sounds_end_gong.mp3",
  done_brring: "operation_sounds_done_brring.mp3",
  test_bell: "operation_sounds_test_bell.mp3",
  baseline: "baseline_murmering.wav",
  reward_sound: [
    {
      value: 1,
      file: "reward_sounds_bird_chirps.wav",
    },
    {
      value: 2,
      file: "reward_sounds_cat_purr.wav",
    },
    {
      value: 3,
      file: "reward_sounds_lapping_waves.wav",
    },
    {
      value: 4,
      file: "",
    },
  ],
  wondering_reminder_sound: [
    {
      value: 1,
      file: "wandering_reminder_sounds_singing_bowl.wav",
    },
    {
      value: 2,
      file: "wandering_reminder_sounds_gong.wav",
    },
    {
      value: 3,
      file: "wandering_reminder_sounds_bell.wav",
    },
    {
      value: 4,
      file: "",
    },
  ],
  movement_sound: [
    {
      value: 1,
      file: "movement_sounds_wind_chimes.wav",
    },
    {
      value: 2,
      file: "movement_sounds_instrumental_chimes.wav",
    },
    {
      value: 3,
      file: "movement_sounds_arctic_wind.wav",
    },
    {
      value: 4,
      file: "",
    },
  ],
  background_sound: [
    {
      value: 1,
      file: "",
    },
    {
      value: 2,
      file: "soundscape_crickets.wav",
    },
    {
      value: 3,
      file: "soundscape_deep_hum.wav",
    },
    {
      value: 4,
      file: "soundscape_babbling_brook.wav",
    },
    {
      value: 5,
      file: "soundscape_om_tibetan_monks.wav",
    },
    {
      value: 7,
      file: "soundscape_light_rain.wav",
    },
  ],
  tap_sound: [
    {
      value: 0,
      file: "",
    },
    {
      value: 1,
      file: "tap_1_bowl.wav",
    },
    {
      value: 2,
      file: "tap_2_drum.wav",
    },
    {
      value: 3,
      file: "tap_3_choir.wav",
    },
  ],
};

export default AUDIO_FILES;
