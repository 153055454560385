import { useState } from "react";
import { useSelector } from "react-redux";

import {
  GenericButton, DiagnosticChart, EmptyData, Dialog,
} from "components";
import { faMagnifyingGlassChart } from "@fortawesome/free-solid-svg-icons";
import arrowUp from "assets/icons_arrow-up.svg";
import { format } from "date-fns";

import "./DiagnosticResults.scss";

export function DiagnosticResults({ onRetake, collapsed = false }) {
  const { diagnostic } = useSelector(({ profileReducer }) => profileReducer.userProfile);
  const [showDiagnosticDialog, setShowDiagnosticDialog] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  return (
    <div className="diagnosticResults">
      <div
        role="presentation"
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="diagnosticResults__header mb-4"
      >
        <div>
          <h2 className="mb-1">Diagnostic Results</h2>
          <p className="text-sm text-muted m-0">
            {diagnostic?.date
              ? format(new Date(diagnostic.date), "MMMM do, yyyy - hh:mma")
              : "Run diagnostic"}
          </p>
        </div>
        <div className="diagnosticResults__header-collapse-wrapper button">
          <img
            className={`diagnosticResults__header-collapse-icon ${isCollapsed ? "is-collapsed" : ""}`}
            src={arrowUp}
            alt="arrow icon"
          />
        </div>
      </div>
      <div className={`diagnosticResults__wrapper ${isCollapsed ? "is-collapsed" : ""}`}>
        {diagnostic
          ? <DiagnosticChart protocols={diagnostic?.protocols} />
          : (
            <EmptyData
              title="No diagnostic yet"
              text="Run diagnostic to check which feedback protocol would be best for your normal style of mediation."
              icon={faMagnifyingGlassChart}
            />
          )}
        <GenericButton
          text={`${diagnostic ? "Retake" : "Run"} Diagnostic`}
          onButtonClick={diagnostic ? () => setShowDiagnosticDialog(true) : onRetake}
          className="button--dark"
        />
        <Dialog
          show={showDiagnosticDialog}
          title="Retake Diagnostic"
          onSuccess={onRetake}
          onCancel={() => setShowDiagnosticDialog(false)}
        >
          <p>
            Are you sure you want to Retake Diagnostic?
          </p>
        </Dialog>
      </div>
    </div>
  );
}

export default DiagnosticResults;
