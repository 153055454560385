import {
  useState, useEffect, useMemo, useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { BottomBarSticky, GenericButton, Spinner } from "components";
import useSessionError from "hooks/useSessionError";
import useInterval from "hooks/useInterval";
import useMuse from "hooks/useMuse";
import {
  getDataStream, stopStreamData, cancelStreamData, clearLiveSessionData,
} from "store/actions/session";
import { audioController } from "utils/native-controllers";
import { formatDuration } from "utils/time-format";

import AUDIO_FILES from "constants/profile_audio";

import "./CalibrationSession.scss";

function CalibrationSession({
  onComplete, onCancel, onError, sessionStatus, sessionStartedText, startStream, audioFile,
  initialTime = 300, minSequences = 270, children,
}) {
  const dispatch = useDispatch();

  const userProfile = useSelector(
    ({ profileReducer }) => profileReducer.userProfile,
  );
  const loggedInUserId = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.pk,
  );
  const { btDevice, liveSessionData } = useSelector(
    ({ sessionReducer }) => sessionReducer,
  );

  const audioBackground = useMemo(() => {
    if (audioFile) return audioFile;
    const getSoundName = (soundCollection, soundValue) => soundCollection.find(
      ({ value }) => value === soundValue,
    ).file;
    return userProfile.background_sound
      ? getSoundName(AUDIO_FILES.background_sound, userProfile.background_sound)
      : AUDIO_FILES.background_sound[1].file;
  }, [userProfile, audioFile]);

  const [status, setStatus] = sessionStatus || [];
  const [remainingTime, setRemainingTime] = useState();
  const sessionCompleted = useRef(false);

  useMuse();

  const interval = useInterval(() => {
    if (liveSessionData.length) {
      setRemainingTime(remainingTime ? remainingTime - 1 : initialTime);
    }
  }, 1000);

  useEffect(() => () => {
    audioController("stop", audioBackground);
    dispatch(getDataStream(false));
    dispatch(clearLiveSessionData());
    if (!sessionCompleted.current) {
      dispatch(cancelStreamData(loggedInUserId));
    }
  }, []);

  const startSession = () => {
    if (btDevice.status === 5) {
      setStatus("started");
      dispatch(getDataStream(true, loggedInUserId, "data", () => startStream()));
    } else {
      toast.error("Muse device not connceted.");
    }
  };

  const completeSession = () => {
    setStatus("completed");
    sessionCompleted.current = true;
    dispatch(stopStreamData(loggedInUserId));
    setTimeout(() => {
      onComplete?.();
    }, 1000);
  };

  useEffect(() => {
    if (remainingTime === initialTime) {
      audioController("loop", audioBackground);
    } else if (remainingTime === 0) {
      clearInterval(interval);
      audioController("stop", audioBackground);
      audioController("play", AUDIO_FILES.done_brring);
      if (liveSessionData.length >= minSequences) {
        completeSession();
      } else {
        toast.error("Not enough good quality data during your recording. Stay still and try again.");
        onCancel?.();
      }
    }
  }, [remainingTime]);

  const sessionError = useSessionError(
    liveSessionData,
    status === "started",
    sessionCompleted.current,
    remainingTime,
  );

  useEffect(() => {
    if (sessionError) {
      audioController("play", AUDIO_FILES.end_gong);
      onError?.();
      toast.error(`Calibration Canceled: ${sessionError}`);
    }
  }, [sessionError]);

  window.nativeBack = () => {
    onCancel?.();
  };

  return (
    <div className={`calibrationSession ${remainingTime ? "is-started" : ""}`}>
      <div className="calibrationSession__content">
        {children}
      </div>
      {status !== "completed" && (
        <>
          {status !== "loading" && (
          <div className="calibrationSession__timer">
            <div className="calibrationSession__timer-wrapper">
              {formatDuration(
                (remainingTime >= 0 ? remainingTime : initialTime) * 1000,
              )}
            </div>
            <div className="calibrationSession__timer-started">
              {sessionStartedText || "Recording"}
            </div>
          </div>
          )}
          <BottomBarSticky>
            {status === "started" && (
            <Spinner />
            )}
            <GenericButton
              className="button--dark"
              text="Start"
              onButtonClick={() => startSession()}
              disabled={status !== "ready"}
            />
          </BottomBarSticky>
        </>
      )}
    </div>
  );
}

export default CalibrationSession;
