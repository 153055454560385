const GRAPH_ZOOM = [
  { scale: 3, status: 0.5 },
  { scale: 6, status: 1 },
  { scale: 30, status: 5 },
  { scale: 60, status: 10 },
];

const GRAPH_TIMESCALE = [
  { scale: 3, steps: 20 },
  { scale: 6, steps: 10 },
  { scale: 30, steps: 2 },
  { scale: 60, steps: 1 },
];

export { GRAPH_ZOOM, GRAPH_TIMESCALE };
