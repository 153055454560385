import "./ErrorValidation.scss";

export function ErrorValidation({ errors }) {
  return errors && (
    <div className="error-message__container">
      {errors.map((errorMsg, i) => (
        <p key={i.toString()} className="error-message__text">{errorMsg}</p>
      ))}
    </div>
  );
}

export default ErrorValidation;
