import { ReactComponent as Diamond } from "assets/icons_diamond.svg";
import { ReactComponent as Trophy } from "assets/icons_trophy.svg";
import { ReactComponent as Warning } from "assets/icons_warning.svg";
import { ReactComponent as Uphill } from "assets/icons_uphill.svg";
import { ReactComponent as Downhill } from "assets/icons_downhill.svg";
import { ReactComponent as Quote } from "assets/icons_quote.svg";
import { ReactComponent as UpDown } from "assets/icons_up_down.svg";
import { ReactComponent as DownUp } from "assets/icons_down_up.svg";

const BADGES = {
  summary: {
    name: "summary",
    content: [
      {
        grade: 5,
        color: "blue",
        metric: (score) => score.rewards >= 60 && score.reminders < 40,
        text: "What a steady Mind you had today. Command performance!",
      },
      {
        grade: 4,
        color: "green",
        metric: (score) => score.rewards > 50 && score.rewards < 60 && score.reminders < 40,
        text: "Great session today!",
      },
      {
        grade: 2,
        color: "orange",
        metric: (score) => score.rewards <= 20 && score.reminders > 40,
        text: "Don't be discouraged with too much mind wandering. The Mind always yields to practice.",
      },
      {
        grade: 1,
        color: "brown",
        metric: (score) => score.reminders >= 40,
        text: "Tough day today. Be kind to yourself.",
      },
      {
        grade: 3,
        color: "yellow",
        metric: () => true,
        text: "Lookin' good.",
      },
    ],
  },
  points: {
    icon: <Diamond />,
    name: "points",
    content: [
      {
        grade: 5,
        color: "purple",
        metric: (score) => score > 100,
        text: "\"You are the sky. Everything else – it's just the weather.\"",
        author: "Pema Chödrön",
      },
      {
        grade: 4,
        color: "blue",
        metric: (score) => score > 80 && score <= 100,
        text: "\"Feelings come and go like clouds in a windy sky. Conscious breathing is my anchor.\"",
        author: "Thich Nhat Hanh",
      },
      {
        grade: 3,
        color: "green",
        metric: (score) => score > 60 && score <= 80,
        text: "\"To uncover your true potential you must first find your own limits and then you have to have the courage to blow past them.\"",
        author: "Picabo Street",
      },
      {
        grade: 2,
        color: "yellow",
        metric: (score) => score > 40 && score <= 60,
        text: "\"The difference between try and triumph is just a little umph!\"",
        author: "Marvin Phillips",
      },
      {
        grade: 1,
        color: "orange",
        metric: (score) => score > 20 && score <= 40,
        text: "\"You Learn More From Failure Than From Success. Don't Let It Stop You. Failure Builds Character.\"",
        author: "Unknown",
      },
      {
        grade: 0,
        color: "brown",
        metric: (score) => score <= 20,
        text: "\"It's Not Whether You Get Knocked Down, It's Whether You Get Up.\"",
        author: "Vince Lombardi",
      },
    ],
  },
  trophies: {
    icon: <Trophy />,
    name: "trophies",
    content: [
      {
        grade: 5,
        color: "purple",
        metric: (score) => score >= 5,
        text: "You are ready for the mediation Olympics!",
      },
      {
        grade: 4,
        color: "blue",
        metric: (score) => score >= 4 && score < 5,
        text: "\"Today's accomplishments were yesterday's impossibilities.\"",
        author: "Robert H. Schuller",
      },
      {
        grade: 3,
        color: "green",
        metric: (score) => score >= 3 && score < 4,
        text: "\"If you are working on something that you really care about, you don't have to be pushed. The vision pulls you.\"",
        author: "Steve Jobs",
      },
      {
        grade: 2,
        color: "yellow",
        metric: (score) => score >= 2 && score < 3,
        text: "\"Who you are tomorrow begins with what you do today.\"",
        author: "Tim Fargo",
      },
      {
        grade: 1,
        color: "orange",
        metric: (score) => score >= 1 && score < 2,
        text: "\"Champions keep playing until they get it right.\"",
        author: "Billie Jean King",
      },
      {
        grade: 0,
        color: "brown",
        metric: (score) => score < 1,
        text: "\"Whether you think you can, or you think you can't – you're right.\"",
        author: "Henry Ford",
      },
    ],
  },
  warnings: {
    icon: <Warning />,
    name: "warnings",
    content: [
      {
        grade: 5,
        color: "purple",
        metric: (score) => score < 1,
        text: "\"To a mind that is still, the entire universe surrenders.\"",
        author: "Zhuangzi",
      },
      {
        grade: 4,
        color: "blue",
        metric: (score) => score >= 1 && score < 2,
        text: "\"Do or do not. There is no try.\"",
        author: "Yoda,\nThe Empire Strikes Back",
      },
      {
        grade: 3,
        color: "green",
        metric: (score) => score >= 2 && score < 3,
        text: "\"A path is made by walking on it.\"",
        author: "Zhuangzi",
      },
      {
        grade: 2,
        color: "yellow",
        metric: (score) => score >= 3 && score < 4,
        text: "\"To be fully alive, fully human, and completely awake is to be continually thrown out of the nest.\"",
        author: "Pema Chödrön",
      },
      {
        grade: 1,
        color: "orange",
        metric: (score) => score >= 4 && score < 5,
        text: "\"It does not matter how slowly you go as long as you do not stop.\"",
        author: "Confucius",
      },
      {
        grade: 0,
        color: "brown",
        metric: (score) => score >= 5,
        text: "\"We may encounter many defeats but we must not be defeated.\"",
        author: "Maya Angelou",
      },
    ],
  },
  recalibration: {
    name: "zones",
    content: [
      {
        grade: 5,
        icon: <Uphill />,
        metric: (score) => score.reminders < 10,
        text: "If you consistently get in the red zone less than 10% of the time you may want to recalibrate. You will learn faster with more challenging thresholds.",
      },
      {
        grade: 5,
        icon: <Uphill />,
        metric: (score) => score.rewards > 30,
        text: "If you consistently get in the blue zone over 50% of the time you may want to recalibrate.  You will learn faster with more challenging thresholds.",
      },
      {
        grade: 1,
        icon: <Downhill />,
        metric: (score) => score.reminders > 30,
        text: "If you consistently get in the red zone more than 30% of the time you may want to recalibrate. You will learn faster if you get rewarded more often.",
      },
      {
        grade: 1,
        icon: <Downhill />,
        metric: (score) => score.rewards < 10,
        text: "If you consistently get in the blue zone less than 30% of the time you may want to recalibrate. You will learn faster if you get rewarded more often.",
      },
    ],
  },
  recalibrationHalves: {
    name: "halfZones",
    content: [
      {
        icon: <DownUp />,
        metric: (score) => (score.rewards.firstHalf * 1.3) < score.rewards.secondHalf,
        text: "You spent significantly more time in the blue zone in the second half of the session. If this happens consistently, you might want to consider longer meditations.",
      },
      {
        icon: <UpDown />,
        metric: (score) => (score.reminders.firstHalf * 1.3) < score.reminders.secondHalf,
        text: "You spent significantly more time in the red zone in the second half of the session. If this happens consistently, you might want to consider shorter meditations.",
      },
    ],
  },
  quote: {
    icon: <Quote />,
    name: "quote",
    content: [
      {
        text: "Don't give up. The beginning is always the hardest. Life rewards those who work hard.",
      },
      {
        text: "\"The pessimist sees difficulty in every opportunity. The optimist sees opportunity in every difficulty.\"",
        author: "Winston Churchill",
      },
      {
        text: "\"Have patience with everything that remains unsolved in your heart. ...live in the question.\"",
        author: "Rainer Maria Rilke",
      },
      {
        text: "\"Practice is the hardest part of learning, and training is the essence of transformation.\"",
        author: "Ann Voskamp,\nOne Thousand Gifts: A Dare to Live Fully Right Where You Are",
      },
      {
        text: "\"Hard work is a prison sentence only if it does not have meaning. Once it does, it becomes the kind of thing that makes you grab your wife around the waist and dance a jig.\"",
        author: "Malcolm Gladwell",
      },
    ],
  },
};

export default BADGES;
