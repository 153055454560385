import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ReactComponent as ArrowIcon } from "assets/icons_arrow_dark.svg";

import "./SelectTile.scss";

export function SelectTile({
  children,
  onSelect,
  customClass = "",
  customStyle = {},
  withButton,
  customIconName,
}) {
  return (
    <div
      className={`selectTile ${customClass}`}
      role="presentation"
      onClick={onSelect}
      style={customStyle}
    >
      <div className="selectTile__select-container">
        {children}
        {withButton && (
          <div className="selectTile__select-button--right">
            {!customIconName ? (
              <ArrowIcon />
            ) : (
              <FontAwesomeIcon icon={customIconName} />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SelectTile;
