import errorHandler from "utils/error-handler";
import * as actionTypes from "./types";

export const changePastSessionReportsOrder = (payload) => ({
  type: actionTypes.CHANGE_PAST_SESSION_REPORTS_ORDER,
  payload,
});
export const changePastSessionReportsFilters = (payload) => ({
  type: actionTypes.CHANGE_PAST_SESSION_REPORTS_FILTERS,
  payload,
});

export const goToSpecificPaginationPage = (pageNumber) => (dispatch) => {
  try {
    dispatch({
      type: actionTypes.GO_TO_SPECIFIC_PAGINATION_PAGE,
      payload: { pageNumber },
    });
  } catch (err) {
    errorHandler(err);
  }
};

export const changeGraphStatus = (payload) => ({
  type: actionTypes.CHANGE_GRAPH_STATUS,
  payload,
});

export const changeGraphModelStatus = (payload) => ({
  type: actionTypes.CHANGE_GRAPH_MODAL_STATUS,
  payload,
});

export const changeGraphProtocol = (payload) => ({
  type: actionTypes.CHANGE_GRAPH_PROTOCOL,
  payload,
});

export const changeProgressView = (payload) => ({
  type: actionTypes.CHANGE_PROGRESS_VIEW,
  payload,
});
