import { useMemo } from "react";
import { useSelector } from "react-redux";

export default function useSkipSignalCheck(trigger) {
  const signalCheckCompleted = useSelector(
    ({ sessionReducer }) => sessionReducer.signalCheckCompleted,
  );

  const skipAllowed = useMemo(() => {
    if (signalCheckCompleted) return (Date.now() - signalCheckCompleted) / 60000 <= 10;
    return false;
  }, [trigger]);

  return skipAllowed;
}
