const normalizedTime = (time) => {
  const [hours, minutes, seconds] = time.split(":").map((value) => +value);
  const normalized = {
    inHours: +(hours + (minutes / 60) + (seconds / 3600)).toFixed(1),
    inMinutes: +((hours * 60) + minutes + (seconds / 60)).toFixed(1),
    inSeconds: +((hours * 3600) + (minutes * 60) + seconds).toFixed(1),
  };
  return normalized;
};

export default normalizedTime;
