import { useRef, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { GenericButton, InfoBox } from "components";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass, faCircleNotch, faPause, faCircleExclamation, faCircleCheck, faAnglesDown,
} from "@fortawesome/free-solid-svg-icons";
import Scrollbar from "react-perfect-scrollbar-z";
import _ from "lodash";

import {
  getTestBatchDetails, resumeTestBatch, cancelTestBatch, getTestBatchesExists,
} from "api/services/coach";

import "features/CoachTable/CoachModal.scss";
import "./BatchDetailsModal.scss";

function BatchDetailsModal({
  batchId,
  showModal,
  setShowModal,
  batchNote = true,
}) {
  const { data: batchDetails, refetch } = useQuery({
    queryKey: ["batch-details", batchId, showModal],
    queryFn: () => getTestBatchDetails(batchId),
    enabled: !!batchId && showModal,
    refetchInterval: (query) => (query.state.data?.status > 1 ? 0 : 1000 * 30),
  });

  const { data: isBatchesProcessing, refetch: checkIsBusy } = useQuery({
    queryKey: ["batches-existance"],
    queryFn: () => getTestBatchesExists(),
  });

  const refScroll = useRef(null);
  const refTargetHeight = useRef(null);

  const [hasMoreTargets, setHasMoreTargets] = useState(false);
  const [canceling, setCanceling] = useState(false);

  const filteredScope = (scope) => {
    const groupedList = _.groupBy(scope, "protocolName");
    return Object.keys(groupedList).sort().map((key) => (
      `${key} [ Lvl ${groupedList[key].map((item) => item.levelId.split("/")[1]).sort().join("/")} ]`
    ));
  };

  const targetScope = (type) => {
    switch (type) {
      case "family":
        return ["Family"];
      case "level":
        return batchDetails.target.scope[0]?.protocolName
          && filteredScope(batchDetails.target.scope);
      case "eb":
        return [`E.B. ${batchDetails.target.scope}`];
      default: return null;
    }
  };

  const sessionStatus = {
    1: { name: "waiting", icon: faPause },
    2: { name: "external_processing", icon: faCircleNotch },
    3: { name: "internal_processing", icon: faCircleNotch },
    4: { name: "finished", icon: faCircleCheck },
    5: { name: "error", icon: faCircleExclamation },
    6: { name: "finished_warnings", icon: faCircleExclamation },
  };

  const onRetry = async () => {
    await resumeTestBatch(batchId);
    refetch();
  };

  const onCancel = async () => {
    setCanceling(true);
    await cancelTestBatch(batchId);
    refetch();
    setCanceling(false);
  };

  useEffect(() => {
    setHasMoreTargets(refTargetHeight.current?.scrollHeight > 50);
    if (showModal && batchDetails?.status > 2) {
      checkIsBusy();
    }
  }, [batchDetails]);

  return (
    <Modal
      animation
      show={showModal}
      dialogClassName="dialog"
      className="coach-modal wide"
      onHide={() => setShowModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Batch details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="batchDetails">
        {batchDetails && (
          <>
            <div className="batchDetails__info">
              <div className="batchDetails__info-details">
                <div>
                  <div>
                    Created at:
                    {" "}
                    <strong>{batchDetails.created}</strong>
                  </div>
                  <div>
                    Created by:
                    {" "}
                    <strong>{batchDetails.user}</strong>
                  </div>
                  <div>
                    Sessions number:
                    {" "}
                    <strong>{batchDetails.total}</strong>
                  </div>
                  {batchNote && (
                  <div>
                    Note:
                    {" "}
                    <strong>{batchDetails.note}</strong>
                  </div>
                  )}
                </div>

                <div className="batchDetails__info-status">
                  <div className={`batchDetails__info-status-name is-${canceling ? "Canceling" : batchDetails.status_name.replaceAll(" ", "_")}`}>
                    {canceling ? "Canceling" : batchDetails.status_name}
                  </div>
                  <div className="text-muted text-sm">status</div>
                </div>
              </div>
              {batchDetails?.target?.type && (
              <div className="batchDetails__info-target">
                Target:
                <ul ref={refTargetHeight} className={hasMoreTargets ? "has-more" : ""}>
                  {hasMoreTargets && <FontAwesomeIcon icon={faAnglesDown} />}
                  {targetScope(batchDetails.target.type)?.map((target) => (
                    <li key={target} className="text-sm">
                      {target}
                    </li>
                  ))}
                </ul>
              </div>
              )}
              {batchDetails.error && (
              <InfoBox type="danger" customClass="justify-content-center">
                <p>{batchDetails.error}</p>
              </InfoBox>
              )}
            </div>
            <Scrollbar refScroll={refScroll} always maxHeight="35vh">
              <table className="table">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Date</th>
                    <th>Original Protocol</th>
                    <th>Level</th>
                    <th>Family</th>
                    <th>Status</th>
                    <th aria-label="actions" />
                  </tr>
                </thead>
                <tbody>
                  {batchDetails.sessions.map((session) => (
                    <tr key={session.session_id}>
                      <td>{session.email}</td>
                      <td>{session.date}</td>
                      <td>{session.protocol_name}</td>
                      <td>{session.protocol_level}</td>
                      <td>{session.protocol_family}</td>
                      <td>{session.status_name}</td>
                      <td>
                        <div className="table__actions">
                          <OverlayTrigger
                            placement="left"
                            overlay={(
                              <Tooltip className={`batchDetails-tooltip is-${sessionStatus[session.status].name} ${session.status < 5 ? "hidden" : ""}`}>
                                {`${session.status === 5 ? "Error" : "Warning"}: ${session.error}`}
                              </Tooltip>
                            )}
                          >
                            <div className={`status is-${sessionStatus[session.status].name}`}>
                              <FontAwesomeIcon icon={sessionStatus[session.status].icon} />
                            </div>
                          </OverlayTrigger>
                          <Link
                            className="button"
                            to={`/coach/clients/${session.user_id}/session-summary/${session.session_id}`}
                            target="_blank"
                          >
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Scrollbar>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <GenericButton
          text="Close"
          onButtonClick={() => setShowModal(false)}
        />
        {batchDetails?.status === 1 && (
          <GenericButton
            text="Cancel"
            className="button--danger"
            onButtonClick={() => onCancel(batchId)}
            disabled={canceling}
          />
        )}
        {batchDetails?.status > 2 && (
          <GenericButton
            text={isBatchesProcessing ? "Another batch running" : "Retry"}
            className="button--primary"
            onButtonClick={() => onRetry(batchId)}
            disabled={isBatchesProcessing}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default BatchDetailsModal;
