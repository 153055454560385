import { useState, useRef, useMemo } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  InputText, EmptyData, GenericButton, Spinner,
} from "components";
import {
  faArrowUp, faTrashAlt, faComments, faLink,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Scrollbar from "react-perfect-scrollbar-z";
import _ from "lodash";

import {
  getResearchData,
  getResearchComments,
  addResearchComment,
  deleteResearchComment,
} from "api/services/coach";

import "react-perfect-scrollbar-z/build/styles.css";
import "features/CoachTable/CoachModal.scss";
import "./ResearchDetails.scss";

function ResearchDetails({
  researchId,
  showModal,
  setShowModal,
}) {
  const commentsScrollRef = useRef(null);
  const detailsScrollRef = useRef(null);
  const queryClient = useQueryClient();

  const [comment, setComment] = useState("");
  const [showDialog, setShowDialog] = useState(null);

  const { data: comments, isPending: isCommentsLoading } = useQuery({
    queryKey: ["research-comments", researchId],
    queryFn: () => getResearchComments(researchId),
    enabled: !!researchId,
    refetchOnMount: "always",
  });
  const { data: researchData, isPending: isResearchLoading } = useQuery({
    queryKey: ["research-title", researchId],
    queryFn: () => getResearchData(researchId),
    enabled: !!researchId,
  });

  const addComment = useMutation({
    mutationFn: () => addResearchComment(researchId, { content: comment.trim() }),
    onSuccess: async (response) => {
      queryClient.setQueryData(
        ["research-comments", researchId],
        (stale) => [...stale, response],
      );
      setComment("");
      setTimeout(() => {
        commentsScrollRef.current.element.scrollTop = commentsScrollRef.current.contentHeight;
      }, 300);
    },
  });

  const deleteComment = useMutation({
    mutationFn: (element) => deleteResearchComment(element.id),
    onSuccess: (_data, element) => {
      element.event.target.offsetParent.offsetParent.classList.add("deleting");
      setTimeout(() => {
        queryClient.setQueryData(
          ["research-comments", researchId],
          (stale) => stale.filter((item) => item.id !== element.id),
        );
      }, 300);
    },
  });

  const isValidUrl = (url) => _.startsWith(url, "https://") || _.startsWith(url, "http://");

  const researchDataElements = useMemo(() => [
    { name: "Year", data: researchData?.year },
    { name: "Author", data: researchData?.author },
    { name: "Category", data: researchData?.category },
    { name: "Technique", data: researchData?.technique },
    { name: "Expertise", data: researchData?.expertise },
    { name: "Paywall", data: researchData?.paywall },
  ], [researchData]);

  return (
    <Modal
      animation
      show={showModal}
      dialogClassName="dialog"
      className="coach-modal researchDetails wide"
      onHide={() => {
        setShowModal(false);
        setShowDialog(null);
        setComment("");
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Research details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Scrollbar refScroll={commentsScrollRef} always height="99.5%">
          {comments?.length > 0 && (
            <ul className="commentsList">
              {comments.map((item) => (
                <li className="commentsList__item" key={item.id}>
                  <div className="commentsList__item-data">
                    <p className={`commentsList__item-data-author${item.is_my ? "--owner" : ""} text-sm `}>{item.author_name}</p>
                    <p className="commentsList__item-data--date text-sm text-muted">{item.created}</p>
                  </div>
                  <div className="commentsList__item-content">
                    <p>{item.content}</p>
                    {item.is_my && (
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="commentsList__item-delete"
                        onClick={() => setShowDialog(item.id)}
                      />
                    )}
                  </div>
                  {item.is_my && showDialog === item.id && (
                  <div className="commentsList__item-dialog">
                    <p>Are you sure you want to permanently delete your comment?</p>
                    <div className="commentsList__item-dialog-actions">
                      <GenericButton
                        text="Cancel"
                        onButtonClick={() => setShowDialog(null)}
                      />
                      <GenericButton
                        text="Delete"
                        className="button--danger"
                        onButtonClick={(event) => deleteComment.mutate({ id: item.id, event })}
                      />
                    </div>
                  </div>
                  )}
                </li>
              ))}
            </ul>
          )}
          {isCommentsLoading && <Spinner />}
          {!isCommentsLoading && comments?.length === 0 && (
            <EmptyData
              icon={faComments}
              title="Empty"
              text="There are no comments for this research yet."
            />
          )}
        </Scrollbar>
        <Scrollbar className="" refScroll={detailsScrollRef} always height="100%">
          {researchData && (
            <div className="researchData">
              <h2>{researchData.title}</h2>
              <ul>
                {researchDataElements.map((item) => (
                  <li key={item.name}>
                    <strong>{`${item.name}: `}</strong>
                    {item.data || "-"}
                  </li>
                ))}
                {isValidUrl(researchData?.webpage) && (
                <li>
                  <Link
                    className="researchData__link"
                    to={researchData.webpage}
                    title={researchData.webpage}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faLink} />
                    Webpage
                  </Link>
                </li>
                )}
              </ul>
              <p className="researchData__abstract">
                <strong>Abstract:</strong>
                <br />
                {researchData.abstract || "-"}
              </p>
            </div>
          )}
          {isResearchLoading && <Spinner />}
        </Scrollbar>
      </Modal.Body>
      <Modal.Footer>
        {!isCommentsLoading && (
          <div className={`researchDetails__input-wrapper ${comment.trim() ? "active" : ""}`}>
            <InputText
              placeholder="Add comment..."
              defaultValue={comment}
              onChangeValue={(e) => setComment(e.target.value)}
              onIconClick={() => comment.trim() && addComment.mutate()}
              onEnterPress={() => comment.trim() && addComment.mutate()}
              icon={faArrowUp}
              withButton
            />
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ResearchDetails;
