import "./ButtonSwitch.scss";

export function ButtonSwitch({ children, pillsLayout, customClass = "" }) {
  return (
    <div className={`button-switch ${pillsLayout ? "nav-pills" : ""} ${customClass}`}>
      { children}
    </div>
  );
}

export default ButtonSwitch;
