import { faClockRotateLeft, faFaceFrownOpen } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as Trophy } from "assets/icons_trophy.svg";
import { ReactComponent as Warning } from "assets/icons_warning.svg";

const BRAINWAVE_STATUS = [
  {
    status: 1,
    status_name: "Processing",
    title: "Preparing metrics data",
    text: "Please wait for a moment...",
    icon: null,
  },
  {
    status: 2,
    status_name: "Finished",
  },
  {
    status: 3,
    status_name: "Error",
    title: "Error during data analysis",
    text: "We rerun the process...",
    icon: faClockRotateLeft,
  },
  {
    status: 4,
    status_name: "Aborted",
    title: "Analysis failed",
    text: "Contact GoDeeper to resolve the issue.",
    icon: faFaceFrownOpen,
  },
];

const FRQ_KEYS = ["delta", "theta", "alpha", "beta", "gamma"];
const ZONE_KEYS = ["whole", "frontal", "back", "left", "right"];
const FRQ_COLOR = {
  delta: "#FF3A44", theta: "#FF8975", alpha: "#32A071", beta: "#3B76F6", gamma: "#AF70FF",
};

const FRQ_RANGE = [
  { name: "delta", from: 2, to: 4 },
  { name: "theta", from: 4, to: 8 },
  { name: "alpha", from: 8, to: 12 },
  { name: "beta", from: 12, to: 35 },
  { name: "gamma", from: 25, to: 100 },
];

const FILTERS = {
  show_menu: false,
  quotes: false,
  intro: true,
  // location: true,
  // whole: true,
  // front_back: true,
  // left_right: true,
  // sd: false,
  sd_value: 1,
  coach: true,
  deeper_anatomy: false,
  deeper_frq: false,
};
const FILTERS_MENU = [
  // [
  //   { key: "intro", text: "Brain Frequency Introductions" },
  //   { key: "location", text: "Location-Frequency Interpretations" },
  // ],
  // [
  //   { key: "whole", text: "Whole Brain Changes" },
  //   { key: "front_back", text: "Front-Back Changes" },
  //   { key: "left_right", text: "Left-Right Changes" },
  //   { key: "sd", text: "Any Change Greater Than" },
  // ],
  [
    { key: "deeper_anatomy", text: "Deeper Brain Anatomy" },
    { key: "deeper_frq", text: "Deeper Brain Frequency Teachings" },
  ],
];

const FRQ_FILTER_DATA = {
  delta: true,
  theta: true,
  alpha: true,
  beta: true,
  gamma: true,
};

const renderTaps = (type) => (
  <div className={`tapIcon type-${type}`}>
    {[...Array(type)].map((_type, i) => <span key={i.toString()} />)}
  </div>
);

const ANALYZE_FILTER_DATA = [
  { text: "All", key: "all", preCertified: true },
  { text: renderTaps(1), key: "1", preCertified: true },
  { text: renderTaps(2), key: "2", preCertified: true },
  { text: renderTaps(3), key: "3", preCertified: true },
  { iconElement: <Trophy />, key: "trophy", preCertified: false },
  { iconElement: <Warning />, key: "warning", preCertified: false },
  { text: "G", key: "blue", preCertified: false },
  { text: "Y", key: "green", preCertified: false },
  { text: "R", key: "red", preCertified: false },
];

const ANALYZE_FILTERS_TOOLTIP = {
  all: "",
  1: "Deep",
  2: "Distracted",
  3: "Special",
  trophy: "Trophies",
  warning: "Reminders",
  blue: "Green Zone",
  green: "Yellow Zone",
  red: "Red Zone",
};

const ANALYZE_CONDITIONS = [
  {
    key: "all",
    condition: () => true,
  },
  {
    key: "trophy",
    condition: (item) => item === 2,
  },
  {
    key: "warning",
    condition: (item) => item === -2,
  },
  {
    key: "blue",
    condition: (item) => item >= 1,
  },
  {
    key: "green",
    condition: (item) => item === 0,
  },
  {
    key: "red",
    condition: (item) => item <= -1,
  },
];

export {
  ANALYZE_FILTER_DATA, ANALYZE_FILTERS_TOOLTIP, ANALYZE_CONDITIONS, FRQ_KEYS, ZONE_KEYS, FRQ_COLOR,
  FRQ_FILTER_DATA, FRQ_RANGE, FILTERS_MENU, FILTERS, BRAINWAVE_STATUS,
};
