import { toast } from "react-toastify";

const formValidator = (response, setFormErrors, onSuccess) => {
  const data = response?.response?.data;

  const printErrorList = (errorData) => (
    <>
      Form is not valid. Please fix the errors.
      {errorData
      && (
      <ul>
        {errorData.map((message, i) => (
          <li key={i.toString()}>
            {message}
          </li>
        ))}
      </ul>
      )}
    </>
  );

  if (response instanceof Error) {
    if (data?.message) toast.error(data?.message);
    if (data?.non_field_errors) toast.error(printErrorList(data?.non_field_errors));
    if (data) {
      setFormErrors(data);
    } else {
      toast.error(response.message);
    }
  } else {
    onSuccess?.();
  }
};

export default formValidator;
