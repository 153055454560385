import { Route, Routes } from "react-router-dom";

import Introduction from "pages/App/OnBoarding/Introduction/Introduction";
import OnboardingSteps from "pages/App/OnBoarding/Steps/OnboardingSteps";
import OnBoardingCompleted from "pages/App/OnBoarding/Completed/CompletedPage";

function Progress() {
  return (
    <Routes>
      <Route path="intro" element={<Introduction />} />
      <Route path="steps" element={<OnboardingSteps />} />
      <Route path="completed" element={<OnBoardingCompleted />} />
    </Routes>
  );
}

export default Progress;
