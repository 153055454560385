import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "components";
import { goToSpecificPaginationPage } from "store/actions/reporting";
import { getPaginationStatus } from "./helpers/paginationStatus";

function ProgressListPagination({ totalPages, totalNumber }) {
  const {
    pastSessionReportsCurrentPage,
  } = useSelector(({ reportingReducer }) => reportingReducer);

  const dispatch = useDispatch();

  const onPageChange = (event) => {
    dispatch(goToSpecificPaginationPage(event.selected + 1));
  };

  const paginagtionStatus = getPaginationStatus(
    pastSessionReportsCurrentPage,
    totalPages,
    totalNumber,
  );

  return (
    <Pagination
      onPageChange={onPageChange}
      pageCount={totalPages}
      initialPage={pastSessionReportsCurrentPage}
      paginagtionStatus={`${paginagtionStatus} Sessions`}
    />
  );
}

export default ProgressListPagination;
