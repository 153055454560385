import { toast } from "react-toastify";

const errorHandler = (error, dispatch, errorSelector) => {
  const data = error?.response?.data || error;
  const printListError = (errorData) => (
    <ul>
      {Object.entries(errorData).map(([key, msg]) => (
        <li key={key}>
          {key !== "non_field_errors" && (
            <>
              <strong>{key}</strong>
              :
              {" "}
            </>
          )}
          {msg}
        </li>
      ))}
    </ul>
  );
  if (data) {
    if (typeof data === "object" && !(data instanceof Error)) {
      if (data.code === "token_not_valid") {
        toast.error(`${data.detail}`);
      } else {
        toast.error(printListError(data));
      }
    } else {
      toast.error(`${data}`);
    }
  }
  console.log("errorHandler", data);
  if (errorSelector) dispatch(errorSelector(data));
};

export default errorHandler;
