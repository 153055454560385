import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { useMutation } from "@tanstack/react-query";

import { GenericButton, SubHeader, Dialog } from "components";
import {
  resetOnboarding, clearClientAccount, clearUserAccount, deleteUserAccount,
} from "api/services/settings";
import { setOnboardingTab } from "store/actions/onBoarding";
import { logoutUserSuccess } from "store/actions/authentication";

import "./ManageData.scss";

function ManageData() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUserId = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user.pk,
  );

  const [dialogType, setDialogType] = useState(null);
  const [dialogLoading, setDialogLoading] = useState(false);

  const onboardingReset = useMutation({
    mutationFn: () => resetOnboarding(),
    onSuccess: () => {
      dispatch(setOnboardingTab(null));
      navigate("/app");
    },
    onError: () => setDialogLoading(false),
  });
  const accountUserDelete = useMutation({
    mutationFn: () => deleteUserAccount(),
    onSuccess: () => {
      dispatch(logoutUserSuccess());
      navigate("/app");
    },
    onError: () => setDialogLoading(false),
  });
  const accountUserClear = useMutation({
    mutationFn: () => clearUserAccount(),
    onSuccess: () => navigate("/app"),
    onError: () => setDialogLoading(false),
  });
  const accountClientClear = useMutation({
    mutationFn: () => clearClientAccount(loggedInUserId),
    onSuccess: () => accountUserClear.mutate(),
    onError: () => setDialogLoading(false),
  });

  const dialog = {
    reset: {
      title: "Reset Onboarding",
      content: "Do you want to reset your Onboarding data and start it again?",
      onSuccess: () => {
        setDialogLoading(true);
        onboardingReset.mutate();
      },
    },
    clear: {
      title: "Clear Account",
      content: "Do you want to reset to factory defaults and clear all meditation history?",
      onSuccess: () => {
        setDialogLoading(true);
        accountClientClear.mutate();
      },
    },
    delete: {
      title: "Delete Account",
      content: "Are you sure you want to delete your whole account and data?",
      onSuccess: () => {
        setDialogLoading(true);
        accountUserDelete.mutate();
      },
    },
  };

  return (
    <Container>
      <div className="settingsPage manageData">
        <SubHeader
          title="Manage Data"
          text="Manage your account data."
        />
        <div className="manageData__btn-container">
          <GenericButton
            text="Reset Onboarding"
            onButtonClick={() => setDialogType("reset")}
          />
          <GenericButton
            text="Clear Account"
            onButtonClick={() => setDialogType("clear")}
          />
          <GenericButton
            className="button--danger"
            text="Delete Account"
            onButtonClick={() => setDialogType("delete")}
          />
        </div>
        <Dialog
          show={dialogType}
          title={dialog[dialogType]?.title}
          onSuccess={dialog[dialogType]?.onSuccess}
          onCancel={() => setDialogType(null)}
          loading={dialogLoading}
        >
          <p>{dialog[dialogType]?.content}</p>
        </Dialog>
      </div>
    </Container>
  );
}

export default ManageData;
