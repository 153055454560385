import { Form } from "react-bootstrap";
import {
  useState, useMemo, useRef,
} from "react";
import _ from "lodash";

import "./RangeInput.scss";

export function RangeInput({
  min,
  max,
  step,
  uiStep = step,
  customValues,
  field,
  value,
  onChange,
  onTouchStart,
  onTouchEnd,
}) {
  const steps = useMemo(() => {
    const array = [...Array(Math.ceil((max - min + 1) / uiStep)).keys()];
    return array.map((i) => (i + (min / uiStep)) * uiStep);
  }, [min, max, uiStep]);

  const [isTouch, setIsTouch] = useState(false);
  const rangeWrapper = useRef();

  const updateValue = (e) => {
    const element = e.type === "click" ? e.target : rangeWrapper.current;
    const xOffset = e.type === "click" ? e.pageX : e.touches[0].pageX;
    const xPosition = xOffset - element.getBoundingClientRect().left;
    const xProgress = _.clamp(xPosition / element.offsetWidth, 0, 1) * 100;
    const currentValue = (xProgress * max) / 100;
    const stepValue = _.round(currentValue / step) * step;
    let newValue = stepValue;
    if (customValues?.length) {
      const inRangeValues = customValues.filter(
        (customValue) => Math.abs(currentValue - customValue) <= step / 2,
      );
      inRangeValues.forEach((customValue) => {
        if (Math.abs(currentValue - customValue) < Math.abs(currentValue - newValue)) {
          newValue = customValue;
        }
      });
    }
    if (newValue !== value) onChange(newValue);
  };

  const handleTouch = _.throttle((e) => updateValue(e), 100);

  const startTouch = (e) => {
    setIsTouch(true);
    handleTouch(e);
    onTouchStart?.();
  };
  const endTouch = () => {
    setIsTouch(false);
    onTouchEnd?.();
  };
  const onClick = (e) => {
    updateValue(e);
    onTouchEnd?.(e);
  };

  const customColor = () => {
    const colors = {
      red_background_volume: "#DD4124",
      green_background_volume: "#ddb224",
      blue_background_volume: "#33bf9f",
      reward_volume: "#33bf9f",
      wondering_reminder_volume: "#DD4124",
      default: "#3F94CB",
    };
    return { background: colors[field] || colors.default };
  };

  return (
    <Form className={`input-range ${isTouch ? "is-active" : ""}`}>
      <div
        role="presentation"
        ref={rangeWrapper}
        className="input-range__wrapper"
        onClick={onClick}
      >
        <div className="input-range__progress-wrapper">
          <div
            className="input-range__progress-bar"
            style={{ ...customColor(), width: `${(value / (min + max)) * 100}%` }}
          />
          <div
            className={`input-range__thumb ${isTouch ? "is-active" : ""}`}
            style={{ left: `${(value / (min + max)) * 100}%` }}
            onTouchStart={(e) => startTouch(e)}
            onTouchMove={(e) => handleTouch(e)}
            onTouchEnd={endTouch}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className="input-range__steps">
          {steps.map((stepValue) => (
            <div
              key={stepValue}
              data-step={stepValue}
              className="input-range__steps-step"
            >
              <span
                className={`input-range__steps-handle ${
                  value === stepValue ? "is-active" : ""
                }`}
                style={value === stepValue ? customColor() : {}}
              />
              <span className="input-range__steps-value">
                {stepValue}
              </span>
            </div>
          ))}
        </div>
      </div>
    </Form>
  );
}

export default RangeInput;
