import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import "./StarsRating.scss";

export function StarsRating({
  label, value = 0, onChange, readonly,
}) {
  const onClick = (newValue) => {
    if (!readonly) onChange(newValue);
  };

  return (
    <div className="starsRating">
      {label && (
      <h4 className="starsRating__label">
        {label}
      </h4>
      )}
      <div className="starsRating__wrapper">
        {[...Array(5)].map((_star, i) => (
          <button
            type="button"
            key={i.toString()}
            className={`starsRating__button ${value >= i + 1 ? "is-active" : ""}`}
            onClick={() => onClick(i + 1)}
          >
            <FontAwesomeIcon icon={faStar} />
          </button>
        ))}
      </div>
    </div>

  );
}

export default StarsRating;
