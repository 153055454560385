import { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { isAllowed } from "utils/user-permissions";
import { Container } from "react-bootstrap";
import { CoachNav, DownloadButton, GenericButton } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

import { CoachTable } from "features";
import { getClientsSessions, getSessionsReport, getTestBatchesExists } from "api/services/coach";
import BatchCreator from "./components/BatchCreator";

import "pages/Coach/Dashboard/Dashboard.scss";

function Sessions() {
  const userLevel = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.subscription_level_id,
  );
  const isSuperCoach = isAllowed(userLevel, "SUPER_COACH");

  const { data: isBatchesProcessing } = useQuery({
    queryKey: ["batches-existance"],
    queryFn: () => getTestBatchesExists(),
    refetchOnMount: "always",
    enabled: isSuperCoach,
  });

  const [activeFilter, setActiveFilter] = useState({});
  const [totalRecords, setTotalRecords] = useState("");
  const [showModal, setShowModal] = useState(false);

  const getSessions = async (filter) => {
    setActiveFilter(filter);
    return getClientsSessions(filter);
  };

  const tableSettings = {
    title: "Sessions",
    tableName: "all-sessions",
    tableType: 2,
    getdata: (filter) => getSessions(filter),
    tabsGroup: "sessions",
    callbackTotal: setTotalRecords,
    stickyColumn: {
      title: "Summary",
      id: "summary",
      data: "sessionId",
      element: {
        icon: <FontAwesomeIcon icon={faChartLine} />,
        path: (clientId, sessionId) => `/coach/clients/${clientId}/session-summary/${sessionId}`,
      },
    },
  };

  return (
    <div className="coach-panel">
      <CoachNav />
      <div className="dashboard">
        <Container className="dashboard__header">
          <h1>Dashboard</h1>
        </Container>
        {isSuperCoach && (
        <Container>
          <div className="dashboard__actions">
            <GenericButton
              className="dashboard__actions-button text-sm"
              text={!isBatchesProcessing ? "Schedule test sessions" : "Tests in progress..."}
              disabled={isBatchesProcessing}
              onButtonClick={() => setShowModal(true)}
            />
            <DownloadButton
              fileName="sessions_report.zip"
              className="dashboard__actions-button text-sm"
              text="Download Reports"
              pendingText="Generating..."
              getDownloadUrl={() => getSessionsReport(activeFilter)}
            />
          </div>
        </Container>
        )}
        <CoachTable {...tableSettings} />
        {isSuperCoach && (
          <BatchCreator
            showModal={showModal}
            setShowModal={setShowModal}
            totalRecords={totalRecords}
            activeFilter={activeFilter}
          />
        )}
      </div>
    </div>
  );
}

export default Sessions;
