import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

import { Spinner } from "components";
import { getCalibratedProtocols, getProtocols } from "store/actions/protocols";
import { getUserProfile, saveUserProfile } from "store/actions/profile";
import { setAppActive, setDevice, sendDataStream } from "store/actions/session";
import { getOnBoardingChoices } from "store/actions/onBoarding";
import { getUserInfo, logoutUserSuccess } from "store/actions/authentication";
import { authController } from "utils/native-controllers";

import brandIcon from "assets/icon.png";

import "./Loader.scss";
import { updateProtocolCertificationQuery } from "api/services/protocols";
import { updatePastProtocolsQuery } from "api/services/reporting";

function IntroductionPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const user = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user,
  );
  const userId = useSelector(
    ({ authenticationReducer }) => authenticationReducer.user?.pk,
  );
  const { isUserLoggedIn, updateUserError } = useSelector(
    ({ authenticationReducer }) => authenticationReducer,
  );
  const userProfile = useSelector(
    ({ profileReducer }) => profileReducer.userProfile,
  );
  const { protocols, allProtocols } = useSelector(({ protocolsReducer }) => protocolsReducer);

  window.pushToast = ({ message, type }) => {
    toast(`${message}`, { type });
  };
  window.setAppActive = (state) => {
    dispatch(setAppActive(state));
  };
  window.setBtDevice = (device) => {
    dispatch(setDevice(device));
  };
  window.publishEEGData = (payload) => {
    dispatch(sendDataStream(userId, payload));
  };
  window.nativeBack = () => false;

  useEffect(() => {
    console.log(`front: ${window.location.origin}`);
    console.log(`back: ${process.env.REACT_APP_API_URL}`);
    if (isUserLoggedIn) {
      dispatch(getUserInfo());
    } else {
      navigate("/app/login");
    }
  }, []);

  useEffect(() => {
    if (updateUserError) {
      dispatch(logoutUserSuccess());
      navigate("/app/login");
    }
  }, [updateUserError]);

  useEffect(() => {
    if (userId) {
      authController(user);
      Sentry.setUser({
        id: userId,
        email: user.email,
        username: `${user.first_name} ${user.last_name}`,
      });
      dispatch(getUserProfile());
      dispatch(getOnBoardingChoices());
      dispatch(getProtocols());
      dispatch(getCalibratedProtocols(userId));
    }
  }, [userId]);

  useEffect(() => {
    const saveDeviceToken = async () => {
      if (!window?.appInfo?.token) return;
      await dispatch(saveUserProfile({
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        device_token: window.appInfo.token,
      }));
    };
    const updateCertification = async () => {
      if (userProfile?.selected_protocol.levelID) {
        await updateProtocolCertificationQuery(
          userProfile?.selected_protocol.levelID,
          userProfile?.selected_protocol.family,
          userProfile?.selected_protocol.rule,
          queryClient,
        );
      }
    };
    const updatePastProtocols = async () => {
      await updatePastProtocolsQuery(queryClient);
    };
    if (userProfile?.onboarding_complete !== undefined && protocols && allProtocols) {
      saveDeviceToken();
      updateCertification();
      updatePastProtocols();
      setTimeout(() => {
        if (userProfile?.onboarding_complete === true) {
          navigate("/app/meditation");
        } else {
          navigate("/app/onboarding/intro");
        }
      }, 1000);
    }
  }, [userProfile, protocols, allProtocols]);

  return (
    <div className="loaderPage__loading">
      <div className="loaderPage__loader">
        <img src={brandIcon} alt="brand icon" />
        <Spinner />
      </div>
    </div>
  );
}

export default IntroductionPage;
