import { memo, useEffect, useState } from "react";

import { GenericButton, Textarea } from "components";
import { Spinner } from "components/ui/Spinner/Spinner";
import { Modal, Button } from "react-bootstrap";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { deleteSessionTap, updateSessionTap } from "api/services/reporting";
import { useSelector } from "react-redux";

const tapsData = [
  {
    type: 1,
    name: "Once",
    text: "after deep moments",
  },
  {
    type: 2,
    name: "Twice",
    text: "after distracting moments",
  },
  {
    type: 3,
    name: "Thrice",
    text: "to flag special moment",
  },
];

const TapEdit = memo(({
  tapToEdit, sessionId, show, setShow, type,
}) => {
  const queryClient = useQueryClient();
  const {
    pastSessionReportsOrder,
    pastSessionReportsFilters,
    pastSessionReportsCurrentPage,
  } = useSelector(({ reportingReducer }) => reportingReducer);

  const [formData, setFormData] = useState([
    { key: "type" },
    { key: "timestamp" },
    { key: "notes" },
    { key: "brainwave_api_session_id" },
  ], {
    type: tapToEdit?.type || null,
    timestamp: tapToEdit?.timestamp ?? null,
    notes: tapToEdit?.notes || null,
    brainwave_api_session_id: sessionId,
  });

  useEffect(() => {
    if (show) {
      setFormData({
        type: tapToEdit?.type || null,
        timestamp: tapToEdit?.timestamp ?? null,
        notes: tapToEdit?.notes || null,
        brainwave_api_session_id: sessionId,
      });
    }
  }, [show]);

  const updateTapsQuery = (taps) => {
    const countTaps = taps.reduce(
      (acc, tap) => { acc[tap.type - 1] += 1; return acc; },
      [0, 0, 0],
    );
    const listTaps = countTaps.map((count, index) => ({ type: index + 1, count }))
      .filter(({ count }) => count > 0);
    queryClient.setQueryData(
      ["session-summary", sessionId],
      (state) => ({ ...state, sessionData: { ...state.sessionData, taps } }),
    );
    queryClient.setQueryData(
      ["past-sessions",
        pastSessionReportsOrder,
        pastSessionReportsFilters,
        pastSessionReportsCurrentPage,
      ],
      (state) => ({
        ...state,
        pastSessionReports: state.pastSessionReports.map(
          (session) => (sessionId === session.sessionId
            ? { ...session, taps: listTaps } : session),
        ),
      }),
    );
  };

  const saveTap = useMutation({
    mutationFn: () => updateSessionTap(formData),
    onSuccess: async (data) => {
      updateTapsQuery(data);
      toast.success(`${type === "note" ? "Note" : "Marker"} has been saved successfully.`);
      setShow(null);
    },
  });

  const deleteTap = useMutation({
    mutationFn: () => deleteSessionTap(sessionId, formData.timestamp),
    onSuccess: async (data) => {
      updateTapsQuery(data);
      toast.success(`${type === "note" ? "Note" : "Marker"} has been deleted successfully.`);
      setShow(null);
    },
  });

  const onSubmit = () => {
    if (formData.type !== tapToEdit.type || formData.notes !== tapToEdit.notes) {
      saveTap.mutate();
    } else {
      setShow(null);
    }
  };

  return (
    <Modal
      animation
      className="tapsDialog"
      dialogClassName={`dialog-xs ${deleteTap.isPending || saveTap.isPending ? "is-loading" : ""}`}
      show={show}
      onHide={() => setShow(null)}
    >
      <Modal.Header closeButton>
        <h2>{type === "note" ? "Add Note" : "Edit Marker"}</h2>
      </Modal.Header>
      <Modal.Body>
        {type !== "note" && (
        <div className="tapsDialog__tapsList">
          <strong className="text-sm">
            Change marker type:
          </strong>
          <div className="tapsList mb-4">
            {tapsData.map((tap) => (
              <div
                role="presentation"
                key={tap.type}
                className={`tapsList__item type-${tap.type} ${tap.type === formData.type ? "is-active" : ""}`}
                onClick={() => setFormData((state) => ({ ...state, type: tap.type }))}
              >
                <div className="tapsList__item-icon">
                  {tap.type}
                  <div className={`tapIcon type-${tap.type}`}>
                    {[...Array(tap.type)].map((_type, i) => <span key={i.toString()} />)}
                  </div>
                </div>
                <div>
                  <strong>{tap.name}</strong>
                  {" "}
                  {tap.text}
                </div>
              </div>
            ))}
          </div>
        </div>
        )}
        <div className="tapsDialog__noteForm">
          {type !== "note" && (
            <strong className="text-sm">
              Edit note:
            </strong>
          )}
          <Textarea
            placeholder="Type note..."
            defaultValue={formData.notes || ""}
            onChangeValue={(e) => setFormData({ ...formData, notes: e.target.value || null })}
          />
          <button
            type="button"
            className="tapsDialog__noteForm-clear"
            onClick={() => setFormData({ ...formData, notes: null })}
          >
            Clear
          </button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={`button button--${type === "note" ? "cancel" : "delete"}`}
          onClick={type === "note"
            ? () => setShow(null)
            : () => deleteTap.mutate()}
          disabled={deleteTap.isPending || saveTap.isPending}
        >
          {type === "note"
            ? "Cancel"
            : (
              <>
                Delete Marker
                <span>*Cannot be undone</span>
              </>
            )}
        </Button>
        <GenericButton
          text="Save"
          className="button--success"
          onButtonClick={onSubmit}
          disabled={deleteTap.isPending || saveTap.isPending}
        />
      </Modal.Footer>
      {(deleteTap.isPending || saveTap.isPending) && <Spinner fixed />}
    </Modal>
  );
});

export default TapEdit;
